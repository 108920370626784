/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import clx from 'classnames';
import Base from '@material-ui/core/Typography';
import { capitalize } from '../button/button';
import withStyles from '../../withStyles';

const styles = theme => ({
  colorSuccess: {
    color: theme.palette.success.main,
  },
});

const muiColors = [
  'initial',
  'inherit',
  'primary',
  'secondary',
  'textPrimary',
  'textSecondary',
  'error',
];
const Typography = React.forwardRef(
  ({ className, color, classes, ...rest }, ref) => {
    const c = clx(className, {
      [classes[`color${capitalize(color)}`]]: color !== 'initial',
    });

    return (
      <Base
        ref={ref}
        className={c}
        /** If we pass a non-standard color value to MUI Typography we'll get a propTypes warning. */
        color={muiColors.includes(color) ? color : 'initial'}
        {...rest}
      />
    );
  }
);

Typography.propTypes = {
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),

  children: PropTypes.node,

  classes: PropTypes.object.isRequired,

  className: PropTypes.string,

  color: PropTypes.oneOf([
    'initial',
    'inherit',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
    'error',
    'success',
  ]),

  component: PropTypes.elementType,

  display: PropTypes.oneOf(['initial', 'block', 'inline']),

  gutterBottom: PropTypes.bool,

  noWrap: PropTypes.bool,

  paragraph: PropTypes.bool,

  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'srOnly',
    'inherit',
  ]),

  variantMapping: PropTypes.object,
};

Typography.defaultProps = {
  align: 'inherit',
  children: undefined,
  className: undefined,
  color: 'initial',
  component: undefined,
  display: 'initial',
  gutterBottom: false,
  noWrap: false,
  paragraph: false,
  variant: 'body1',
  variantMapping: undefined,
};

export default withStyles(styles, { name: 'MuiTypography' })(Typography);
