/* eslint-disable camelcase */
import { compose, withHandlers, withProps } from 'recompose';

import { connect } from '../redux';
import { selectors } from '../redux/reducers/index.reducer';
import { isEmptyObject } from '../util/helpers';

export const mapStateToProps = state => ({
  isOrgAdmin: selectors.getIsOrgAdmin(state),
  permissions: selectors.getPermissions(state),
  features: selectors.getFeatures(state),
  org: selectors.getOrg(state),
});

/**
  * checks if current user is allowed to perform action on a resource
  * ex: can('view', 'assets'); can('view', 'asset', '1')
  @param {string} action - string describing an action to be performed
  @param {string} rosourceType - string describing rosource type
  @param {string} rosourceId? - string describing rosource id. Optional
  @returns {boolean}
*/
export const can = ({ permissions }) => (
  action,
  resourceType,
  resourceId = null
) => {
  // TODO
  console.log('TODO can', action, resourceType, resourceId, permissions);
  return true;
};

/**
  * checks if current user is allowed to access a feature
  * ex: canAccessFeature(Features.SHOP_VEHICLES.INDEX)
  @param {string} feature - string describing a feature to be accessed
  @returns {(feature: string) => boolean}
*/
export const canAccessFeature = ({ features }) => feature => {
  if (!features) return false;
  return features.findIndex(f => f === feature) >= 0;
};

export const canAccessService = ({ org }) => serviceName => {
  if (!org || !serviceName) return false;
  return org.services.findIndex(service => service.name === serviceName) >= 0;
};

export const canAccessFullMenu = org => {
  if (org) {
    let hasAccessToMenu = false;
    if (org.data && org.data.address && org.data.contacts) {
      hasAccessToMenu =
        !isEmptyObject(org.data.address) && !isEmptyObject(org.data.contacts);
    }
    return hasAccessToMenu;
  }
  return false;
};

export const hasAssetBasedService = org => {
  if (!org?.services) {
    return false;
  }
  return org.services.some(service => service?.basis?.includes('asset'));
};

/**
 * Higher Order Componeent to manage permissions.
 */
export default compose(
  connect(mapStateToProps),
  withHandlers({
    can,
    canAccessFeature,
    canAccessService,
  }),
  withProps(({ org }) => ({
    canAccessFullMenu: canAccessFullMenu(org),
    hasAssetBasedService: hasAssetBasedService(org),
  }))
);
