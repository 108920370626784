/**
 *
 * @typedef {Object} Filter
 * @property {string} id
 * @property {(number|string|Date)} value
 * @property {string} [operator]
 */

import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useReducer } from 'react';

const FilteringContext = createContext({
  filters: [],
  lastInteractedField: null,
  setState: () => {},
  addFilter: () => {},
  removeByValue: () => {},
  removeByOperator: () => {},
  clearFilter: () => {},
});

const filteringReducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return {
        filters: action.keepFields
          ? state.filters.filter(f => action.keepFields.includes(f.id))
          : [],
        lastInteractedField: null,
      };
    case 'addFilter': {
      const { id, operator } = action;
      let { value } = action;
      if (typeof value === 'undefined') value = true;
      return {
        filters: state.filters.concat({ id, value, operator }),
        lastInteractedField: id,
      };
    }

    case 'removeByValue': {
      const { id, value } = action;
      return {
        filters: state.filters.filter(f => !(f.id === id && f.value === value)),
        lastInteractedField: id,
      };
    }

    case 'removeByOperator': {
      const { id, operator } = action;
      return {
        filters: state.filters.filter(
          f => !(f.id === id && f.operator === operator)
        ),
        lastInteractedField: id,
      };
    }

    case 'clearFilter': {
      const { id } = action;
      return {
        filters: state.filters.filter(f => f.id !== id),
        lastInteractedField: id,
      };
    }

    case 'replaceByOperators': {
      const { id, valuesWithOps } = action;

      let newFilters = [...state.filters];
      valuesWithOps.forEach(({ value, operator }) => {
        newFilters = newFilters.filter(
          f => !(f.id === id && f.operator === operator)
        );
        if (typeof value === 'undefined') value = true;
        newFilters = newFilters.concat({ id, value, operator });
      });
      return {
        filters: newFilters,
        lastInteractedField: id,
      };
    }

    default:
      return state;
  }
};

export const FilteringProvider = ({
  filters: initFilters,
  lastInteractedField: initLastInteractedField,
  ...props
}) => {
  const [{ filters, lastInteractedField }, dispatch] = useReducer(
    filteringReducer,
    {
      filters: initFilters,
      lastInteractedField: initLastInteractedField,
    }
  );

  const ctx = useMemo(
    () => ({
      filters,
      lastInteractedField,
      reset: (keepFields = []) => dispatch({ type: 'reset', keepFields }),
      addFilter: (id, value, operator) =>
        dispatch({ type: 'addFilter', id, value, operator }),
      removeByValue: (id, value) =>
        dispatch({ type: 'removeByValue', id, value }),
      removeByOperator: (id, operator) =>
        dispatch({ type: 'removeByOperator', id, operator }),
      clearFilter: id => dispatch({ type: 'clearFilter', id }),
      replaceByOperators: (id, valuesWithOps) =>
        dispatch({ type: 'replaceByOperators', id, valuesWithOps }),
    }),
    [filters, lastInteractedField, dispatch]
  );

  return <FilteringContext.Provider value={ctx} {...props} />;
};

FilteringProvider.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape()),
  lastInteractedField: PropTypes.string,
};

FilteringProvider.defaultProps = {
  filters: [],
  lastInteractedField: null,
};

/**
 * @typedef {Object} UseFiltering
 * @property {Array<Filter>} filters
 * @property {(keepFields?: string[]) => void} reset
 * @property {(id: string, value: (string | number | Date), operator?: string) => void} addFilter
 * @property {(id: string, value: string | number | Date) => void} removeByValue
 * @property {(id: string, operator: string) => void} removeByOperator
 * @property {(id: string) => void} clearFilter
 * @property {(id: string, valuesWithOps: any) => void} replaceByOperators
 */

/**
 * @return {UseFiltering}
 */
export function useFiltering() {
  const ctx = useContext(FilteringContext);

  if (!ctx) {
    throw new Error(
      `useFiltering must be called in a child of FilteringContext.Provider.`
    );
  }

  return ctx;
}
