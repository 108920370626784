import toArray from '../fp/toArray';
import { FILTER_TYPES, isFilterable } from '../use-filtering';
import { isDefined, isString } from '../util/helpers';
import { fromRangeQueryParam, toRangeQueryParam } from './citrus-framework';

/**
 * Converts an object of of query parameter keys and values into a state object usable by CitrusFramework.
 * Now that the underlying states and contexts are split it might make more sense to break this apart, but
 * it is as it is for now.
 *
 * @param {Object} searchObject
 * @param {Object} view
 */
export const generateInitialState = (searchObject, view) => {
  /**
   * {
   *    searchTerm: 'nancy',
   *    sortDirection: 'desc',
   *    sortField: 'name',
   *    take: '15',
   *    skip: '0',
   * }
   */
  const { fields, defaultSortDirection, defaultSortField, take } = view;

  const {
    searchTerm: querySearchTerm,
    skip: querySkip,
    sortDirection: querySortDirection,
    sortField: querySortField,
    take: queryTake,
    lastInteractedField: queryLastInteractedField,
  } = searchObject;

  const state = {};

  /** query values are strings and must be coherced to numbers. */
  if (isString(queryTake) && Number(queryTake) !== take) {
    state.take = Number(queryTake);
  }

  /** query values are strings and must be coherced to numbers. */
  if (isString(querySkip) && Number(querySkip) > 0) {
    state.skip = Number(querySkip);
  }

  if (querySortDirection && querySortDirection !== defaultSortDirection) {
    state.sortDirection = querySortDirection;
  }

  if (querySortField && querySortField !== defaultSortField) {
    state.sortField = querySortField;
  }

  if (querySearchTerm && querySearchTerm !== '') {
    state.searchTerm = querySearchTerm;
  }

  if (queryLastInteractedField && queryLastInteractedField !== '') {
    state.lastInteractedField = queryLastInteractedField;
  }

  state.filters = fields.filter(isFilterable).flatMap(field => {
    const {
      id: fieldId,
      filter: { type: filterType, operator: filterOperator },
    } = field;

    const queryIdentifiers = Array.isArray(filterOperator)
      ? filterOperator.map(op => toRangeQueryParam(fieldId, op))
      : [fieldId];

    return (
      queryIdentifiers
        /**
         *
         */
        .filter(queryIdentifier => isDefined(searchObject[queryIdentifier]))
        /**
         *  We have to flat map because we need one filter object per filter. The MANY and RANGE
         * filters may returnan array of filter objects and this flattens that response.
         */
        /**
         * {
         *  year~lte: 2020,
         *  year~gte: 1920,
         *  assetStatus: 'Active',
         *  make: Acura
         *  make: [Acura, Honda]
         * }
         */
        .flatMap(queryIdentifier => {
          const searchValue = searchObject[queryIdentifier];
          switch (filterType) {
            /**
             * The ONE values are found in the search string as `?id=value`, and are converted to
             * { id: value }.
             */
            case FILTER_TYPES.ONE:
              return [{ id: queryIdentifier, value: searchValue }];

            /**
             * The MANY values are found in the search string as one or many `?id=value` and are
             * converts to either { id: value } or { id: [some, many, values] }. We coerce to an array
             * and map to a filter.
             */
            case FILTER_TYPES.MANY:
              return toArray(searchValue).map(value => ({
                id: queryIdentifier,
                value,
              }));

            /**
             * The range values are stored in the search query as `?id~operator=value`, and URIJS converts.
             * to { id~operator: value }.
             * To keep the dates are stored as milliseconds from the UNIX epoch using Date.prototype.getTime,
             * so we just parse that string to a number and supply it as the only arg to the Date constructor.
             *
             *  year~gte: 1920,
             *  year~lte: 2020,
             */
            case FILTER_TYPES.RANGE:
            case FILTER_TYPES.SELECT_RANGE: {
              const [id, operator] = fromRangeQueryParam(queryIdentifier); // [year, lte]
              return Array.of({
                id,
                operator,
                value:
                  field.type === 'date'
                    ? new Date(Number(searchValue))
                    : Number(searchValue),
              });
            }

            /**
             * The DISTANCE_FROM_ZIP values are stored in the search query as "distance"from"zip"+"lat"+"lon", and URIJS converts to
             * { id: year, operator: 'closeTo', value: { zip, lat, lon, distance } },.
             */
            case FILTER_TYPES.DISTANCE_FROM_ZIP: {
              const [distance, location] = searchValue.split('from');
              const [zip, lat, lon] = location.split(' ');
              return [
                {
                  id: queryIdentifier,
                  operator: field.filter?.operator,
                  value: { zip, lat: +lat, lon: +lon, distance: +distance },
                },
              ];
            }

            default:
              throw new Error(`Invalid field filter type ${filterType} found.`);
          }
        })
    );
  });

  return state;
};

export default generateInitialState;
