import React from 'react';
import PropTypes from 'prop-types';
import Table from '~/shared/components/table';
import TableBody from '~/shared/components/table-body';
import TableRow from '~/shared/components/table-row';
import TableHead from '~/shared/components/table-head';
import makeStyles from '~/shared/components/makeStyles';

const useStyle = makeStyles(theme => ({
  hsbsTable: {
    '& tbody': {
      '& tr:nth-child(odd)': {
        backgroundColor: theme.palette.backgroundColors.blue,
      },
    },
    marginBottom: theme.spacing(2),
    '& td': {
      padding: ({ denseTable }) =>
        denseTable ? theme.spacing(1.5, 0.5) : theme.spacing(2),
    },
    '& th': {
      padding: ({ denseTable }) =>
        denseTable ? theme.spacing(1.5, 0.5) : theme.spacing(2),
    },
  },
}));

const HSBSTable = props => {
  const { headerRows, bodyRows, id } = props;
  // "denseTable" is an optional boolean prop passed in from the main page; it is used in the styles to override default padding
  const classes = useStyle(props);

  return (
    <Table className={classes.hsbsTable} id={id}>
      <TableHead>
        <TableRow>{headerRows}</TableRow>
      </TableHead>
      <TableBody>{bodyRows}</TableBody>
    </Table>
  );
};

HSBSTable.propTypes = {
  headerRows: PropTypes.element.isRequired,
  bodyRows: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  id: PropTypes.string,
};

HSBSTable.defaultProps = {
  bodyRows: undefined,
  id: '',
};

export default HSBSTable;
