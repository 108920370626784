import React from 'react';

import useSafeDispatch from './use-safe-dispatch';

function asyncReducer(state, action) {
  switch (action.type) {
    case 'pending': {
      return { status: 'pending', data: state.data, error: null };
    }
    case 'resolved': {
      return { status: 'resolved', data: action.data, error: null };
    }
    case 'rejected': {
      return { status: 'rejected', data: null, error: action.error };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default function useAsync(initialState) {
  const [state, unsafeDispatch] = React.useReducer(asyncReducer, {
    status: 'idle',
    data: null,
    error: null,
    ...initialState,
  });

  const dispatch = useSafeDispatch(unsafeDispatch);

  const { data, error, status } = state;

  const run = React.useCallback(
    promise => {
      dispatch({ type: 'pending' });
      promise.then(
        promiseData => {
          dispatch({ type: 'resolved', data: promiseData });
        },
        promiseError => {
          dispatch({ type: 'rejected', error: promiseError });
        }
      );
    },
    [dispatch]
  );

  return React.useMemo(
    () => ({
      error,
      status,
      data,
      run,
    }),
    [error, status, data, run]
  );
}
