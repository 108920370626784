/* eslint-disable no-unused-vars */
import pluralize from 'pluralize';
import { capitalizeFirstLetter } from './util';

export function createAllReducer(entity, options) {
  const initialState = {
    lastUpdated: 0,
    isFetching: false,
    ids: [],
    messages: [],
    failed: false,
  };

  const singularActionEntity = entity.toUpperCase();
  const pluralEntity = pluralize(entity);
  const actionEntity = pluralEntity.toUpperCase();

  return (state = initialState, action) => {
    switch (action.type) {
      case `ADD_${singularActionEntity}_SUCCESS`:
        return {
          ...state,
          isFetching: false,
          ids: [...state.ids, action[entity].id],
          messages: action.messages || [],
        };

      case `REMOVE_${singularActionEntity}_SUCCESS`:
        return {
          ...state,
          isFetching: false,
          ids: state.ids.filter(id => id !== action.id),
          messages: action.messages || [],
        };

      case `FETCH_${actionEntity}_REQUEST`:
        return {
          ...state,
          isFetching: true,
          messages: [],
          failed: false,
        };

      case `FETCH_${actionEntity}_SUCCESS`:
        return {
          ...state,
          lastUpdated: Date.now(),
          isFetching: false,
          ids: action[pluralEntity].map(record => record.id),
          messages: action.messages || [],
        };

      case `FETCH_${actionEntity}_FAILURE`:
        return {
          ...state,
          isFetching: false,
          failed: true,
          messages: action.errors,
        };

      default:
        return state;
    }
  };
}

export function createAllSelectors(entity) {
  const entityCap = capitalizeFirstLetter(entity);
  const pluralEntityCap = pluralize(entityCap);

  return {
    [`get${entityCap}Ids`]: state => state.ids,
    [`getIsFetching${pluralEntityCap}`]: state => state.isFetching,
    [`getLastUpdated${pluralEntityCap}`]: state => state.lastUpdated,
    [`get${pluralEntityCap}Messages`]: state => ({
      failed: state.failed,
      messages: state.messages,
    }),
  };
}
