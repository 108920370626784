import { createContext, useContext } from 'react';

/**
 * Context that stores data specific to processing.
 * It is shared by various providers.
 * But regardless of provider implementation, all component should use the useJuice and useJuiceFilters hooks.
 */
export const JuiceContext = createContext();
export const JuiceFiltersContext = createContext();

export function useJuice() {
  const ctx = useContext(JuiceContext);

  if (!ctx) {
    throw new Error(`useJuice must be used within a juice provider`);
  }

  return ctx;
}

export function useJuiceFilters() {
  const ctx = useContext(JuiceFiltersContext);

  if (!ctx) {
    throw new Error(`useJuiceFilters must be used within a juice provider`);
  }

  return ctx;
}
