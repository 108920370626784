/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import States from '@holmanfm/lib/common/us-state-list.json';
import DateHelpers from '@holmanfm/lib/common/date-helpers';
import { updateAsset } from '@holmanfm/lib/services/assets';
import { DatePicker } from '@material-ui/pickers';
import { removeInputPunctuation } from '@holmanfm/lib/util/helpers';
import { useNSTranslation } from '@holmanfm/lib/lang';
import TextField from '~/shared/components/forms/text-field';
import Select, { MenuItem } from '~/shared/components/forms/select';
import Button from '~/shared/components/atom/button';
import makeStyles from '~/shared/components/makeStyles';
import ProgressButton from '~/shared/components/progress-btn';
import ServerMessages from '~/shared/components/messages/server-messages';

const useStyles = makeStyles(theme => ({
  editRows: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '& > div:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const RegistrationInformationEditForm = props => {
  const { data, closeEditing, setHasDataUpdated, hasDataUpdated } = props;
  const [ApiError, setApiError] = React.useState(null);
  const { t } = useNSTranslation('individualAsset', 'overview');

  const [selectedDate, setSelectedDate] = React.useState(
    data?.registration_renew_date || null
  );

  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        licensePlate: data?.license_plate || '',
        licenseState: data?.license_state || '',
        registrationExp: data?.registration_renew_date || '',
        tempTagNumber: data?.tempTagNumber || '',
      }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        const updatedValues = {
          id: data?.id,
          licensePlate: values?.licensePlate
            ? values.licensePlate.toUpperCase()
            : '',
          licenseState: values.licenseState,
          registrationRenewDate: values?.registrationExp
            ? values.registrationExp
            : null,
          tempTagNumber: values?.tempTagNumber
            ? values?.tempTagNumber.toUpperCase()
            : '',
        };
        updateAsset(updatedValues)
          .then(async () => {
            setSubmitting(false);
            setTimeout(() => {
              setHasDataUpdated(!hasDataUpdated);
              closeEditing();
            }, 3000);
          })
          .catch(err => {
            setFieldError({ err });
            setApiError(err);
            setSubmitting(false);
          });
      }}
    >
      {FormikBag => {
        const {
          handleSubmit,
          setFieldValue,
          errors,
          values,
          handleChange,
          isSubmitting,
        } = FormikBag;

        const handleDateChange = date => {
          if (date === null) {
            setFieldValue('registrationExp', '');
            setSelectedDate(date);
          } else {
            setFieldValue('registrationExp', date);
            setSelectedDate(date);
          }
        };

        return (
          <Form>
            <div className={classes.editRows}>
              <Select
                name="licenseState"
                id="licenseState"
                label={t('license-plate-state')}
                variant="outlined"
                displayEmpty
                notched
                style={{ flex: 1 }}
              >
                <MenuItem value="" key="null">
                  &nbsp;
                </MenuItem>
                {States.map(state => (
                  <MenuItem value={state.value} key={state.value}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                name="licensePlate"
                label={t('license-plate')}
                variant="outlined"
                style={{ flex: 1 }}
              />
            </div>
            <div className={classes.editRows}>
              <DatePicker
                clearable
                openTo="year"
                label={t('registration-exp-date')}
                views={['year', 'month', 'date']}
                value={selectedDate}
                placeholder="MM/DD/YYYY"
                onChange={date => handleDateChange(date)}
                minDate={new Date()}
                maxDate={new Date(DateHelpers.maxDate())}
                format="MM/DD/YYYY"
                error={!!errors?.registrationExp}
                invalidDateMessage={
                  values.registrationExp !== ''
                    ? errors?.registrationExp || null
                    : ''
                }
                style={{ flex: 1 }}
                inputVariant="outlined"
                inputProps={{
                  className: 'browser-default',
                }}
                initialFocusedDate={new Date().setDate(0)}
                fullWidth
              />
              <TextField
                name="tempTagNumber"
                onChange={e => {
                  const { value, name } = e.target;
                  handleChange({
                    target: { value: removeInputPunctuation(value), name },
                  });
                }}
                label={t('temp-tag-number')}
                variant="outlined"
                style={{ flex: 1 }}
              />
            </div>
            <div>
              <ProgressButton
                btnTitle={t('update-registration-information')}
                submitFunc={handleSubmit}
                processing={isSubmitting}
              />
              <Button onClick={closeEditing} variant="text" color="secondary">
                {t('cancel')}
              </Button>
              {ApiError && <ServerMessages messages={ApiError} />}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

RegistrationInformationEditForm.propTypes = {
  data: PropTypes.shape({}).isRequired,
  closeEditing: PropTypes.func.isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  hasDataUpdated: PropTypes.bool,
};

RegistrationInformationEditForm.defaultProps = {
  hasDataUpdated: undefined,
};

export default RegistrationInformationEditForm;
