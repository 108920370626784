// money utility functions

/**
 * parse money string to float number, i.e. "$1,234.56" => 1234.56
 * if the value is not a number then return an empty string
 */
export const moneyToFloat = money => {
  const parsedVal = parseFloat(money?.replace('$', '').replace(/,/g, ''));
  return !Number.isNaN(parsedVal) ? parsedVal : '';
};

/**
 * convert number to money string, i.e. 1234.567 => "$1,234.58"
 */
export const floatToMoney = num =>
  num.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });

/**
 * convert dollar amount: $12,300.50 to decimal number: 12300.50
 */
export const moneyToDecimal = value =>
  value?.replace('$', '').replace(/,/g, '');

/**
 * sum select "propName" from "items" array of items as "money"
 * convert each prop value to number, * 100 to convert to cents to avoid rounding errors
 * sum, devide by 100 to return to dollars, and convert sum to money string
 */
export const sumMoney = (items, propName) =>
  floatToMoney(
    items?.reduce((sum, item) => sum + 100 * moneyToFloat(item[propName]), 0) /
      100
  );

// for consolidated billing

// In Pay-Your-Bill: A convenience fee of 0.8% per transaction will be charged, capped at a maximum of $5.00
export const calculateFee = (value, rate, maxFee) => {
  if (!value) {
    return '';
  }
  const num = moneyToFloat(value);
  return floatToMoney(num + Math.min(num * rate, maxFee));
};

export const sumBillingFuel = aa =>
  sumMoney(
    aa?.billing?.filter(i => i.chargeCategory === 'Fuel'),
    'amount'
  );

export const sumBillingMaintenance = aa =>
  sumMoney(
    aa?.billing?.filter(i => i.chargeCategory === 'Maintenance'),
    'amount'
  );

export const sumBillingTotal = aa => sumMoney(aa?.billing, 'amount');
