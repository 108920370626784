import React, { useMemo } from 'react';
import usePermissions from '@holmanfm/lib/hooks/use-permissions';
import { useSelector } from '@holmanfm/lib/redux';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import {
  NavigationContext,
  RoutingContext,
} from '~/hooks/routing/app-routes-context';
import { generateNavigation, generateRouting } from '~/areas/nav/app-routes';

const RouteProvider = props => {
  const {
    generateProps: { features, isOrgAdmin, services, org },
  } = usePermissions();

  const isLoggedIn = useSelector(selectors.getLoggedIn);

  const filteredRoutes = useMemo(() => {
    return isLoggedIn && org?.services
      ? generateRouting(features, isOrgAdmin, services, org)
      : [];
  }, [features, isOrgAdmin, services, org, isLoggedIn]);

  const filteredNavigation = useMemo(() => {
    return org?.isOrgSetupComplete
      ? generateNavigation(features, isOrgAdmin, services, org)
      : [];
  }, [features, isOrgAdmin, services, org]);

  return (
    <NavigationContext.Provider value={filteredNavigation}>
      <RoutingContext.Provider value={filteredRoutes}>
        {props.children}
      </RoutingContext.Provider>
    </NavigationContext.Provider>
  );
};

export default RouteProvider;
