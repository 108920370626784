import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '~/shared/components/makeStyles';
import Badge from '~/shared/components/badge';
import Request from '~/shared/icons/request';

const useStyles = makeStyles(theme => ({
  requestIcon: count => ({
    color: count === 0 ? theme.palette.grey[400] : theme.palette.secondary.main,
    '& + span': {
      color: 'white',
      padding: '0 3px',
    },
  }),
}));

const RequestWithCount = ({ count, badgeColor }) => {
  const classes = useStyles(count || 0);
  return (
    <div>
      <Badge
        overlap="rectangular"
        badgeContent={count}
        color={badgeColor}
        max={999}
      >
        <Request className={classes.requestIcon} />
      </Badge>
    </div>
  );
};

RequestWithCount.propTypes = {
  count: PropTypes.number,
  badgeColor: PropTypes.string,
};
RequestWithCount.defaultProps = {
  count: null,
  badgeColor: 'secondary',
};

export default RequestWithCount;
