import map from '@holmanfm/lib/fp/map';
import pipe from '@holmanfm/lib/fp/pipe';
import T from '@holmanfm/lib/fp/t';
import Features from '@holmanfm/lib/lib-global';
import {
  hasNavigation,
  hasNavigationSubroutes,
  hasRouting,
  hasRoutingSubroutes,
  hasSubroutes,
} from '@holmanfm/lib/util/routes';
import React from 'react';
import lazyLoadComponent from '~/shared/component-loader';

const FaqLanding = lazyLoadComponent(() => import('../services/service-faq'));
const NewsLanding = lazyLoadComponent(() => import('../services/service-news'));
const UploadsLanding = lazyLoadComponent(() =>
  import('../services/service-uploads')
);

export function validateSpec(route) {
  if (!route.id) {
    throw new Error(`A route spec must include an 'id' property.`);
  }
}

export function validateNavigationSpec(navigation) {
  const { to, fullTo } = navigation;
  if (!to && !fullTo) {
    throw new Error(`A navigation route spec must include a 'to' property.`);
  }
}

export function validateRoutingSpec(routing) {
  const { path, fullPath } = routing;
  if (
    (path === undefined || path === '') &&
    (fullPath === undefined || fullPath === '')
  ) {
    throw new Error(
      `A routing spec must have a 'routing.path' or 'routing.fullPath' property.`
    );
  }
}

export function flattenSubroutes(specs, tail = []) {
  return specs.reduce((previous, spec) => {
    previous.push(spec);

    return hasSubroutes(spec)
      ? flattenSubroutes(spec.subroutes, previous)
      : previous;
  }, tail);
}

function compoundFilters(spec, type) {
  if (hasSubroutes(spec)) {
    return (...args) =>
      flattenSubroutes([spec]).reduce(
        (result, { filter: subfilter }) =>
          subfilter ? result || subfilter(...args) : result,
        false
      );
  }

  if (type === 'nav') {
    return spec.navigation.filter ?? spec.filter ?? T;
  }

  return spec.filter ? spec.filter : T;
}

export function filterNavigationSpecs(specs, ...args) {
  return specs.reduce((filteredSpecs, spec) => {
    validateSpec(spec);

    if (hasNavigation(spec)) {
      validateNavigationSpec(spec.navigation);

      const filter = compoundFilters(spec, 'nav');

      if (filter(...args)) {
        const subroutes = hasSubroutes(spec)
          ? filterNavigationSpecs(spec.subroutes, ...args)
          : null;
        // Push spec only if there are no subroutes or if they are still present after all of the filtering.
        if (!subroutes || subroutes.length > 0) {
          filteredSpecs.push({
            ...spec,
            subroutes: subroutes || [],
          });
        }
      }
    } else if (hasNavigationSubroutes(spec)) {
      throw new Error(
        `${spec.id} has navigatable subroutes, but no navigation property.`
      );
    }

    return filteredSpecs;
  }, []);
}

export function filterRoutingSpecs({ specs, ...args }) {
  return specs.reduce((filteredRoutes, spec) => {
    validateSpec(spec);

    if (hasRouting(spec)) {
      validateRoutingSpec(spec.routing);

      const filter = compoundFilters(spec, 'routing');

      if (filter(args.features, args.isAdmin, args.services, args.org)) {
        filteredRoutes.push({
          ...spec,
          subroutes: hasSubroutes(spec)
            ? filterRoutingSpecs({
                specs: spec.subroutes,
                ...args,
              })
            : [],
        });
      }
    } else if (hasRoutingSubroutes(spec)) {
      throw new Error(`${spec.id} has subroutes, but no routing property.`);
    }

    return filteredRoutes;
  }, []);
}

export function mergeNavigationPaths(routes = [], parentPath = '') {
  return routes.reduce((updatedSpecs, spec) => {
    const to = parentPath.concat(spec.navigation.to);

    updatedSpecs.push({
      ...spec,
      navigation: { ...spec.navigation, to },
      subroutes: hasSubroutes(spec)
        ? mergeNavigationPaths(spec.subroutes, to)
        : [],
    });

    return updatedSpecs;
  }, []);
}

export function mergeRouteSpecPaths(specs = [], parentPath = '') {
  return specs.reduce((updatedSpecs, spec) => {
    if (
      !spec.routing ||
      (spec.routing.path === undefined && spec.routing.fullPath === undefined)
    ) {
      throw new Error(
        `A parent route spec must include a 'routing.path' or 'routing.fullPath' property. Check ${spec.id}.`
      );
    }

    /**
     * A path defined as null is used for the catch-all 404. There will never been a child path and
     * should simple return null.
     */
    const path =
      spec.routing?.fullPath ||
      (spec.routing.path === null
        ? spec.routing.path
        : parentPath.concat(spec.routing.path));

    updatedSpecs.push({
      ...spec,
      routing: { ...spec.routing, path },
      subroutes: hasSubroutes(spec)
        ? mergeRouteSpecPaths(spec.subroutes, path)
        : [],
    });

    return updatedSpecs;
  }, []);
}

export function mapSpecToRouteProps(spec) {
  const {
    id,
    routing: {
      path,
      component,
      icon,
      render,
      strict = false,
      exact = false,
      sensitive = false,
    },
    homepage,
  } = spec;

  return {
    id,
    path,
    component,
    icon,
    render,
    strict,
    exact,
    sensitive,
    homepage,
  };
}

export const generateNavigationRoutesWithSpecs = pipe(
  filterNavigationSpecs,
  mergeNavigationPaths
);

const routeArgumentsToObject = (specs, features, isAdmin, services, org) => {
  return { specs, features, isAdmin, services, org };
};

export function setSpecsWithState(newFunction, state) {
  return { ...state, specs: newFunction(state) };
}

export function appendSpecsWithState(newFunction, state) {
  return { ...state, specs: [...newFunction(state), ...state.specs] };
}

function createConsumerFAQRouteSpec(state) {
  return appendSpecsWithState(({ services }) => {
    return services.map(({ id, name, displayName }) => ({
      id: `${name}-faq`,
      filter: T,
      routing: {
        path: `/services/${name}/faq`,
        exact: true,
        render: () => <FaqLanding serviceName={displayName} serviceId={id} />,
      },
    }));
  }, state);
}

function createConsumerNewsRouteSpecs(state) {
  return appendSpecsWithState(({ services }) => {
    return services.map(({ id, name, displayName }) => ({
      id: `${name}-news`,
      filter: T,
      routing: {
        path: `/services/${name}/news`,
        exact: true,
        render: () => <NewsLanding serviceName={displayName} serviceId={id} />,
      },
    }));
  }, state);
}

const canManageMessages = ({ providerFeatures }) =>
  providerFeatures.includes(Features.SUPPLIERS.SHARED.MANAGE_MESSAGES);

const canUploadFiles = ({ providerFeatures }) =>
  providerFeatures.includes(Features.SUPPLIERS.SHARED.UPLOAD_FILES);

function createProviderServiceFaqRouteSpec(state) {
  return appendSpecsWithState(({ org }) => {
    return org.servicesProvided
      .filter(canManageMessages)
      .map(({ id, name, displayName }) => ({
        id: `${name}-faq`,
        filter: T,
        routing: {
          path: `/supplier/services/${name}/faq`,
          exact: true,
          render: () => (
            <FaqLanding admin serviceName={displayName} serviceId={id} />
          ),
        },
      }));
  }, state);
}

function createProviderServiceNewsRouteSpec(state) {
  return appendSpecsWithState(({ org }) => {
    return org.servicesProvided
      .filter(canManageMessages)
      .map(({ id, name, displayName }) => ({
        id: `${name}-news`,
        filter: T,
        routing: {
          path: `/supplier/services/${name}/news`,
          exact: true,
          render: () => (
            <NewsLanding admin serviceName={displayName} serviceId={id} />
          ),
        },
      }));
  }, state);
}

function createProviderServiceUploadsRouteSpec(state) {
  return appendSpecsWithState(({ org }) => {
    return org.servicesProvided
      .filter(canUploadFiles)
      .map(({ id, name, displayName }) => ({
        id: `${name}-uploads`,
        filter: T,
        routing: {
          path: `/supplier/services/${name}/uploads`,
          exact: true,
          render: () => (
            <UploadsLanding admin serviceName={displayName} serviceId={id} />
          ),
        },
      }));
  }, state);
}

export const generateStateRoutes = pipe(
  createConsumerNewsRouteSpecs,
  createConsumerFAQRouteSpec,
  createProviderServiceFaqRouteSpec,
  createProviderServiceNewsRouteSpec,
  createProviderServiceUploadsRouteSpec
);

export const generateRoutingWithSpecs = pipe(
  routeArgumentsToObject,
  generateStateRoutes,
  filterRoutingSpecs,
  mergeRouteSpecPaths,
  flattenSubroutes,
  map(mapSpecToRouteProps)
);
