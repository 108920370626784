// Singleton static service.
// Call the constructor with the Storage system you're using (AsyncStorage / localStorage)
class StorageSystem {
  constructor(storage) {
    this.storage = storage;
  }

  async getItem(key) {
    return this.storage.getItem(key);
  }

  async setItem(key, value) {
    this.storage.setItem(key, value);
    return value;
  }

  async removeItem(key) {
    const value = await this.getItem(key);
    this.storage.removeItem(key);
    return value;
  }
}

export default StorageSystem;
