import PropTypes from 'prop-types';
import React from 'react';
import clx from 'classnames';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { MaintenanceRepairItemsShape } from '@holmanfm/lib/services/maintenance-repair-history';
import { getSortedItems } from '@holmanfm/lib/common/service-request-helpers';
import Table from '~/shared/components/table';
import TableHead from '~/shared/components/table-head';
import TableRow from '~/shared/components/table-row';
import TableCell from '~/shared/components/table-cell';
import TableBody from '~/shared/components/table-body';
import makeStyles from '~/shared/components/makeStyles';
import MaintenanceRepairDetailsRequest from '~/areas/service-requests/view-update-request/details/maintenenace-repair-details-request';

const useStyles = makeStyles(theme => ({
  detailTable: {
    border: 'none',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '0.7rem',
    lineHeight: 1.25,
    border: 'none',
    padding: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  data: {
    fontSize: '0.75rem',
    border: 'none',
    padding: theme.spacing(0.25, 1),
    whiteSpace: 'nowrap',
  },

  hideBelow: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  itemWrapper: {
    marginBottom: 2,
    padding: '3px 10px',
  },
}));

const MaintenanceRepairDetails = props => {
  const { t } = useNSTranslation('maintenanceRepair', 'history');
  const classes = useStyles();
  const { item, className, size, requestView } = props;
  const sortItems = getSortedItems(item, 'ataCode');

  if (requestView) {
    return (
      <MaintenanceRepairDetailsRequest className={className} size={size} />
    );
  }

  return (
    <Table
      size={size}
      aria-label={t('maint-repair-details')}
      className={classes.detailTable}
    >
      <TableHead>
        <TableRow>
          <TableCell className={classes.title}>{t('description')}</TableCell>
          <TableCell className={classes.title}>
            {t('ata-code')} / {t('ata-code-category')}
          </TableCell>
          <TableCell className={clx(classes.hideBelow, classes.title)}>
            {t('complaint')}
          </TableCell>
          <TableCell className={clx(classes.hideBelow, classes.title)}>
            {t('correction')}
          </TableCell>
          <TableCell className={clx(classes.hideBelow, classes.title)}>
            {t('repair-type')}
          </TableCell>
          <TableCell
            className={clx(classes.hideBelow, classes.title)}
            align="right"
          >
            {t('quantity')}
          </TableCell>
          <TableCell className={classes.title} align="right">
            {t('extended-cost')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortItems.map((detail, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={`${detail.ataCode}=${index}`}>
            <TableCell className={classes.data}>
              {detail?.description}
            </TableCell>
            <TableCell
              className={classes.data}
              style={{ maxWidth: '200px', whiteSpace: 'wrap' }}
            >
              {detail?.ataCode} {detail?.repairTypeDescription && '/'}{' '}
              {detail?.repairTypeDescription}
            </TableCell>
            <TableCell className={clx(classes.hideBelow, classes.data)}>
              {detail?.complaintDescription}
            </TableCell>
            <TableCell className={clx(classes.hideBelow, classes.data)}>
              {detail?.correction}
            </TableCell>
            <TableCell className={clx(classes.hideBelow, classes.data)}>
              {detail?.repairType}
            </TableCell>
            <TableCell
              align="right"
              className={clx(classes.hideBelow, classes.data)}
            >
              {detail?.quantity}
            </TableCell>
            <TableCell align="right" className={classes.data}>
              {detail?.extendedCost}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

MaintenanceRepairDetails.propTypes = {
  item: PropTypes.arrayOf(MaintenanceRepairItemsShape).isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  requestView: PropTypes.bool,
};

MaintenanceRepairDetails.defaultProps = {
  className: undefined,
  size: undefined,
  requestView: false,
};

export default MaintenanceRepairDetails;
