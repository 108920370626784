/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import { useSelector } from '@holmanfm/lib/redux';
import ThirdLevel from '~/shared/icons/hierarchy-third-level';
import SecondLevel from '~/shared/icons/hierarchy-second-level';
import FirstLevel from '~/shared/icons/hierarchy-first-level';

const displayLevelIcon = lvl => {
  if (lvl === 1) {
    return <FirstLevel style={{ width: 16, marginRight: 4 }} />;
  }
  if (lvl === 2) {
    return <SecondLevel style={{ width: 16, marginRight: 4 }} />;
  }
  if (lvl === 3) {
    return <ThirdLevel style={{ width: 16, marginRight: 4 }} />;
  }
};

const ShowAssetLocation = props => {
  const { orgNodeId } = props;

  const orgNodesFlattened = useSelector(selectors.getOrgNodes);

  if (orgNodeId) {
    const selectedNode = orgNodesFlattened.filter(x => x.id === orgNodeId);
    const hierarchyLevel = selectedNode[0]?.hierarchy_name.split('.')?.length;
    return (
      <div style={{ display: 'flex', alignItems: 'self-end' }}>
        {' '}
        {displayLevelIcon(hierarchyLevel)} {selectedNode[0]?.node_label}
      </div>
    );
  }

  return <div>No Location Selected</div>;
};

ShowAssetLocation.propTypes = {
  orgNodeId: PropTypes.string,
};

ShowAssetLocation.defaultProps = {
  orgNodeId: undefined,
};

export default ShowAssetLocation;
