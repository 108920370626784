import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'formik';
import PropTypes from 'prop-types';

const ResetButton = ({ formik, ...props }) => {
  const { disabled, ...rest } = props;
  const { isSubmitting, dirty } = formik;

  return (
    <Button
      variant="text"
      color="secondary"
      type="reset"
      onClick={formik.handleReset}
      disabled={dirty === false || disabled || isSubmitting}
      {...rest}
    />
  );
};

ResetButton.propTypes = {
  formik: PropTypes.shape({
    handleReset: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
};

ResetButton.defaultProps = {
  disabled: undefined,
};

export default connect(ResetButton);
