import { GA_KEY, GA_KEY_MARKETING } from '@holmanfm/lib/common/environment';

export default class Analytics {
  static initialized = false;

  static gtag() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }

  static init() {
    if (GA_KEY && !Analytics.initialized) {
      window.dataLayer = window.dataLayer || [];
      Analytics.gtag('js', new Date());
      Analytics.gtag('config', GA_KEY);
      Analytics.gtag('config', GA_KEY_MARKETING);
      Analytics.initialized = true;
      console.log('Google Analytics enabled');
    }
  }
}
