import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import byId, * as fromById from './org-nodes-by-id.reducer';
import meta, * as fromMeta from './org-nodes-meta.reducer';

export default combineReducers({
  byId,
  meta,
});

export const getOrgNode = (state, id) => fromById.getOrgNode(state.byId, id);
export const getIsFetchingOrgNode = (state, id) =>
  fromById.getIsFetchingOrgNode(state.byId, id);
export const getOrgNodeMessages = (state, id) =>
  fromById.getOrgNodeMessages(state.byId, id);

export const getOrgNodes = state => fromById.getOrgNodes(state.byId);

export const getOrgNodesDict = state => fromById.getOrgNodesDict(state.byId);
export const getRootOrgNodes = state => fromById.getRootOrgNodes(state.byId);

export const getIsFetchingOrgNodes = state =>
  fromMeta.getIsFetchingOrgNodes(state.meta);
export const getOrgNodesMessages = state =>
  fromMeta.getOrgNodesMessages(state.meta);

/**
 * @deprecated Superseded by hook useOrgNodeLevel()
 * @param {*} state
 * @param {*} level
 */
export const getOrgNodesForLevel = (state, level) => {
  const orgNodes = getOrgNodes(state);
  return orgNodes.filter(
    orgNode => orgNode && orgNode.hierarchy_name.split('.').length === +level
  );
};

const injectChildren = (node, nodesDict) => {
  node.children = node.childIds
    .filter(id => !!nodesDict[id])
    .map(id => {
      if (id === node.id) {
        return null;
      }
      return injectChildren({ ...nodesDict[id] }, nodesDict);
    });
  return node;
};

export const getOrgNodesTree = createSelector(
  [getOrgNodesDict, getRootOrgNodes],
  (nodesDict, rootNodes) => {
    return rootNodes.map(node => injectChildren({ ...node }, nodesDict));
  }
);

export const getOrgNodeChildren = createSelector(
  getOrgNodesDict,
  getRootOrgNodes,
  (_, id) => id,
  (nodesDict, rootOrgNodes, id) => {
    if (id) {
      const orgNode = nodesDict?.[id];
      return orgNode ? orgNode.childIds.map(childId => nodesDict[childId]) : [];
    }
    return rootOrgNodes;
  }
);
