import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import AssetHelpers from '@holmanfm/lib/common/asset-helpers';
import Dialog from '~/shared/components/dialog';
import Box from '~/shared/components/box';
import AppBar from '~/shared/components/bar/appbar';
import Toolbar from '~/shared/components/bar/toolbar';
import IconButton from '~/shared/components/atom/icon-button';
import CloseIcon from '~/shared/icons/close';
import Table from '~/shared/components/table';
import TableBody from '~/shared/components/table-body';
import TableCell from '~/shared/components/table-cell';
import TableRow from '~/shared/components/table-row';
import Typography from '~/shared/components/atom/typography';
import Paper from '~/shared/components/paper';
import makeStyles from '~/shared/components/makeStyles';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dataBody: {
    margin: 0,
    fontSize: '1.1rem',
  },
  tableTD: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
}));

const useDialogStyles = makeStyles(() => ({
  appBar: {
    position: 'relative',
  },
}));
const VinRow = ({ label, value }) => {
  const classes = useStyles();
  if (Array.isArray(value)) {
    return null;
  }
  return (
    <TableRow key={label}>
      <TableCell className={classes.tableTD}>
        <p className={classes.dataBody}>
          <span style={{ fontWeight: '700' }}>{label}</span>
        </p>
      </TableCell>
      <TableCell className={classes.tableTD}>
        <p className={classes.dataBody}>{value}</p>
      </TableCell>
    </TableRow>
  );
};

VinRow.defaultProps = {
  value: 'N/A',
};

VinRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const Rows = ({ vinData }) => {
  const filtered = AssetHelpers.returnFilteredAssetAttributes(vinData);
  return Object.entries(filtered).map(vdata => {
    const [label, value] = vdata;
    const stringLabel = AssetHelpers.returnReadableLabel(label);
    return <VinRow key={stringLabel} label={stringLabel} value={value} />;
  });
};

const VinDialog = ({ isDialogOpen, Transition, closeForm, data }) => {
  const { t } = useNSTranslation('assets', ['add-asset', 'vin-dialog']);
  const classes = useDialogStyles();
  if (!data) return null;
  const parsedData = data.Results ? data.Results[0] : data;
  return (
    <Dialog
      open={isDialogOpen}
      onClose={closeForm}
      TransitionComponent={Transition}
      maxWidth="lg"
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeForm}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">{t('vindecoded-data')}</Typography>
        </Toolbar>
      </AppBar>
      <Box>
        <Paper className={classes.dialogPaper} elevation={0}>
          <Table>
            <TableBody>
              <VinRow
                label={t('vin')}
                value={parsedData?.vin || parsedData?.VIN}
              />
              <VinRow
                label={t('year')}
                value={parsedData?.year || parsedData?.ModelYear}
              />
              <VinRow
                label={t('make')}
                value={parsedData?.make || parsedData?.Make}
              />
              <VinRow
                label={t('model')}
                value={parsedData?.model || parsedData?.Model}
              />
              <VinRow
                label={t('trim')}
                value={parsedData?.trim || parsedData?.Trim}
              />
              <VinRow
                label={t('series')}
                value={parsedData?.series || parsedData?.Series}
              />
              <VinRow
                label={t('vehicle-type')}
                value={parsedData?.type || parsedData?.VehicleType}
              />
              <VinRow
                label={t('fuel-type')}
                value={parsedData?.fuel || parsedData?.FuelTypePrimary}
              />
              <VinRow
                label={t('cylinders')}
                value={
                  parsedData?.engineCylinders || parsedData?.EngineCylinders
                }
              />
              <VinRow
                label={t('curb-weight')}
                value={parsedData?.gvwr || parsedData?.GVWR}
              />
              <Rows
                vinData={typeof parsedData !== 'object' ? {} : parsedData}
              />
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </Dialog>
  );
};

VinDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  Transition: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  closeForm: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
};

VinDialog.defaultProps = {
  data: {},
};

export default VinDialog;
