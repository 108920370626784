import axios from 'axios';
import { refreshAceSessionThunk } from '../redux/actions/ace.actions';
import { shouldAceRefreshAxiosInterceptor } from '../redux/reducers/ace.reducer';

/**
 * Under normal conditions, this interceptor will just silently let responses pass through.
 * Its purpose is to restore the ACE slice of Redux state when the app is refreshed during an ace session.
 * It should NOT attempt to clear ACE state if no headers are sent; non-authenticated or super-user endpoints
 * will not send ACE headers (rightly so) which could confuse this responder.
 */
export const createAxiosAceResponseHandler = reduxStore => async response => {
  if (
    response.headers['hsv-ace-uid'] &&
    shouldAceRefreshAxiosInterceptor(reduxStore.getState())
  ) {
    await reduxStore.dispatch(refreshAceSessionThunk());
  }
  return response;
};

export default function aceInterceptor(store) {
  axios.interceptors.response.use(createAxiosAceResponseHandler(store));
}
