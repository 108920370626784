import commonColors from '@holmanfm/lib/common/colors';
import CreateMuiTheme from '~/shared/components/create-theme';
import responsiveFontSizes from '~/shared/components/responsive-font-sizes';

export const defaultThemeObject = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1280,
    },
  },
  typography: {
    // TODO back up font family, arial?
    fontFamily: ['Poppins'],
    fontSize: 14, // default
    color: commonColors.sharedPalette.grey.greyDark,
    h1: {
      fontSize: '1.875rem', // 30px
      fontWeight: 700,
      lineHeight: 1.1,
      color: commonColors.sharedPalette.primary.main,
    },
    h2: {
      fontSize: '1.55rem', // 24px
      fontWeight: 700,
      color: commonColors.sharedPalette.primary.main,
    },
    h3: {
      fontSize: '1.25rem', // 20px
      fontWeight: 700,
      color: commonColors.sharedPalette.primary.main,
    },
    h4: {
      fontSize: '1.125rem', // 18px
      fontWeight: 700,
      color: commonColors.sharedPalette.primary.main,
    },
    h5: {
      fontSize: '1rem', // 16px
      fontWeight: 700,
      color: commonColors.sharedPalette.primary.main,
    },
    h6: {
      fontSize: '0.875rem', // 14px
      fontWeight: 700,
      'button.MuiButton-text &': {
        color: 'inherit',
      },
      'button:not(.MuiButton-text) &': {
        color: '#fff',
      },
      color: commonColors.sharedPalette.primary.main,
    },
    subtitle1: {
      fontSize: '0.75rem', // 12px
      fontWeight: 700,
      lineHeight: 1.3,
    },
    subtitle2: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
      lineHeight: 1.3,
    },
    caption: {
      fontSize: '0.65rem', // 10px
    },
    body2: {
      lineHeight: 1.5,
    },
  },
  palette: {
    ...commonColors.sharedPalette,
    background: {
      default: commonColors.sharedPalette.backgroundColors.body,
    },
  },
  /**
   * Overriding components CSS.
   *
   * @see https://material-ui.com/customization/globals/#css
   */
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
        '&.MuiButton-disableElevation': {
          boxShadow: 'none',
        },
        '&.MuiButton-contained': {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        '&.MuiButton-containedSecondary': {
          '&:hover': {
            background: commonColors.sharedPalette.primary.main,
          },
        },
        '&.MuiButton-outlined': {
          '&:hover': {
            // test later opacity to 0.06 if possible
          },
        },
        '&.MuiButton-outlinedSecondary': {
          '&:hover': {
            background: commonColors.sharedPalette.secondary.light,
            borderColor: 'transparent',
          },
        },
      },
    },

    // List Subheader (the category header)
    MuiListSubheader: {
      sticky: {
        backgroundColor: 'white',
      },
    },

    // time picker hourMinuteLabel
    MuiDialogContent: {
      root: {
        '& .MuiPickersTimePickerToolbar-hourMinuteLabel h2': {
          fontSize: '3rem',
          fontWeight: 400,
        },
        '& .MuiPickersTimePickerToolbar-hourMinuteLabel h3': {
          fontSize: '3rem',
          fontWeight: 400,
        },
      },
    },
    PrivateRadioButtonIcon: {
      layer: {
        left: 0,
      },
    },
    MuiRadio: {
      root: {
        padding: '4px 9px',
      },
    },
    // outlined textfields
    MuiInputBase: {
      input: {
        fontSize: '14px',
      },
    },
    // text within the input box
    MuiOutlinedInput: {
      input: {
        padding: '14px',
      },
      inputMarginDense: {
        paddingTop: '9px',
        paddingBottom: '9px',
      },
    },
    // outlined labels
    MuiInputLabel: {
      outlined: {
        color: 'black',
        fontWeight: 400,
        transform: 'translate(16px, 16px) scale(1)',
        '&.MuiInputLabel-shrink': {
          fontWeight: 500,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: 'black',
        '&.Mui-disabled': {
          color: commonColors.sharedPalette.grey.greyMedium,
        },
      },
    },
    //
    MuiBadge: {
      colorSecondary: {
        backgroundColor: commonColors.sharedPalette.tertiary.main,
      },
    },
    // checkboxes, radios
    MuiFormControlLabel: {
      label: {
        fontSize: '0.875rem',
      },
    },

    // Stepper
    MuiStepIcon: {
      root: {
        color: commonColors.sharedPalette.tertiary.main,
        opacity: 0.3,
        fontWeight: 700,
        '&.MuiStepIcon-completed': {
          color: commonColors.sharedPalette.secondary.main,
          opacity: 1,
        },
        '&.MuiStepIcon-active': {
          color: commonColors.sharedPalette.tertiary.main,
          opacity: 1,
        },
      },
      // text refers to the number within the circle
      text: {
        fontSize: '0.75rem',
      },
    },
    MuiStepLabel: {
      label: {
        color: commonColors.sharedPalette.primary.main,
        opacity: 0.3,
        fontWeight: 500,
        '&.MuiStepLabel-active': {
          fontWeight: 700,
          opacity: 1,
          color: commonColors.sharedPalette.primary.main,
        },
        '&.MuiStepLabel-completed': {
          opacity: 1,
          color: commonColors.sharedPalette.primary.main,
        },
        // responsive: hide labels on small screen width
        // if a wider width is required, that should be handled per implementation
        '@media screen and (max-width: 600px)': {
          display: 'none',
        },
      },
    },
    MuiStepConnector: {
      root: {
        padding: '8px',
      },
      line: {
        borderColor: '#f3e8da',
      },
    },

    MuiSlider: {
      rail: {
        height: 10,
        borderRadius: 4,
      },
      track: {
        height: 10,
        borderRadius: 4,
      },
      thumb: {
        height: 22,
        width: 22,
        backgroundColor: '#fff',
        boxShadow:
          '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
        marginLeft: -11,
        '&:focus, &:hover, &active': {
          boxShadow:
            '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
          // touch devices
          '@media (hover: none)': {
            boxShadow:
              '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
          },
        },
      },
      mark: {
        backgroundColor: '#d8d8d8',
        height: 7,
        width: 1,
        marginTop: 10,
      },
      markActive: {
        backgroundColor: '#d8d8d8',
      },
      markLabel: {
        top: 30,
        fontSize: '11px',
      },
    },
  },

  /**
   * Overriding components default properties.
   *
   * @see https://material-ui.com/customization/globals/#default-props
   */
  props: {
    MuiGrid: {
      spacing: 1,
    },
  },
};

export default responsiveFontSizes(CreateMuiTheme(defaultThemeObject));
