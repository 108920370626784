import {
  isFilterableWithOptions,
  isFilterableWithoutOptions,
  createFacetFilters,
} from '../../use-filtering/util';
import { createSearchFilters } from '../../use-searching/util';
import fieldSorter from '../../util/fieldSorter';
import { sortByKeyCompare } from '../../util/helpers';
import { createFieldOptions, createFieldMap } from '../fields-util';

/**
 * This file is used to compute filtersProps variable - it contains filterable fields and all their options with counts.
 * It performs local calculations
 * NOTE it's async only to keep it consistent when it's used as is and as a web worker.
 */

// eslint-disable-next-line import/prefer-default-export
export const computeFiltersProps = async ({
  fields,
  data,
  searchTerm,
  filters,
  type,
  filterDisplayOrder,
}) => {
  const fieldsOptions = createFieldMap(fields, data);

  const searchFilters = createSearchFilters(searchTerm, fields);

  const filterSortFn =
    filterDisplayOrder && filterDisplayOrder.length > 0
      ? sortByKeyCompare('id', filterDisplayOrder, 'ASC')
      : fieldSorter('display');

  const result = fields
    .filter(isFilterableWithOptions)
    .map(field => {
      const facetFilters = createFacetFilters(
        filters,
        fields.filter(f => f.id !== field.id)
      );

      const options = createFieldOptions(
        field,
        facetFilters.concat(searchFilters),
        { ...fieldsOptions[field.id] },
        data,
        type
      );

      const sortFn = Array.isArray(field.filter.order)
        ? sortByKeyCompare('value', field.filter.order, 'ASC')
        : fieldSorter({ key: 'value', reverse: field.filter.order === 'DESC' });

      // [{value: 'Active', count: 9 }, { value: 'Inactive', count: 22 }]
      return { ...field, options: options.sort(sortFn) };
    })
    .filter(field => field.options.length > 0)
    .concat(fields.filter(isFilterableWithoutOptions))
    .sort(filterSortFn);

  return result;
};
