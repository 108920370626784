import api from '../../util/api';

// get
export const getParentOrgData = () => {
  return api.get(`/parent-org`);
};

export const getAssets = () => {
  return api.get(`/parent-org/assets`);
};

export const getChildOrgData = childOrgId => {
  return api.get(`/parent-org/${childOrgId}`);
};

export const getFuelTransactions = childOrgId =>
  api.get(`/parent-org/${childOrgId}/fuel/transactions`);

export const getFuelCards = childOrgId => {
  return api.get(`/parent-org/${childOrgId}/fuel/cards`);
};

export const getMaintRepairHistory = (childOrgId, assetNo) => {
  return api.get(`/parent-org/${childOrgId}/maint-repair/asset/${assetNo}`);
};

export const getFuelAssetTransactions = (childOrgAssetId, childOrgId) => {
  return api.get(
    `/parent-org/${childOrgId}/fuel/transactions/${childOrgAssetId}`
  );
};

export const getParentServiceRequests = childOrgId => {
  return api.get(`/parent-org/${childOrgId}/requests`);
};

export const getChildFuelPreferences = childOrgId =>
  api.get(`/parent-org/${childOrgId}/fuel/preferences`);

export const getChildAssets = (childOrgId, assetNo) =>
  api.get(`/parent-org/${childOrgId}/asset/${assetNo}`);

export const getChildOdometer = (childOrgId, assetId) =>
  api.get(`/parent-org/${childOrgId}/asset/${assetId}/odometer`);

export const getChildDocuments = (childOrgId, assetId) =>
  api.get(`/parent-org/${childOrgId}/asset/${assetId}/documents`);

export const getChildAssetPhotos = (childOrgId, resource, resourceId, prefix) =>
  api.get(`/parent-org/${childOrgId}/documents/${resource}/${resourceId}`, {
    params: { prefix },
  });

export const getPMMaintenance = (childOrgId, assetId) =>
  api.get(`/parent-org/${childOrgId}/asset/${assetId}/preventive-maintenance`);

export const getChildFeatures = childOrgId =>
  api.get(`/parent-org/${childOrgId}/features`);
