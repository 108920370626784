import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '~/shared/components/table-cell';
import Typography from '~/shared/components/atom/typography/typography';
import makeStyles from '~/shared/components/makeStyles';

const useStyle = makeStyles(theme => ({
  headerName: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    border: 'none',
  },
}));

const HSBSTableHeader = ({ children, align }) => {
  const classes = useStyle();
  return (
    <TableCell className={classes.headerName} align={align}>
      <Typography variant="subtitle1">{children}</Typography>
    </TableCell>
  );
};

HSBSTableHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  align: PropTypes.string,
};

HSBSTableHeader.defaultProps = {
  children: null,
  align: 'inherit',
};

export default HSBSTableHeader;
