import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '~/shared/components/makeStyles';
import PaginationLayout from '~/areas/individual-assets/pagination-layout';

const useStyles = makeStyles(() => ({
  contentWrapper: {
    overflowX: 'auto',
    overflowY: 'hidden',
    flex: 1,
    position: 'relative',
  },
}));

const PaginationContainer = props => {
  const { children, assetId } = props;
  const classes = useStyles();

  return (
    <div className={classes.contentWrapper}>
      <PaginationLayout assetId={assetId}>{children}</PaginationLayout>
    </div>
  );
};

PaginationContainer.propTypes = {
  children: PropTypes.node,
};

PaginationContainer.defaultProps = {
  children: undefined,
};

export default PaginationContainer;
