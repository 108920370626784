import { FILTER_TYPES, isFilterable } from '../use-filtering';
import { isNumber, isString } from '../util/helpers';
import { toRangeQueryParam } from './citrus-framework';

/**
 * Iterates through all known state values and runtime defined filters values calling handleSetSearch
 * on each. This is used to build a URL query parameter on WEB.
 * @param {} state
 * @param {(k, v) => void} handleSetSearch
 * @param {Object} view
 */
export default function setSearchParameters(state, handleSetSearch, view) {
  const {
    take,
    skip,
    sortDirection,
    sortField,
    filters,
    lastInteractedField,
    searchTerm,
  } = state;

  if (isNumber(take) && take !== view.take) {
    handleSetSearch('take', take);
  }

  if (isNumber(skip) && skip > 0) {
    handleSetSearch('skip', skip);
  }

  if (isString(sortDirection) && sortDirection !== view.defaultSortDirection) {
    handleSetSearch('sortDirection', sortDirection);
  }

  if (isString(sortField) && sortField !== view.defaultSortField) {
    handleSetSearch('sortField', sortField);
  }

  if (isString(searchTerm) && searchTerm !== '') {
    handleSetSearch('searchTerm', searchTerm);
  }

  if (isString(lastInteractedField) && lastInteractedField !== '') {
    handleSetSearch('lastInteractedField', lastInteractedField);
  }

  if (Array.isArray(filters) && filters.length > 0) {
    view.fields.filter(isFilterable).forEach(field => {
      switch (field.filter.type) {
        /**
         * ONE is the easies with just `?id=value`
         */
        case FILTER_TYPES.ONE: {
          const found = filters.find(f => f.id === field.id);
          if (found) {
            handleSetSearch(field.id, found.value);
          }
          break;
        }

        /**
         * In the case of a many we just have to map the filters to an array of their values
         * before using setting to the query. This results in one or many `?id=value` or `?id=value,id=value,id=value`
         */
        case FILTER_TYPES.MANY: {
          const found = filters
            /**
             * [
             *    { id: 'make', value: 'Honda', },
             *    { id: 'make', value: 'Acura', },
             *    { id: 'color', value: 'red', },
             * ]
             */
            .filter(f => f.id === field.id)
            /**
             * [
             *    { id: 'make', value: 'Honda', },
             *    { id: 'make', value: 'Acura', },
             * ]
             */
            .map(({ value }) => value); // -> ['Honda', 'Acura]

          if (found.length) {
            handleSetSearch(field.id, found); // -> ?make=Honda&make=Acura
          }
          break;
        }

        /**
         * The RANGE values need to include their operator in the search query, so we use toRangeQueryParam
         * to convert the id and operator to the `id~operator` making the search `?id-operator=value`.
         * In the case of dates we call getTime() to convert to milliseconds from the UNIX epoch for
         * a clearner URL.
         */
        case FILTER_TYPES.RANGE:
        case FILTER_TYPES.SELECT_RANGE: {
          filters
            /**
             * [
             *    { id: year, operator: gte, value: 1909 },
             *    { id: year, operator: lte, value: 2020 },
             * ]
             */
            .filter(f => f.id === field.id)
            .map(({ id, value, operator }) => ({
              key: toRangeQueryParam(id, operator),
              value: field.type === 'date' ? value.getTime() : value,
            }))
            .forEach(({ key, value }) => {
              handleSetSearch(key, value);
            });
          break;
        }

        /**
         * The DISTANCE_FROM_ZIP value contains zip, lat, lon, and distance. As of right now, there can only be 1 element.
         * We map it to "distance"from"zip"+"lat"+"lon" string in the url
         */
        case FILTER_TYPES.DISTANCE_FROM_ZIP: {
          /**
           * { id: year, operator: 'closeTo', value: { zip: 111111, lat: 1, lon: 1, distance: 25 } },
           */

          const found = filters.find(f => f.id === field.id);
          if (found) {
            handleSetSearch(
              field.id,
              `${found.value.distance}from${found.value.zip} ${found.value.lat} ${found.value.lon}`
            );
          }
          break;
        }

        default:
          throw new Error(
            `Invalid filter type specified during query manipulation.`
          );
      }
    });
  }
}
