import api from '../../util/api';

export const getFuelPreferences = config =>
  api.get(`/fuel/card/preferences`, config);

export const postFuelPreferences = data =>
  api.post(`/fuel/card/preferences/`, data);

export const getFuelCardReplace = config =>
  api.get(`/fuel/card/fuel-card-replace`, config);

export const getFuelCard = fuelCardId => api.get(`/fuel/cards/${fuelCardId}`);

export const getAssetFuelCards = assetId =>
  api.get(`/fuel/assets/${assetId}/cards`);

export const postFuelCardReplace = (assetId, data) =>
  api.post(`/fuel/card/${assetId}/replace`, data);

export const getFuelAssetTransactions = (assetId, config) => {
  return api.get(`/fuel/transactions/${assetId}`, config);
};

export const getAllTransactions = () => api.get('/fuel/transactions');

export const getFuelTransactionsUrl = (
  minDate = undefined,
  maxDate = undefined,
  invoiceYearMonth = undefined,
  orgNodeId = undefined,
  childOrgId = undefined,
  format = '',
  creditCard = undefined,
  assetId = undefined
) => {
  const query = new URLSearchParams({ format });
  if (minDate) {
    query.append('minDate', minDate);
  }
  if (maxDate) {
    query.append('maxDate', maxDate);
  }
  if (invoiceYearMonth) {
    query.append('invoice', invoiceYearMonth);
  }
  if (orgNodeId) {
    query.append('orgNodeId', orgNodeId);
  }
  if (creditCard && creditCard.length) {
    query.append('ccLast4', creditCard);
  }
  if (assetId) {
    query.append('assetId', assetId);
  }
  if (childOrgId) {
    return `/parent-org/${childOrgId}/fuel/transactions?${query}`;
  }
  return `/fuel/transactions?${query}`;
};

export const getFuelTransactions = (
  minDate,
  maxDate,
  invoiceYearMonth,
  creditCard,
  orgNodeId,
  childOrgId,
  format = 'tables'
) =>
  api.get(
    getFuelTransactionsUrl(
      minDate,
      maxDate,
      invoiceYearMonth,
      orgNodeId,
      childOrgId,
      format,
      creditCard
    )
  );

export const getFuelStats = childOrgId => {
  return api.get(
    childOrgId
      ? `/parent-org/${childOrgId}/fuel/transactions-stats`
      : '/fuel/transactions-stats'
  );
};

export const getFuelPinStats = childOrgId => {
  return api.get(
    childOrgId
      ? `/parent-org/${childOrgId}/fuel/report/pin-transactions-stats`
      : '/fuel/report/pin-transactions-stats'
  );
};

export const getPinTransactionsUrl = (
  minDate = undefined,
  maxDate = undefined,
  invoiceYearMonth = undefined,
  orgNodeId = undefined,
  format = 'camelCase',
  byPIN = true
) => {
  const query = new URLSearchParams({
    format,
    byPIN,
  });
  if (minDate) {
    query.append('minDate', minDate);
  }
  if (maxDate) {
    query.append('maxDate', maxDate);
  }
  if (invoiceYearMonth) {
    query.append('invoiceYearMonth', invoiceYearMonth);
  }
  if (orgNodeId) {
    query.append('orgNodeId', orgNodeId);
  }
  return `/fuel/report?${query}`;
};

/**
 *
 * @param minDate string YYYY-MM-DD
 * @param maxDate string YYYY-MM-DD
 * @param invoiceYearMonth string YYYYMM
 * @returns {Promise<*>}
 */
export const getPinTransactions = (
  minDate,
  maxDate,
  invoiceYearMonth,
  orgNodeId,
  format,
  byPin
) =>
  api.get(
    getPinTransactionsUrl(
      minDate,
      maxDate,
      invoiceYearMonth,
      orgNodeId,
      format,
      byPin
    )
  );

export const getFuelReportYearMonths = () =>
  api.get('/fuel/report/year-months');

// Manage Fuel Cards
/**
 * @param {boolean} update
 */
export const getAllFuelCards = update => {
  return update ? api.get('/fuel/cards?update=true') : api.get('/fuel/cards');
};

/**
 * Suspend a single fuel card
 * @param {string} id fuel card id.
 */
export const postFuelCardSuspend = id => api.post(`/fuel/cards/${id}/suspend`);

/**
 * Reactivate a single fuel card
 * @param {string} id fuel card id.
 */
export const postFuelCardReactivate = id =>
  api.post(`/fuel/cards/${id}/reactivate`);

/**
 * Suspend a group of fuel cards
 * @param {string[]} cardIds array of fuel card ids
 */
export const postFuelCardSuspendBulk = cardIds => {
  return api.post(`/fuel/cards-bulk/suspend`, { fcIds: cardIds });
};

/**
 * Reactivate a group of fuel cards
 * @param {string[]} cardIds array of fuel card ids
 */
export const postFuelCardReactivateBulk = cardIds => {
  return api.post(`/fuel/cards-bulk/reactivate`, { fcIds: cardIds });
};

/**
 * Cancel any bulk request,
 * any fuel card ids that are in the queue will be cancelled
 */
export const postFuelCardCancelBulk = () => {
  return api.post(`/fuel/cards-bulk/cancel`);
};

// fuel management preferences: includes Asset Groups, Fuel Card Profiles, and Pin Management
// Pin Management
/**
 * Generate a random 4 digit fuel pin that is not in use,
 */
export const getRandomFuelPin = () => api.get('/fuel/pin/random');

export const getAllFuelPins = () => api.get('/fuel/pins');

/**
 * Used from My Account page, Returns fuel pin data for current user
 */
export const getUserFuelPin = () => api.get('/fuel/user/pin');

/**
 * Create new fuel pin
 * {Object} data
 * {string} data.assetId
 * {string} data.pin
 * {string} data.userId
 * {string} data.employeeId
 */
export const postCreateFuelPin = data => {
  return api.post(`/fuel/pin/create`, data);
};

export const patchFuelPin = (fuelPinId, data) => {
  return api.patch(`/fuel/pin/${fuelPinId}`, data);
};
export const getAssetGroups = () => api.get('/fuel/profile/asset-groups');

export const getAssetGroupByID = id =>
  api.get(`/fuel/profile/asset-group/${id}`);

export const postAssetToGroup = (id, assetId) =>
  api.post(`/fuel/profile/asset-group/${id}/asset/${assetId}`);

export const deleteAssetFromAssetGroup = (id, assetId) =>
  api.delete(`/fuel/profile/asset-group/${id}/asset/${assetId}`);

export const postFuelAssetGroup = data =>
  api.post(`/fuel/profile/asset-group`, data);

export const patchAssetGroup = (id, data) =>
  api.patch(`/fuel/profile/asset-group/${id}`, data);

export const deleteAssetGroup = id =>
  api.delete(`/fuel/profile/asset-group/${id}`);

export const getUnassignedAssets = () =>
  api.get('/fuel/profile/unassigned-assets');

export const getFuelProfiles = () => api.get('/fuel/profile/list');

export const getSingleFuelProfile = id => api.get(`/fuel/profile/${id}`);

export const getFuelProfileView = () => api.get('/fuel/profile/view');

/**
 * Create new fuel profile, format required by ARI
 * {Object} data
 * {string} data.name
 * {string} data.assetGroupId
 * {Object} data.data.details
 * {Object} data.data.products
 * {Object} data.data.schedules
 */
export const postFuelProfile = data => api.post('/fuel/profile', data);

export const patchFuelProfile = (id, data) => {
  return api.patch(`/fuel/profile/${id}`, data);
};

export const deleteFuelProfile = id => api.delete(`/fuel/profile/${id}`);

export const getWexProfileNames = () => api.get('/fuel/profile/wex-names');

export const getFuelKpiData = date =>
  api.get(`/fuel/transactions?format=tables&minDate=${date}`);
