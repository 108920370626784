/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { updateAsset } from '@holmanfm/lib/services/assets';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { isEmptyObject } from '@holmanfm/lib/util/helpers';
import TextField from '~/shared/components/forms/text-field';
import Button from '~/shared/components/atom/button';
import makeStyles from '~/shared/components/makeStyles';
import ProgressButton from '~/shared/components/progress-btn';
import ServerMessages from '~/shared/components/messages/server-messages';

const useStyles = makeStyles(theme => ({
  editRows: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '& > div:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const ROW_SIZE = 4;

const makeColumns = obj => {
  const makeArr = Object.keys(obj);
  return makeArr.reduce((acc, item, index) => {
    const group = index % ROW_SIZE;
    const groupSize = acc.length;
    if (group === 0) {
      acc.push([item]);
    } else {
      acc[groupSize - 1].push(item);
    }
    return acc;
  }, []);
};

const CustomAuxFieldsEditForm = props => {
  const {
    data,
    closeEditing,
    setHasDataUpdated,
    hasDataUpdated,
    auxData,
  } = props;
  const [ApiError, setApiError] = React.useState(null);
  const { t: cdT } = useNSTranslation('individualAsset', 'custom-data');
  const { t } = useNSTranslation('individualAsset', 'overview');

  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        auxData2: data?.assetExtend?.auxData2 || '',
        auxData3: data?.assetExtend?.auxData3 || '',
        auxData4: data?.assetExtend?.auxData4 || '',
        auxData5: data?.assetExtend?.auxData5 || '',
        auxData6: data?.assetExtend?.auxData6 || '',
        auxData7: data?.assetExtend?.auxData7 || '',
        auxData8: data?.assetExtend?.auxData8 || '',
        auxData9: data?.assetExtend?.auxData9 || '',
        auxData10: data?.assetExtend?.auxData10 || '',
        auxData11: data?.assetExtend?.auxData11 || '',
        auxData12: data?.assetExtend?.auxData12 || '',
        auxData13: data?.assetExtend?.auxData13 || '',
        auxData14: data?.assetExtend?.auxData14 || '',
      }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        const updatedValues = {
          id: data?.id,
          assetNo: data?.asset_no,
          assetExtend: {
            auxData2: values.auxData2 ?? null,
            auxData3: values.auxData3 ?? null,
            auxData4: values.auxData4 ?? null,
            auxData5: values.auxData5 ?? null,
            auxData6: values.auxData6 ?? null,
            auxData7: values.auxData7 ?? null,
            auxData8: values.auxData8 ?? null,
            auxData9: values.auxData9 ?? null,
            auxData10: values.auxData10 ?? null,
            auxData11: values.auxData11 ?? null,
            auxData12: values.auxData12 ?? null,
            auxData13: values.auxData13 ?? null,
            auxData14: values.auxData14 ?? null,
          },
        };
        updateAsset(updatedValues)
          .then(async () => {
            setSubmitting(false);
            setTimeout(() => {
              setHasDataUpdated(!hasDataUpdated);
              closeEditing();
            }, 3000);
          })
          .catch(err => {
            setSubmitting(false);
            setFieldError({ err });
            setApiError(err);
          });
      }}
    >
      {FormikBag => {
        const { handleSubmit, isSubmitting } = FormikBag;

        return (
          <Form>
            {makeColumns(auxData)?.map(ad => {
              return (
                <div className={classes.editRows} key={ad[0]}>
                  {ad.map(d => (
                    <TextField
                      key={d}
                      name={d}
                      label={auxData?.[d]?.name}
                      variant="outlined"
                      style={{ flex: 1 }}
                      maxLength={50}
                    />
                  ))}
                  {ad.length < ROW_SIZE &&
                    [...Array(ROW_SIZE - ad.length).keys()]?.map(s => (
                      <div key={s} className="spacers" style={{ flex: 1 }} />
                    ))}
                </div>
              );
            })}
            <div>
              <ProgressButton
                btnTitle={cdT('update-custom-fields')}
                submitFunc={handleSubmit}
                processing={isSubmitting}
                disabled={isEmptyObject(auxData)}
              />
              <Button onClick={closeEditing} variant="text" color="secondary">
                {t('cancel')}
              </Button>
              {ApiError && <ServerMessages messages={ApiError} />}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

CustomAuxFieldsEditForm.propTypes = {
  data: PropTypes.shape({}).isRequired,
  closeEditing: PropTypes.func.isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  hasDataUpdated: PropTypes.bool,
  auxData: PropTypes.shape({}).isRequired,
};

CustomAuxFieldsEditForm.defaultProps = {
  hasDataUpdated: undefined,
};

export default CustomAuxFieldsEditForm;
