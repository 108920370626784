import { MPActionTypes } from '../../../actions/action-types';
import { safeGet } from '../../../../util/helpers';

const {
  UPDATE_DELIVERY_STATE,
  UPDATE_DELIVERY_LOCATION,
  UPDATE_DELIVERY_PREFERENCE,
  UPDATE_VEHICLE_TITLE,
  SET_PURCHASE_CHECKOUT_OPEN,
  SET_VEHICLE_PURCHASE_AGREEMENT_AGREED,
  SET_DELIVERY_AUTHORIZATION_RELEASE_AGREED,
} = MPActionTypes.SV;

export const initialState = {
  deliveryOptions: {
    deliveryState: '',
    deliveryLocation: '',
    deliveryPreference: null,
    vehicleTitle: null,
  },
  purchaseCheckoutOpen: false,
};

const deliveryOptionsReducer = (state = initialState, action) => {
  const prevDeliveryOptions = safeGet(state, ['deliveryOptions']);
  switch (action.type) {
    case UPDATE_DELIVERY_STATE: {
      return {
        ...state,
        deliveryOptions: {
          ...prevDeliveryOptions,
          deliveryState: action.deliveryState,
          deliveryLocation: '',
        },
      };
    }

    case UPDATE_DELIVERY_LOCATION: {
      return {
        ...state,
        deliveryOptions: {
          ...prevDeliveryOptions,
          deliveryLocation: action.deliveryLocation,
        },
      };
    }

    case UPDATE_DELIVERY_PREFERENCE: {
      return {
        ...state,
        deliveryOptions: {
          ...prevDeliveryOptions,
          deliveryPreference: action.deliveryPreference,
        },
      };
    }

    case UPDATE_VEHICLE_TITLE: {
      return {
        ...state,
        deliveryOptions: {
          ...prevDeliveryOptions,
          vehicleTitle: action.vehicleTitle,
        },
      };
    }

    case SET_PURCHASE_CHECKOUT_OPEN:
      return {
        ...state,
        purchaseCheckoutOpen: action.purchaseCheckoutOpen,
      };

    case SET_VEHICLE_PURCHASE_AGREEMENT_AGREED:
      return {
        ...state,
        vehiclePurchaseAgreement: {
          ...state.vehiclePurchaseAgreement,
          [action.selectorId]: action.agreed,
        },
      };

    case SET_DELIVERY_AUTHORIZATION_RELEASE_AGREED:
      return {
        ...state,
        deliveryAuthorizationReleaseAgreement: {
          ...state.deliveryAuthorizationReleaseAgreement,
          [action.selectorId]: action.deliveryAuthorizationReleaseAgreed,
        },
      };

    default:
      return state;
  }
};

export default deliveryOptionsReducer;

export const getDeliveryOptions = state => safeGet(state, ['deliveryOptions']);
export const getPurchaseCheckoutOpen = state =>
  safeGet(state, ['purchaseCheckoutOpen']);
export const getVehiclePurchaseAgreementAgreed = (state, selectorId) =>
  safeGet(state, ['vehiclePurchaseAgreement', selectorId]) || false;
export const getDeliveryAuthorizationReleaseAgreed = (state, selectorId) =>
  safeGet(state, ['deliveryAuthorizationReleaseAgreement', selectorId]) ||
  false;
