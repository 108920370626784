/* eslint-disable no-case-declarations,no-use-before-define,no-shadow */
import { createSelector } from 'reselect';
import { OrgActionTypes } from '../../actions/action-types';
import { fetchOrgNodeSuccess } from '../../actions/org-nodes/org-node.actions';
import orgNode, * as fromOrgNode from './org-node.reducer';
import { transformOrgNodesArray } from './util';

export const initialState = {};

const orgNodesByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrgActionTypes.FETCH_ORG_NODES_SUCCESS:
      const newState = { ...state };

      return transformOrgNodesArray(action.orgNodes).reduce(
        (nextState, node) => {
          nextState[node.id] = orgNode(
            nextState[node.id],
            fetchOrgNodeSuccess(node)
          );
          return nextState;
        },
        { ...newState }
      );

    case OrgActionTypes.FETCH_ORG_NODE_REQUEST:
    case OrgActionTypes.UPDATE_ORG_NODE_REQUEST:
    case OrgActionTypes.ADD_ORG_NODE_REQUEST:
    case OrgActionTypes.REMOVE_ORG_NODE_REQUEST:
    case OrgActionTypes.FETCH_ORG_NODE_FAILURE:
    case OrgActionTypes.ADD_ORG_NODE_FAILURE:
    case OrgActionTypes.UPDATE_ORG_NODE_FAILURE:
    case OrgActionTypes.REMOVE_ORG_NODE_FAILURE:
      return {
        ...state,
        [action.id]: orgNode(state[action.id], action),
      };

    case OrgActionTypes.FETCH_ORG_NODE_SUCCESS:
    case OrgActionTypes.ADD_ORG_NODE_SUCCESS:
    case OrgActionTypes.UPDATE_ORG_NODE_SUCCESS:
      return {
        ...state,
        [action.orgNode.id]: orgNode(state[action.orgNode.id], action),
      };

    case OrgActionTypes.ADD_ORG_NODE_CHILD:
      if (!action.parentId) return state;
      return {
        ...state,
        [action.parentId]: orgNode(state[action.parentId], action),
      };

    case OrgActionTypes.REMOVE_ORG_NODE_SUCCESS:
      // remove org node and its occurences as a child
      const updatedState = Object.keys(state).reduce((acc, id) => {
        const node = fromOrgNode.getOrgNode(state[id]);
        if (id !== action.id) {
          acc[id] = orgNode(
            state[id],
            fetchOrgNodeSuccess({
              ...node,
              childIds: node.childIds.filter(childId => childId !== action.id),
            })
          );
        }
        return acc;
      }, {});
      return updatedState;

    default:
      return state;
  }
};

export default orgNodesByIdReducer;

//-----------------------------------------------
// Selectors

export const getOrgNode = (state, id) =>
  state[id] && fromOrgNode.getOrgNode(state[id]);
export const getIsFetchingOrgNode = (state, id) =>
  state[id] && fromOrgNode.getIsFetchingOrgNode(state[id]);
export const getOrgNodeMessages = (state, id) =>
  state[id] && fromOrgNode.getOrgNodeMessages(state[id]);

export const getOrgNodes = createSelector([state => state], state => {
  let orgNode;
  return Object.keys(state)
    .reduce((acc, id) => {
      orgNode = getOrgNode(state, id);
      if (orgNode && !orgNode.apiId) acc.push(orgNode);
      return acc;
    }, [])
    .sort((a, b) => {
      if (a.node_label > b.node_label) {
        return 1;
      }
      if (a.node_label < b.node_label) {
        return -1;
      }
      return 0;
    });
});

export const getOrgNodesDict = createSelector([state => state], state => {
  let orgNode;
  return Object.keys(state).reduce((acc, id) => {
    orgNode = getOrgNode(state, id);
    if (orgNode && !orgNode.apiId) acc[id] = orgNode;
    return acc;
  }, {});
});

export const getRootOrgNodes = createSelector([getOrgNodes], orgNodes =>
  orgNodes.filter(node => node && node.hierarchy_name.split('.').length === 1)
);
