/* eslint-disable import/prefer-default-export */
//-----------------------------------------------
// NODES ARRAY TRANSFORMATION

function getNodeChildren(parent, orgNodes) {
  return orgNodes.filter(
    node =>
      parent.id !== node.id &&
      node.hierarchy_name.startsWith(`${parent.hierarchy_name}.`) &&
      node.hierarchy_name.replace(`${parent.hierarchy_name}.`, '').split('.')
        .length === 1
  );
}

export function transformOrgNodesArray(orgNodes) {
  orgNodes = orgNodes.slice().sort((a, b) => {
    if (a.node_label > b.node_label) {
      return 1;
    }
    if (a.node_label < b.node_label) {
      return -1;
    }
    return 0;
  });

  return orgNodes.map(orgNode => ({
    ...orgNode,
    childIds: getNodeChildren(orgNode, orgNodes).map(node => node.id),
  }));
}

export function getAllDescendantIds(orgNodesDict, nodeId) {
  return orgNodesDict[nodeId].childIds.reduce(
    (acc, childId) => [
      ...acc,
      childId,
      ...getAllDescendantIds(orgNodesDict, childId),
    ],
    []
  );
}
