/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { BASE_URL } from '@holmanfm/lib/common/environment';
import { getMaintenanceHistory } from '@holmanfm/lib/services/maintenance-repair-history';
import useAsync from '@holmanfm/lib/hooks/use-async';
import { getMaintRepairHistory } from '@holmanfm/lib/services/parent-org';
import Typography from '~/shared/components/atom/typography/typography';
import PmNotificationSection from '~/areas/individual-assets/maintenance-components/pm-notification-section';
import LinkText from '~/shared/components/text-link';
import MaintenanceHistorySection from '~/areas/individual-assets/maintenance-components/maintenance-history-section';
import makeStyles from '~/shared/components/makeStyles';
import MaintKPI from '~/areas/individual-assets/maintenance-components/maint-kpi';
import PmNextDue from '~/areas/individual-assets/maintenance-components/pm-next-due';
import CircularProgress from '~/shared/components/circular-progress';
import ServerMessages from '~/shared/components/messages/server-messages';

const useStyles = makeStyles(theme => ({
  tanDivider: {
    borderBottom: `1px solid ${theme.palette.backgroundColors.tan}`,
    padding: theme.spacing(2),
  },
}));

const MaintenanceTab = props => {
  const {
    asset,
    setHasDataUpdated,
    hasDataUpdated,
    parentOrg,
    childOrgId,
  } = props;
  const {
    run: maintHistoryRun,
    data: maintHistoryData,
    status,
    error,
  } = useAsync({
    status: 'pending',
  });
  const classes = useStyles();

  React.useEffect(() => {
    if (parentOrg) {
      maintHistoryRun(
        getMaintRepairHistory(childOrgId, asset?.id).then(
          res => res.payload?.items
        )
      );
    } else {
      maintHistoryRun(
        getMaintenanceHistory(asset?.id).then(res => res?.payload?.items)
      );
    }
  }, [maintHistoryRun, asset, childOrgId, parentOrg]);

  const { t } = useNSTranslation('individualAsset', 'maintenance');

  if (status === 'pending') {
    return <CircularProgress />;
  }
  if (status === 'rejected') {
    return <ServerMessages messages={error} />;
  }

  return (
    <div>
      <Typography variant="h5" style={{ padding: '24px 0' }}>
        {t('title')}
      </Typography>
      <div>
        <MaintKPI data={maintHistoryData} asset={asset} />
      </div>
      <div>
        <div style={{ textAlign: 'right' }}>
          {parentOrg ? null : (
            <Typography variant="h6">
              <LinkText
                href={`${BASE_URL}/v1/pdf/maintenance-card/${asset?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('view-download-maint-card')}
              </LinkText>
            </Typography>
          )}
        </div>
        <PmNotificationSection
          asset={asset}
          setHasDataUpdated={setHasDataUpdated}
          hasDataUpdated={hasDataUpdated}
          parentOrg={parentOrg}
        />
        <div className={classes.tanDivider} />
        <PmNextDue
          asset={asset}
          parentOrg={parentOrg}
          childOrgId={childOrgId}
        />
        <div className={classes.tanDivider} />
        <MaintenanceHistorySection asset={asset} data={maintHistoryData} />
      </div>
    </div>
  );
};

MaintenanceTab.propTypes = {
  asset: PropTypes.shape({}).isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  hasDataUpdated: PropTypes.bool.isRequired,
  parentOrg: PropTypes.bool,
  childOrgId: PropTypes.string,
};

MaintenanceTab.defaultProps = {
  parentOrg: false,
  childOrgId: undefined,
};

export default MaintenanceTab;
