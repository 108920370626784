/* eslint-disable func-names,no-shadow,prefer-const */
// import { selectors } from '../reducers/index.reducer';
// import { refreshToken } from './auth.actions';

// NOTE this is no longer used in UI - API refreshes tokens now.
// But this is good piece of code, so I keep it.

// const verifyTokens = Storage => ({ dispatch, getState }) => {
//   let bufferedActions = [];

//   return next => action => {
//     if (!action.type) {
//       const state = getState();
//       const user = selectors.getCurrentUser(state);
//       const isFetching = selectors.getLoggingIn(state);

//       if (isFetching) {
//         bufferedActions.push({ next, action });
//       } else if (!user) {
//         return next(action);
//       } else if (new Date().getTime() / 1000 < user.exp) {
//         return next(action);
//       } else {
//         return refreshToken(dispatch, Storage)
//           .then(() => {
//             bufferedActions.forEach(actionObj =>
//               actionObj.next(actionObj.action)
//             );
//             bufferedActions = [];
//             return next(action);
//           })
//           .catch(() => {
//             bufferedActions = [];
//             return next(action);
//           });
//       }
//     } else {
//       return next(action);
//     }
//   };
// };

const callAPI = Storage => ({ dispatch, getState }) => next => action => {
  const {
    actions,
    callAPI,
    shouldCallAPI = () => true,
    addPayload = null,
    successPayload = undefined,
    responseMapper,
    onSuccess,
    beforeSuccess,
    onFailure,
  } = action;

  if (!actions) {
    // Normal action: pass it on
    return next(action);
  }

  if (
    !Array.isArray(actions) ||
    actions.length !== 3 ||
    !actions.every(action => typeof action === 'function')
  ) {
    throw new Error('Expected an array of three action creators.');
  }

  if (typeof callAPI !== 'function') {
    throw new Error('Expected callAPI to be a function.');
  }

  if (!responseMapper) {
    throw new Error('Expected responseMapper to be defined.');
  }

  if (shouldCallAPI && !shouldCallAPI(getState())) return;

  const [request, success, failure] = actions;

  dispatch(request(addPayload));

  const state = getState();

  return callAPI(state, Storage).then(
    response => {
      if (beforeSuccess) beforeSuccess(response, dispatch, Storage);
      if (successPayload !== undefined) {
        dispatch(success(successPayload, []));
      } else {
        dispatch(success(...responseMapper.map(response)));
      }
      if (onSuccess) onSuccess(response, dispatch, Storage, state);
      return response;
    },

    errors => {
      if (addPayload) {
        dispatch(failure(addPayload, errors));
      } else {
        dispatch(failure(errors));
      }
      if (onFailure) {
        onFailure(errors, dispatch, Storage, state);
      }
      // Added by Alex: I don't rethrow to not break other code.
      // This object has the same structure as the one in selectors.
      return {
        failed: true,
        messages: errors,
      };
    }
  );
};

export default {
  // verifyTokens,
  callAPI,
};
