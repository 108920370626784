import set from 'lodash/set';
import get from 'lodash/get';
import { formatDate } from '../util/moment';
import {
  createNoopTransition,
  isNoopTransition,
} from './status-transition-helpers';

export const requestStatusEmptyDate = '-- / -- / ----';

export const findUnassignedTransition = ({ to }) => to === 'unassigned';
/**
 * @todo
 * Ensure thats if we unassign without clearing the vendor, the vendor is unassigned.
 * This should probably be done on the API. 🤷‍♂️
 */
export const getVendorIdForUpdate = (
  newTransition,
  availableTransitions,
  vendorId
) => {
  const unassignedTransition = availableTransitions.find(
    findUnassignedTransition
  );

  if (
    unassignedTransition &&
    unassignedTransition.transition === newTransition
  ) {
    return null;
  }

  return vendorId === '' ? null : vendorId;
};

/**
 * @todo - Move to API...
 *
 *  If we are assigne and and have a vendor ID but submit an update without a vendor ID
 * the assumption is the user selected "null" vendor, and we unassign.
 */
export const getTransitionForUpdate = (
  currVendor,
  newVendorId,
  newTransition,
  unassignTransition
) => {
  if (Boolean(currVendor) && newVendorId === '' && unassignTransition) {
    return unassignTransition.transition;
  }

  return isNoopTransition(newTransition) ? undefined : newTransition;
};
/**
 * What the heck is a noop transition? And what are these status-transition-helpers?
 *
 * TLDR Each radio in the RequestStatusDisplay needs a unique value for state management to function.
 * I generate transition values for those which cannot be transitioned too then ignore those before
 * we send the API request.
 *
 */

export function getSelectedVendor({
  formik,
  recentVendors,
  requestVendor,
  foundVendor,
}) {
  const {
    status: { preferredVendors },
    values,
  } = formik;
  return (
    values.vendorId &&
    [...preferredVendors, ...recentVendors, requestVendor, foundVendor].find(
      v => v && v.id === values.vendorId
    )
  );
}

export function getIsAssignedPreferred({ formik, requestVendor }) {
  const {
    status: { preferredVendors },
  } = formik;
  return Boolean(
    requestVendor && preferredVendors.some(v => v.id === requestVendor.id)
  );
}

export function getIsFoundVendorNew({
  formik,
  foundVendor,
  recentVendors,
  requestVendor,
}) {
  const {
    status: { preferredVendors },
  } = formik;
  return (
    foundVendor &&
    ![...preferredVendors, ...recentVendors, requestVendor].some(
      v => v && v.id === foundVendor.id
    )
  );
}

export function getIsSelectedPreferred({ selectedVendor, formik }) {
  const {
    status: { preferredVendors },
  } = formik;
  return Boolean(
    selectedVendor && preferredVendors.some(v => v.id === selectedVendor.id)
  );
}

export function getHandleChange(statuses, setFieldValue) {
  return () => {
    const unassignedNewStatus = statuses.find(
      entry => entry.status === 'unassigned'
    );
    if (unassignedNewStatus.transition) {
      setFieldValue('vendorId', '');
      setFieldValue('transition', unassignedNewStatus.transition);
    }
  };
}

export function getHandleSelectVendor(
  setFieldValue,
  setFoundVendor,
  closeDialog
) {
  return vendor => {
    setFieldValue('vendorId', vendor.id);
    setFoundVendor(vendor);
    closeDialog();
  };
}

export function getStatusDate(statusDate) {
  return statusDate ? formatDate(statusDate) : requestStatusEmptyDate;
}

export const getActiveStatus = (request, statuses) =>
  statuses.find(({ name }) => name === request.status);

export const getNoopMap = statuses =>
  statuses.reduce(
    (result, current) =>
      set(result, current.name, createNoopTransition(current.name)),
    {}
  );

export const getStatusChangeHandlers = (activeStatus, noopMap) =>
  get(activeStatus, 'transitions', []).reduce(
    (result, current) => set(result, current.to, current.transition),
    noopMap
  );

export const getMappedStatuses = (statuses, statusChangeHandlers) =>
  statuses.map(status => ({
    status: status.name,
    date: getStatusDate(status.date),
    transition: statusChangeHandlers[status.name],
    canUpdateDate: status.canUpdateDate,
  }));

export const canChangeVendor = status =>
  ['unassigned', 'out-to-bid', 'assigned'].includes(status);
