/* eslint-disable import/prefer-default-export */

export const assetSearchLink = (searchTerm, parentOrg) =>
  parentOrg
    ? `/parent/assets?searchTerm=${searchTerm}`
    : `/asset/assets?searchTerm=${searchTerm}`;

export const managementRequestLink = (assetNo, parentOrg, childOrgId) =>
  parentOrg
    ? `/parent/${childOrgId}/requests?searchTerm=${assetNo}`
    : `/customer/request-management?searchTerm=${assetNo}`;

export const fuelManagementAsset = (assetId, parentOrg, childOrgId) =>
  parentOrg
    ? `/parent/${childOrgId}/fuel-management/asset-transactions/${assetId}`
    : `/customer/services/fuel-management/asset-transactions/${assetId}`;

export const individualAssetView = (assetNo, parentOrg, childOrgId) =>
  parentOrg ? `/parent/assets/${assetNo}/${childOrgId}` : `/assets/${assetNo}`;
