/* eslint-disable import/prefer-default-export */
import api from '../../util/api';

/**
 * Just pass in the VIN to decode
 * @param {String} vin
 */
export const decodeVin = vin =>
  api.get('/asset/decode-vin', { params: { vin } });

export const decodeVinAsync = async vin => {
  try {
    return (await api.get('/asset/decode-vin', { params: { vin } }))?.payload;
  } catch (_) {
    return null;
  }
};
