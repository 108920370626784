import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '~/shared/components/table-cell';
import Typography from '~/shared/components/atom/typography/typography';
import withStyles from '~/shared/components/withStyles';

const StyledTableCell = withStyles(theme => ({
  root: {
    border: 'none',
    borderRadius: 4,
    borderColor: theme.palette.grey[400],
  },
}))(TableCell);

const HSBSTableCell = ({ children, align }) => {
  return (
    <StyledTableCell align={align}>
      <Typography variant="subtitle2">{children}</Typography>
    </StyledTableCell>
  );
};

HSBSTableCell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  align: PropTypes.string,
};
HSBSTableCell.defaultProps = {
  children: null,
  align: 'inherit',
};

export default HSBSTableCell;
