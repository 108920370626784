/**
 * Returns true if the `obj` has a property with the name `prop`.
 * @param {string} prop
 * @param {object} obj
 * @example
 *  has('key', {}) // false
 *  has('key', { key: {} }) // true
 *  has('key', { key: null }) // true
 *  has('key', { key: undefined }) // true
 */
export default function has(prop, obj) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}
