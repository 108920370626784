import get from 'lodash/get';

function normalizePath(path) {
  const [head] = path.split('[]');
  return head;
}

/**
 *  The first step to understanding recursion is to understand recursion ™️
 *
 * Given an item, paths, and an accumulated list dig into item at paths to find values. This is safeGet
 * with the twist that if the found value is an array it will iterate through that list using the
 * remain path to sniff out the end values. Also, it handles the `string[]` notation used by the API
 * but simply discards the `[]` portion (this means the API can stop using it).
 *
 * @example
 *   getFieldValue({ numbers: [1, 2, 3] }, 'numbers') -> [1, 2, 3]
 *   getFieldValue({ names: [{ value: 'michael' }, { value: 'gary' }, { value: 'scott' }}] }, 'names[].value') -> [ 'michael', 'gary', 'scott' ]
 *   getFieldValue({ names: [{ value: 'michael' }, { value: 'gary' }, { value: 'scott' }}] }, 'names.value') -> [ 'michael', 'gary', 'scott' ]
 *
 */
function getFieldValue(item, paths = [], init = []) {
  switch (paths.length) {
    case 0: {
      return item;
    }

    /** key or key[] */
    case 1: {
      const [path] = paths;
      const result = get(item, normalizePath(path));
      return result;
    }

    default: {
      const [path, ...tail] = paths;
      const value = get(item, normalizePath(path));
      return Array.isArray(value)
        ? value.reduce(
            (acc, curr) => acc.concat(getFieldValue(curr, tail)),
            init
          )
        : getFieldValue(value, tail);
    }
  }
}

export default function getFieldValues(item, path) {
  return getFieldValue(item, path.split('.'));
}
