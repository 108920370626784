import React from 'react';
import PropTypes from 'prop-types';
import useFileManager from '@holmanfm/lib/hooks/file-manager/use-file-manager';
import Backdrop from '~/shared/components/transitions/backdrop';
import CircularProgress from '~/shared/components/circular-progress';
import Grid from '~/shared/components/grid';
import ImageList from './file-list/image-list';
import FileUpload from './file-upload';
import ServerMessages from '../messages/server-messages';
import { FileType } from './prop-types';
import OtherFileList from './file-list/other-file-list';
import Typography from '~/shared/components/atom/typography/typography';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10000000;

const FileManager = ({
  files,
  setFiles,
  onUploadFile,
  onDeleteFile,
  fetchFiles,
  maxFileSizeInBytes,
  accept,
  multiple,
  helperText,
  helperTextStyle,
  isDisabled,
}) => {
  // This ref is used to reset html input once there are no more files,
  // so that the user can upload the same file again. HTML file input limitation.
  const inputRef = React.useRef(null);
  const { handleUpload, handleRemoveFile, status, error } = useFileManager({
    files,
    setFiles,
    onUploadFile,
    onDeleteFile,
    fetchFiles,
    maxFileSizeInBytes,
    accept,
    multiple,
    inputRef,
  });

  const images = files.filter(file => file.type?.startsWith('image/'));
  const otherFiles = files.filter(file => !file.type?.startsWith('image/'));

  return (
    <div style={{ position: 'relative' }}>
      <Backdrop
        style={{ zIndex: 10000, position: 'absolute' }}
        open={status === 'pending'}
      >
        <CircularProgress size={40} style={{ color: 'white' }} />
      </Backdrop>
      {error && <ServerMessages messages={error} />}
      <Grid container>
        {!isDisabled && (
          <Grid item id="file-upload-container">
            <FileUpload
              onUpload={handleUpload}
              multiple={multiple}
              accept={accept}
              ref={inputRef}
            />
            {helperText && (
              <Typography
                variant="caption"
                component="div"
                style={helperTextStyle}
              >
                {helperText}
              </Typography>
            )}
          </Grid>
        )}
        <Grid item>
          <OtherFileList files={otherFiles} removeFile={handleRemoveFile} />
        </Grid>
      </Grid>

      <ImageList files={images} removeFile={handleRemoveFile} />
    </div>
  );
};

FileManager.propTypes = {
  files: PropTypes.arrayOf(FileType).isRequired,
  setFiles: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  fetchFiles: PropTypes.func.isRequired,
  maxFileSizeInBytes: PropTypes.number,
  accept: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
  helperTextStyle: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
};

FileManager.defaultProps = {
  maxFileSizeInBytes: DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  multiple: false,
  accept: undefined,
  helperText: undefined,
  helperTextStyle: undefined,
  isDisabled: false,
};

export default FileManager;
