import { Provider, connect, useSelector, useDispatch } from 'react-redux';
import storeFactory from './store';

const createAction = type => {
  const actionCreator = payload => ({ type, payload });

  actionCreator.type = type;

  actionCreator.toString = () => `${type}`;

  return actionCreator;
};

export {
  createAction,
  connect,
  Provider,
  storeFactory,
  useSelector,
  useDispatch,
};
