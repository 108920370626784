import get from 'lodash/get';
import getFieldValues from '../util/get-field-values';
import { formatDate } from '../util/moment';

export const isSearchable = ({ search }) => Boolean(search);

export function createSearchFilters(searchTerm = '', fields) {
  return {
    logic: 'or',
    filters: fields.filter(isSearchable).map(field => {
      const ignoreCase = get(field, 'search.ignoreCase', true);
      return {
        field: item => {
          const values = getFieldValues(item, field.id);
          if (!values) {
            return values;
          }

          if (values.getTime) {
            return formatDate(values);
          }

          return String(values).toLowerCase();
        },
        value: searchTerm,
        operator: get(field, 'search.operator', 'contains'),
        ignoreCase,
      };
    }),
  };
}
