import MUIMenuItem from '@material-ui/core/MenuItem';
import MUISelect from '@material-ui/core/Select';
import { connect, getIn } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import FormControl from '../form-control';
import FormHelperText from '../form-helper-text';
import InputLabel from '../input-label';

export const MenuItem = MUIMenuItem;

const Select = props => {
  const { formik, handleChange: propsHandleChange, ...rest } = props;

  const {
    name,
    id,
    autoComplete,
    fullWidth,
    margin,
    label,
    helpText,
    children,
    disabled,
    variant,
    InputLabelProps: labelProps,
    displayEmpty,
    notched,
    required = false,
    className,
    style,
    selectClassName,
  } = rest;
  const {
    errors,
    values,
    handleChange: formikHandleChange,
    handleBlur,
    touched,
  } = formik;

  const fieldValue = getIn(values, name);
  const fieldError = getIn(errors, name);
  const touch = getIn(touched, name);

  let formControlProps = {
    fullWidth,
    margin,
    variant,
    required,
    style,
    className,
  };

  const inputLabelProps = {
    htmlFor: id,
    variant,
    disabled,
    ...labelProps,
    shrink: displayEmpty || fieldValue?.length > 0,
    disableAnimation: fieldValue === '' && !displayEmpty,
    error: Boolean(fieldError),
  };

  const selectProps = {
    value: fieldValue,
    onChange: propsHandleChange || formikHandleChange,
    variant,
    label,
    disabled,
    displayEmpty,
    notched,
    onBlur: handleBlur,
    inputProps: { name, id, autoComplete },
    className: selectClassName,
  };

  if (fieldError && touch) {
    formControlProps = {
      ...formControlProps,
      error: Boolean(fieldError),
    };
  }

  return (
    <FormControl {...formControlProps}>
      <InputLabel {...inputLabelProps}>{label}</InputLabel>
      {/** @todo Remove height when materializecss is removed */}
      <MUISelect {...selectProps}>{children}</MUISelect>
      {(fieldError || helpText) && touch && (
        <FormHelperText>{fieldError || helpText}</FormHelperText>
      )}
    </FormControl>
  );
};

Select.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
  }).isRequired,
  autoComplete: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  helpText: PropTypes.string,
  margin: PropTypes.oneOf(['normal', 'none', 'dense']),
  children: PropTypes.node.isRequired,
  displayEmpty: PropTypes.bool,
  variant: PropTypes.string,
  handleChange: PropTypes.func,
  style: PropTypes.shape({}),
  selectClassName: PropTypes.string,
};

Select.defaultProps = {
  style: undefined,
  fullWidth: false,
  helpText: undefined,
  margin: 'none',
  displayEmpty: false,
  variant: 'standard',
  autoComplete: 'off',
  handleChange: undefined,
  label: undefined,
  selectClassName: '',
};

export default connect(Select);
