import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import Typography from '~/shared/components/atom/typography';
import StyledDialog from '~/shared/components/styled-dialog';
import useEstimator from '~/areas/dealer-portal/payment-estimator/use-estimator';

const CancelEstimateDialog = props => {
  const { open, closeDialog, workflowId } = props;
  const { handleCancelEstimate } = useEstimator();

  const { t } = useNSTranslation('dealerPortal', 'payment-estimator');

  return (
    <StyledDialog
      isOpen={open}
      onClose={closeDialog}
      dialogTitle={t('cancel-estimate')}
      btnActions={{
        btnFunc: () => handleCancelEstimate(workflowId, 'requestManagement'),
        btnTitle: t('cancel-estimate'),
      }}
      closeText={t('close')}
    >
      <div>
        <Typography variant="body2">{t('cancel-estimate-body1')}</Typography>
      </div>
    </StyledDialog>
  );
};

CancelEstimateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  workflowId: PropTypes.string.isRequired,
};

export default CancelEstimateDialog;
