/* eslint-disable func-names,no-shadow,prefer-const,max-classes-per-file */

export function makeActionCreator(type, ...argNames) {
  if (!type) throw new Error('Action Type does not exist!');

  return function(...args) {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}

export function createSortArray(sortArray) {
  if (!sortArray) return null;
  return sortArray.map(s => `${s.field},${s.dir}`);
}

export class ActionsResponseMapperBase {
  constructor(options = {}) {
    this.options = options;
  }

  applyOptionsToPayload(payload) {
    if (this.options.apply) {
      payload = this.options.apply(payload);
    }

    if (this.options.parse) {
      payload = JSON.parse(payload);
    }

    if (this.options.append) {
      payload = { ...payload, ...this.options.append };
    }

    return payload;
  }

  map() {
    if (this.constructor === ActionsResponseMapperBase) {
      throw new Error('Not implemented yet');
    }
  }
}

export class ActionsResponseMapper extends ActionsResponseMapperBase {
  map(response) {
    super.map(response);
    let { payload, messages } = response;
    payload = this.applyOptionsToPayload(payload);
    return [payload, messages];
  }
}

// NOTE fake telemetry lib:
// const a = Math.random() * 2 * Math.PI;
// const r = 0.5 * Math.sqrt(Math.random());
//
// {
//   latitude: r * Math.cos(a) + 39.95,
//   longitude: r * Math.sin(a) - 74.97,
//   driving: Math.random() > 0.3,
//   bearing: Math.random() * 180,
//   fuel_percent: Math.floor(Math.random() * 100),
//   battery_voltage: (2 * Math.random() + 12).toFixed(2),
// }

export class AssetsActionsResponseMapper extends ActionsResponseMapper {
  map(response) {
    let [payload, messages] = super.map(response);

    const rows = payload.rows.map(row => {
      if (!row.asset_telemetry) {
        // console.warn(`asset ${row.id} is missing telemetry data`);
        row.asset_telemetry = {};
      }

      return row;
    });

    if (this.options.asArray) {
      payload = rows;
    } else {
      payload.rows = rows;
    }

    return [payload, messages];
  }
}

export class AssetsListResponseMapper extends ActionsResponseMapper {
  map(response) {
    let [payload, messages] = super.map(response);
    return [
      {
        ...payload,
      },
      messages,
    ];
  }
}

export class AssetsFiltersResponseMapper extends ActionsResponseMapper {
  map(response) {
    let [payload, messages] = super.map(response);
    if (!payload || payload.length === 0) return [payload, messages];

    const payloadKeys = Object.keys(payload[0]);

    const dict = payloadKeys.reduce((acc, val) => {
      const filteredData = payload.filter(x => x[val] !== null);
      const firstFive = filteredData.slice(0, 5);

      acc[val] = {};
      acc[val].hasMore = filteredData.length > 5;
      acc[val].rows = firstFive.map(x => {
        return { val: x[val], isSelected: false };
      });
      return acc;
    }, {});

    return [dict, messages];
  }
}

export class AssetsFilterResponseMapper extends ActionsResponseMapper {
  map(response) {
    let [payload, messages] = super.map(response);
    if (!payload || payload.length === 0) return [payload, messages];

    const isFetchAll = payload.fetchAll === 'true';
    const key = Object.keys(payload.rows[0])[0];
    const hasMore = payload.rows.length > 5;
    let rowsToAdd = payload.rows.slice(0, 5); // Slice the next 5 rows

    if (isFetchAll) {
      // Append all remaining rows
      rowsToAdd = payload.rows;
    }

    const p = rowsToAdd.reduce(
      (acc, val) => {
        acc.key = key;
        if (!isFetchAll) {
          acc.hasMore = hasMore;
        } else {
          acc.hasMore = false;
        }
        acc.rows.push({ val: val[key], isSelected: false });

        return acc;
      },
      { rows: [] }
    );

    return [p, messages];
  }
}

export class PaymentChargeActionsResponseMapper extends ActionsResponseMapper {
  map(response) {
    let [payload, messages] = super.map(response);
    payload.rows = payload.rows.map(row => {
      row.invoicePaymentHistory.response_created_at = new Date(
        row.invoicePaymentHistory.response_created_at
      );
      row.invoicePaymentHistory.response_created_at.setHours(0, 0, 0, 0);

      row.invoiceItem = row.invoiceItem.map(subRow => {
        // Invoice Date Conversion
        subRow.invoice_date = new Date(subRow.invoice_date);
        subRow.invoice_date.setHours(0, 0, 0, 0);

        // Reference Date Conversion
        subRow.reference_date = new Date(subRow.reference_date);
        subRow.reference_date.setHours(0, 0, 0, 0);
        return subRow;
      });

      return row;
    });
    return [payload, messages];
  }
}

export class ActionsResponseMapperSimple extends ActionsResponseMapperBase {
  map(response) {
    super.map(response);
    return [response];
  }
}

export class ActionsResponseMapperEmpty extends ActionsResponseMapperBase {
  map() {
    return [];
  }
}
