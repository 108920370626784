import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import clx from 'classnames';
import Dialog from '~/shared/components/dialog';
import makeStyles from '~/shared/components/makeStyles';
import IconButton from '~/shared/components/atom/icon-button';
import Close from '~/shared/icons/close';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '1600px',
    margin: 'auto',
    '& .slick-next': {
      top: '90%',
      right: '25px',
      zIndex: 999,
      width: '30px',
      height: '30px',
      [theme.breakpoints.down('xs')]: {
        top: '85%',
      },
      '&:hover': {
        background: theme.palette.grey[400],
        borderRadius: '20px',
      },
      '&:before': {
        fontSize: '40px',
        marginLeft: '-5px',
        color: theme.palette.grey[200],
        [theme.breakpoints.down('xs')]: {
          fontSize: '25px',
        },
      },
    },
    '& .slick-prev': {
      top: '90%',
      left: '25px',
      zIndex: 999,
      width: '30px',
      height: '30px',
      [theme.breakpoints.down('xs')]: {
        top: '85%',
      },
      '&:hover': {
        background: theme.palette.grey[400],
        borderRadius: '20px',
      },
      '&:before': {
        fontSize: '40px',
        marginLeft: '-5px',
        color: theme.palette.grey[200],
        [theme.breakpoints.down('xs')]: {
          fontSize: '25px',
        },
      },
    },
    '& button.slick-arrow': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  modal: {
    '& .slick-next': {
      top: '50%',
      [theme.breakpoints.down('xs')]: {
        right: 0,
      },
    },
    '& .slick-prev': {
      top: '50%',
      [theme.breakpoints.down('xs')]: {
        left: 0,
      },
    },
  },
  modalImage: {
    marginBottom: '-6px',
    maxHeight: '80vh',
    height: 'auto',
    width: '100%',
  },
  modalClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 999,
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: 0,
    },
    '& svg': {
      fontSize: '30px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
      '&:hover': {
        fill: '#fff',
      },
    },
  },
}));

const ImageViewerDialog = ({
  open,
  handleClose,
  photos,
  initialIndex,
  renderTitle,
}) => {
  const classes = useStyles();

  const settingsModal = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: initialIndex,
    adaptiveHeight: true,
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="large-photo-viewer"
      open={open}
      className={clx(classes.container, classes.modal)}
      maxWidth="md"
    >
      {!renderTitle && (
        <IconButton
          aria-label="close"
          className={classes.modalClose}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      )}

      <Slider {...settingsModal}>
        {photos.map((photo, i) => (
          <div key={photo}>
            {renderTitle && renderTitle(i)}
            <img alt={photo} src={photo} className={classes.modalImage} />
          </div>
        ))}
      </Slider>
    </Dialog>
  );
};

ImageViewerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderTitle: PropTypes.func,
  initialIndex: PropTypes.number,
};

ImageViewerDialog.defaultProps = {
  renderTitle: undefined,
  initialIndex: 0,
};

export default ImageViewerDialog;
