import React from 'react';
import { ReactComponent } from '@holmanfm/lib/assets/icons/green-drive-bar.svg';
import SvgIcon from '~/shared/components/svg-icon';

export default props => (
  <SvgIcon
    style={{ width: 45, height: 5 }}
    component={ReactComponent}
    viewBox="0 0 45 5"
    {...props}
  />
);
