/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import useAsync from '@holmanfm/lib/hooks/use-async';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { getAssetOdometer } from '@holmanfm/lib/services/assets';
import { formatDate } from '@holmanfm/lib/util/moment';
import { getChildOdometer } from '@holmanfm/lib/services/parent-org';
import Typography from '~/shared/components/atom/typography/typography';
import HSBSTableHeader from '~/areas/fleet-assets/hsbs-table/hsbs-table-header';
import HSBSTableCell from '~/areas/fleet-assets/hsbs-table/hsbs-table-cell';
import HSBSTable from '~/areas/fleet-assets/hsbs-table/hsbs-table';
import HSBSTableRow from '~/areas/fleet-assets/hsbs-table/hsbs-table-row';
import CircularProgress from '~/shared/components/circular-progress';
import ServerMessages from '~/shared/components/messages/server-messages';

const OdometerTab = props => {
  const { asset, parentOrg, childOrgId } = props;
  const { run, status, error, data: odometerData } = useAsync({
    status: 'pending',
  });

  React.useEffect(() => {
    if (parentOrg) {
      run(getChildOdometer(childOrgId, asset?.id).then(res => res.payload));
    } else {
      run(getAssetOdometer(asset?.id).then(res => res.payload));
    }
  }, [run, asset, parentOrg, childOrgId]);

  const { t } = useNSTranslation('individualAsset', 'odometer');

  const headerRows = (
    <>
      <HSBSTableHeader>{t('odometer')}</HSBSTableHeader>
      <HSBSTableHeader>{t('entry-date')}</HSBSTableHeader>
      <HSBSTableHeader>{t('source')}</HSBSTableHeader>
      {/* <HSBSTableHeader>{t('quality')}</HSBSTableHeader> */}
    </>
  );

  const bodyRows = (od, index) => (
    <React.Fragment key={od.odometerDate || od.odometer}>
      <HSBSTableRow name={index}>
        <HSBSTableCell>{od?.odometer?.toLocaleString()}</HSBSTableCell>
        <HSBSTableCell>
          {od?.odometerDate ? formatDate(od?.odometerDate) : ''}
        </HSBSTableCell>
        <HSBSTableCell>{od?.odometerSource}</HSBSTableCell>
        {/* <HSBSTableCell>Excluded</HSBSTableCell> */}
      </HSBSTableRow>
    </React.Fragment>
  );

  const defaultOdometerArray = () => {
    if (asset?.latest_odometer) {
      return [
        {
          odometer: asset?.latest_odometer || '',
          odometerDate: asset?.odometer_date,
          odometerSource: asset?.odometer_source || '',
        },
      ];
    }
    return [
      {
        odometer: 'No Data',
        odometerDate: asset?.odometerDate,
        odometerSource: asset?.odometer_source || '',
      },
    ];
  };

  if (status === 'pending') {
    return <CircularProgress />;
  }
  if (status === 'rejected') {
    return <ServerMessages messages={error} />;
  }

  return (
    <div>
      <Typography variant="h5" style={{ padding: '24px 0' }}>
        {t('title')}
      </Typography>
      {/* Hide KPIs for the moment */}
      {/* <div
        style={{ border: '1px solid #ddd', padding: '24px', display: 'flex' }}
      >
        <div style={{ flex: 1, textAlign: 'center' }}>
          <Typography variant="subtitle2">{t('current-odometer')}</Typography>
          <Typography variant="body1">
            {asset?.latest_odometer?.toLocaleString()} miles
          </Typography>
        </div>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <Typography variant="subtitle2">{t('avg-monthly-travel')}</Typography>
          <Typography variant="body1">Metric Value miles</Typography>
        </div>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <Typography variant="subtitle2">{t('avg-daily-travel')}</Typography>
          <Typography variant="body1">Metric Value miles</Typography>
        </div>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <Typography variant="subtitle2">
            {t('avg-entries-per-person')}
          </Typography>
          <Typography variant="body1">Metric Value</Typography>
        </div>
      </div> */}
      <div style={{ marginTop: 32 }}>
        <HSBSTable
          headerRows={headerRows}
          bodyRows={
            odometerData?.length > 0
              ? odometerData?.map(od => bodyRows(od))
              : defaultOdometerArray().map(od => bodyRows(od))
          }
        />
      </div>
    </div>
  );
};

OdometerTab.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    latest_odometer: PropTypes.number,
    odometer_date: PropTypes.string,
    odometer_source: PropTypes.string,
  }).isRequired,
  parentOrg: PropTypes.bool,
  childOrgId: PropTypes.string,
};

OdometerTab.defaultProps = {
  parentOrg: false,
  childOrgId: undefined,
};

export default OdometerTab;
