import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from '@holmanfm/lib/redux';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import { formatDate } from '@holmanfm/lib/util/moment';
import useFuelSuspend from '@holmanfm/lib/hooks/fuel/use-fuel-suspend';
import useDialog from '@holmanfm/lib/hooks/use-dialog';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { getStatusColorFromPalette } from '@holmanfm/lib/common/service-request-helpers';
import HSBSTable from '~/areas/fleet-assets/hsbs-table/hsbs-table';
import HSBSTableHeader from '~/areas/fleet-assets/hsbs-table/hsbs-table-header';
import HSBSTableRow from '~/areas/fleet-assets/hsbs-table/hsbs-table-row';
import HSBSTableCell from '~/areas/fleet-assets/hsbs-table/hsbs-table-cell';
import CircularProgress from '~/shared/components/circular-progress';
import Link from '~/shared/components/link';
import LinkText from '~/shared/components/text-link';
import StyledDialog from '~/shared/components/styled-dialog';
import Typography from '~/shared/components/atom/typography/typography';

const AssociatedFuelCards = props => {
  const {
    fuelCards,
    fuelPreferences,
    parentOrg,
    hasDataUpdated,
    setHasDataUpdated,
  } = props;
  const { wexAccountNumber } = useSelector(selectors.getOrg);
  const { t: fuelT } = useNSTranslation('fuel', 'manage-cards');
  const { t } = useNSTranslation('individualAsset', 'fuelManagement');

  const { pending, isProcessing, handleSubmit } = useFuelSuspend(
    fuelCards,
    fuelCards,
    () => setHasDataUpdated(!hasDataUpdated)
  );

  const { open, openDialog, closeDialog } = useDialog();

  const [cardId, setCardId] = React.useState('');
  const [action, setAction] = React.useState('');

  const handleOpenDialog = (id, selectedAction) => {
    setCardId(id);
    setAction(selectedAction);
    openDialog();
  };

  const handleAction = () => {
    handleSubmit(cardId, action);
    closeDialog();
  };

  const statusColor = status => {
    return (
      <span
        style={{
          color: getStatusColorFromPalette(status.toLowerCase()),
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      >
        {status}
      </span>
    );
  };

  const showLinks = (status, id) => {
    if (status === 'Active' && !parentOrg) {
      return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
          <LinkText
            disabled={pending}
            href={`/customer/services/fuel-management/replace-fuel-card/${id}`}
            style={{ marginRight: 8 }}
          >
            {fuelT('replace')}
          </LinkText>
          <div style={{ textAlign: 'center' }}>
            <Link
              disabled={pending}
              onClick={() => handleOpenDialog(id, 'suspend')}
            >
              {isProcessing?.includes(id) ? (
                <CircularProgress inlineStyle size={24} />
              ) : (
                fuelT('suspend')
              )}
            </Link>
          </div>
        </div>
      );
    }
    if (status === 'Suspended') {
      return (
        <Link
          disabled={pending}
          onClick={() => handleOpenDialog(id, 'activate')}
        >
          {isProcessing?.includes(id) ? (
            <CircularProgress inlineStyle size={24} />
          ) : (
            fuelT('activate')
          )}
        </Link>
      );
    }
  };

  const fuelPreferencesOptions = fuelPreferences?.data?.options;

  const maxFuelPerDay =
    fuelPreferencesOptions?.maxTransactionsPerDay *
    fuelPreferencesOptions?.maxAmountPerTransaction;

  // the business prefers to see Active or Replacement cards
  // at the top to reduce scrolling
  const sortCardsByStatus = (cards) => {
    cards?.sort((a, b) => {
        const order = ['Replacement', 'Active'];
        return order.indexOf(b.uiStatus) - order.indexOf(a.uiStatus);
    });
  }

  React.useEffect(() => {
    if (fuelCards) {
      sortCardsByStatus(fuelCards);
    }
  }, [fuelCards])

  return (
    <HSBSTable
      headerRows={
        <>
          <HSBSTableHeader>{t('wex-card')}</HSBSTableHeader>
          <HSBSTableHeader>{t('account-number')}</HSBSTableHeader>
          <HSBSTableHeader>{t('exp-date')}</HSBSTableHeader>
          <HSBSTableHeader>{t('status')}</HSBSTableHeader>
          <HSBSTableHeader>{t('details')}</HSBSTableHeader>
        </>
      }
      bodyRows={fuelCards?.map(card => (
        <HSBSTableRow key={card?.id}>
          <HSBSTableCell>{`X ${card?.creditCardNumber}`}</HSBSTableCell>
          <HSBSTableCell>{wexAccountNumber}</HSBSTableCell>
          <HSBSTableCell>
            {card?.expDate ? formatDate(card?.expDate) : ''}
          </HSBSTableCell>
          <HSBSTableCell>{statusColor(card?.uiStatus)}</HSBSTableCell>
          <HSBSTableCell>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 2 }}>
                <strong>{t('fuel')}</strong>{' '}
                {fuelPreferencesOptions?.maxTransactionsPerDay} /{' '}
                {fuelPreferencesOptions?.maxAmountPerTransaction.toLocaleString(
                  'en-US',
                  {
                    style: 'currency',
                    currency: 'USD',
                  }
                )}
                &nbsp; /{' '}
                {maxFuelPerDay.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
                &nbsp; &nbsp; &nbsp;
                <strong>{t('fluid')}</strong>{' '}
                {fuelPreferencesOptions?.allowFluids ? t('yes') : t('no')}{' '}
                &nbsp; &nbsp; &nbsp;
                <strong>{t('general-merchandise')}</strong>{' '}
                {fuelPreferencesOptions?.allowGeneralMerchandising
                  ? t('yes')
                  : t('no')}
                &nbsp; &nbsp; &nbsp;
              </div>
              <div style={{ flex: 1, justifyContent: 'end' }}>
                {showLinks(card?.uiStatus, card?.id)}
              </div>
              <StyledDialog
                onClose={closeDialog}
                dialogTitle={
                  action === 'suspend'
                    ? fuelT('confirm-suspend-title')
                    : fuelT('confirm-activate-title')
                }
                isOpen={open}
                btnActions={{
                  btnFunc: handleAction,
                  btnTitle: fuelT('confirm'),
                }}
              >
                <Typography variant="body1" gutterBottom>
                  {fuelT('confirm-body-single', { action })}
                </Typography>
                <Typography variant="body1">
                  {fuelT('confirm-processing')}
                </Typography>
              </StyledDialog>
            </div>
          </HSBSTableCell>
        </HSBSTableRow>
      ))}
    />
  );
};

AssociatedFuelCards.propTypes = {
  fuelCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      creditCardNumber: PropTypes.string,
      expDate: PropTypes.string,
      statusDesc: PropTypes.string,
    })
  ),
  fuelPreferences: PropTypes.shape({}),
  parentOrg: PropTypes.bool,
  hasDataUpdated: PropTypes.bool.isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
};

AssociatedFuelCards.defaultProps = {
  fuelCards: undefined,
  fuelPreferences: undefined,
  parentOrg: false,
};

export default AssociatedFuelCards;
