import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { Trans, useNSTranslation } from '@holmanfm/lib/lang';
import Typography from '~/shared/components/atom/typography';

const PmNotificationsNoUpdates = props => {
  const {
    formik: { values },
  } = props;
  const { t } = useNSTranslation('assets', 'add-asset');

  const messageToDisplay = () => {
    const emailType = values.pmEmailType;
    switch (emailType) {
      case 'manualEnter':
        return (
          <Trans
            i18nKey="assets:add-asset:pm-notification-no-permission:manual"
            values={{ email: values.pmEmail }}
          />
        );
      case 'defaultOrgAddress':
        return (
          <Trans
            i18nKey="assets:add-asset:pm-notification-no-permission:default"
            values={{ email: values.pmEmail }}
          />
        );
      case 'none':
        return (
          <Trans i18nKey="assets:add-asset:pm-notification-no-permission:none" />
        );
      case 'hierarchyLocations':
        return (
          <Trans
            i18nKey="assets:add-asset:pm-notification-no-permission:hierarchy"
            values={{ email: values.pmEmail }}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <Typography variant="h6">{t('pm-title')}</Typography>
      <Typography variant="body2">{messageToDisplay()}</Typography>
    </>
  );
};

PmNotificationsNoUpdates.propTypes = {
  formik: PropTypes.shape({
    initialValues: PropTypes.shape({}).isRequired,
    values: PropTypes.shape({}).isRequired,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }).isRequired,
};

PmNotificationsNoUpdates.defaultProps = {};

export default connect(PmNotificationsNoUpdates);
