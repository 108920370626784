/* eslint-disable no-case-declarations */
import pluralize from 'pluralize';
import {
  createRecordReducer,
  createRecordSelectors,
} from './create-record.reducer';
import { makeActionCreator } from '../../actions/util';

export function createByIdReducer(entity, options) {
  const recordReducer = createRecordReducer(entity);

  const initialState = {};
  const actionEntity = entity.toUpperCase();
  const pluralEntity = pluralize(entity);
  const pluralActionEntity = pluralEntity.toUpperCase();

  const fetchRecordSuccess = makeActionCreator(
    `FETCH_${actionEntity}_SUCCESS`,
    entity,
    'messages'
  );

  return (state = initialState, action) => {
    switch (action.type) {
      case `FETCH_${pluralActionEntity}_SUCCESS`:
        const initState = options.replacePrev ? {} : { ...state };
        return action[pluralEntity].reduce((nextState, record) => {
          nextState[record.id] = recordReducer(
            nextState[record.id],
            fetchRecordSuccess(record)
          );
          return nextState;
        }, initState);

      case `FETCH_${actionEntity}_REQUEST`:
      case `REMOVE_${actionEntity}_REQUEST`:
      case `UPDATE_${actionEntity}_REQUEST`:
      case `ADD_${actionEntity}_REQUEST`:
      case `FETCH_${actionEntity}_FAILURE`:
      case `ADD_${actionEntity}_FAILURE`:
      case `REMOVE_${actionEntity}_FAILURE`:
      case `UPDATE_${actionEntity}_FAILURE`:
        return {
          ...state,
          [action.id]: recordReducer(state[action.id], action),
        };

      case `FETCH_${actionEntity}_SUCCESS`:
      case `ADD_${actionEntity}_SUCCESS`:
      case `UPDATE_${actionEntity}_SUCCESS`:
        return {
          ...state,
          [action[entity].id]: recordReducer(state[action[entity].id], action),
        };

      case `REMOVE_${actionEntity}_SUCCESS`:
        const newState = { ...state };
        delete newState[action.id];
        return newState;

      default:
        return state;
    }
  };
}

export function createByIdSelectors(entity) {
  const recordSelectors = createRecordSelectors(entity);

  return Object.keys(recordSelectors).reduce((dict, key) => {
    dict[key] = (state, id) => state[id] && recordSelectors[key](state[id]);
    return dict;
  }, {});
}
