import { useNSTranslation } from '../../lang';
import countBy from '../../fp/countyBy';
import { getPMNotifications } from '../../services/preventive-maintenance';
import fieldSorter from '../../util/fieldSorter';
import { formatDate } from '../../util/moment';
import { getFormattedMileage, isDefined } from '../../util/helpers';
import useEnrolledInService from '../services/use-service-enrolled';
import Features from '../../lib-global';

/** Maps PM status (pmDueBin) to a translation key. */
export function mapPMDueBinToTranslationKey(status) {
  switch (status) {
    case '-1':
      return 'past-due';
    case '30':
      return 'due-30';
    case '60':
      return 'due-60';
    case '90':
      return 'due-90';
    case '90+':
      return 'due-over-90';

    default:
      return 'missing-data';
  }
}

/** Maps PM recommendation basis (estPmPicker) to a translation key. */
export function mapEstPMPickerToTranslationkey(v) {
  switch (v) {
    case 'by_time':
      return 'by-time';

    case 'by_odometer':
      return 'by-miles';

    default:
      return 'unknown';
  }
}

/** Maps to a color key on theme.palette. */
export function mapPMDueBinToThemeColoKey(v) {
  switch (v) {
    case '-1':
      return 'pastDue';
    case '30':
      return 'due30';

    case '60':
      return 'due60';

    case '90':
      return 'due90';

    case '90+':
      return 'duePast90';

    default:
      return 'primary';
  }
}

export function getDaysUntilNextService(estDaysToNextPm) {
  return typeof estDaysToNextPm === 'number'
    ? Math.round(estDaysToNextPm)
    : undefined;
}

// I don't think it's used anywhere for now.
function hasLastPMData({ lastPmDate, lastPmOdometer }) {
  return isDefined(lastPmDate) && isDefined(lastPmOdometer);
}

export function mapDataToProps(t) {
  const noRecord = t(`no-record`);
  const timeDriven = t('time-driven');
  const mileageDriven = t('mileage-driven');
  return data => {
    const {
      assetCustomerStatus,
      assetId,
      assetMake,
      assetModel,
      assetNumber,
      assetVehicleBodyType,
      assetVehicleType,
      assetVin,
      assetYear,
      avgDailyOdometer,
      estDaysToNextPm,
      estNextPmDateByOdom,
      estPmDate,
      estPmPicker,
      lastCompletedRequest,
      lastPmDate,
      lastPmOdometer,
      nextPmDateByTime,
      openRequest,
      orgNodeLabel,
      pmDueBin,
      pmSchedule,
      thirdPartyPoNumber,
      latestOdometer,
      odometerSource,
      odometerDate,
    } = data;
    return {
      hasLastPM: hasLastPMData(data),
      assetCustomerStatus,
      assetId,
      assetMileage: getFormattedMileage(latestOdometer, noRecord),
      assetYear,
      assetMake,
      assetModel,
      assetNumber,
      assetTitle: `${assetYear} ${assetMake} ${assetModel}`,
      assetVehicleType,
      assetVehicleBodyType,
      assetVin,
      averageDailyMileage: Math.round(avgDailyOdometer),
      daysUntilNextService: getDaysUntilNextService(estDaysToNextPm),
      estDaysToNextPm,
      estNextPMBasis: estPmPicker
        ? t(mapEstPMPickerToTranslationkey(estPmPicker))
        : noRecord,
      estNextPMDate: estPmDate ? new Date(estPmDate) : undefined,
      estPMDateByDate: nextPmDateByTime
        ? formatDate(new Date(nextPmDateByTime), 'UTC')
        : mileageDriven,
      estPMDateByMileage: estNextPmDateByOdom
        ? formatDate(new Date(estNextPmDateByOdom), 'UTC')
        : timeDriven,
      key: assetId,
      lastPMDate: lastPmDate ? formatDate(lastPmDate) : noRecord,
      lastPMOdometer: getFormattedMileage(lastPmOdometer, noRecord),
      lastPMPONumber: thirdPartyPoNumber || noRecord,
      pmSchedule: pmSchedule || noRecord,
      pmStatus: t(mapPMDueBinToTranslationKey(pmDueBin)),
      themeColorKey: mapPMDueBinToThemeColoKey(pmDueBin),
      noRecord,
      estPmPicker,
      pmDueBin,
      orgNodeLabel,
      lastCompletedRequest,
      openRequest,
      odometerSource,
      odometerDate,
    };
  };
}

export function DaysUntilNextService({ value }) {
  const { t } = useNSTranslation(`pm`, `common`);
  const count = Math.abs(value);

  if (value === undefined) {
    return t(`no-record`);
  }

  if (count === 0) {
    return t('due-days_0');
  }

  return value > 0 ? t(`due-days`, { count }) : t(`past-due-days`, { count });
}

function usePMFetch() {
  const { t } = useNSTranslation(`pm`, `common`);
  const isEnrolledInMaintAndRepair = useEnrolledInService(
    Features.SERVICE_MAINT.INDEX
  );

  const getAllPMForBrowse = async () => {
    const {
      payload: { items, totalAssetsEnrolled, totalAssetsInFleet },
    } = await getPMNotifications();
    return {
      payload: items
        .map(mapDataToProps(t))
        /**
         * @note This is setting the default order, although the "order arrow" doesnt agree. Amy
         * is aware this is a temporary situation until the BrowseMarketFramework can support
         * default ordering instructions.
         */
        .sort(fieldSorter({ key: 'estDaysToNextPm', reverse: true })),
      extra: {
        isEnrolledInMaintAndRepair,
        totalAssetsEnrolled,
        totalAssetsInFleet,
        ...items.reduce(
          countBy(({ pmDueBin }) => pmDueBin),
          {}
        ),
      },
    };
  };
  return getAllPMForBrowse;
}

export default usePMFetch;
