import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import {
  getAllFuelCards,
  getFuelAssetTransactions,
  getFuelPreferences,
} from '@holmanfm/lib/services/fuel';

import useAsync from '@holmanfm/lib/hooks/use-async';
import LocalJuiceProvider from '@holmanfm/lib/citrus-framework/juice/local-juice-provider';
import { toDate } from '@holmanfm/lib/util/moment';
import {
  getChildFuelPreferences,
  getFuelCards,
  getFuelTransactions,
} from '@holmanfm/lib/services/parent-org';
import Typography from '~/shared/components/atom/typography/typography';
import RecentFuelTransactions from '~/areas/individual-assets/fuel-management-components/recent-fuel-transactions';
import LemonFramework from '~/shared/components/lemon-framework/lemon-framework';
import PaginationContainer from '~/areas/individual-assets/pagination-container';
import CircularProgress from '~/shared/components/circular-progress';
import FuelView from './view-fuel.json';
import AssociatedFuelCards from '~/areas/individual-assets/fuel-management-components/associated-fuel-cards';
import makeStyles from '~/shared/components/makeStyles';
import GenericNoDisplay from '~/areas/individual-assets/generic-nothing-to-display';
import ServerMessages from '~/shared/components/messages/server-messages';
import FuelKpi from '~/areas/individual-assets/fuel-management-components/fuel-kpi';

const useStyles = makeStyles(theme => ({
  tanDivider: {
    borderBottom: `1px solid ${theme.palette.backgroundColors.tan}`,
    padding: theme.spacing(2),
  },
}));

const FuelManagementTab = props => {
  const {
    asset,
    setHasDataUpdated,
    hasDataUpdated,
    parentOrg,
    childOrgId,
  } = props;
  const { t } = useNSTranslation('individualAsset', 'fuelManagement');
  const classes = useStyles();

  const {
    run: fuelCardRun,
    status: fuelCardStatus,
    error: fuelCardsError,
    data: fuelCards,
  } = useAsync({ status: 'pending' });

  const {
    run: fuelPreferencesRun,
    status: fuelPreferencesStatus,
    error: fuelPreferencesError,
    data: fuelPreferences,
  } = useAsync({ status: 'pending' });

  const filterOutAsset = React.useCallback(
    cards => cards.filter(card => card.assetId === asset?.id),
    [asset]
  );

  React.useEffect(() => {
    if (parentOrg) {
      fuelPreferencesRun(
        getChildFuelPreferences(childOrgId).then(res => res.payload)
      );
    } else {
      fuelPreferencesRun(getFuelPreferences().then(res => res.payload));
    }
  }, [fuelPreferencesRun, parentOrg, childOrgId]);

  React.useEffect(() => {
    if (parentOrg) {
      fuelCardRun(getFuelCards(childOrgId).then(res => res.payload));
    } else {
      fuelCardRun(getAllFuelCards().then(res => filterOutAsset(res.payload)));
    }
  }, [fuelCardRun, filterOutAsset, childOrgId, parentOrg]);

  const fetchAllTransactions = async () => {
    if (parentOrg) {
      const payload = await getFuelTransactions(childOrgId);
      return payload;
    }
    const {
      payload: { extra, items },
    } = await getFuelAssetTransactions(asset?.id);
    return {
      payload: items.map(item => {
        return {
          ...item,
          date: item?.date ? toDate(item.date) : null,
          specificRange: item?.date ? toDate(item.date) : null,
        };
      }),
      extra,
    };
  };

  if (fuelCardStatus === 'pending' || fuelPreferencesStatus === 'pending') {
    return <CircularProgress />;
  }
  if (fuelCardStatus === 'rejected') {
    return <ServerMessages messages={fuelCardsError} />;
  }
  if (fuelPreferencesStatus === 'rejected') {
    return <ServerMessages messages={fuelPreferencesError} />;
  }

  return (
    <div>
      <Typography variant="h5" style={{ padding: '24px 0' }}>
        {t('title')}
      </Typography>
      <FuelKpi asset={asset} />

      <div>
        <Typography variant="h6" style={{ padding: '24px 0' }}>
          {t('associated-fuel-cards')}
        </Typography>
        {fuelCards?.length > 0 ? (
          <AssociatedFuelCards
            fuelCards={fuelCards}
            fuelPreferences={fuelPreferences}
            setHasDataUpdated={setHasDataUpdated}
            hasDataUpdated={hasDataUpdated}
          />
        ) : (
          <GenericNoDisplay category={t('fuel-cards')} />
        )}
        <div className={classes.tanDivider} />
      </div>
      <div>
        <Typography variant="h6" style={{ padding: '24px 0' }}>
          {t('recent-fuel-transactions')}
        </Typography>
        <LemonFramework view={FuelView}>
          <LocalJuiceProvider requestData={fetchAllTransactions}>
            <PaginationContainer assetId={asset?.id}>
              <RecentFuelTransactions />
            </PaginationContainer>
          </LocalJuiceProvider>
        </LemonFramework>
      </div>
    </div>
  );
};

FuelManagementTab.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  hasDataUpdated: PropTypes.bool.isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  parentOrg: PropTypes.bool,
  childOrgId: PropTypes.string,
};

FuelManagementTab.defaultProps = {
  parentOrg: false,
  childOrgId: undefined,
};

export default FuelManagementTab;
