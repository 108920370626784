import InputAdornment from '@material-ui/core/InputAdornment';
import MaterialUITextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
// import * as TinyVariant from 'tiny-invariant';
import makeStyles from '~/shared/components/makeStyles';

export const handleNumericChange = (e, maxLength, onChange) => {
  const { value } = e.target;
  const { length } = value;
  if (length === 0) {
    onChange(e);
    return;
  }
  if (/[^0-9]/.test(value)) {
    return;
  }
  if (maxLength && length > maxLength) {
    return;
  }
  onChange(e);
};

const useStyles = makeStyles(theme => ({
  adornment: {
    color: theme.palette.grey[500],
  },
}));

const TextField = props => {
  const {
    /**
     * We need `name` to locate errors, touched, and value from the Formik values, errors, and
     * touched.
     */
    name,
    /**
     * The `StartAdornment` is a convenience prop that we use to set the appropriate InputProps
     * and then discard.
     */
    StartAdornment,
    EndAdornment,
    onChange,
    handleBlur,
    value,
    children,
    select,
    inputProps,
    minLength,
    maxLength,
    type,
    disableUnderline,
    variant,
    disabled,
    ...rest
  } = props;

  const classes = useStyles();
  // eslint-disable-next-line react/destructuring-assignment, react/prop-types
  // TinyVariant(
  //   disableUnderline === undefined,
  //   `'disableUnderline' is a deprecated property and should no longer be used.`
  // );

  const updatedProps = {
    name,
    value,
    variant,
    disabled,
    // @TODO: find better solution for handling numeric fields & zipCode
    type: type === 'number' && name !== 'vehicle.quantity' ? 'text' : type,
    onChange: e => {
      if (typeof onChange !== 'function') {
        return;
      }

      const { value: inputValue } = e.target;
      const { length } = inputValue;
      if (type === 'number') {
        return handleNumericChange(e, maxLength, onChange);
      }
      if (maxLength && length > maxLength) {
        return;
      }
      onChange(e);
    },
    onBlur: handleBlur,
    select,
    inputProps: {
      ...inputProps,
      className: 'browser-default',
      minLength,
      maxLength,
    },
    InputProps: {
      startAdornment: StartAdornment && (
        <InputAdornment className={classes.adornment} position="start">
          <StartAdornment />
        </InputAdornment>
      ),
      endAdornment: EndAdornment && (
        <InputAdornment className={classes.adornment} position="start">
          <EndAdornment />
        </InputAdornment>
      ),
    },
    ...rest,
  };

  return (
    <MaterialUITextField {...updatedProps}>{children}</MaterialUITextField>
  );
};

TextField.propTypes = {
  /** OwnProps */
  handleBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  StartAdornment: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  EndAdornment: PropTypes.shape(),
  children: PropTypes.node,
  inputProps: PropTypes.shape({}),
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  select: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
};

TextField.defaultProps = {
  type: 'text',
  onChange: undefined,
  handleBlur: undefined,
  value: '',
  StartAdornment: null,
  EndAdornment: null,
  children: null,
  inputProps: {},
  minLength: undefined,
  maxLength: undefined,
  select: false,
  disableUnderline: undefined,
  disabled: false,
  variant: undefined,
};

export default TextField;
