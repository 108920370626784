import React from 'react';
import lazyLoadComponent from '~/shared/component-loader';

const DMCA = lazyLoadComponent(() =>
  import(/* webpackChunkName: "DMCA" */ '~/areas/dmca')
);
const ForgotPassword = lazyLoadComponent(() =>
  import(
    /* webpackChunkName: "ForgotPassword" */ '~/areas/login/forgot-password'
  )
);
const Login = lazyLoadComponent(() =>
  import(/* webpackChunkName: "login" */ '~/areas/login/login')
);
const ResetPassword = lazyLoadComponent(() =>
  import(/* webpackChunkName: "ResetPassword" */ '~/areas/login/reset-password')
);
const PrivacyPolicy = lazyLoadComponent(() =>
  import(/* webpackChunkName: "PrivacyPolicy" */ '~/areas/privacy-policy')
);
const NewUserFinalize = lazyLoadComponent(() =>
  /* webpackChunkName: "NewUserFinalize" */ import(
    '~/areas/signup/new-user-finalize'
  )
);
const Signup = lazyLoadComponent(() =>
  import(/* webpackChunkName: "Signup" */ '~/areas/signup/signup')
);
const TermsOfUse = lazyLoadComponent(() =>
  import(/* webpackChunkName: "TermsOfUse" */ '~/areas/terms-of-use')
);
const ContactFF = lazyLoadComponent(() =>
  /* webpackChunkName: "ContactFF" */ import(
    '~/shared/components/routes/contact-ff'
  )
);
const ResetEmailSent = lazyLoadComponent(() =>
  import(/* webpackChunkName: "ResetEmailSent" */ '~/areas/login/email-sent')
);
const VerifyEmailChange = lazyLoadComponent(() =>
  import(
    /* webpackChunkName: "VerifyEmailChange" */ '~/areas/login/verify-email-change'
  )
);
const BrowseStock = lazyLoadComponent(() =>
  import(
    /* webpackChunkName: "BrowseStock" */ '~/areas/vehicle-acquisition/stock/browse-stock'
  )
);

const StockSearchDetail = lazyLoadComponent(() =>
  import(
    /* webpackChunkName: "StockSearchDetail" */ '~/areas/vehicle-acquisition/stock/stock-vehicle-detail/stock-vehicle'
  )
);

const StockLanding = lazyLoadComponent(() =>
  import(
    /* webpackChunkName: "StockLanding" */ '~/areas/vehicle-acquisition/stock/stock-landing/stock-landing'
  )
);

const NotFound = lazyLoadComponent(() =>
  import(
    /* webpackChunkName: "NotFound" */ '~/shared/components/routes/not-found'
  )
);

const SelectorsList = lazyLoadComponent(() =>
  import(
    /* webpackChunkName: "SelectorsList" */ '~/areas/vehicle-acquisition/selectors/list-page/browse-selectors-list'
  )
);

const SingleSelector = lazyLoadComponent(() =>
  import(
    /* webpackChunkName: "SingleSelector" */ '~/areas/vehicle-acquisition/selectors/single-selector/single-selector'
  )
);

export default [
  {
    path: '/dmca',
    exact: true,
    component: DMCA,
    id: 'dmca',
  },
  {
    path: '/terms-of-use',
    exact: true,
    component: TermsOfUse,
    id: 'terms-of-use',
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicy,
    id: 'privacy-policy',
  },
  {
    path: '/signup/finalize/:authUrl?',
    exact: true,
    component: NewUserFinalize,
    id: 'new-user-finalize.create-password',
  },
  {
    path: '/signup/:authUrl?',
    exact: false,
    component: Signup,
    id: 'signup',
  },
  {
    path: '/login/:authUrl?',
    exact: false,
    component: Login,
    id: 'login',
  },
  {
    path: '/contact-futurefleet',
    exact: true,
    component: ContactFF,
    id: 'contact-ff',
  },
  {
    path: '/forgot-password/:authUrl?',
    exact: false,
    component: ForgotPassword,
    id: 'forgot-password',
  },
  {
    path: '/reset-password/:authUrl?',
    exact: false,
    component: ResetPassword,
    id: 'reset-password',
  },
  {
    path: '/email-sent/:authUrl?',
    exact: false,
    component: ResetEmailSent,
    id: 'email-sent',
  },
  {
    path: '/verify-email-change',
    exact: false,
    component: VerifyEmailChange,
    id: 'verify-email-change',
  },
  {
    path: null,
    exact: false,
    component: Login,
    id: 'login',
  },
];

/**
 * Routes used in our public app.
 */
export const publicAppRoutes = [
  {
    path: '/marketplace/shop-stock-vehicles/landing',
    exact: true,
    component: StockLanding,
    id: 'marketplace-shop-stock-vehicles-landing',
  },
  {
    path: '/factory-ordering',
    exact: true,
    component: SelectorsList,
    id: 'shop-factory-ordering',
  },
  {
    path: '/marketplace/shop-stock-vehicles',
    exact: true,
    component: BrowseStock,
    id: 'shop-stock-inventory',
  },
  {
    path: '/marketplace/shop-stock-vehicles/:vinNo',
    exact: true,
    component: StockSearchDetail,
    id: 'stock-search-detail',
  },
  {
    id: 'shop-pool-vehicles',
    path: '/marketplace/pool-vehicle-acquisition',
    exact: true,
    render: () => <SelectorsList type="pool" />,
  },
  {
    id: 'shop-factory-vehicles',
    path: '/marketplace/vehicle-acquisition',
    exact: true,
    component: SelectorsList,
  },
  {
    id: 'single-selector',
    path: '/marketplace/vehicle-acquisition/:savedVehicleId',
    exact: true,
    component: SingleSelector,
  },
  {
    path: null,
    exact: false,
    component: NotFound,
    id: 'not-found',
  },
];
