import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const Placeholder = props => {
  const { location } = props;

  return (
    <div>
      <h1>{location.pathname}</h1>
    </div>
  );
};

Placeholder.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default withRouter(Placeholder);
