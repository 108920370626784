/**
 * @typedef {Object} FieldObject
 * @property {string} key - The object key by which to sort
 * @property {boolean} [reverse] (optional) - Will order descending if true.
 * @property {Function} [primer] (optional) - Function applied to values, generally used to prime them for sorting
 *
 * @typedef {FieldObject | string} Fields
 *
 * @name fieldSorter
 * Sorts an array by one or many fields. Provide a mix of strings and FieldObjects to sort an array by multiple fields.
 *
 * @param  {Array<Fields>} fields
 */
export default function fieldSorter(...fields) {
  return function _fieldSorter(a, b) {
    return fields
      .map(field => {
        const fieldObject = typeof field === 'string' ? { key: field } : field;
        const { key, reverse = false, primer = i => i } = fieldObject;
        const dir = reverse ? -1 : 1;
        const aa = primer(a[key]);
        const bb = primer(b[key]);

        if (aa > bb) {
          return dir;
        }
        return aa < bb ? -dir : 0;
      })
      .reduce((p, n) => p || n, 0);
  };
}
