import { UICacheActionTypes } from '../../actions/action-types';

export const initialState = {
  browse: {},
};

const uiCacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case UICacheActionTypes.SET_BROWSE_CONFIG:
      return {
        ...state,
        browse: {
          ...state.browse,
          [action.viewName]: action.config,
        },
      };

    default: {
      return state;
    }
  }
};

export default uiCacheReducer;

//-----------------------------------
// Selectors

export const getBrowseCache = (state, viewName) => state.browse[viewName];
