import { createContext, useContext } from 'react';

export const NavigationContext = createContext(null);
export const RoutingContext = createContext(null);

export function useRouting() {
  const ctx = useContext(RoutingContext);

  if (!ctx) {
    throw new Error(`useRouting must be used within RouteProvider`);
  }

  return ctx;
}

export function useNavigationRouting() {
  const ctx = useContext(NavigationContext);

  if (!ctx) {
    throw new Error(`useNavigationRouting must be used within RouteProvider`);
  }

  return ctx;
}
