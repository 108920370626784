/* eslint-disable prefer-destructuring */

export default function pipe(...fns) {
  return function _pipe(...args) {
    const length = fns.length;
    let idx = 1;
    let result = fns[0].apply(this, args);
    while (idx < length) {
      result = fns[idx].call(this, result);
      idx += 1;
    }

    return result;
  };
}
