/* eslint-disable import/prefer-default-export */

/**
 * Wrap an async Formik onSubmit handler and setSubmitting will be called on success/failure. In
 * the event of an error, it will be re-thrown so the onSubmit handler receives it
 */
export const handleSubmitting = onSubmit => async (values, actions) => {
  try {
    await onSubmit(values, actions);
  } catch (error) {
    actions.setSubmitting(false);
    throw error;
  }
};
