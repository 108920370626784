/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { Formik, Form } from 'formik';
import { updateAsset } from '@holmanfm/lib/services/assets';
import FormControlLabel from '~/shared/components/form-control-label';
import FormControl from '~/shared/components/form-control';
import makeStyles from '~/shared/components/makeStyles';
import Checkbox from '~/shared/components/forms/checkbox';
import Typography from '~/shared/components/atom/typography';
import ProgressButton from '~/shared/components/progress-btn';

const useStyles = makeStyles(theme => ({
  switchBase: {
    padding: '0 9px',
  },
  serviceOfferings: {
    backgroundColor: theme.palette.backgroundColors.tan,
    width: '100%',
    padding: theme.spacing(3),
  },
}));

const AssetServicesEnrollment = props => {
  const { savedSelection, asset, setHasDataUpdated, hasDataUpdated } = props;
  const { t } = useNSTranslation('individualAsset', 'overview');
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        excludeServices: asset?.excludeServices ? asset?.excludeServices : [],
      }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        const updatedData = {
          id: asset?.id,
          assetNo: asset?.asset_no,
          excludeServices: values.excludeServices,
        };
        updateAsset(updatedData)
          .then(async () => {
            setSubmitting(false);
            setTimeout(() => {
              setHasDataUpdated(!hasDataUpdated);
            }, 3000);
          })
          .catch(err => {
            setFieldError({ err });
          });
      }}
    >
      {FormikBag => {
        const { values, setFieldValue, isSubmitting, handleSubmit } = FormikBag;

        const checkChange = () => {
          // Hard coded for one combined value, may be updated to allow for multiple selections later.
          if (
            values?.excludeServices?.includes('fuel-management') &&
            values?.excludeServices?.includes('service-maint')
          ) {
            setFieldValue('excludeServices', []);
          } else {
            setFieldValue('excludeServices', [
              'fuel-management',
              'service-maint',
            ]);
          }
        };

        return (
          <Form>
            <FormControl component="div" className={classes.serviceOfferings}>
              <Typography
                variant="body2"
                style={{ color: '#000', fontWeight: 'bold', marginBottom: 16 }}
              >
                {t('service-offerings')}
              </Typography>
              <div>
                {`${t('vehicle-input-description', {
                  assetInfo: `${asset?.year || ''} ${asset?.make ||
                    ''} ${asset?.model || ''} ${asset?.trim || ''}`,
                  closing: '.',
                })}`}
              </div>
              <div>
                <strong>{t('cancel-instructions')}</strong>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      (savedSelection?.includes('fuel-management') &&
                        savedSelection?.includes('service-maint')) ||
                      false
                    }
                    name="fuelAndMaintService"
                    id="fuelAndMaintService"
                    onChange={checkChange}
                  />
                }
                label={`${t('serv-maint')} and ${t('serv-fuel')}`}
              />
              {values.excludeServices.length > 1 && (
                <div style={{ marginTop: 8 }}>
                  <ProgressButton
                    btnTitle={t('progress-button-title')}
                    submitFunc={handleSubmit}
                    processing={isSubmitting}
                  />
                </div>
              )}
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};

AssetServicesEnrollment.propTypes = {
  savedSelection: PropTypes.arrayOf(PropTypes.string),
  asset: PropTypes.shape({
    make: PropTypes.string,
    model: PropTypes.string,
    trim: PropTypes.string,
    year: PropTypes.number,
  }).isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  hasDataUpdated: PropTypes.bool.isRequired,
};

AssetServicesEnrollment.defaultProps = {
  savedSelection: [],
};

export default AssetServicesEnrollment;
