import React from 'react';
import { formatDateTime } from '@holmanfm/lib/util/moment';
import Box from '~/shared/components/box';
import Typography from '~/shared/components/atom/typography';
import makeStyles from '~/shared/components/makeStyles';
import { NoteShape } from '../../../../../../lib/services/maintenance-repair-history';

const useStyles = makeStyles(theme => ({
  noteCard: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    marginBottom: theme.spacing(2),
    borderRadius: 4,
  },
  noteDateTime: {
    color: theme.palette.grey[900],
    display: 'inline-block',
    marginLeft: theme.spacing(1),
  },
  noteTitle: {
    display: 'inline-block',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1 / 3),
  },
}));

const NoteItem = props => {
  const { noteItem } = props;
  const { createdAt, createdBy, note } = noteItem;
  const classes = useStyles();

  return (
    <Box pt={1 / 3} pb={1} px={3} className={classes.noteCard}>
      <Typography variant="subtitle1" className={classes.noteTitle}>
        {createdBy}{' '}
      </Typography>
      <Typography variant="subtitle2" className={classes.noteDateTime}>
        {formatDateTime(createdAt)}
      </Typography>
      <Typography variant="body2">{note}</Typography>
    </Box>
  );
};

NoteItem.propTypes = {
  noteItem: NoteShape.isRequired,
};

export default NoteItem;
