import { createSelector } from 'reselect';
import { SettingsActionTypes } from '../actions/action-types';

export const initialState = {
  isFetching: false,
  settings: null,
  messages: [],
  failed: false,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SettingsActionTypes.FETCH_SETTINGS_REQUEST:
    case SettingsActionTypes.UPDATE_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
        messages: [],
        failed: false,
      };

    case SettingsActionTypes.FETCH_SETTINGS_SUCCESS:
    case SettingsActionTypes.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        settings: action.settings,
        messages: action.messages || [],
      };

    case SettingsActionTypes.FETCH_SETTINGS_FAILURE:
    case SettingsActionTypes.UPDATE_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        failed: true,
        messages: action.errors,
      };

    default: {
      return state;
    }
  }
};

export default settingsReducer;

//-----------------------------------
// Selectors

export const getIsFetchingSettings = state => state.isFetching;

export const getSettings = state => state.settings;

export const getSettingsMessages = createSelector(
  [state => state.failed, state => state.messages],
  (failed, messages) => ({ failed, messages })
);
