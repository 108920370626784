import axios from 'axios';
import { preLogOut } from '../redux/actions/auth.actions';

export default function authInterceptor(store) {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (!error.response || !error.response.config.url)
        return Promise.reject(error);
      const { status } = error.response;

      if (status === 401 && !error.response.config.url.includes('logout')) {
        if (
          !error.response.data.messages ||
          error.response.data.messages[0].code !== 'invalid-credentials'
        ) {
          store.dispatch(preLogOut());
        }
      }

      return Promise.reject(error);
    }
  );
}
