/* eslint-disable import/prefer-default-export */

export const getQueryFromFilters = filters => {
  const location = filters?.filter(f => f.id === 'orgNodeLabel'); // use .filter() for multiple choice filters
  const assetStatus = filters?.find(f => f.id === 'assetStatus'); // use .find() for single choice filters
  const activeStatus = filters?.filter(f => f.id === 'activeStatus');
  const insurance = filters?.filter(f => f.id === 'insurance');
  const minYear = filters?.find(f => f.id === 'year' && f.operator === 'gte');
  const maxYear = filters?.find(f => f.id === 'year' && f.operator === 'lte');
  const make = filters?.filter(f => f.id === 'make');
  const model = filters?.filter(f => f.id === 'model');
  const odometer = filters?.filter(f => f.id === 'odometerRange');
  const licenseState = filters?.filter(f => f.id === 'license_state');
  const assetGroupName = filters?.filter(
    f => f.id === 'selectedUser.assetGroupName'
  );
  const orgName = filters?.find(f => f.id === 'org_name');

  const results = {
    location: location?.length ? location : undefined, // for multiple choice filters
    activeStatus: activeStatus?.length ? activeStatus : undefined,
    insurance: insurance?.length ? insurance : undefined,
    minYear: minYear ? minYear.value : '',
    maxYear: maxYear ? maxYear.value : '',
    make: make?.length ? make : undefined,
    model: model?.length ? model : undefined,
    odometer: odometer?.length ? odometer : undefined,
    licenseState: licenseState?.length ? licenseState : undefined,
    assetGroupName: assetGroupName?.length ? assetGroupName : undefined,
    orgName: orgName ? orgName.value : '',
  };

  // AssetStatus
  // converting assetStatus from string to boolean string
  if (assetStatus && assetStatus.value === 'Active') {
    results.assetStatus = 'true';
  } else if (assetStatus && assetStatus.value === 'Inactive') {
    results.assetStatus = 'false';
  } else if (!assetStatus) {
    results.assetStatus = '';
  }

  return results;
};

export const appendQueryParams = (query, paramName, value = undefined) => {
  if (Array.isArray(value) && value.length) {
    value.forEach(val => {
      if (val) {
        query.append(paramName, String(val.value));
      }
    });
  } else if (value) {
    query.append(paramName, String(value));
  }
};
