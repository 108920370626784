import React from 'react';
import PropTypes from 'prop-types';
import {
  mapEstPMPickerToTranslationkey,
  DaysUntilNextService,
  getDaysUntilNextService,
  mapPMDueBinToTranslationKey,
  mapPMDueBinToThemeColoKey,
} from '@holmanfm/lib/hooks/pm-tracker/use-pm-fetch';
import useAsync from '@holmanfm/lib/hooks/use-async';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { getPMNotification } from '@holmanfm/lib/services/preventive-maintenance';
import { formatDate } from '@holmanfm/lib/util/moment';
import { getFormattedMileage } from '@holmanfm/lib/util/helpers';
import CommonColors from '@holmanfm/lib/common/colors';
import { getPMMaintenance } from '@holmanfm/lib/services/parent-org';
import CircularProgress from '~/shared/components/circular-progress';
import Link from '~/shared/components/link';
import Typography from '~/shared/components/atom/typography/typography';
import makeStyles from '~/shared/components/makeStyles';
import typeStyles from '~/shared/components/styles/global-type-styles';
import Schedule from '~/shared/icons/schedule';
import ServerMessages from '~/shared/components/messages/server-messages';
import GenericNoDisplay from '~/areas/individual-assets/generic-nothing-to-display';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    minHeight: 115,
  },
  content: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  faded: {
    color: '#949494',
  },
  bold: {
    fontWeight: 'bold',
  },
  daysUntilContainer: {
    '& svg': {
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    marginTop: theme.spacing(0.5),
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
      marginLeft: theme.spacing(1),
    },
  },
  untilServiceContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  pmDueContainer: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
  pmRows: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  mobileRow: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  mobileRowSeparation: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
  daysUntil: {},
  notAButton: {
    backgroundColor: 'transparent',
    border: '1px solid',
    borderRadius: 4,
    fontWeight: 'bold',
    padding: [[theme.spacing(1), theme.spacing(2)]],
    textAlign: 'center',
    width: 100,
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
    },
  },
  createRequest: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textDecoration: 'underline',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.875rem',
    cursor: 'pointer',
  },
  viewRequest: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textDecoration: 'underline',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  viewLastPm: {
    margin: theme.spacing(1, 0),
  },
  expanded: {},
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$expanded': {
      transform: 'rotate(180deg)',
    },
  },
  infoTextContainer: {
    display: 'flex',
    fontSize: 14,
    color: '#949494',
    '& svg': {
      fontSize: 15,
      width: 20,
      minWidth: 20,
    },
    '& p': {
      paddingLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0.5),
    },
  },
  checkCircle: {
    width: 20,
    minWidth: 20,
    alignItems: 'baseline',
  },
  categoryTitles: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  daysNextSpacing: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
    },
  },
}));

const PMDataRow = ({ selectedItem }) => {
  const { t: pmCommon } = useNSTranslation('pm', 'common');
  const { t } = useNSTranslation('pm', 'pm-tracker-row');
  const classes = useStyles();

  const {
    estDaysToNextPm,
    estPmDate,
    estPmPicker,
    lastPmDate,
    lastPmOdometer,
    noRecord,
    pmSchedule,
    pmDueBin,
    lastCompletedRequest,
  } = selectedItem;

  const estNextPmBasis = estPmPicker
    ? pmCommon(mapEstPMPickerToTranslationkey(estPmPicker))
    : noRecord;
  const colorKey =
    CommonColors.sharedPalette?.[mapPMDueBinToThemeColoKey(pmDueBin)]?.main;
  const estNextPmDate = estPmDate ? new Date(estPmDate) : undefined;
  const lastPMDate = lastPmDate ? formatDate(lastPmDate) : noRecord;
  const daysUntilNextService = getDaysUntilNextService(estDaysToNextPm);
  const pmStatus = pmCommon(mapPMDueBinToTranslationKey(pmDueBin));
  const typeClasses = typeStyles();
  const viewLastPmUrl = `/customer/request-management/${lastCompletedRequest}`;

  return (
    <div style={{ display: 'flex' }} className={classes.pmRows}>
      <div className={classes.categoryTitles}>
        <Typography
          variant="subtitle2"
          className={typeClasses.driveBlue}
          style={{ marginBottom: 4 }}
        >
          {t(`last-pm-date`)}
        </Typography>
        <Typography variant="body2">{lastPMDate}</Typography>
        {lastCompletedRequest && (
          <Typography variant="subtitle2" className={classes.viewLastPm}>
            <Link to={{ pathname: viewLastPmUrl, state: { type: 'pm' } }}>
              {t(`last-pm-view-last-pm`)}
            </Link>
          </Typography>
        )}
      </div>
      <div className={classes.categoryTitles}>
        <Typography
          variant="subtitle2"
          className={typeClasses.driveBlue}
          style={{ marginBottom: 4 }}
        >
          {t(`last-pm-odometer`)}
        </Typography>
        <Typography variant="body2">
          {getFormattedMileage(lastPmOdometer, noRecord)}
        </Typography>
      </div>
      <div className={classes.categoryTitles}>
        <Typography
          variant="subtitle2"
          className={typeClasses.driveBlue}
          style={{ marginBottom: 4 }}
        >
          {t(`next-pm-date`)}
        </Typography>
        <Typography variant="body2">
          {estNextPmDate ? formatDate(estNextPmDate, 'UTC') : noRecord}
        </Typography>
      </div>
      <div className={classes.categoryTitles}>
        <Typography
          variant="subtitle2"
          className={typeClasses.driveBlue}
          style={{ marginBottom: 4 }}
        >
          {t(`next-pm-basis`)}
        </Typography>
        <Typography variant="body2">{estNextPmBasis}</Typography>
      </div>
      <div className={classes.categoryTitles}>
        <Typography
          variant="subtitle2"
          className={typeClasses.driveBlue}
          style={{ marginBottom: 4 }}
        >
          {t(`pm-schedule`)}
        </Typography>
        <Typography variant="body2">{pmSchedule || noRecord}</Typography>
      </div>
      <div className={classes.daysNextSpacing}>
        <div className={classes.notAButton} style={{ color: colorKey }}>
          {pmStatus}
        </div>
        {daysUntilNextService && (
          <div className={classes.daysUntilContainer}>
            <div
              className={classes.daysUntil}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Schedule fontSize="inherit" />
            </div>
            <Typography variant="subtitle2">
              <DaysUntilNextService value={daysUntilNextService} />
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

PMDataRow.propTypes = {
  selectedItem: PropTypes.shape({
    estDaysToNextPm: PropTypes.number,
    estPmDate: PropTypes.string,
    estPmPicker: PropTypes.string,
    lastPmDate: PropTypes.string,
    lastPmOdometer: PropTypes.number,
    noRecord: PropTypes.string,
    pmSchedule: PropTypes.string,
    pmDueBin: PropTypes.string,
    themeColorKey: PropTypes.string,
    lastCompletedRequest: PropTypes.string,
  }).isRequired,
};

const PmNextDue = props => {
  const { asset, parentOrg, childOrgId } = props;
  const { data, run, error, status } = useAsync();
  const { t } = useNSTranslation('individualAsset', 'maintenance');

  React.useEffect(() => {
    if (parentOrg) {
      run(getPMMaintenance(childOrgId, asset?.id).then(res => res.payload));
    } else {
      run(getPMNotification(asset?.id).then(res => res?.payload));
    }
  }, [run, parentOrg, asset, childOrgId]);

  if (!data && !status === 'resolved') {
    return <CircularProgress size={24} inlineStyle />;
  }

  if (error) {
    return <ServerMessages messages={error} />;
  }

  return (
    <div>
      <Typography variant="h6" style={{ padding: '24px 0' }}>
        {t('pm-schedule-next-service')}
      </Typography>
      {(!data || data?.length === 0) && (
        <GenericNoDisplay category={t('service-schedule')} />
      )}
      {data?.length > 0 && <PMDataRow selectedItem={data[0]} />}
      <div />
    </div>
  );
};

PmNextDue.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  parentOrg: PropTypes.bool,
  childOrgId: PropTypes.string,
};

PmNextDue.defaultProps = {
  parentOrg: false,
  childOrgId: undefined,
};

export default PmNextDue;
