import de from './de';
import enUS from './en-US';
import es from './es';
import frCA from './fr-CA';
import ru from './ru';
import sr from './sr';

export const LANGS = ['de', 'en-US', 'es', 'fr-CA', 'ru', 'sr'];
export const FILE_NAMES = [
  'common',
  'customerService',
  'dashboard',
  'main',
  'map',
  'marketplace',
  'validator',
  'vehicles',
  'assets',
  'drivertraining',
  'checkout',
  'services',
  'browse',
  'pm',
  'telemetry',
  'stock',
  'credit',
  'shopvehicles',
  'consolidatedBilling',
  'selectorAcquisition',
  'locationsHierarchy',
  'telematics',
  'dealerPortal',
  'individualAsset',
];
export default { de, 'en-US': enUS, es, 'fr-CA': frCA, ru, sr };
