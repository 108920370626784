/**
 * This component should only be used within a Formik controlled form. The Formik connect high-order
 * component connects us to the closes Context Provider and supplies the form state and handlers we
 * need.
 *
 * @see https://jaredpalmer.com/formik/docs/api/connect
 */
import { connect, getIn } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../molecule/text-field';
import withStyles from '../withStyles';

const FormTextField = props => {
  const {
    /**
     * We need `name` to locate errors, touched, and value from the Formik values, errors, and
     * touched.
     */
    name,
    /**
     * The `formik` prop is supplied by Formik's connect high-order component.
     */
    formik: { errors, touched, values, handleChange, handleBlur },
    onChange,
    variant,
    labelTextColor,
    fontWeight,
    disabled,
    maxLength,

    classes,
    ...rest
  } = props;

  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  const value = getIn(values, name);

  const labelColor = () => {
    if (disabled) {
      return disabled;
    }
    if (error) {
      return error;
    }
    if (labelTextColor) {
      return labelTextColor;
    }
    return '#000';
  };

  let updatedProps = {
    name,
    /** value, onChange, and onBlur are supplied by Formik. */
    value,
    variant,
    disabled,
    maxLength,
    InputLabelProps: {
      shrink: true,
      style: { color: labelColor },
    },
    onChange: onChange || handleChange,
    onBlur: handleBlur,
    ...rest,
  };

  /**
   * If the field has an error and has been touched we toggle error on for the coloring and
   * set the helperText accordingly.
   */
  if (error && touch) {
    updatedProps = {
      ...updatedProps,
      error: true,
      helperText: error,
    };
  }

  return <TextField {...updatedProps} />;
};

FormTextField.propTypes = {
  /** Formik connect Props */
  formik: PropTypes.shape({
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
    values: PropTypes.shape({}).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
  }).isRequired,

  /** WithStyles */
  classes: PropTypes.shape({
    adornment: PropTypes.string.isRequired,
  }).isRequired,

  /** OwnProps */
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  StartAdornment: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  variant: PropTypes.string,
  labelTextColor: PropTypes.string,
  fontWeight: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
};

FormTextField.defaultProps = {
  onChange: undefined,
  StartAdornment: undefined,
  variant: undefined,
  labelTextColor: undefined,
  fontWeight: undefined,
  disabled: false,
  maxLength: undefined,
};

const styles = theme => ({
  adornment: {
    color: theme.palette.grey[500],
  },
});

const styled = withStyles(styles);

export default styled(connect(FormTextField));
