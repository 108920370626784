import PropTypes from 'prop-types';
import { branch, compose } from 'recompose';
import { bindActionCreators } from 'redux';

import { connect } from '../redux';
import { withTranslation } from '../lang';

export const actionCreatorBinder = actionCreators => dispatch =>
  bindActionCreators(actionCreators, dispatch);

/**
 * This high-order component will 'connect' a component if stateToProp or actionCreators are
 * provided. If the actionCreators are supplied, they are provided to bindActionCreators.
 *
 * The expectation is to supply react-redux's `connect` function, which will then connect
 * the component to Redux and bind the action creators to the store.
 *
 * @param {*} [mapState]
 * @param {bindActionCreators} bindActionCreatorsFn
 * @param {connect} connectHoc
 */
export const maybeConnectGenerator = actionCreatorBinderFn => connectHoc => (
  mapState,
  actionCreators
) =>
  branch(
    () => Boolean(mapState) || Boolean(actionCreators),
    connectHoc(
      mapState,
      actionCreators ? actionCreatorBinderFn(actionCreators) : undefined
    )
  );

/**
 * The resulting high-order component wraps a component in connectHoc and maybeConnect.
 *
 * @see https://github.com/acdlite/recompose/blob/master/docs/API.md#compose
 * @see https://github.com/acdlite/recompose/blob/master/docs/API.md#branch
 *
 * @param {Function} maybeConnectHoc - A high-order component that might subscribes to global state.
 * @param {Function} translationHoc - A high-order component that provides translation services.
 */
export const hocGenerator = (maybeConnectHoc, translationHoc) => (
  mapState,
  actionCreators
) =>
  compose(
    // Inject localization object as a prop.
    translationHoc(),

    // Connect to redux only if stateToProp or actionCreators are provided.
    maybeConnectHoc(mapState, actionCreators)
  );

hocGenerator.propTypes = {
  t: PropTypes.func,
};

export default hocGenerator(
  maybeConnectGenerator(actionCreatorBinder)(connect),
  withTranslation
);
