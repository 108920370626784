import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNSTranslation } from '@holmanfm/lib/lang';
import {
  calculateCapCost,
  creditAssetMinDownPmt,
  exceedsMaxApprovalAmount,
  getCreditRulesForAssetType,
} from '@holmanfm/lib/hooks/dealer-portal/util';
import useValidationSchema from '@holmanfm/lib/hooks/useValidationSchema';
import { getLeasingOptions } from '@holmanfm/lib/services/payment-estimator';
import { ANNUAL_MILEAGE_DISPLAY_VALUES } from '@holmanfm/lib/common/constants';
import {
  allowNumberAndDecimalOnly,
  formatLocaleString,
  formatMoney,
} from '@holmanfm/lib/common/marketplace-helpers';
import { moneyToFloat } from '@holmanfm/lib/common/marketplace/money';
import { useSelector } from '@holmanfm/lib/redux';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import Typography from '~/shared/components/atom/typography';
import Button from '~/shared/components/atom/button';
import makeStyles from '~/shared/components/makeStyles';
import TextField from '~/shared/components/forms/text-field';
import Select, { MenuItem } from '~/shared/components/forms/select';
import StyledDialog from '~/shared/components/styled-dialog';
import LoadingOverlay from '~/shared/components/loading-overlay';
import Switch from '~/shared/components/forms/switch';
import FormControlLabel from '~/shared/components/form-control-label';
import { useToasts } from '~/shared/components/withToast';
import InputAdornment from '~/shared/components/input-adornment';
import FormHelperText from '~/shared/components/form-helper-text';

// See notes:
// notes--financial-information.md (For Business Logic and Documentation)

const financialInformationDialogSchema = acqFee => t => {
  return Yup.object({
    financeAmount: Yup.string().required(
      `${t('dealerPortal:payment-estimator.financed-amount')} is required`
    ),
    minDownPayment: Yup.number(),
    downPayment: Yup.string().when('minDownPayment', {
      is: num => num > 0,
      then: schema =>
        schema
          .test({
            name: 'is-minDownPayment',
            test(value) {
              const numVal =
                typeof value === 'number' ? value : moneyToFloat(value);
              const minVal = this.resolve(Yup.ref('minDownPayment'));
              return value == null || numVal < minVal
                ? this.createError({
                    message: `${t(
                      'dealerPortal:payment-estimator.down-payment-min'
                    )}
                  ${formatMoney(minVal)}`,
                    path: 'downPayment',
                  })
                : true;
            },
          })
          .required(
            `${t('dealerPortal:payment-estimator.down-payment')} is required`
          ),
      otherwise: schema =>
        schema.required(
          `${t('dealerPortal:payment-estimator.down-payment')} is required`
        ),
    }),
    additionalFI: Yup.string().required(
      `${t('dealerPortal:payment-estimator.additional-fi')} is required`
    ),
    upfit: Yup.string().required(
      `${t('dealerPortal:payment-estimator.upfit-cost')} is required`
    ),
    rateMarkup: Yup.number()
      .max(2, `${t('dealerPortal:payment-estimator.rate-markup-max')}`)
      .min(0)
      .required(
        `${t('dealerPortal:payment-estimator.rate-markup')} is required`
      )
      .typeError('A number is required'),
    taxRate: Yup.number()
      .required(`${t('dealerPortal:payment-estimator.tax-rate')} is required`)
      .typeError('A number is required'),
    capitalizeTax: Yup.bool(),
    annualMileage: Yup.number().required(
      `${t('dealerPortal:payment-estimator.annual-mileage')} is required`
    ),
    acqFee: Yup.string().test({
      name: 'is-minAcqFee',
      test(value) {
        const numVal = typeof value === 'number' ? value : moneyToFloat(value);
        return value == null || numVal < acqFee
          ? this.createError({
              message: `${t(
                'dealerPortal:payment-estimator.acquisition-fee-min'
              )} ${formatMoney(acqFee)}`,
              path: 'acqFee',
            })
          : true;
      },
    }),
  });
};

const useStyles = makeStyles(theme => ({
  nestedContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: theme.spacing(0, 1, 0, 0),
    },
    '& > div': {
      marginRight: theme.spacing(2),
      width: '45%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: theme.spacing(1, 0),
      },
    },
  },
  required: {
    color: theme.palette.error.main,
    marginBottom: '.25rem',
  },
}));
const DEFAULT_ACQFEE = 650;

const downPmtHelperText = (minDownPmt, isDownPaymentOverCap) => {
  if (isDownPaymentOverCap) {
    return `You have exceeded the total financed amount.`;
  }
  if (minDownPmt) {
    return `Minimum ${formatMoney(minDownPmt)} required`;
  }
  return '';
};

const FinancialInformationDialog = props => {
  const {
    open,
    closeDialog,
    setLeasingOptions,
    asset,
    setEstimateLeasingOptions,
    estimateLeasingOptions,
    dealerCustomerOrgId,
    setChosenOptions,
    creditAssetTypes,
  } = props;
  const classes = useStyles();
  const { t } = useNSTranslation('dealerPortal', 'payment-estimator');
  const org = useSelector(selectors.getOrg);

  const acqFeeMin = () => {
    if (
      estimateLeasingOptions?.acqFee >=
      (org.data?.DealerAcquisitionFee || DEFAULT_ACQFEE)
    ) {
      return estimateLeasingOptions?.acqFee;
    }
    return org.data?.DealerAcquisitionFee || DEFAULT_ACQFEE;
  };
  const validationSchema = useValidationSchema(
    financialInformationDialogSchema(
      org.data?.DealerAcquisitionFee || DEFAULT_ACQFEE
    )
  );
  const [processing, setProcessing] = React.useState(false);
  const { error: errorToast } = useToasts();

  const convertToDollarFormat = val => {
    const num = typeof val !== 'number' ? moneyToFloat(val) : val;
    // specific format since dollar sign is already applied to input box
    return !Number.isNaN(+num) ? formatLocaleString(num, 2) : '';
  };

  const makeNumber = val =>
    typeof val === 'number' ? val : Number(val.replace(/[^0-9.]+/g, ''));

  const getOptions = async values => {
    // Pure input values. Nothing should be adjusted, calculated, etc.
    try {
      const res = await getLeasingOptions({
        creditAssetTypeId: values.creditAssetTypeId,
        creditAssetTypeName: values.creditAssetTypeName,
        vehicleType: asset.vehicleType,
        downPayment: moneyToFloat(values.downPayment) || 0,
        minDownPayment: values?.minDownPayment, // this is the calculated min, can be null
        financeInsurance: moneyToFloat(values?.additionalFI) || 0,
        upfit: moneyToFloat(values?.upfit) || 0,
        rateMarkup: makeNumber(values.rateMarkup),
        taxRate: makeNumber(values.taxRate),
        capitalizeTax: values.capitalizeTax,
        annualMileage: values.annualMileage,
        estimatedCost: moneyToFloat(values.financeAmount),
        dealerCustomerOrgId,
        acqFee: makeNumber(values.acqFee),
      });
      setLeasingOptions(res.payload);
      setChosenOptions([]); // reset the selection when re-submitting new values
    } catch (err) {
      errorToast(err[0].message);
    }
    setProcessing(false);
    closeDialog();
  };

  return (
    <StyledDialog
      isOpen={open}
      onClose={closeDialog}
      dialogTitle={t('financial-information-title')}
      btnActions={{}}
    >
      <LoadingOverlay display={processing === true}>
        <Formik
          initialValues={{
            creditAssetTypeId: estimateLeasingOptions?.creditAssetTypeId || '',
            creditAssetTypeName: estimateLeasingOptions?.creditAssetTypeName,
            financeAmount: convertToDollarFormat(
              estimateLeasingOptions?.financeAmount
            ),
            additionalFI:
              estimateLeasingOptions?.additionalFI === 0
                ? '0'
                : convertToDollarFormat(estimateLeasingOptions?.additionalFI),
            upfit:
              estimateLeasingOptions?.upfit === 0
                ? '0'
                : convertToDollarFormat(estimateLeasingOptions?.upfit),
            minDownPayment: estimateLeasingOptions?.minDownPayment || 0,
            downPayment:
              estimateLeasingOptions?.downPayment === 0
                ? '0'
                : convertToDollarFormat(estimateLeasingOptions?.downPayment),
            rateMarkup: estimateLeasingOptions?.rateMarkup,
            taxRate: estimateLeasingOptions?.taxRate,
            capitalizeTax: estimateLeasingOptions?.capitalizeTax || false,
            annualMileage: estimateLeasingOptions?.annualMileage || 0,
            acqFee: acqFeeMin(),
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setProcessing(true);
            setEstimateLeasingOptions({
              creditAssetTypeId: values.creditAssetTypeId,
              creditAssetTypeName:
                creditAssetTypes?.find(
                  type => type.id === values.creditAssetTypeId
                )?.name || null,
              vehicleType: asset.vehicleType,
              financeAmount: makeNumber(values.financeAmount),
              downPayment: makeNumber(values.downPayment),
              additionalFI: makeNumber(values.additionalFI),
              upfit: makeNumber(values.upfit),
              minDownPayment: values.minDownPayment,
              rateMarkup: makeNumber(values.rateMarkup),
              taxRate: makeNumber(values.taxRate),
              capitalizeTax: values.capitalizeTax,
              annualMileage: values.annualMileage,
              acqFee: makeNumber(values.acqFee),
            });
            getOptions(values);
            setSubmitting(false);
          }}
          validateOnMount
          validationSchema={validationSchema}
        >
          {FormikBag => {
            const {
              handleSubmit,
              isValid,
              setFieldValue,
              values,
              handleChange,
              handleBlur,
              setFieldTouched,
            } = FormikBag;

            const restrictSymbols = e => {
              const { value, name } = e.target;
              const val = allowNumberAndDecimalOnly(value);
              setFieldValue(name, val);
            };

            const formatVal = e => {
              const { name, value } = e.target;
              let formattedNum;
              if (
                (name === 'upfit' && value.startsWith('0')) ||
                (name === 'additionalFI' && value.startsWith('0')) ||
                (name === 'downPayment' && value.startsWith('0'))
              ) {
                // for upfit, additionalFI and downPayment allow 0, a value is required and cannot be null
                formattedNum = '0';
              } else if (
                /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/.test(value) &&
                !value.startsWith('0')
              ) {
                // currency pattern test: test if the number has already been formatted: i.e. already entered and then user clicks back into the field and doesn't change value.
                // And make sure no leading 0s have been added on edit
                formattedNum = value;
              } else {
                formattedNum = convertToDollarFormat(value);
              }
              setFieldValue(name, formattedNum);
              setTimeout(() => handleBlur(e));
              return formattedNum;
            };

            // these variables: minDownPmt, isOverMaxApproval, creditRules, isDownPaymentOverCap
            // are calculated values used in helper text, not included in the form values
            //
            // calculate the minimum down payment required,
            const minDownPmt = creditAssetMinDownPmt(values, creditAssetTypes);
            // calculate cap cost and compare to max approval limit (maxIndAssetCost)
            const isOverMaxApproval = exceedsMaxApprovalAmount(
              values,
              creditAssetTypes
            );
            // credit rules for the selected asset type
            const creditRules = getCreditRulesForAssetType(
              values,
              creditAssetTypes
            );
            // check if the down payment has exceeded the total cap cost
            const isDownPaymentOverCap =
              calculateCapCost(values) < moneyToFloat(values?.downPayment);

            const handleOnChange = e => {
              const { name, value } = e.target;
              if (name !== 'creditAssetTypeId') {
                const val = allowNumberAndDecimalOnly(value);
                setFieldValue(name, val);
              } else {
                handleChange(e);
                if (values.downPayment) {
                  // after an estimate is saved, returning to the finance dialog and then switching asset type: re-trigger validation on downPayment
                  setFieldTouched('downPayment', true);
                }
              }
              // wait for the handle change then proceed
              setTimeout(() => {
                // this is used to get the current value for validation, rather than the stale formik value
                const currentValues = {
                  creditAssetTypeId:
                    name === 'creditAssetTypeId'
                      ? value
                      : values.creditAssetTypeId,
                  financeAmount:
                    name === 'financeAmount' ? value : values.financeAmount,
                  additionalFI:
                    name === 'additionalFI' ? value : values.additionalFI,
                  upfit: name === 'upfit' ? value : values.upfit,
                };
                const currentMinDownPmt = creditAssetMinDownPmt(
                  currentValues,
                  creditAssetTypes
                );
                // use minDownPayment only as a dependent field for Yup, it is not displayed in the form
                setFieldValue('minDownPayment', currentMinDownPmt);
                // if no minDownPayment is set (no assetTypes approved, or none selected)
                // then no minimum validation is needed.
              });
            };

            return (
              <Form onSubmit={handleSubmit}>
                <div style={{ marginBottom: 16 }}>
                  <Typography variant="body2" className={classes.required}>
                    {t('fields-required')}
                  </Typography>
                </div>
                <div>
                  <div style={{ marginBottom: 16 }}>
                    <Select
                      name="creditAssetTypeId"
                      id="creditAssetTypeId"
                      label={t('asset-type')}
                      style={{ width: '93%' }}
                      fullWidth
                      variant="outlined"
                      displayEmpty
                      notched
                      disabled={
                        creditAssetTypes?.length === 0 || !creditAssetTypes
                      }
                      handleChange={e => handleOnChange(e)}
                    >
                      <MenuItem value="">&nbsp;</MenuItem>
                      {creditAssetTypes?.map(assetType => (
                        <MenuItem key={assetType.id} value={assetType.id}>
                          {assetType.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {t('optional')}.&nbsp;
                      <i>
                        {creditAssetTypes?.length
                          ? t('quote-will-not-be-enabled')
                          : t('no-asset-types-available')}
                      </i>
                    </FormHelperText>
                  </div>
                  {isOverMaxApproval && (
                    <div style={{ paddingBottom: '24px' }}>
                      <Typography variant="subtitle2" color="error">
                        {t('max-approval-exceeded')}
                      </Typography>
                      <Typography variant="subtitle2" color="error">
                        {`${t('max-approval-limit-message')} ${formatMoney(
                          creditRules?.maxIndAssetCost
                        )}, ${t(
                          'current-financed-amount-message'
                        )} ${formatMoney(calculateCapCost(values))}`}
                      </Typography>
                    </div>
                  )}
                  <div className={classes.nestedContainer}>
                    <TextField
                      name="financeAmount"
                      id="financeAmount"
                      variant="outlined"
                      fullWidth
                      label={t('financed-amount')}
                      onChange={handleOnChange}
                      onBlur={formatVal}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      name="upfit"
                      id="upfit"
                      variant="outlined"
                      fullWidth
                      label={t('upfit-cost')}
                      onChange={handleOnChange}
                      onBlur={formatVal}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className={classes.nestedContainer}>
                    <TextField
                      name="additionalFI"
                      id="additionalFI"
                      variant="outlined"
                      fullWidth
                      label={t('additional-fi')}
                      onChange={handleOnChange}
                      onBlur={formatVal}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      name="downPayment"
                      id="downPayment"
                      variant="outlined"
                      fullWidth
                      label={t('down-payment')}
                      onChange={handleOnChange}
                      onBlur={formatVal}
                      error={isDownPaymentOverCap}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      helperText={downPmtHelperText(
                        minDownPmt,
                        isDownPaymentOverCap
                      )}
                    />
                  </div>
                  <div className={classes.nestedContainer}>
                    <TextField
                      name="acqFee"
                      id="acqFee"
                      variant="outlined"
                      fullWidth
                      label={t('acquisition-fee')}
                      onChange={e => restrictSymbols(e)}
                      onBlur={formatVal}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      name="rateMarkup"
                      id="rateMarkup"
                      variant="outlined"
                      fullWidth
                      label={t('rate-markup-label')}
                      onChange={e => restrictSymbols(e)}
                    />
                  </div>
                  <div className={classes.nestedContainer}>
                    <TextField
                      name="taxRate"
                      id="taxRate"
                      variant="outlined"
                      fullWidth
                      label={t('tax-rate-label')}
                      onChange={e => restrictSymbols(e)}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          name="capitalizeTax"
                          switchProps={{ size: 'medium', color: 'secondary' }}
                        />
                      }
                      labelPlacement="end"
                      label={t('capitalize-tax')}
                      style={{ marginLeft: 16 }}
                    />
                  </div>
                  <div className={classes.nestedContainer}>
                    <Select
                      name="annualMileage"
                      fullWidth
                      label={t('annual-mileage')}
                      variant="outlined"
                      displayEmpty
                      id="annualMileage"
                    >
                      {ANNUAL_MILEAGE_DISPLAY_VALUES.map(amf => (
                        <MenuItem key={amf.value} value={amf.value}>
                          {amf.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    paddingTop: 32,
                  }}
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={
                      !isValid || isOverMaxApproval || isDownPaymentOverCap
                    }
                  >
                    {t('calculate-options')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </LoadingOverlay>
    </StyledDialog>
  );
};

FinancialInformationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  setLeasingOptions: PropTypes.func.isRequired,
  setEstimateLeasingOptions: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    vehicleType: PropTypes.string,
  }),
  estimateLeasingOptions: PropTypes.shape({
    financeAmount: PropTypes.number,
    downPayment: PropTypes.number,
    additionalFI: PropTypes.number,
    upfit: PropTypes.number,
    rateMarkup: PropTypes.number,
    taxRate: PropTypes.number,
    capitalizeTax: PropTypes.bool,
    annualMileage: PropTypes.number,
    estimatedCost: PropTypes.number,
  }),
  dealerCustomerOrgId: PropTypes.string,
  setChosenOptions: PropTypes.func,
};

FinancialInformationDialog.defaultProps = {
  asset: undefined,
  estimateLeasingOptions: undefined,
  setChosenOptions: () => {},
  dealerCustomerOrgId: undefined,
};

export default FinancialInformationDialog;
