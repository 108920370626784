import { useEffect, useState } from 'react';
import { getAceSelectListOptions } from '../../util/ace.service';
import convertPayloadToOrgUserList from './ace-data-formatting-utils';

const useAceSelectList = () => {
  const [selectListData, setSelectListData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { payload } = await getAceSelectListOptions();
        setSelectListData(convertPayloadToOrgUserList(payload));
      } catch (error) {
        console.warn('Failed retrieving ACE select list data.', error);
      }
    }
    fetchData();
  }, [setSelectListData]);

  return {
    selectListData,
  };
};

export default useAceSelectList;
