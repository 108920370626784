/* eslint-disable import/no-duplicates,no-underscore-dangle */
import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import * as Constants from '../common/constants';
import { Persistent } from '../common/constants';
import customMiddleware from './actions/custom-middleware';
import Reducers from './reducers/index.reducer';

const storeFactory = (Storage, ENABLE_LOGGER, ENABLE_REDUX_DEVTOOLS) => {
  const middleware = [
    customMiddleware.callAPI(Storage),
    thunk.withExtraArgument(Storage),
  ];

  if (ENABLE_LOGGER) {
    middleware.push(createLogger());
  }

  // NOTE enable to persist redux to browser storage.
  const saver = store => next => action => {
    const result = next(action);
    const fullState = store.getState();
    // selectively persist state.
    const state = Persistent.persistentStates.reduce((acc, key) => {
      acc[key] = fullState[key];
      return acc;
    }, {});

    return Storage.setItem(Persistent.STORE, JSON.stringify(state)).then(
      () => result
    ); // persist store to localStorage
  };
  middleware.push(saver);

  return Storage.getItem(Persistent.STORE).then(item => {
    const localState = item;
    const preloadedState = localState
      ? JSON.parse(localState, (key, value) => {
          if (Constants.DateFields[key]) {
            if (value) {
              return new Date(value);
            }
          }

          return value;
        })
      : {};

    // Enable redux dev tools
    const composeEnhancers = !ENABLE_REDUX_DEVTOOLS
      ? compose
      : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(
      Reducers,
      preloadedState,
      composeEnhancers(applyMiddleware(...middleware))
    );
  });
};
export default storeFactory;
