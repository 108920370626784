import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import { OrgActionTypes, AuthActionTypes } from '../actions/action-types';

export const initialState = {
  lastUpdated: 0,
  isFetching: false,
  byId: {},
  ids: [],
  currentOrgId: null,
  messages: [],
  failed: false,
};

const createEmptyOrgDataLabel = n => {
  const levelName = `level${n}`;
  const obj = {};
  obj[levelName] = [
    {
      labelLong: '',
      labelShort: '',
      addressRequired: false,
      garageRequired: false,
    },
  ];
  return obj;
};
let updatedOrg;

const orgsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrgActionTypes.FETCH_ORGS_REQUEST:
    case OrgActionTypes.UPDATE_ORG_REQUEST:
    case OrgActionTypes.SELECT_ORG_REQUEST:
      return {
        ...state,
        isFetching: true,
        messages: [],
        failed: false,
      };

    case OrgActionTypes.FETCH_ORGS_SUCCESS:
      return {
        ...state,
        lastUpdated: Date.now(),
        isFetching: false,
        orgs: action.orgs,
        ids: action.orgs.map(org => org.id),
        byId: action.orgs.reduce((dict, org) => {
          if (org.id === state.currentOrgId && state.byId[state.currentOrgId]) {
            // Current org details are supplied by bootstrap call
            dict[org.id] = {
              ...state.byId[state.currentOrgId],
              ...org,
            };
          } else {
            dict[org.id] = org;
          }
          return dict;
        }, {}),
        messages: action.messages || [],
        failed: false,
      };

    case OrgActionTypes.SELECT_ORG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentOrgId: action.id,
        byId: {
          ...state.byId,
          [action.id]: { ...state.byId[action.id] },
          [state.currentOrgId]: { ...state.byId[state.currentOrgId] },
        },
        messages: action.messages || [],
      };

    case AuthActionTypes.LOAD_USER_DATA:
      return {
        ...state,
        currentOrgId: action.user && action.user.currentOrgId,
      };

    case OrgActionTypes.UPDATE_ORG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        byId: {
          ...state.byId,
          [state.currentOrgId]: {
            ...state.byId[state.currentOrgId],
            ...action.org,
          },
        },
        messages: action.messages || [],
      };

    case OrgActionTypes.FETCH_ORGS_FAILURE:
    case OrgActionTypes.UPDATE_ORG_FAILURE:
    case OrgActionTypes.SELECT_ORG_FAILURE:
      return {
        ...state,
        isFetching: false,
        failed: true,
        messages: action.errors,
      };

    case OrgActionTypes.ADD_EMPTY_ORG_DATA_LABEL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrgId]: {
            ...state.byId[state.currentOrgId],
            data: {
              ...state.byId[state.currentOrgId].data,
              labels: [
                ...state.byId[state.currentOrgId].data.labels,
                createEmptyOrgDataLabel(action.level),
              ],
            },
          },
        },
      };

    case OrgActionTypes.REMOVE_EMPTY_ORG_DATA_LABEL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrgId]: {
            ...state.byId[state.currentOrgId],
            data: {
              ...state.byId[state.currentOrgId].data,
              labels: [
                ...state.byId[state.currentOrgId].data.labels.filter(
                  label => !label[`level${action.level}`]
                ),
              ],
            },
          },
        },
      };

    case OrgActionTypes.INIT_ORG_DATA_LABELS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrgId]: {
            ...state.byId[state.currentOrgId],
            data: {
              ...state.byId[state.currentOrgId].data,
              labels: [],
            },
          },
        },
      };

    case OrgActionTypes.UPDATE_USER_CONFIG_DISPLAY_FIELDS:
      // eslint-disable-next-line no-case-declarations
      updatedOrg = cloneDeep(state.byId[state.currentOrgId]);
      updatedOrg = set(
        updatedOrg,
        `currentUserConfig.${action.resource}.displayFields`,
        action.displayFields
      );

      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrgId]: updatedOrg,
        },
      };

    case OrgActionTypes.UPDATE_ASSET_AUX_METADATA:
      // eslint-disable-next-line no-case-declarations
      updatedOrg = cloneDeep(state.byId[state.currentOrgId]);
      updatedOrg = set(
        updatedOrg,
        `config.assetAuxMetadata`,
        action.assetAuxMetadata
      );

      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrgId]: updatedOrg,
        },
      };

    default:
      return state;
  }
};

export default orgsReducer;

//-----------------------------------
// Selectors

export const getIsFetchingOrg = state => state.isFetching;

export const getOrg = state => state.byId[state.currentOrgId];

export const getOrgMessages = createSelector(
  [state => state.failed, state => state.messages],
  (failed, messages) => ({ failed, messages })
);

const getOrgIds = state => state.ids;

const getOrgsDict = state => state.byId;

export const getOrgs = createSelector([getOrgIds, getOrgsDict], (ids, byId) =>
  ids.map(id => byId[id])
);
