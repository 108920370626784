import React from 'react';
import PropTypes from 'prop-types';

import useDialogItem from '@holmanfm/lib/hooks/use-dialog-item';
import CloseIcon from '~/shared/icons/close';
import DeleteIcon from '~/shared/icons/delete';
import IconButton from '~/shared/components/atom/icon-button';
import Typography from '~/shared/components/atom/typography';
import makeStyles from '~/shared/components/makeStyles';
import ImageViewerDialog from '../../images/image-viewer-dialog';
import { FileType } from '../prop-types';

const useStyles = makeStyles(theme => ({
  wrapper: {},
  imageContainer: ({ imageWidth, smallImageWidth }) => ({
    display: 'inline-block',
    position: 'relative',
    width: imageWidth,
    paddingBottom: imageWidth,
    height: 0,
    [theme.breakpoints.down('sm')]: {
      width: smallImageWidth,
      paddingBottom: smallImageWidth,
    },
  }),
  image: {
    position: 'absolute',
    top: '10%',
    right: '10%',
    bottom: '10%',
    left: '10%',
    backgroundSize: 'cover' /* or contain */,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  dialogTitleWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  dialogTitle: {
    flex: 1,
    textAlign: 'center',
    marginLeft: '5em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5em',
    },
  },
  dialogActions: { margin: '0 auto', textAlign: 'right' },
}));

const FileList = props => {
  const { removeFile, files, imageWidth, smallImageWidth } = props;
  const classes = useStyles({ imageWidth, smallImageWidth });

  const { open, item: photoIndex, openDialog, closeDialog } = useDialogItem();

  return (
    <div className={classes.wrapper}>
      {files.map((file, i) => (
        <div key={file.name} className={classes.imageContainer}>
          <div
            style={{
              backgroundImage: `url(${file.downloadUrl})`,
            }}
            className={classes.image}
            role="button"
            onClick={() => openDialog(i)}
          />
        </div>
      ))}

      <ImageViewerDialog
        open={open}
        handleClose={closeDialog}
        photos={files.map(file => file.downloadUrl)}
        initialIndex={photoIndex}
        renderTitle={i => (
          <div className={classes.dialogTitleWrapper}>
            <Typography className={classes.dialogTitle}>
              {files[i]?.name}
            </Typography>
            <div className={classes.dialogActions}>
              <IconButton
                onClick={() => {
                  removeFile(files[i]?.name);
                  closeDialog();
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        )}
      />
    </div>
  );
};

FileList.propTypes = {
  files: PropTypes.arrayOf(FileType).isRequired,
  removeFile: PropTypes.func.isRequired,
  imageWidth: PropTypes.string,
  smallImageWidth: PropTypes.string,
};

FileList.defaultProps = {
  imageWidth: '12.5%',
  smallImageWidth: '25%',
};

export default FileList;
