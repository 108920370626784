/**
 * This file is responsible for loading date library dynamically.
 * On modern browsers, we use dayjs - light-weight library.
 * On legacy browsers like IE11, we use moment-timezone - heavy and deprecated library
 *
 * Both libraries have almost exactly the same interface so the code is unchanged
 * and there are no if-statements except for this file.
 */

export const shouldUseDayJS = () =>
  typeof Intl !== 'undefined' &&
  Intl &&
  'DateTimeFormat' in Intl &&
  'formatToParts' in Intl.DateTimeFormat.prototype;

export const loadDayJS = async () => {
  const [
    { default: dayjs },
    { default: relativeTime },
    { default: updateLocale },
    { default: utc },
    { default: timezonePlugin },
    { default: isSameOrAfter },
    { default: isSameOrBefore },
    { default: isBetween },
    { default: localizedFormat },
    { default: advancedFormat },
    { default: customParseFormat },
  ] = await Promise.all([
    import('dayjs'),
    import('dayjs/plugin/relativeTime'),
    import('dayjs/plugin/updateLocale'),
    import('dayjs/plugin/utc'),
    import('dayjs/plugin/timezone'),
    import('dayjs/plugin/isSameOrAfter'),
    import('dayjs/plugin/isSameOrBefore'),
    import('dayjs/plugin/isBetween'),
    import('dayjs/plugin/localizedFormat'),
    import('dayjs/plugin/advancedFormat'),
    import('dayjs/plugin/customParseFormat'),
  ]);

  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale, {
    thresholds: [
      { l: 'ss', r: 3 },
      { l: 's', r: 90 },
      { l: 'm', r: 120 },
      { l: 'h', r: 48 },
      { l: 'd', r: 99999999999 },
    ],
  });
  dayjs.extend(utc);
  dayjs.extend(timezonePlugin);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isBetween);
  dayjs.extend(localizedFormat);
  dayjs.extend(advancedFormat);
  dayjs.extend(customParseFormat);

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      ss: '%d seconds',
      m: '1 minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: '1 day',
      dd: '%d days',
      M: '1 month',
      MM: '%d months',
      y: '1 year',
      yy: '%d years',
    },
  });

  return dayjs;
};

const loadMoment = async () => {
  const { default: moment } = await import('moment-timezone');
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      ss: '%d seconds',
      m: '1 minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: '1 day',
      dd: '%d days',
      M: '1 month',
      MM: '%d months',
      y: '1 year',
      yy: '%d years',
    },
  });
  // Set new thresholds
  moment.relativeTimeThreshold('ss', 3);
  moment.relativeTimeThreshold('s', 90);
  moment.relativeTimeThreshold('m', 120);
  moment.relativeTimeThreshold('h', 48);
  moment.relativeTimeThreshold('d', 99999999999);

  return moment;
};

export default async () => {
  if (shouldUseDayJS()) {
    // For modern browsers, load light-weight loadjs.
    return loadDayJS();
  }
  // otherwise, load heavy moment.
  return loadMoment();
};
