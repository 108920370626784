import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '~/shared/components/makeStyles';
import CircularProgress from './circular-progress';

const useStyles = makeStyles(() => ({
  overlay: {
    display: 'block',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    zIndex: 998,
    cursor: 'pointer',
  },
}));

const LoadingOverlay = ({
  display,
  size,
  thickness,
  style,
  children,
  ...rest
}) => {
  const classes = useStyles();

  if (!display && children) {
    return children;
  }

  return (
    display && (
      <div>
        <div
          className={classes.overlay}
          style={children ? { position: 'absolute' } : { position: 'fixed' }}
          {...rest}
        >
          <CircularProgress size={size} thickness={thickness} style={style} />
        </div>
        {children}
      </div>
    )
  );
};

LoadingOverlay.propTypes = {
  children: PropTypes.node,
  display: PropTypes.bool,
  size: PropTypes.number,
  thickness: PropTypes.number,
  style: PropTypes.shape({}),
};

LoadingOverlay.defaultProps = {
  children: undefined,
  display: false,
  size: 80,
  thickness: 2,
  style: null,
};

export default LoadingOverlay;
