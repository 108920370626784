import { useNSTranslation } from '@holmanfm/lib/lang';
import PropTypes from 'prop-types';
import React from 'react';
import clx from 'classnames';
// import { REQUEST_STATUSES } from '@holmanfm/lib/services/service-request';

import { getStatusColorFromPalette } from '@holmanfm/lib/common/service-request-helpers';
import Typography from '~/shared/components/atom/typography';
import makeStyles from '~/shared/components/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  bullet: {
    height: 6,
    width: 6,
    marginRight: theme.spacing(1),
    borderRadius: '50%',
    backgroundColor: ({ status, disabled }) =>
      disabled
        ? theme.palette.action.disabled
        : getStatusColorFromPalette(status),
  },
  typography: {
    color: ({ disabled }) =>
      disabled ? theme.palette.action.disabled : theme.typography.body2.color,
  },
}));

const StatusText = props => {
  const { status, statusName, className, disabled, variant, ...rest } = props;
  const { t } = useNSTranslation('serviceRequests', 'statuses');
  const classes = useStyles(props);
  return (
    <div className={clx(classes.root, className)} {...rest}>
      <div className={classes.bullet} />
      <Typography
        variant={variant}
        className={clx(classes.typography, className)}
      >
        {statusName || t(status)}
      </Typography>
    </div>
  );
};

StatusText.propTypes = {
  // status: PropTypes.oneOf(Object.values(REQUEST_STATUSES)).isRequired,
  status: PropTypes.string.isRequired,
  statusName: PropTypes.string, // optional pre-translated status label, shared from API
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
};

StatusText.defaultProps = {
  className: undefined,
  statusName: undefined,
  disabled: false,
  variant: 'subtitle2',
};

export default StatusText;
