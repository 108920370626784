import React from 'react';
import PropTypes from 'prop-types';
import clx from 'classnames';
import useDialog from '@holmanfm/lib/hooks/use-dialog';
import { FileType } from '../prop-types';
import Typography from '../../atom/typography';
import IconButton from '../../atom/icon-button';
import DeleteIcon from '~/shared/icons/delete';
import FileIcon from '~/shared/icons/file';
import makeStyles from '~/shared/components/makeStyles';
import Dialog from '~/shared/components/dialog';

const isPDF = file => file.type === 'application/pdf';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '1em',
  },
  fileContainer: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  pdfPreview: { width: '50vw', height: '75vh', overflow: 'hidden' },
}));

const OtherFileList = ({ files, removeFile }) => {
  const classes = useStyles();
  const { open, openDialog, closeDialog } = useDialog();
  const [selectedFile, setSelectedFile] = React.useState();
  const handleSelect = file => {
    setSelectedFile(file);
    openDialog();
  };
  return (
    <div className={classes.container}>
      {files.map(file => (
        <div key={file.name} className={classes.fileContainer}>
          <FileIcon />
          <Typography
            variant="subtitle1"
            className={clx({ [classes.clickable]: isPDF(file) })}
            onClick={() => handleSelect(file)}
          >
            {file.name}
          </Typography>
          <IconButton
            size="small"
            className={classes.deleteButton}
            onClick={() => removeFile(file.name)}
          >
            <DeleteIcon size="small" />
          </IconButton>
        </div>
      ))}

      <Dialog
        onClose={closeDialog}
        aria-labelledby="pdf viewer"
        open={open}
        maxWidth="lg"
      >
        <div className={classes.pdfPreview}>
          <embed
            src={selectedFile?.downloadUrl}
            width="100%"
            height="100%"
            type="application/pdf"
          />
        </div>
      </Dialog>
    </div>
  );
};

OtherFileList.propTypes = {
  files: PropTypes.arrayOf(FileType).isRequired,
  removeFile: PropTypes.func.isRequired,
};

export default OtherFileList;
