import has from '../fp/has';
import not from '../fp/not';
import pipe from '../fp/pipe';
import both from '../fp/both';

export function hasSubroutes(r) {
  return has('subroutes', r) && r.subroutes.length > 0;
}

export function hasNavigation(r) {
  return has('navigation', r);
}

export function hasRouting(r) {
  return has('routing', r);
}

export function hasFeature(feature) {
  return function _hasFeature(features /* , isAdmin, services, org */) {
    return features.includes(feature);
  };
}

export function withOrgServices(pred) {
  return function _hasOrgService(features, isAdmin, services, org) {
    return pred(org.services);
  };
}

export const doesNotHaveFeature = feature => pipe(hasFeature(feature), not);

export function isOrgAdmin(features, isAdmin /* , services, org */) {
  return isAdmin === true;
}
export const isNotOrgAdmin = pipe(isOrgAdmin, not);
export const hasNavigationSubroutes = both(hasSubroutes, route =>
  route.subroutes.some(({ navigation }) => Boolean(navigation))
);

export const hasRoutingSubroutes = both(hasSubroutes, route =>
  route.subroutes.some(({ routing }) => Boolean(routing))
);

/**
 * @deprecated
 * Please rely on features instead. Specifically, Features.ASSETS.INDEX.
 */
export const hasAssetBasedService = withOrgServices(services =>
  services.some(service => service?.basis?.includes('asset'))
);
