import React from 'react';
import PropTypes from 'prop-types';

import { MaintenanceRepairRejectionShape } from '@holmanfm/lib/services/maintenance-repair-history';
import { useNSTranslation } from '@holmanfm/lib/lang';
import Dialog from '~/shared/components/dialog';
import DialogContent from '~/shared/components/dialog-content';
import DialogTitle from '~/shared/components/dialog-title';
import IconButton from '~/shared/components/atom/icon-button';
import Close from '~/shared/icons/close';
import makeStyles from '~/shared/components/makeStyles';
import Table from '~/shared/components/table';
import TableHead from '~/shared/components/table-head';
import TableRow from '~/shared/components/table-row';
import TableCell from '~/shared/components/table-cell';
import TableBody from '~/shared/components/table-body';

const useStyles = makeStyles(theme => ({
  modalClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 999,
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: 0,
    },
    '& svg': {
      fontSize: '30px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
  },
}));

/**
 * Dialog to view apc rejection reasons.
 */
const RejectionReasonsModal = ({
  open,
  items,
  rejectionReasons,
  onClose,
  title,
}) => {
  const { t } = useNSTranslation('maintenanceRepair', 'history');
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <IconButton onClick={onClose} className={classes.modalClose}>
        <Close />
      </IconButton>

      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('description')}</TableCell>
              <TableCell>{t('reason')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .filter(item => rejectionReasons?.[item.sourceItemId])
              .map(item => (
                <TableRow key={item.sourceItemId}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>
                    {rejectionReasons[item.sourceItemId].map(reason => (
                      <p key={reason.ruleId}>{reason.message}</p>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

RejectionReasonsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      // Not required for legacy items. In this case, the component is still rendered but not visible
      sourceItemId: PropTypes.number,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  // keys correspond to items[].sourceItemId
  rejectionReasons: PropTypes.objectOf(
    PropTypes.arrayOf(MaintenanceRepairRejectionShape)
  ),
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

RejectionReasonsModal.defaultProps = {
  rejectionReasons: {},
};

export default RejectionReasonsModal;
