import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '~/shared/icons/check-icon';
import CircularProgress from '~/shared/components/circular-progress';
import Button from '~/shared/components/atom/button';

const ProgressButton = props => {
  const { submitFunc, btnTitle, processing, disabled } = props;
  const [processFinished, setProcessFinished] = React.useState(false);

  const prevProcessing = React.useRef(processing);

  const clearProcessing = () => {
    setTimeout(() => {
      setProcessFinished(false);
      prevProcessing.current = false;
    }, 2000);
  };

  React.useEffect(() => {
    if (processing === true && prevProcessing.current === false) {
      prevProcessing.current = processing;
      clearProcessing();
      setProcessFinished(true);
    }
  }, [processing, setProcessFinished, processFinished]);

  const iconToShow = () => {
    if (processing) {
      return <CircularProgress inlineStyle size={24} />;
    }
    if (processFinished) {
      return <CheckIcon style={{ color: '#2C9942' }} />;
    }
    return null;
  };

  return (
    <Button
      color="secondary"
      onClick={submitFunc}
      variant="contained"
      disabled={disabled}
      style={{ marginRight: 8 }}
      endIcon={iconToShow()}
    >
      {btnTitle}
    </Button>
  );
};

ProgressButton.propTypes = {
  submitFunc: PropTypes.func.isRequired,
  btnTitle: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

ProgressButton.defaultProps = {
  disabled: false,
};

export default ProgressButton;
