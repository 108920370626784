/**
 * @param  {...function} predicates list of functions that return a boolean
 * @returns a new function that returns true only if any of the predicates return true.
 */
export default function anyPass(...predicates) {
  return function _anyPass(...args) {
    const { length } = predicates;
    let idx = 0;
    while (idx < length) {
      if (predicates[idx].apply(this, args)) {
        return true;
      }
      idx += 1;
    }
    return false;
  };
}
