import React from 'react';
import PropTypes from 'prop-types';

import { NoteShape } from '@holmanfm/lib/services/maintenance-repair-history';
import { mapNotes } from '@holmanfm/lib/common/service-request-helpers';
import Dialog from '~/shared/components/dialog';
import DialogContent from '~/shared/components/dialog-content';
import DialogTitle from '~/shared/components/dialog-title';
import IconButton from '~/shared/components/atom/icon-button';
import Close from '~/shared/icons/close';
import makeStyles from '~/shared/components/makeStyles';
import NoteItem from '../notes/note-item';

const useStyles = makeStyles(theme => ({
  modalClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 999,
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: 0,
    },
    '& svg': {
      fontSize: '30px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
  },
}));

const ViewNotesModal = ({ open, notes, onClose, title }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <IconButton onClick={onClose} className={classes.modalClose}>
        <Close />
      </IconButton>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{mapNotes(notes, NoteItem)}</DialogContent>
    </Dialog>
  );
};

ViewNotesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  notes: PropTypes.arrayOf(NoteShape),
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

ViewNotesModal.defaultProps = {
  notes: [],
};

export default ViewNotesModal;
