// Asset Utility Functions

/**
 * To convert third_party_lease_type to readable string
 */
export const purchaseTypeConvert = {
  '4': 'Own',
  '5': 'Own',
  '6': 'Lease',
};

export const temp = {};
