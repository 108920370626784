/* eslint-disable no-restricted-imports */

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const SEP = '.';
/**
 * Allows namespace and scoped translations. Makes full use of react-18next useTranslation arguments
 * and return values.
 *
 * @param {string | string[]} [ns] - Namespace or namespaces provided to react-i18next useTranslation.
 * @param {string | string[]} [scope] - String or array of strings used to scope translation object.
 * @param {*} [opt] - Options passed to react-i18next useTranslation.
 *
 * @example
 * const { t } = useNSTranslation(`namespace`, `scope-1`);
 *    t(`some-key`) //--> namespace:scope-1.some-key
 *
 * const { t } = useNSTranslation(`namespace`, `scope-1.scope-2`);
 *    t(`some-key`) //--> namespace:scope-1.scope-2.some-key
 *
 * const { t } = useNSTranslation(`namespace`, [`scope-1`, `scope-2`]);
 *    t(`some-key`) //--> namespace:scope-1.scope-2.some-key
 *
 * const { t } = useNSTranslation([`main`, `namespace`], `scope-1`)
 *    t(`some-key`) //--> main:scope-1.scope-2.some-key
 *    t(`some-key`) //--> namespace:scope-1.scope-2.some-key
 */
export default function useNSTranslation(ns, scope = [], opt) {
  const translation = useTranslation(ns, opt);

  const t = useCallback(
    (v, ...rest) => {
      const arr = Array.isArray(scope) ? scope : [scope];

      return translation.t(arr.concat(v).join(SEP), ...rest);
    },
    [translation, scope]
  );

  const tIfExists = useCallback(
    (v, fallback, ...rest) => {
      const arr = Array.isArray(scope) ? scope : [scope];
      if (translation.i18n.exists(arr.concat(v).join(SEP))) {
        return translation.t(arr.concat(v).join(SEP), ...rest);
      }
      return fallback;
    },
    [translation, scope]
  );

  return { ...translation, t, tIfExists };
}
