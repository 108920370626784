import { useState } from 'react';

/**
 * State for a dialog that requires an item selected
 */
export default (initialState = { open: false, item: null }) => {
  const [dialog, setDialog] = useState(initialState);
  const openDialog = item => setDialog({ open: true, item });
  const closeDialog = () => setDialog({ open: false, item: null });

  return {
    ...dialog,
    openDialog,
    closeDialog,
  };
};
