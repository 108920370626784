import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '@holmanfm/lib/util/moment';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { formatMoney } from '@holmanfm/lib/common/marketplace-helpers';
import Typography from '~/shared/components/atom/typography';
import typeStyles from '~/shared/components/styles/global-type-styles';
import makeStyles from '~/shared/components/makeStyles';

const useStyles = makeStyles(theme => ({
  quoteContainer: {
    background: theme.palette.backgroundColors.blue,
    maxWidth: 600,
  },
  quoteHeader: {
    background: theme.palette.secondary.main,
    color: '#fff',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const SubmittedQuoteInformation = props => {
  const { chosenOptions, submittedData } = props;
  const typeClasses = typeStyles();
  const { t } = useNSTranslation('dealerPortal', 'payment-estimator');
  const classes = useStyles();

  return (
    <div style={{ marginTop: 32 }}>
      <div className={classes.quoteContainer}>
        <div className={classes.quoteHeader}>
          <Typography variant="body2">
            <strong>{t('quote-submitted')}</strong>{' '}
            {formatDate(submittedData.start)}
          </Typography>
        </div>
        <div
          style={{
            padding: '8px 16px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Typography variant="subtitle2" className={typeClasses.driveBlue}>
              {t('terms')}
            </Typography>
            <Typography variant="body2">
              {chosenOptions[0].term} {t('months')}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle2" className={typeClasses.driveBlue}>
              {t('residual')} (%/$)
            </Typography>
            <Typography variant="body2">
              {chosenOptions[0]?.residual}/
              {formatMoney(chosenOptions[0]?.residualDollars)}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle2" className={typeClasses.driveBlue}>
              {t('monthly-cost')}
            </Typography>
            <Typography variant="body2">
              {formatMoney(chosenOptions[0].leaseEstimate)}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle2" className={typeClasses.driveBlue}>
              {t('dealer-profit')}
            </Typography>
            <Typography variant="body2">
              {formatMoney(chosenOptions[0]?.profit) || 'N/A'}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

SubmittedQuoteInformation.propTypes = {
  submittedData: PropTypes.shape({
    start: PropTypes.string,
  }).isRequired,
  chosenOptions: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.number,
      leaseEstimate: PropTypes.string,
      residualDollars: PropTypes.string,
      residual: PropTypes.number,
    })
  ).isRequired,
};

export default SubmittedQuoteInformation;
