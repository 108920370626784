/* eslint-disable import/prefer-default-export */
import { currencyToNumber } from '../../common/marketplace-helpers';
import { numberToRange } from '../../util/helpers';
import { toDate, toPostgresDate } from '../../util/moment';

export const getQueryFromFilters = filters => {
  const minDate = filters?.find(
    f => f.id === 'specificRange' && f.operator === 'gte'
  );
  const maxDate = filters?.find(
    f => f.id === 'specificRange' && f.operator === 'lte'
  );
  const invoiceYearMonth = filters?.find(f => f.id === 'invoiceYearMonth');
  const creditCard = filters?.find(f => f.id === 'creditCardLast4');
  const orgNode = filters?.find(f => f.id === 'orgNode');
  const [orgNodeId, orgNodeLabel] = (orgNode?.value || '|').split('|');
  return {
    minDate: minDate ? toPostgresDate(minDate.value) : '',
    maxDate: maxDate ? toPostgresDate(maxDate.value) : '',
    invoiceYearMonth: invoiceYearMonth ? invoiceYearMonth.value : '',
    creditCard: creditCard ? creditCard.value : '',
    orgNodeId,
    orgNodeLabel,
  };
};

// aAssets [[]] = array of asset data
// aHDs [[]] = array of fuel header data and fuel detail data
// fields [{}] = object array of field definitions that matches the aAssets + aHDs values
// Note: the first value in each item of the 'aHDs' array correlates to the index of the asset in the 'aAssets' array
// This code assembles objects from three arrays - asset data (aAssets) + header/detail data (aHDs) mapped to field definitions (fields)
export const mapFuelTransactionData = payload => {
  const { fields, aHDs, aAssets } = payload;
  const [fieldsLen, assetLen] = [
    fields.length,
    fields.filter(f => f.table === 'a' || f.table === 'aCustom').length,
  ];
  return aHDs.map(hd => {
    const asset = aAssets[hd[0]];
    const obj = {};
    for (let i = 0; i < fieldsLen; i += 1) {
      let val;
      if (i < assetLen) {
        val = asset[i + 1]; // skip the first item in the array because that is the assetId
      } else if (i === assetLen) {
        [val] = asset; // asset[0] === the assetId
      } else {
        val = hd[i - assetLen]; // header & detail data is mapped after the asset data (assetLen is 6)
      }
      obj[fields[i].name] = val;
    }
    obj.specificRange = toDate(obj.refuelDate);
    return obj;
  });
};

export const formatFuelTransactionsData = (items, displayFields) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    item.orgNode = item.location;
    delete item.location;
    item.totalCost = item.totalCost
      ? currencyToNumber(item.totalCost)
      : undefined;
    item.unitCost = item.unitCost ? currencyToNumber(item.unitCost) : undefined;
    item.odometerRange = numberToRange(item.odometer, [
      0,
      20000,
      40000,
      60000,
      80000,
      100000,
      120000,
      140000,
      160000,
    ]);
    // If there are display fields, make sure all date fields are converted to Date objects
    if (displayFields) {
      // eslint-disable-next-line no-restricted-syntax
      for (const field of displayFields) {
        if (field.type === 'date') {
          item[field.field] = item[field.field]
            ? new Date(item[field.field])
            : undefined;
        }
      }
    }
  }
  return items;
};
