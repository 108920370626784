export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function uncapitalizeFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function mapSelectors(nextLevelSelectors, stateSlice) {
  return Object.keys(nextLevelSelectors).reduce((dict, key) => {
    dict[key] = (state, ...args) =>
      nextLevelSelectors[key](state[stateSlice], ...args);
    return dict;
  }, {});
}
