/* eslint-disable no-await-in-loop */
import { useEffect, useState } from 'react';
import {
  postFuelCardSuspend,
  postFuelCardReactivate,
  postFuelCardSuspendBulk,
  postFuelCardReactivateBulk,
  postFuelCardCancelBulk,
} from '../../services/fuel';

function useFuelSuspend(items, allFilteredData, refetchData) {
  const [pending, setPending] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [bulkActionSelect, setBulkActionSelect] = useState('');
  const [cardData, setCardData] = useState(allFilteredData);
  const [bulkErrors, setBulkErrors] = useState([]);
  const [isProcessing, setIsProcessing] = useState([]); // displays which item is being processed
  const [cancelling, setCancelling] = useState(false);
  const [serverError, setServerError] = useState(null);
  const selectedCardData = allFilteredData.filter(x => isCheck.includes(x.id));

  const handleBulkErrors = (cardId, err, creditCard) => {
    if (!bulkErrors.find(error => error.id === cardId)) {
      setBulkErrors(prevState =>
        prevState.concat({
          id: cardId,
          message: err,
          creditCard,
        })
      );
    }
  };

  useEffect(() => {
    setCardData(allFilteredData);
    let anyPending = false;
    allFilteredData.forEach(card => {
      if (card.pending === true) {
        anyPending = true;
        setIsProcessing(prevState => prevState.concat(card.id));
      } else {
        setIsProcessing(prevState =>
          prevState.filter(item => item !== card.id)
        );
      }
      if (card.error) {
        handleBulkErrors(card.id, card.error, card.creditCardNumber);
      } else {
        setBulkErrors(prevBulkErrors =>
          prevBulkErrors.filter(item => item.id !== card.id)
        );
      }
    });
    setPending(anyPending);
    // while any item is pending, continue to refetch data for updates every 5 seconds
    if (anyPending) {
      setTimeout(refetchData, 5000);
      selectedCardData.forEach(card => {
        // remove checks from items as they are processed
        if (card.pending === false) {
          // uncheck cards as processing completes
          setIsCheck(prevCheck => prevCheck.filter(item => item !== card.id));
        }
      });
    } else {
      setCancelling(false);
      setBulkActionSelect('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFilteredData]);

  useEffect(() => {
    if (pending === false) {
      // clear the last remaining checks when processing completes
      setIsCheck([]);
      setIsCheckAll(false);
    }
  }, [pending]);

  const handleChange = e => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  const handleProcessBulk = async (cardIds, action) => {
    try {
      const res =
        action === 'suspend'
          ? await postFuelCardSuspendBulk(cardIds)
          : await postFuelCardReactivateBulk(cardIds);
      // refetch data to retrieve pending states
      setTimeout(refetchData, 1000);
      return res;
    } catch (err) {
      setServerError(err);
    }
  };

  const handleSubmit = async (cardId, action) => {
    try {
      setIsProcessing([cardId]);
      setPending(true);
      return action === 'suspend'
        ? await postFuelCardSuspend(cardId)
        : await postFuelCardReactivate(cardId);
    } catch (err) {
      setServerError(err);
    } finally {
      setTimeout(refetchData, 1000);
    }
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    const selectedItems = cardData.reduce((acc, li) => {
      // Reduce to only the items that have checkboxes
      if (
        li?.uiStatus?.toLowerCase() === 'active' ||
        li?.uiStatus?.toLowerCase() === 'suspended'
      ) {
        acc.push(li.id);
      }
      return acc;
    }, []);
    setIsCheck(selectedItems);
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const cancelBulkProcessing = async () => {
    setCancelling(true);
    try {
      await postFuelCardCancelBulk();
    } catch (err) {
      setServerError(err);
    }
  };

  const clearSelection = () => {
    setIsCheckAll(false);
    setIsCheck([]);
    setBulkActionSelect('');
  };

  // filter cardData (allFilteredData) down to the paged items
  const itemsArr = items.map(x => x.id);
  const pagedItems = cardData.filter(item => itemsArr.includes(item.id));

  return {
    pending,
    handleChange,
    handleSelectAll,
    clearSelection,
    isCheckAll,
    isCheck,
    handleSubmit,
    handleProcessBulk,
    bulkActionSelect,
    setBulkActionSelect,
    bulkErrors,
    cancelBulkProcessing,
    isProcessing,
    pagedItems,
    cancelling,
    serverError,
  };
}

export default useFuelSuspend;
