import * as React from 'react';
import PropTypes from 'prop-types';
import useServerMessages from '@holmanfm/lib/hooks/use-server-messages';
import { useNSTranslation } from '@holmanfm/lib/lang';
import ErrorMessage from './error/error-message';
import ServerDownMessage from '~/shared/components/messages/error/server-down-message';

const ServerMessages = props => {
  const { condensed, showErrors, showWarnings, showInfo, messages } = props;
  const { t } = useNSTranslation('common', 'errors');
  const { errors, warnings, errorCategory } = useServerMessages(messages);

  const errorMessages = React.useMemo(() => {
    if (!showErrors) return null;
    if (errorCategory === 'critical') {
      return <ServerDownMessage message={errors} dataTestId="error-message" />;
    }
    return (
      <ErrorMessage title={condensed ? '' : t('general')}>
        <p className="center-align" data-testid="error-message">
          {errors}
        </p>
      </ErrorMessage>
    );
  }, [errors, showErrors, condensed, t]);

  const warningMessages = React.useMemo(() => {
    if (!showWarnings) return null;
    return warnings.map((warning, index) => (
      <ErrorMessage title={condensed ? '' : t('general')} key={warning}>
        <p className="center-align" data-testid={`warning-${index}`}>
          {warning}
        </p>
      </ErrorMessage>
    ));
  }, [warnings, showWarnings, t, condensed]);

  const info = showInfo && [];

  return (
    <div style={{ whiteSpace: 'pre-line' }}>
      <div className="errors">{errorMessages}</div>
      <div className="warnings">{warningMessages}</div>
      <div className="info">{info}</div>
    </div>
  );
};

ServerMessages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  showErrors: PropTypes.bool,
  showWarnings: PropTypes.bool,
  showInfo: PropTypes.bool,
  condensed: PropTypes.bool,
};

ServerMessages.defaultProps = {
  condensed: false,
  showErrors: true,
  showWarnings: false,
  showInfo: false,
};

export default ServerMessages;
