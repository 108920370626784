/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { useSelector } from '@holmanfm/lib/redux';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import useGetLocationsFromId from '@holmanfm/lib/hooks/assets/use-get-locations-from-id';
import Typography from '~/shared/components/atom/typography';
import Select, { MenuItem } from '~/shared/components/forms/select';
import makeStyles from '~/shared/components/makeStyles';

const useStyles = makeStyles(theme => ({
  labelContainer: {
    display: 'flex',
    '& > div': {
      marginRight: theme.spacing(2),
      '&:last-child': {
        marginRight: 0,
      },
    },
    // marginBottom: theme.spacing(3),
    // '& > div:not(:last-child)': {
    //   marginRight: theme.spacing(1),
    // },
  },
  inputWidth: {
    [theme.breakpoints.up('md')]: {
      width: '33%',
    },
  },
}));

const childrenOfSelectedLevel = (allNodes, levelData, level) => {
  const node = allNodes.filter(x => x.id === levelData?.[level]?.id)[0];
  return node?.childIds?.map(childId => allNodes.find(x => x.id === childId));
};

const AssetLocations = props => {
  const {
    orgLevels,
    tree,
    formik: { values, handleChange, setFieldValue },
  } = props;
  const classes = useStyles();
  const { t } = useNSTranslation();
  const orgNodesFlattened = useSelector(selectors.getOrgNodes);
  const selectedLevel = useGetLocationsFromId(values?.orgNodeId);
  const [oldOrgNodeId, setOldOrgNodeId] = React.useState('');

  React.useEffect(() => {
    setFieldValue('hierarchyLevel1', selectedLevel?.level1?.id || '');
    setFieldValue('hierarchyLevel2', selectedLevel?.level2?.id || '');
    setFieldValue('hierarchyLevel3', selectedLevel?.level3?.id || '');
    setOldOrgNodeId(values?.orgNodeId);
  }, []);

  const [level1Label, level2Label, level3Label] = orgLevels;

  const dropDownHandleChange = (e, lvl) => {
    handleChange(e);
    const { value } = e.target;
    const updateOrgNodeId = () => {
      if (value === '') {
        // If the selected value is empty go to this code
        if (lvl === 1) {
          // if the selected value is empty and the level is the topmost level (lvl 1) then it should be empty.
          return value;
        }
        const parent = orgNodesFlattened.find(on =>
          on.childIds.includes(oldOrgNodeId)
        );
        setOldOrgNodeId(parent?.id);
        return parent?.id;
      }
      setOldOrgNodeId(value);
      return value;
    };
    if (lvl === 1) {
      setFieldValue('orgNodeId', updateOrgNodeId());
      setFieldValue('hierarchyLevel1', value);
      setFieldValue('hierarchyLevel2', '');
      setFieldValue('hierarchyLevel3', '');
    }
    if (lvl === 2) {
      setFieldValue('orgNodeId', updateOrgNodeId());
      setFieldValue('hierarchyLevel2', value);
      setFieldValue('hierarchyLevel3', '');
    }
    if (lvl === 3) {
      setFieldValue('orgNodeId', updateOrgNodeId());
      setFieldValue('hierarchyLevel3', value);
    }
  };

  const level1Children = tree?.map(lv1 => lv1);
  const level2Children = childrenOfSelectedLevel(
    orgNodesFlattened,
    selectedLevel,
    'level1'
  );
  const level3Children = childrenOfSelectedLevel(
    orgNodesFlattened,
    selectedLevel,
    'level2'
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div>
        <Typography
          variant="body2"
          style={{ color: '#000', fontWeight: 'bold', marginBottom: 16 }}
        >
          {t('assets:add-asset:locations')}
        </Typography>
      </div>
      <div className={classes.labelContainer}>
        {level1Label && (
          <Select
            fullWidth
            name="hierarchyLevel1"
            id="hierarchyLevel1"
            label={level1Label}
            variant="outlined"
            displayEmpty
            notched
            handleChange={e => dropDownHandleChange(e, 1)}
            className={classes.inputWidth}
            style={{ flex: 0.9 }}
          >
            <MenuItem value="" key="null">
              &nbsp;
            </MenuItem>
            {level1Children?.map(lv1 => (
              <MenuItem value={lv1.id} key={lv1.id}>
                {lv1.node_label}
              </MenuItem>
            ))}
          </Select>
        )}
        {level2Label && (
          <Select
            fullWidth
            name="hierarchyLevel2"
            id="hierarchyLevel2"
            label={level2Label}
            variant="outlined"
            displayEmpty
            notched
            handleChange={e => dropDownHandleChange(e, 2)}
            className={classes.inputWidth}
            style={{ flex: 1 }}
          >
            <MenuItem value="">&nbsp;</MenuItem>
            {values?.hierarchyLevel1 &&
              level2Children?.map(lv2 => (
                <MenuItem value={lv2?.id} key={lv2?.id}>
                  {lv2?.node_label}
                </MenuItem>
              ))}
          </Select>
        )}

        {level3Label && (
          <Select
            fullWidth
            name="hierarchyLevel3"
            id="hierarchyLevel3"
            label={level3Label}
            variant="outlined"
            displayEmpty
            notched
            handleChange={e => dropDownHandleChange(e, 3)}
            className={classes.inputWidth}
            style={{ flex: 1 }}
          >
            <MenuItem value="">&nbsp;</MenuItem>
            {values?.hierarchyLevel2 &&
              level3Children?.map(lv3 => (
                <MenuItem value={lv3?.id} key={lv3?.id}>
                  {lv3?.node_label}
                </MenuItem>
              ))}
          </Select>
        )}
        <div style={{ flex: 0.65 }} />
      </div>
    </div>
  );
};

AssetLocations.propTypes = {
  orgLevels: PropTypes.arrayOf(PropTypes.string).isRequired,
  tree: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      hierarchyLevel1: PropTypes.string,
      hierarchyLevel2: PropTypes.string,
      hierarchyLevel3: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
  }).isRequired,
};

export default connect(AssetLocations);
