import React from 'react';

const useSelectedLeasingOptions = () => {
  const [chosenOptions, setChosenOptions] = React.useState([]);
  const [selectedLease, setSelectedLease] = React.useState();

  const updateSelection = selectedOption => {
    const optionIndex = chosenOptions.findIndex(
      x =>
        x?.term === selectedOption?.term &&
        x?.leaseEstimate === selectedOption?.leaseEstimate
    );
    if (optionIndex === -1) {
      setChosenOptions([selectedOption]);
    }
  };

  React.useEffect(() => {
    if (chosenOptions.length === 0) {
      setSelectedLease(undefined);
    }
  }, [chosenOptions]);

  // on a resumed estimate - highlight the previously selected option
  React.useEffect(() => {
    if (chosenOptions[0]?.selected && !selectedLease) {
      setSelectedLease(
        `${chosenOptions[0]?.term}-${chosenOptions[0]?.leaseEstimate}`
      );
    }
  }, [chosenOptions, selectedLease]);

  return {
    chosenOptions,
    setChosenOptions,
    updateSelection,
    selectedLease,
    setSelectedLease,
  };
};

export default useSelectedLeasingOptions;
