import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import URI from 'urijs';
import { useSelector } from '@holmanfm/lib/redux';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import Box from '~/shared/components/box';

const getOrgId = createSelector(selectors.getOrg, org =>
  org ? org.id : undefined
);

const getOrgFeatures = createSelector(selectors.getOrg, org =>
  org ? org.features : undefined
);

const getOrgName = createSelector(selectors.getOrgData, data =>
  data ? data.organizationName : undefined
);

function stringListContains(stringList = '') {
  const split = stringList.split(',');
  return function _contains(needle) {
    return split.includes(needle);
  };
}

const DebugPanel = () => {
  const org = useSelector(selectors.getOrg);
  const { search } = useLocation();
  const uri = new URI(search);

  const isDebugging = uri.hasQuery('debug');

  if (isDebugging === false) {
    return null;
  }

  const { debug } = uri.query(true);

  return <>{org && <OrganizationDetails values={debug} />}</>;
};

const OrganizationDetails = ({ values }) => {
  const id = useSelector(getOrgId);
  const name = useSelector(getOrgName);
  const features = useSelector(getOrgFeatures) || [];
  const orgRole = useSelector(selectors.getOrgRole);

  const valuesContain = stringListContains(values);

  return (
    <Box display="flex" justifyContent="center" p={1}>
      <Box maxWidth="500">
        {valuesContain('org') && (
          <p>
            <span style={{ fontWeight: 'bold' }}>Organization:</span> {name} (
            {id})
          </p>
        )}
        {valuesContain('features') && (
          <p>
            <span style={{ fontWeight: 'bold' }}>Features:</span>{' '}
            {features.join(', ')}
          </p>
        )}
        {valuesContain('role') && (
          <p>
            <span style={{ fontWeight: 'bold' }}>Organization Role:</span>{' '}
            {orgRole.name}
          </p>
        )}
      </Box>
    </Box>
  );
};

OrganizationDetails.propTypes = {
  values: PropTypes.string,
};

OrganizationDetails.defaultProps = {
  values: '',
};

export default DebugPanel;
