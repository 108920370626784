import { createSelector } from 'reselect';
import { StripeActionTypes } from '../actions/action-types';

export const initialState = {
  isFetching: false,
  messages: [],
  payments: {},
  failed: false,
};

const stripeReducer = (state = initialState, action) => {
  switch (action.type) {
    case StripeActionTypes.FETCH_STRIPE_PAYMENT_REQUEST:
    case StripeActionTypes.ADD_STRIPE_PAYMENT_REQUEST:
    case StripeActionTypes.DELETE_STRIPE_PAYMENT_REQUEST:
    case StripeActionTypes.UPDATE_STRIPE_PAYMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        messages: [],
        failed: false,
      };

    case StripeActionTypes.FETCH_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payments: action.payment,
        messages: action.messages || [],
      };

    case StripeActionTypes.ADD_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payments: action.payment,
        messages: action.messages || [],
      };

    case StripeActionTypes.DELETE_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payments: action.payment,
        messages: action.messages || [],
      };

    case StripeActionTypes.UPDATE_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payments: action.payment,
        messages: action.messages || [],
      };

    case StripeActionTypes.ADD_STRIPE_PAYMENT_FAILURE:
    case StripeActionTypes.FETCH_STRIPE_PAYMENT_FAILURE:
    case StripeActionTypes.DELETE_STRIPE_PAYMENT_FAILURE:
    case StripeActionTypes.UPDATE_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        failed: true,
        messages: action.errors,
      };

    default:
      return state;
  }
};

export default stripeReducer;

export const getStripePayments = state => state.payments;
export const getIsFetchingStripePayments = state => state.isFetching;
export const getStripePaymentMessages = createSelector(
  [state => state.failed, state => state.messages],
  (failed, messages) => ({ failed, messages })
);
