import MemoryCache from 'memory-cache';

const ttlSeconds = 60 * 5 * 1000;
const cache = new MemoryCache.Cache();

export async function getCached(key, getDataFunction, forceRefresh = false) {
  let data = forceRefresh ? null : cache.get(key);
  if (data) {
    return Promise.resolve(data);
  }
  data = await getDataFunction();
  cache.put(key, data, ttlSeconds);
  return data;
}

export function flushCache() {
  // eslint-disable-next-line no-console
  cache.clear();
}
