import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

const SearchingContext = createContext({
  searchTerm: '',
  setSearchTerm: () => undefined,
});

export const SearchingProvider = ({ term, ...props }) => {
  const [searchTerm, setSearchTerm] = useState(() => term);
  const ctx = useMemo(() => ({ searchTerm, setSearchTerm }), [searchTerm]);

  return <SearchingContext.Provider value={ctx} {...props} />;
};

SearchingProvider.propTypes = {
  term: PropTypes.string,
};

SearchingProvider.defaultProps = {
  term: '',
};

/**
 * @typedef {Object} UseSearching
 * @property {string} searchTerm
 * @property {(searchTerm: string) => void} setSearchTerm
 */

/**
 * @returns {UseSearching}
 */
export function useSearching() {
  const ctx = useContext(SearchingContext);

  if (!ctx) {
    throw new Error(``);
  }

  return ctx;
}
