import { useDispatch, useSelector } from '../../redux';
import {
  beginAceSessionThunk,
  endAceSessionThunk,
} from '../../redux/actions/ace.actions';
import {
  getAceActorUserEmail,
  getAceActingAsUserEmail,
  getAceActingAsUserOrgName,
  getAceActingAsUserRoleName,
  getIsAceActive,
} from '../../redux/reducers/ace.reducer';
import { useNSTranslation } from '../../lang';

const useAceControls = () => {
  const { t } = useNSTranslation('customerService', 'ace.messages');
  const reduxDispatch = useDispatch();
  const actorEmail = useSelector(getAceActorUserEmail);
  const actingAsEmail = useSelector(getAceActingAsUserEmail);
  const actingAsOrgName = useSelector(getAceActingAsUserOrgName);
  const actingAsRole = useSelector(getAceActingAsUserRoleName);
  const isAceSessionActive = useSelector(getIsAceActive);

  return {
    // properties
    isAceSessionActive,
    statusMessage: isAceSessionActive
      ? t('active-session', {
          actorEmail,
          actingAsEmail,
          actingAsRole,
          actingAsOrgName,
        })
      : t('inactive-session'),
    // methods
    startAceSession: (userId, orgId) =>
      reduxDispatch(beginAceSessionThunk(userId, orgId)),
    stopAceSession: () => reduxDispatch(endAceSessionThunk()),
  };
};

export default useAceControls;
