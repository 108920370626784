import API from '../../../util/api';
import { OrgActionTypes } from '../action-types';
import { makeActionCreator, ActionsResponseMapper } from '../util';
import { bootstrap } from '../auth.actions';

// %%%%%%%%%%%%%%%%%%%%%%%%%% ACTION CREATORS %%%%%%%%%%%%%%%%%%%%%.

// UPDATE ORG.
export const updateOrgRequest = makeActionCreator(
  OrgActionTypes.UPDATE_ORG_REQUEST
);
export const updateOrgSuccess = makeActionCreator(
  OrgActionTypes.UPDATE_ORG_SUCCESS,
  'org',
  'messages'
);
export const updateOrgFailure = makeActionCreator(
  OrgActionTypes.UPDATE_ORG_FAILURE,
  'errors'
);

// SELECT ORG
export const selectOrgRequest = makeActionCreator(
  OrgActionTypes.SELECT_ORG_REQUEST
);
export const selectOrgSuccess = makeActionCreator(
  OrgActionTypes.SELECT_ORG_SUCCESS,
  'id',
  'messages'
);
export const selectOrgFailure = makeActionCreator(
  OrgActionTypes.SELECT_ORG_FAILURE,
  'errors'
);

// INITIALIZE ORG DATA LABELS
export const initializeOrgDataLabels = makeActionCreator(
  OrgActionTypes.INIT_ORG_DATA_LABELS
);

export const addEmptyOrgDataLabel = makeActionCreator(
  OrgActionTypes.ADD_EMPTY_ORG_DATA_LABEL,
  'level'
);

export const removeEmptyOrgDataLabel = makeActionCreator(
  OrgActionTypes.REMOVE_EMPTY_ORG_DATA_LABEL,
  'level'
);

export const updateUserConfigDisplayFields = makeActionCreator(
  OrgActionTypes.UPDATE_USER_CONFIG_DISPLAY_FIELDS,
  'resource',
  'displayFields'
);

export const updateAssetAuxMetadata = makeActionCreator(
  OrgActionTypes.UPDATE_ASSET_AUX_METADATA,
  'assetAuxMetadata'
);

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%% ASYNC ACTIONS %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// UPDATE ORG
export const updateOrg = updates => ({
  actions: [updateOrgRequest, updateOrgSuccess, updateOrgFailure],
  callAPI: () => API.patch('/organizations', updates),
  responseMapper: new ActionsResponseMapper(),
});

// UPDATE ORG DOC STATUS
// export const updateOrgTermsStatus = status => ({
//   actions: [updateOrgRequest, updateOrgSuccess, updateOrgFailure],
//   callAPI: state => API.patch(`/organizations/status/${selectors.getOrg(state).id}`, status),
//   responseMapper: new ActionsResponseMapper(),
// });

// SELECT ORG
export const selectOrg = id => ({
  actions: [selectOrgRequest, selectOrgSuccess, selectOrgFailure],
  callAPI: () => API.patch(`/user/organization/select/${id}`, {}),
  successPayload: id,
  responseMapper: new ActionsResponseMapper(),
  beforeSuccess: (_, dispatch) => {
    dispatch(bootstrap());
  },
});
