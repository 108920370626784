import { useContext } from 'react';
import ruddContext from './rudd.context';

function useRudd() {
  const context = useContext(ruddContext);

  if (!context) {
    throw Error(
      `One does not simply useRudd. One must first be Provided the Rudd.`
    );
  }

  return context;
}

export default useRudd;
