// Caching was disabled. Ignoring the warnings to preserve the interface of the functions
/* eslint-disable no-unused-vars */
import MemoryCache from 'memory-cache';
import { process as kendoProcess } from '@progress/kendo-data-query';
import getFieldValues from '../util/get-field-values';
import setPath from '../fp/setPath';
import { isDefined } from '../util/helpers';
import { parseValue, isFilterable } from '../use-filtering/util';

const ttlSeconds = 60 * 1000;
const caches = {}; // dictionary of caches by view names: [key: string]: MemoryCache

/**
 * Flush cache by name
 * @param {*} cachePrefix string used to identify cache
 */
export function flushCitrusCache(cachePrefix) {
  const cache = caches[cachePrefix];
  if (cache) {
    console.log('flush citrus cache for', cachePrefix);
    cache.clear();
  }
}

/**
 * Get value with caching.
 * @param {*} cachePrefix string used to identify cache
 * @param {*} key used to identify object within the cache
 * @param {*} getDataFunction get data if it's not in the cache
 */
function getCached(cachePrefix, key, getDataFunction) {
  caches[cachePrefix] = caches[cachePrefix] || new MemoryCache.Cache();
  const cache = caches[cachePrefix];

  let data = cache.get(key);
  if (data) {
    return data;
  }
  data = getDataFunction();
  cache.put(key, data, ttlSeconds);
  return data;
}

function expandItemByPath(path) {
  return item => {
    const values = getFieldValues(item, path);

    if (Array.isArray(values)) {
      return values.map(value => setPath(path.split('.'), value, item));
    }
    return [item];
  };
}

/**
 * Message from Rob Stires :
 * What!?
 *
 * I can't seem to find a way to make this less complex. The issue is that we store values as an
 * array. For example vendor capabilities is an array of strings, that we want to provide checkboxes
 * for including a count of the services that have that capability. In order to compute the counts
 * correctly we filter the entire dataset using the currently selected filters except for filters
 * of the current category. However the filterBy / process function from @progress/kendo-data-query
 * does not support array searching.
 *
 * What I've done here is I've duplicated an item, once for each value in a list, which is then
 * included in the filtering and counting, giving us an accurate count of items containing those
 * filtered values
 *
 * For example, assuming we're trying to count how many shops provide the 'oil change' service and
 * knowing that 'oil change' can only appear once as a service for a given vendor.
 *
 *    { id: 1, color: 'red', services: ['tires', 'oil change'] }
 *    { id: 2, services: ['oil change', 'diagnostics', 'alignment'] }
 *    { id: 3, services: ['diagnostics', 'alignment'] }
 *
 *    is expanded to;
 *
 *    { id: 1, color: 'red', services: 'tires' }
 *    { id: 1, color: 'red', services: 'oil change' }
 *    { id: 2, services: 'oil change' }
 *    { id: 2, services: 'diagnostics' }
 *    { id: 2, services: 'alignment' }
 *    { id: 3, services: 'diagnostics' }
 *    { id: 3, services: 'alignment' }
 *
 * So this dataset can now be handed off to process for a basic equality check where services === 'oil change'
 * and we would get a count of two.
 *
 * This pattern cannot be used for other processes (searching, displaying, etc) because the unique
 * items are duplicated.
 */
export function createFieldOptions(
  field,
  filters,
  optionsMap,
  data,
  cachePrefix
) {
  const { id, type } = field;

  const extendedData = /string\[\]/.test(type)
    ? data.flatMap(expandItemByPath(id))
    : data;

  // const { data: groupedData } = getCached(
  //   cachePrefix,
  //   // JSON.stringify represents a hash of the "filters" object.
  //   // I (Alex) am using it because I expect the "filters" object to be relatively small.
  //   `${id}:${JSON.stringify(filters)}`,
  //   () =>
  //     kendoProcess(extendedData, {
  //       filter: { logic: 'and', filters },
  //       group: [{ field: id }],
  //     })
  // );
  const { data: groupedData } = kendoProcess(extendedData, {
    filter: { logic: 'and', filters },
    group: [{ field: id }],
  });

  // {
  //   Active: 0,
  //   Inactive: 0,
  // }
  for (let idx = 0; idx < groupedData.length; idx += 1) {
    const { value, items } = groupedData[idx];
    if (isDefined(value) && value !== '') {
      optionsMap[value] = items.length;
    }
  }

  // {
  //   Active: 9,
  //   Inactive: 22
  // }
  // [["Active", 9], ["Inactive", 22]]
  return Object.entries(optionsMap).map(([value, count]) => ({
    value: parseValue(value, type),
    count,
  }));
}

function createFieldOptionsMap(data = [], id) {
  const result = {};

  for (let idx = 0, len = data.length; idx < len; idx += 1) {
    const item = data[idx];
    const itemFieldValue = getFieldValues(item, id);
    if (isDefined(itemFieldValue) && itemFieldValue !== '') {
      if (Array.isArray(itemFieldValue)) {
        itemFieldValue.forEach(v => {
          result[v] = 0;
        });
      } else {
        result[itemFieldValue] = 0;
      }
    }
  }

  return result;
}

export function createFieldMap(fields = [], data = []) {
  const result = {};

  for (let idx = 0, len = fields.length; idx < len; idx += 1) {
    const field = fields[idx];
    if (isFilterable(field)) {
      result[field.id] = createFieldOptionsMap(data, field.id);
    }
  }

  return result;
}

// Sometimes, field configuration does not follow default behavior.
const displayFieldsOverride = field => {
  switch (field.field) {
    case 'latest_odometer':
    case 'odometer':
      return {
        id: 'odometerRange',
        type: 'string',
        filter: field.filter && {
          type: 'MANY',
          operator: 'eq',
        },
      };
    default:
      return null;
  }
};

/**
 * Dynamically build a citrus filter based on field name and type
 */
const getDisplayFieldFilter = field => {
  switch (field.type) {
    case 'string':
      return {
        type: 'MANY',
        operator: 'eq',
      };
    case 'number':
      return {
        type: 'SELECT_RANGE',
        operator: ['gte', 'lte'],
      };
    case 'date':
      return {
        type: 'SELECT_RANGE',
        operator: ['gte', 'lte'],
      };
    default:
      return null;
  }
};

/**
 * Update view with selected by the user display fields (stored in API in user_organization_role.config).
 * If there is a field in the view, it will take it.
 * Otherwise, it will apply default config based on field type
 * Some fields require special handling that can be defined in "displayFieldsOverride" function
 * @param {*} oldView original view from json file
 * @param {*} fields user-selected fields
 * @returns patched view
 */
export const patchViewWithDisplayFields = (oldView, fields) => {
  const { fields: oldFields } = oldView;
  const searchFields = new Set(oldFields.filter(f => f.search).map(f => f.id));
  const sortFields = new Set(oldFields.filter(f => f.order).map(f => f.id));

  const newFields = fields.map(({ field: id, type, filter }) => {
    const origField = oldFields.find(f => f.id === id);
    const newField = displayFieldsOverride({ field: id, type, filter });

    if (newField) return newField;

    const baseField = origField || {
      id,
      type,
      search: searchFields.has(id),
      order: sortFields.has(id),
    };
    return {
      ...baseField,
      filter:
        filter &&
        (origField?.filter ||
          getDisplayFieldFilter({ field: id, type, filter })),
    };
  });

  return {
    ...oldView,
    fields: newFields,
    filterDisplayOrder: newFields.filter(f => f.filter).map(f => f.id),
  };
};
