import React, { useState, useContext, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import noop from '../fp/noop';

const PaginationContext = createContext({
  skip: undefined,
  take: undefined,
  setState: noop,
});

export const PagingProvider = ({
  skip: propsSkip,
  take: propsTake,
  ...props
}) => {
  const [state, setState] = useState(() => ({
    skip: propsSkip,
    take: propsTake,
  }));
  const { skip, take } = state;
  const ctx = useMemo(() => ({ skip, take, setState }), [skip, take]);

  return <PaginationContext.Provider value={ctx} {...props} />;
};

PagingProvider.propTypes = {
  skip: PropTypes.number,
  take: PropTypes.number,
};

PagingProvider.defaultProps = {
  skip: undefined,
  take: undefined,
};

/**
 * @typedef {Object} UsePaging
 * @property {number} skip
 * @property {(?number)} take
 * @property {(((state) => void | PagingState))} setState
 */

/**
 * @returns {UsePaging}
 */
export function usePaging() {
  const ctx = useContext(PaginationContext);

  if (!ctx) {
    throw new Error(``);
  }

  return ctx;
}
