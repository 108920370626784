import api from '../../util/api';

export const getOrganizations = config => api.get('/organizations', config);

export const getOrganizationNodes = config =>
  api.get('/organization/node', config);

export const getOrgNonLeasedUnits = config =>
  api.get('/organizations/non-leased/limit', config);

export const updateOrganizationInformation = data =>
  api.patch('/organizations', { data });

export const getOrgAuxMetadata = () =>
  api.get('/organizations/asset-aux-metadata');

export const putOrgAuxMetadata = data =>
  api.put('/organizations/asset-aux-metadata', data);

export const getOrgNodeStats = () => api.get('/organization/node/stats');
