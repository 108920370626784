import React, { useEffect, useMemo, useState, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import useAsync from '../../hooks/use-async';
import { useFiltering } from '../../use-filtering';
import { usePaging } from '../../use-paging';
import { useSearching } from '../../use-searching';
import { useSorting } from '../../use-sorting';
import { useView } from '../../use-view';
import process, { kendoProcess } from '../process';
import { JuiceContext, JuiceFiltersContext } from './index';
import { computeFiltersProps as defaultComputeFiltersProps } from './process-filters';
import { flushCitrusCache } from '../fields-util';
import useDebouncedEffect from '../../hooks/use-debounced-effect';

/**
 * This is a specific provider for the JuiceContext.
 * It fetches all data at once and performs local processing.
 */

const LocalJuiceProvider = ({
  requestData,
  computeFiltersProps,
  postProcessing,
  ...props
}) => {
  const { filters: allFilters } = useFiltering();
  const { searchTerm } = useSearching();
  const { field: sortField, direction: sortDirection } = useSorting();
  const { skip, take } = usePaging();
  const { fields, type, filterDisplayOrder } = useView();

  // Separate normal filters from API filters.
  // API filters need special handling and require an API call to get filtered data
  const apiFilterIds = useMemo(
    () => fields.filter(f => f.filter?.api).map(f => f.id),
    [fields]
  );
  const filters = useMemo(
    () => allFilters.filter(f => !apiFilterIds.includes(f.id)),
    [apiFilterIds, allFilters]
  );

  /**
   * apiFilters depends on allFilters, and we don't want to update it whenever non-api filters changes.
   * Therefore, we are using a custom useState with useEffect instead of useMemo.
   */
  const [apiFilters, setApiFilters] = useState();
  useEffect(() => {
    const newApiFilters = allFilters.filter(f => apiFilterIds.includes(f.id));
    if (!isEqual(newApiFilters, apiFilters)) {
      setApiFilters(newApiFilters);
    }
  }, [apiFilterIds, allFilters, apiFilters]);

  // Async hook to fetch data. Further processing is done synchronously
  const dataState = useAsync({
    status: 'pending',
  });
  const dataRun = dataState.run;
  const allData = dataState.data?.payload;
  const extra = dataState.data?.extra;
  // Async hook to calculate filter options asynchronously.
  const filtersState = useAsync({
    status: 'pending',
  });
  const filtersRun = filtersState.run;

  // Internal state. It holds local processing results.
  const [{ data, allFilteredData }, setProcessResult] = useState({
    data: [],
    allFilteredData: [],
  });

  const juiceRequestData = useCallback(() => {
    // start calling only when apiFilters was calculated in useEffect
    if (apiFilters) {
      flushCitrusCache(type);
      dataRun(requestData(apiFilters));
    }
  }, [dataRun, requestData, apiFilters, type]);

  useEffect(() => juiceRequestData(apiFilters), [juiceRequestData, apiFilters]);

  // Process data whenever any criteria changes
  // The effect is debounced since there could be multiple changes coming at the same time, such as page reset after change filter
  useDebouncedEffect(
    () => {
      if (dataState.status !== 'resolved') {
        return;
      }
      const processInput = {
        fields,
        data: allData || [],
        filters,
        searchTerm,
      };
      // sorting is enabled if specified in the view.
      if (sortDirection && sortField) {
        processInput.sortField = sortField;
        processInput.sortDirection = sortDirection;
      }

      let { data: filteredData } = process(processInput);

      // This is for additional processing before pagination.
      // Use case is for Vendor Locator and segmentVendors
      if (postProcessing) {
        filteredData = postProcessing(filteredData);
      }

      const { data: filteredPaginatedData } = kendoProcess(filteredData, {
        skip,
        take,
      });

      setProcessResult({
        data: filteredPaginatedData,
        allFilteredData: filteredData,
      });
    },
    [
      take,
      skip,
      sortDirection,
      sortField,
      searchTerm,
      filters,
      fields,
      allData,
      dataState.status,
      postProcessing,
    ],
    100
  );

  // Process filters whenever any criteria changes
  // The effect is debounced since there could be multiple changes coming at the same time, such as page reset after change filter
  useDebouncedEffect(
    () => {
      if (dataState.status !== 'resolved') {
        return;
      }
      filtersRun(
        computeFiltersProps({
          fields,
          data: allData || [],
          searchTerm,
          filters,
          type,
          filterDisplayOrder,
        })
      );
    },
    [
      fields,
      allData,
      searchTerm,
      filters,
      type,
      filterDisplayOrder,
      dataState.status,
      computeFiltersProps,
      filtersRun,
    ],
    100
  );

  const dataCtx = useMemo(
    () => ({
      ...dataState,
      data,
      count: data.length,
      allFilteredData,
      totalCount: allFilteredData.length,
      extra,
      requestData: juiceRequestData,
      sortDirection,
      sortField,
    }),
    [dataState, data, allFilteredData, extra, juiceRequestData]
  );

  const filtersCtx = useMemo(
    () => ({
      ...filtersState,
      // to avoid loading indicator. Processing is too fast
      status:
        filtersState.status === 'pending' ? 'resolved' : filtersState.status,
    }),
    [filtersState]
  );

  return (
    <JuiceContext.Provider value={dataCtx}>
      <JuiceFiltersContext.Provider value={filtersCtx} {...props} />
    </JuiceContext.Provider>
  );
};

LocalJuiceProvider.propTypes = {
  requestData: PropTypes.func.isRequired,
  computeFiltersProps: PropTypes.func,
  postProcessing: PropTypes.func,
};

LocalJuiceProvider.defaultProps = {
  computeFiltersProps: defaultComputeFiltersProps,
  postProcessing: null,
};

export default LocalJuiceProvider;
