import { createSelector } from 'reselect';

import { DocumentsActionTypes } from '../actions/action-types';

export const initialState = {
  isFetching: false,
  byName: {},
  messages: [],
  failed: false,
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DocumentsActionTypes.FETCH_DOCUMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        messages: [],
        failed: false,
        byName: {
          ...state.byName,
          [action.name]: undefined,
        },
      };

    case DocumentsActionTypes.FETCH_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        byName: {
          ...state.byName,
          [action.document.name]: action.document,
        },
        messages: action.messages || [],
      };

    case DocumentsActionTypes.FETCH_DOCUMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        failed: true,
        messages: action.errors,
      };

    default:
      return state;
  }
};

export default documentsReducer;

//-----------------------------------
// Selectors

export const getIsFetchingDocuments = state => state.isFetching;

export const getDocumentByName = (state, name) => state.byName[name];

export const getDocumentsMessages = createSelector(
  [state => state.failed, state => state.messages],
  (failed, messages) => ({ failed, messages })
);
