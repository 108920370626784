import React from 'react';
import { formatDate, formatTime } from '@holmanfm/lib/util/moment';
import { useJuice } from '@holmanfm/lib/citrus-framework/juice';
import { useNSTranslation } from '@holmanfm/lib/lang';
import HSBSTable from '~/areas/fleet-assets/hsbs-table/hsbs-table';
import HSBSTableHeader from '~/areas/fleet-assets/hsbs-table/hsbs-table-header';
import HSBSTableRow from '~/areas/fleet-assets/hsbs-table/hsbs-table-row';
import HSBSTableCell from '~/areas/fleet-assets/hsbs-table/hsbs-table-cell';
import GenericNoDisplay from '~/areas/individual-assets/generic-nothing-to-display';

const RecentFuelTransactions = () => {
  const { data: fuelTransactions } = useJuice();
  const { t } = useNSTranslation('individualAsset', 'fuelManagement');

  if (fuelTransactions?.length === 0) {
    return (
      <div style={{ marginTop: 16 }}>
        <GenericNoDisplay category="fuel transactions" />
      </div>
    );
  }

  return (
    <HSBSTable
      headerRows={
        <>
          <HSBSTableHeader>{t('date')}</HSBSTableHeader>
          <HSBSTableHeader>{t('time')}</HSBSTableHeader>
          <HSBSTableHeader>{t('odometer')}</HSBSTableHeader>
          <HSBSTableHeader>{t('product-name')}</HSBSTableHeader>
          {/* <HSBSTableHeader>{t('mpg')}</HSBSTableHeader> */}
          <HSBSTableHeader>{t('unit-cost')}</HSBSTableHeader>
          <HSBSTableHeader>{t('unit')}</HSBSTableHeader>
          <HSBSTableHeader>{t('amount')}</HSBSTableHeader>
          <HSBSTableHeader>{t('brand')}</HSBSTableHeader>
          <HSBSTableHeader>{t('site-name')}</HSBSTableHeader>
          <HSBSTableHeader>{t('city')}</HSBSTableHeader>
          <HSBSTableHeader>{t('state')}</HSBSTableHeader>
        </>
      }
      bodyRows={fuelTransactions?.map(transaction => (
        <HSBSTableRow key={transaction?.id}>
          <HSBSTableCell>
            {transaction?.date ? formatDate(transaction?.date) : ''}
          </HSBSTableCell>
          <HSBSTableCell>
            {transaction?.date ? formatTime(transaction?.date) : ''}
          </HSBSTableCell>
          <HSBSTableCell>
            {/* @todo a transaction shouldn't have a null or undefined odometer */}
            {transaction?.odometer
              ? Number(transaction?.odometer).toLocaleString()
              : t('na')}
          </HSBSTableCell>
          <HSBSTableCell>{transaction?.product}</HSBSTableCell>
          {/* <HSBSTableCell>{transaction?.gallons}</HSBSTableCell> */}
          <HSBSTableCell>{transaction?.unitCost}</HSBSTableCell>
          <HSBSTableCell>{transaction?.units}</HSBSTableCell>
          <HSBSTableCell>{transaction?.total}</HSBSTableCell>
          <HSBSTableCell>{transaction?.brand}</HSBSTableCell>
          <HSBSTableCell>{transaction?.locationName}</HSBSTableCell>
          <HSBSTableCell>{transaction?.locationCity}</HSBSTableCell>
          <HSBSTableCell>{transaction?.locationState}</HSBSTableCell>
        </HSBSTableRow>
      ))}
    />
  );
};

export default RecentFuelTransactions;
