import CitrusFramework, {
  setSearchParameters,
  generateInitialState,
} from '@holmanfm/lib/citrus-framework';

import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import URI from 'urijs';

export function createHandleStateChange(
  view,
  push,
  pathname,
  currentState,
  prevSearch
) {
  return state => {
    const uri = new URI('');
    const setSearchHandler = (key, value) => uri.setSearch(key, value);
    setSearchParameters(state, setSearchHandler, view);
    if (!uri.equals(new URI(prevSearch))) {
      // push only if something changed in the url - to maintain consistency with "back" button
      push({ pathname, search: uri.search(), state: currentState });
    }
  };
}

export function createInitialState(queryString, view) {
  return () => {
    const uri = new URI(queryString);
    const queryObject = uri.search(true);

    return generateInitialState(queryObject, view);
  };
}

const LemonFramework = props => {
  const { children, view, scrollName } = props;
  const { push } = useHistory();
  const { pathname, search, state } = useLocation();

  const handleStateChange = useCallback(
    createHandleStateChange(view, push, pathname, state, search),
    [view, push, pathname]
  );

  const searchRef = useRef(search);
  const initialState = useMemo(
    createInitialState(state?.search || searchRef.current, view),
    [view]
  );

  const selectedScrollItem = useMemo(
    () => localStorage.getItem('selectedItem'),
    []
  );

  const setLocalStorage = (itemName, data) =>
    localStorage.setItem(itemName, data);

  return (
    <CitrusFramework
      view={view}
      initialState={initialState}
      onStateChange={handleStateChange}
      scrollName={scrollName}
      selectedScrollItem={selectedScrollItem}
      localScrollTime={localStorage.getItem('scrollTime')}
      setLocalStorage={setLocalStorage}
    >
      {children}
    </CitrusFramework>
  );
};

LemonFramework.propTypes = {
  children: PropTypes.node.isRequired,
  view: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    defaultSortDirection: PropTypes.string,
    defaultSortField: PropTypes.string,
    take: PropTypes.number,
  }).isRequired,
  scrollName: PropTypes.string,
};

LemonFramework.defaultProps = {
  scrollName: '',
};

export default LemonFramework;
