/* eslint-disable no-restricted-imports */

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  initReactI18next,
  Trans,
  useTranslation,
  withTranslation,
} from 'react-i18next';
import { isDevelopment } from '../common/environment';
import resources, { FILE_NAMES } from './resources';
import useNSTranslation from '../hooks/useNSTranslation';

let languageProps = {};

if (typeof window !== 'undefined' && window.location) {
  i18n.use(LanguageDetector); // Only uses react browser detector if inside of the react application
} else {
  languageProps = { lng: 'en' };
}

i18n.use(initReactI18next).init({
  ns: FILE_NAMES,
  defaultNS: 'main',
  resources,
  initImmediate: false,
  debug: isDevelopment,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  fallbackLng: 'en-US',
  react: {
    wait: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'u'],
  },
  ...languageProps,
});

export default i18n;

export { withTranslation, useTranslation, useNSTranslation, Trans };
