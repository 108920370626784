import {
  aceRefreshAction,
  aceRefreshActionLock,
  aceRefreshActionUnlock,
} from '../actions/ace.actions';

export const initialState = {
  actorUserEmail: undefined,
  actorUserId: undefined,
  actingAsUserEmail: undefined,
  actingAsUserId: undefined,
  actingAsUserRoleId: undefined,
  actingAsUserRoleName: undefined,
  actingAsUserOrgId: undefined,
  actingAsUserOrgName: undefined,
  isACESessionActive: undefined, // session may be active when this is undefined; don't do truthy-checks on this prop.
  isACERefreshing: undefined,
};

const getAce = state => state.ace;

const aceReducer = (state = initialState, action) => {
  // begin/end are handled in the root reducer because they change the entire redux state.

  if (action.type === aceRefreshActionLock.type) {
    return {
      actorEmail: state.actorEmail,
      actorUserId: state.actorUserId,
      actingAsUserEmail: state.actingAsUserEmail,
      actingAsUserId: state.actingAsUserId,
      actingAsUserRoleId: state.actingAsUserRoleId,
      actingAsUserRoleName: state.actingAsUserRoleName,
      actingAsUserOrgId: state.actingAsUserOrgId,
      actingAsUserOrgName: state.actingAsUserOrgName,
      isACESessionActive: state.isACESessionActive,
      isACERefreshing: true,
    };
  }

  if (action.type === aceRefreshActionUnlock.type) {
    return {
      actorEmail: state.actorEmail,
      actorUserId: state.actorUserId,
      actingAsUserEmail: state.actingAsUserEmail,
      actingAsUserId: state.actingAsUserId,
      actingAsUserRoleId: state.actingAsUserRoleId,
      actingAsUserRoleName: state.actingAsUserRoleName,
      actingAsUserOrgId: state.actingAsUserOrgId,
      actingAsUserOrgName: state.actingAsUserOrgName,
      isACESessionActive: state.isACESessionActive,
      isACERefreshing: false,
    };
  }

  if (action.type === aceRefreshAction.type) {
    const {
      actorEmail,
      actorUserId,
      actingAsUserEmail,
      actingAsUserId,
      actingAsUserOrgId,
      actingAsUserOrgName,
      actingAsUserRoleId,
      actingAsUserRoleName,
      isACESessionActive,
    } = action.payload.payload;
    // this is the 'ace' slice of global state
    return {
      actorEmail,
      actorUserId,
      actingAsUserEmail,
      actingAsUserId,
      actingAsUserRoleId,
      actingAsUserRoleName,
      actingAsUserOrgId,
      actingAsUserOrgName,
      isACESessionActive,
      isACERefreshing: state.isACERefreshing,
    };
  }

  return state;
};

export default aceReducer;
export const getAceActorUserEmail = state => getAce(state).actorEmail;
export const getAceActorUserId = state => getAce(state).actorUserId;
export const getAceActingAsUserEmail = state => getAce(state).actingAsUserEmail;
export const getAceActingAsUserId = state => getAce(state).actingAsUserId;
export const getAceActingAsUserOrgId = state => getAce(state).actingAsUserOrgId;
export const getAceActingAsUserOrgName = state =>
  getAce(state).actingAsUserOrgName;
export const getAceActingAsUserRoleId = state =>
  getAce(state).actingAsUserRoleId;
export const getAceActingAsUserRoleName = state =>
  getAce(state).actingAsUserRoleName;
export const getIsAceActive = state => getAce(state).isACESessionActive;

/* This selector is designed specifically for use in the Axios interceptor,
 * to determine whether or not the Ace slice of Redux state is stale compared with the
 * ACE headers sent by the API. Do not use it anywhere else in the application.
 */
export const shouldAceRefreshAxiosInterceptor = state => {
  const ace = getAce(state);
  if (!ace) {
    return true;
  }
  return !ace.isACESessionActive && !ace.isACERefreshing;
};
