import PropTypes from 'prop-types';
import React from 'react';
import set from 'lodash/set';
import MUICheckbox from '@material-ui/core/Checkbox';
import { connect, getIn } from 'formik';
import styled, { StyleProps } from './ checkbox.styles';

const Checkbox = ({ formik, classes, ...props }) => {
  const { name, onChange, inputProps = { style: {} } } = props;

  const { values, handleChange } = formik;

  const value = getIn(values, name);

  /** @todo Conflict with materializecss hack */
  const updatedInputProps = set(inputProps, 'style.pointerEvents', 'auto');

  return (
    <MUICheckbox
      {...props}
      inputProps={updatedInputProps}
      checked={value}
      onChange={onChange || handleChange}
      value={value}
      name={name}
    />
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  classes: StyleProps.isRequired,
  onChange: PropTypes.func,
  inputProps: PropTypes.shape({}) /** @todo */,
  formik: PropTypes.shape({
    values: PropTypes.shape({}).isRequired,
    handleChange: PropTypes.func.isRequired,
  }).isRequired,
};

Checkbox.defaultProps = {
  inputProps: undefined,
  onChange: undefined,
};

export default styled(connect(Checkbox));
