import axios from 'axios';
import i18next from '../lang';

export default function langInterceptor() {
  axios.interceptors.request.use(
    config => {
      config.headers['Accept-Language'] = i18next.language;
      return config;
    },
    error => Promise.reject(error)
  );
}
