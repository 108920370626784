import { createAction } from '@reduxjs/toolkit';
import {
  getAceSessionStatus,
  startAceSession,
  stopAceSession,
} from '../../util/ace.service';
import { bootstrap } from './auth.actions';
import {
  selectOrgFailure,
  selectOrgRequest,
  selectOrgSuccess,
} from './orgs/org.actions';
import { ActionsResponseMapper } from './util';

export const aceRefreshAction = createAction('ace/refresh');

// Internal only to refreshing; do not dispatch manually from the application.
// Prevents multiple refreshes from triggering via axios interceptor.
export const aceRefreshActionLock = createAction('ace/refresh/lock');
export const aceRefreshActionUnlock = createAction('ace/refresh/unlock');

/**
 * Begins an ACE session. If an ACE session is already active, it will fail;
 *  this is ok to ignore since starting ACE sessions is idempotent.
 * @param {uuid} userId
 * @param {uuid} orgId
 * @example
 * import { useDispatch } from 'at_holmanfm/lib/redux';
 * const reduxDispatch = useDispatch();
 * await reduxDispatch(
 *   beginAceSession({
 *     userId: 'a1b2c3d4-e5f6-a1b2-c3d4-e5f6a7b8c9d0',
 *     orgId:  'e5f6a7b8-a4b7-c3d4-e2f5-a1b2c3d4e5f6',
 *   })
 * );
 */

/* Awaiting start of the ace session guarantees that the server delivers the ACE token as a cookie
 * to the client. API calls made after that will then act as the "target" instead of the actual caller.
 * This is why the start ace call must be made and completed prior to allowing the promise.all of the other calls.
 * This action closely resembles org.actions => selectOrg. Just different API endpoint
 */
export const beginAceSessionThunk = (userId, orgId) => ({
  actions: [selectOrgRequest, selectOrgSuccess, selectOrgFailure],
  callAPI: () => startAceSession(userId, orgId),
  successPayload: orgId,
  responseMapper: new ActionsResponseMapper(),
  beforeSuccess: (_, dispatch) => {
    dispatch(bootstrap());
  },
});

/**
 * Refreshes client-side data for the ACE session from the server. Updates Redux.
 * Note that doing this sets a flag in the ace redux state that indicates updating;
 * client should check and respect the result of `shouldAceRefresh` before calling this.
 * TODO: it would be much safer to put the "should refresh" guard in here...
 *   only reason it hasnt been is to prevent circular inclusion between this file and reducer.
 * @example
 * import { useDispatch } from 'at_holmanfm/lib/redux';
 * const reduxDispatch = useDispatch();
 * await reduxDispatch(refreshAceSession());
 */
export const refreshAceSessionThunk = () => async dispatch => {
  await dispatch(aceRefreshActionLock());
  try {
    const { payload } = await getAceSessionStatus();
    await dispatch(aceRefreshAction({ payload }));
  } catch (errors) {
    throw errors;
  } finally {
    await dispatch(aceRefreshActionUnlock());
  }
};

// awaiting this call is more important than just getting the payload. It guarantees future API calls do not carry
// the ACE token (the stopAce api sends a signal back to client to delete the http-only cookie).
// This action closely resembles org.actions => selectOrg. Just different API endpoint
export const endAceSessionThunk = () => ({
  actions: [selectOrgRequest, selectOrgSuccess, selectOrgFailure],
  callAPI: () => stopAceSession(),
  responseMapper: new ActionsResponseMapper({
    options: {
      apply: payload => payload.resumeAsOrgId,
    },
  }),
  beforeSuccess: (_, dispatch) => {
    dispatch(bootstrap());
  },
});
