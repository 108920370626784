import queryString from 'query-string';
import React from 'react';

// eslint-disable-next-line import/no-unresolved
import moment from '../util/moment';
import { safeGet } from '../util/helpers';

/**
 * format number with commas
 */
export const formatNumberWithCommas = (number, decimalPoints = 2) => {
  return (+number).toLocaleString(undefined, {
    minimumFractionDigits: decimalPoints,
    maximumFractionDigits: decimalPoints,
  });
};

/**
 * value can be a number, or a string that can be converted to a number,
 * function will add commas, and decimals if requested, and return string
 * if value is null or undefined, will return empty string
 * if value cannot be converted to a number, the original value is returned.
 * @param value 1234, 1234.123, "1234", "1234.123"
 * @param decimalPoints
 * @returns {string}
 */
export const formatLocaleString = (value, decimalPoints = 0) => {
  if (value) {
    const num = +value;
    if (!Number.isNaN(num)) {
      return num.toLocaleString('en-US', {
        minimumFractionDigits: decimalPoints,
        maximumFractionDigits: decimalPoints,
      });
    }
    return value;
  }
  return '';
};

/**
 * format price
 */
export const formatPrice = (price, decimalPoints = 2) => {
  if (!price && price !== 0) {
    return '';
  }
  return `${price < 0 ? '-' : ''}$${formatNumberWithCommas(
    Math.abs(+price),
    decimalPoints
  )}`;
};
export const formatPriceNoCents = price => {
  return formatPrice(Math.trunc(+price), 0);
};

/**
 * Format string to add commas for currency display, "100000" => "100,000"
 * Does not allow cents or dollar sign
 */
export const formatCurrency = value => {
  if (value) {
    const val = typeof value === 'string' ? value : value.toString();
    return val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
};

/**
 * Format number to currency, e.g., 123.4525234 => "$123.45"
 * This function is similar to formatCurrency, but a bit more predictable and returns actual currency string.
 * For example, formatCurrency(123.4525234) => "1,234,525,234", which is not correct.
 * @param {*} value
 * @returns
 */
export const formatMoney = value => {
  if (!value || (typeof value !== 'number' && typeof value !== 'string')) {
    return value;
  }
  return (+value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

/**
 * Format currency string to number type.
 * @param {string} currencyStr
 * @returns {number}
 */
export const currencyToNumber = currencyStr => {
  const numericStr = currencyStr.replace(/[^0-9.-]+/g, '');
  return parseFloat(numericStr);
};

/**
 * Remove non-numeric characters from a string, "$100,000" => "100000"
 * note: if value has cents it will convert as "$1,000.22" => "100022"
 */
export const removeNonNumeric = value => {
  if (value) {
    const val = typeof value === 'string' ? value : value.toString();
    return val.replace(/\D/g, '');
  }
  return value;
};

/**
 * Restricts characters to numbers and decimals only
 */
export const allowNumberAndDecimalOnly = val => {
  if (val) {
    return val.replace(/[^0-9.]/g, '');
  }
};

/**
 * Convert to string
 * Remove non-numeric characters
 * Format string: "12-3456789"
 */
export const formatFEIN = value => {
  if (value) {
    const string = typeof value === 'string' ? value : value.toString();
    const val = string.replace(/[^\d]/g, '');
    if (val.length < 2) return val;
    if (val.length >= 3 && !val.includes('-')) {
      return `${val.slice(0, 2)}-${val.slice(2, 9)}`;
    }
    return val;
  }
  return value;
};

/**
 * Format string to US/Canada phone format
 * Remove non-numeric characters
 * Find matched segments: 1 222 333 4444 or 222 333 4444; if not matching then return value as is
 * Format: "+1 (222) 333-4444" or "(222) 333-4444"
 */
export const formatPhoneNumber = value => {
  if (value) {
    const phoneNumberString =
      typeof value === 'string' ? value : value.toString();
    const cleaned = phoneNumberString.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? `+${match[1]} ` : '';
      return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
    }
  }
  return value;
};

/**
 * returns true if value is found in array of values, otherwise returns false
 */
export const inArray = (values, value) =>
  Array.isArray(values) && values.includes(value);

/**
 * get array of acceptable values from object with path,
 * and return result if value is found in array of values, otherwise returns undefined
 * @param {*} object i.e. a = {b: {c: 'valabc'}}
 * @param {*} path, array of prop names i.e. ['b', 'c']
 * @param {*} value that needs to be equal to a.b.c
 * @param {*} result returned if value === a.b.c, othewise returns undefined
 */
export const condVal = (obj, path, value, result) =>
  inArray(safeGet(obj, path), value) ? result : undefined;

export function mapColorToValue(colorName) {
  let colorValue;
  if (colorName) {
    if (colorName.includes('white')) {
      colorValue = '#fff';
    } else if (colorName.includes('red')) {
      colorValue = '#cc0000';
    } else if (colorName.includes('grey')) {
      colorValue = '#ccc';
    } else if (colorName.includes('gray')) {
      colorValue = '#ccc';
    } else if (colorName.includes('steel')) {
      colorValue = '#ccc';
    } else if (colorName.includes('silver')) {
      colorValue = '#ccc';
    } else if (colorName.includes('black')) {
      colorValue = '#000';
    } else if (colorName.includes('ebony')) {
      colorValue = '#000';
    } else if (colorName.includes('onyx')) {
      colorValue = '#000';
    } else if (colorName.includes('blue')) {
      colorValue = '#003399';
    } else if (colorName.includes('oyster')) {
      colorValue = '#928b8c';
    } else if (colorName.includes('green')) {
      colorValue = '#006622';
    } else if (colorName.includes('brown')) {
      colorValue = '#663300';
    } else if (colorName.includes('beige')) {
      colorValue = '#fcf4e8';
    } else if (colorName.includes('tan')) {
      colorValue = '#e8a94a';
    } else if (colorName.includes('camel')) {
      colorValue = '#e8a94a';
    } else {
      colorValue = 'transparent';
    }
  } else {
    colorValue = 'transparent';
  }
  return colorValue;
}

/**
 * Find last matching element of array, by calling match() function with elements of array from last to first
 * @param {*} array, the array to search
 * @param {*} match, the function that identifies the element to match, e.g.: x => creditStates.includes(x.state)
 * if no match is found, return null
 */
export const findLast = (array, match) => {
  for (let l = array.length - 1; l >= 0; l -= 1) {
    if (match(array[l])) {
      return array[l];
    }
  }
  return null;
};

export const sleepTimer = ms => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise(resolve => setTimeout(resolve, ms));
};

export default class MarketplaceHelpers {
  static parseQuery(search, defaultQuery = {}) {
    return {
      ...defaultQuery,
      ...queryString.parse(search),
    };
  }

  static buildViewLink(viewLinkObj, dataItem, search = null) {
    let query = viewLinkObj.view ? `view=${viewLinkObj.view}` : '';
    if (viewLinkObj.queryProps) {
      viewLinkObj.queryProps.forEach(queryProp => {
        const propNameSplit = queryProp.split('.');
        query += `${query ? '&' : ''}${queryProp}=${safeGet(
          dataItem,
          propNameSplit
        )}`;
      });
    }
    if (search && viewLinkObj.passQueryProps) {
      const searchProps = queryString.parse(search);
      viewLinkObj.passQueryProps.forEach(queryProp => {
        query += `${query ? '&' : ''}${queryProp}=${searchProps[queryProp]}`;
      });
    }
    if (viewLinkObj.nextUrl) {
      return `${viewLinkObj.nextUrl}${
        query ? encodeURIComponent(`?${query}`) : ''
      }`;
    }
    return `?${query}`;
  }

  static checkActionCondition(action, data) {
    if (!action || !action.if) {
      return true;
    }
    if (!data) {
      return false;
    }
    return this.checkConditions(action.if, data);
  }

  static checkConditions(cond, data) {
    if (cond === undefined || cond === null) {
      return false;
    }
    if (Array.isArray(cond) && cond.length > 1) {
      if (cond[0] === 'and') {
        for (let i = 1; i < cond.length; i += 1) {
          if (!this.checkConditions(cond[i], data)) {
            return false;
          }
        }
        return true;
      }
      if (cond[0] === 'or') {
        for (let i = 1; i < cond.length; i += 1) {
          if (this.checkConditions(cond[i], data)) {
            return true;
          }
        }
        return false;
      }
    }
    const val = this.getRawItemProp(data, cond.propName);
    switch (cond.rel) {
      case 'eq':
        return val === cond.value;
      case 'ne':
        return val !== cond.value;
      case 'in':
        return cond.value.includes(val);
      case 'is null':
        return !val;
      case 'is not null':
        return !!val;
      default:
        return false;
    }
  }

  /**
   * extract price from marketplace item
   */
  static getPrice(item, pricingType) {
    if (item.purchase_price) {
      return formatPrice(item.purchase_price);
    }
    const priceArray =
      item.subviews_items && item.subviews_items.pricing && pricingType
        ? item.subviews_items.pricing.filter(
            pricing =>
              pricing.pricingType &&
              pricing.pricingType.indexOf(pricingType.toLowerCase()) >= 0
          )
        : [];
    const price = priceArray.length === 0 ? null : priceArray[0].price;
    return price;
  }

  static splitPropName(propName) {
    if (!propName || typeof propName !== 'string') {
      return { propName: null, propNameArr: [] };
    }
    const propNameArr = propName.split('.');
    return {
      propNameArr,
      propName: propNameArr[propNameArr.length - 1],
    };
  }

  static getPropLabel(propTypes, propName) {
    ({ propName } = this.splitPropName(propName));
    return propTypes[propName]
      ? propTypes[propName].label
      : propName.replace('_', ' ');
  }

  static filterMap(array, filterFunc, mapFunc) {
    const result = [];
    if (array) {
      for (let i = 0; i < array.length; i += 1) {
        if (filterFunc(array[i])) {
          result.push(mapFunc(array[i]));
        }
      }
    }
    return result;
  }

  /**
   * get raw item prop. propName may have hierarchy, like 'data.asset'.
   * @param {object} item - data object
   * @param {string} propName - item prop name (from metadata)
   */

  static getRawItemProp(item, propName) {
    const { propNameArr } = this.splitPropName(propName);
    const index = propNameArr.findIndex(propNamePart =>
      propNamePart.endsWith('[]')
    );
    if (index >= 0) {
      // for propName like 'testArray[].test' - map to an array of elements.
      const before = propNameArr.slice(0, index + 1);
      before[before.length - 1] = before[before.length - 1].replace('[]', '');
      const after = propNameArr.slice(index + 1);
      const arr = safeGet(item, before);
      return arr && arr.map(arrItem => safeGet(arrItem, after));
    }
    return safeGet(item, propNameArr);
  }

  // get formatted item prop. propName may have hierarchy, like 'data.asset'.
  static getItemVal(item, propName, propType) {
    const format = propType && propType.format;
    const val = this.getRawItemProp(item, propName);
    if (!format) return val;

    if (format.startsWith('date')) {
      return moment(val).format(format.split('date.')[1]);
    }

    return val;
  }

  static getActionData(action, item, search = null) {
    const searchProps = search && queryString.parse(search);
    const actionData = {};
    action.bodyProps.forEach(prop => {
      if (prop.source === 'query' && !prop.value && searchProps) {
        actionData[prop.bodyName || prop.name] = searchProps[prop.name];
      } else {
        actionData[prop.bodyName || prop.name] =
          prop.value || this.getRawItemProp(item, prop.name);
      }
    });
    return actionData;
  }

  // Get an array of values and display them in a list. Also supports ** being turned into italic
  static renderListLines(lines) {
    return lines.map(line => {
      const isItalic = line.startsWith('**') && line.endsWith('**');
      const tLine = isItalic ? line.substring(2, line.length - 2) : line;
      const props = {
        key: line,
        style: {
          marginLeft: '1rem',
          fontStyle: isItalic ? 'italic' : 'normal',
        },
      };

      return React.createElement('li', props, [tLine]);
    });
  }
}

export const capitalize = s => {
  return s && s.charAt(0).toUpperCase() + s.slice(1);
};

export const isValidISODateString = dateString => {
  const regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(.\d{1,3})?(Z|([+-]\d{2}:\d{2})))?$/;
  return (
    regex.test(dateString) &&
    moment(dateString, moment.ISO_8601, true).isValid()
  );
};
