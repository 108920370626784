import { operators } from '@holmanfm/lib/use-filtering';
import PropTypes from 'prop-types';

export const OptionShape = {
  count: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
};

export const Operator = PropTypes.oneOf(operators);

export const RangeOperator = PropTypes.arrayOf(Operator);

export const FilterShape = {
  type: PropTypes.string.isRequired,
  operator: PropTypes.oneOfType([RangeOperator, Operator]),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
};
