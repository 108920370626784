export const statuses = {
  idle: 'idle',

  fulfilled: 'fulfilled',

  /** Fetching describes the first request to retrieve a Thing.. */
  fetching: 'fetching',
  errorFetching: 'error-fetching',

  /** Refreshing describes subsequent requests to retrieve a Thing. */
  refreshing: 'refreshing',
  errorRefreshing: 'error-refreshing',

  /** Updating describes the intent to change and retrieve the updated Thing. */
  updating: 'updating',
  updated: 'updated',
  errorUpdating: 'error-updating',
};

export const initialState = {
  /**
   * The behavior right now is to invoke the handleRead handler immediately. Rather that
   * putting us into 'idle' then 'fetching' and rerendering, we're setting the initial status
   * to fetching. If we add an option to opt-out of the immediate request cycle, we'd have to update
   * this initial status as well.
   */
  status: statuses.fetching,
  error: undefined,
  data: undefined,
};
