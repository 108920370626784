/* eslint-disable import/prefer-default-export */
import { moneyToFloat } from '../../common/marketplace/money';

// calculates the minimum down payment required
// rules are based on creditAssetType selected (creditAssetTypeId)
// minDownPaymentPercent is calculated based on cap-cost values entered: (financeAmount + upfit + additionalFI)
// use the max of either the minDownPaymentAmount or minDownPaymentPercent. If none, no minimum is enforced

const convertToNum = val => (typeof val === 'number' ? val : moneyToFloat(val));

export const calculateCapCost = values =>
  (convertToNum(values?.financeAmount) || 0) +
  (convertToNum(values?.upfit) || 0) +
  (convertToNum(values?.additionalFI) || 0);

export const getCreditRulesForAssetType = (values, creditAssetTypes) =>
  creditAssetTypes?.find(type => type.id === values.creditAssetTypeId);

export const creditAssetMinDownPmt = (values, creditAssetTypes) => {
  const capCost = calculateCapCost(values);
  if (values.creditAssetTypeId) {
    const assetTypeCreditRules = getCreditRulesForAssetType(
      values,
      creditAssetTypes
    );
    return Math.max(
      // eslint-disable-next-line no-unsafe-optional-chaining  -- the || operator will handle it
      assetTypeCreditRules?.minDownPaymentAmount || null,
      // eslint-disable-next-line no-unsafe-optional-chaining
      (capCost * assetTypeCreditRules?.minDownPaymentPercent || null) / 100
    );
  }
  return 0;
};

export const exceedsMaxApprovalAmount = (values, creditAssetTypes) => {
  const capCost = calculateCapCost(values);
  const assetTypeCreditRules = getCreditRulesForAssetType(
    values,
    creditAssetTypes
  );
  return assetTypeCreditRules?.maxIndAssetCost
    ? capCost > assetTypeCreditRules?.maxIndAssetCost
    : false;
};
