/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';

import lfHoc from '@holmanfm/lib/lf-hoc/lf-hoc';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import * as appPropTypes from '@holmanfm/lib/common/app-prop-types';
import ServerMessages from '~/shared/components/messages/server-messages';

export const NotFound = ({ t, isFetchingOrg, messages }) => (
  <div className="container center-align">
    {messages && messages.failed ? (
      <ServerMessages messages={messages.messages} />
    ) : (
      <div>
        {!isFetchingOrg ? (
          <h2>{t('main:not-found.title')}</h2>
        ) : (
          <div className="preloader-wrapper big active not-found-loader">
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle" />
              </div>
              <div className="gap-patch">
                <div className="circle" />
              </div>
              <div className="circle-clipper right">
                <div className="circle" />
              </div>
            </div>
          </div>
        )}
      </div>
    )}
  </div>
);

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
  isFetchingOrg: PropTypes.bool,
  messages: appPropTypes.ServerMessages,
};

NotFound.defaultProps = {
  isFetchingOrg: false,
  messages: null,
};

const mapStateToProps = state => ({
  org: selectors.getOrg(state),
  isFetchingOrg: selectors.getIsFetchingOrg(state),
  messages: selectors.getOrgMessages(state),
});

const lfContainer = lfHoc(mapStateToProps);

export default lfContainer(NotFound);
