/* eslint-disable no-shadow */
import AuthService from '../../util/auth.service';
import { AuthActionTypes } from './action-types';
import {
  ActionsResponseMapperEmpty,
  ActionsResponseMapperSimple,
  makeActionCreator,
} from './util';
import { ErrorResponseHandler } from '../../util/api';
import { fetchOrgsSuccess } from './orgs/orgs.actions';
import { fetchOrgNodesSuccess } from './org-nodes/org-nodes.actions';
import SentryProvider from '../../util/sentry-provider';

// %%%%%%%%%%%%%%%%%%%%%%%%%% ACTION CREATORS %%%%%%%%%%%%%%%%%%%%%.

// BOOTSTRAP
export const bootstrapRequest = makeActionCreator(
  AuthActionTypes.BOOTSTRAP_REQUEST
);
export const bootstrapSuccess = makeActionCreator(
  AuthActionTypes.BOOTSTRAP_SUCCESS,
  'messages'
);
export const bootstrapFailure = makeActionCreator(
  AuthActionTypes.BOOTSTRAP_FAILURE,
  'errors'
);

// CHECK ACCOUNT
export const checkAccountRequest = makeActionCreator(
  AuthActionTypes.CHECK_ACCOUNT_REQUEST
);
export const checkAccountSuccess = makeActionCreator(
  AuthActionTypes.CHECK_ACCOUNT_SUCCESS,
  'accountStatus',
  'messages'
);
export const checkAccountFailure = makeActionCreator(
  AuthActionTypes.CHECK_ACCOUNT_FAILURE,
  'errors'
);

// LOAD USER DATA
export const loadUserData = makeActionCreator(
  AuthActionTypes.LOAD_USER_DATA,
  'user',
  'date'
);

// LOGIN
export const logInRequest = makeActionCreator(AuthActionTypes.LOGIN_REQUEST);
export const logInSuccess = makeActionCreator(
  AuthActionTypes.LOGIN_SUCCESS,
  'messages'
);
export const logInFailure = makeActionCreator(
  AuthActionTypes.LOGIN_FAILURE,
  'errors'
);

// CHANGE PASSWORD
export const changePasswordRequest = makeActionCreator(
  AuthActionTypes.CHANGE_PASSWORD_REQUEST
);
export const changePasswordSuccess = makeActionCreator(
  AuthActionTypes.CHANGE_PASSWORD_SUCCESS,
  'messages'
);
export const changePasswordFailure = makeActionCreator(
  AuthActionTypes.CHANGE_PASSWORD_FAILURE,
  'errors'
);

// SIGNUP
export const signUpRequest = makeActionCreator(AuthActionTypes.SIGNUP_REQUEST);
export const signUpSuccess = makeActionCreator(
  AuthActionTypes.SIGNUP_SUCCESS,
  'messages'
);
export const signUpFailure = makeActionCreator(
  AuthActionTypes.SIGNUP_FAILURE,
  'errors'
);

// NEW USER FINALIZE
export const newUserFinalizeRequest = makeActionCreator(
  AuthActionTypes.NEW_USER_FINALIZE_REQUEST
);
export const newUserFinalizeSuccess = makeActionCreator(
  AuthActionTypes.NEW_USER_FINALIZE_SUCCESS,
  'messages'
);
export const newUserFinalizeFailure = makeActionCreator(
  AuthActionTypes.NEW_USER_FINALIZE_FAILURE,
  'errors'
);

export const resetAccountStatus = makeActionCreator(
  AuthActionTypes.RESET_ACCOUNT_STATUS
);

// FETCH AUTH DETAILS
export const fetchAuthDetailsRequest = makeActionCreator(
  AuthActionTypes.FETCH_AUTH_DETAILS_REQUEST
);
export const fetchAuthDetailsSuccess = makeActionCreator(
  AuthActionTypes.FETCH_AUTH_DETAILS_SUCCESS,
  'authDetails',
  'messages'
);
export const fetchAuthDetailsFailure = makeActionCreator(
  AuthActionTypes.FETCH_AUTH_DETAILS_FAILURE,
  'errors'
);

export const serverDown = makeActionCreator(AuthActionTypes.SERVER_DOWN);

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%% ASYNC ACTIONS %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// BOOTSTRAP REQUEST
export const bootstrap = () => ({
  actions: [bootstrapRequest, bootstrapSuccess, bootstrapFailure],
  callAPI: () => AuthService.bootstrap(),
  responseMapper: new ActionsResponseMapperSimple(),
  // Note that we are using beforeSuccess instead of onSuccess.
  // We want to dispatch all the related actions before the final BOOTSTRAP_SUCCESS.
  beforeSuccess: (response, dispatch) => {
    const { user, org, orgNodes } = response;
    if (SentryProvider.getSentry()) {
      SentryProvider.getSentry().configureScope(scope => {
        scope.setExtra('orgId', org?.id);
        scope.setExtra('features', org?.features);
        scope.setUser({
          id: user?.id,
          username: user?.id, // this field is prominent in sentry dashboard
        });
      });
    }
    dispatch(logInSuccess());
    dispatch(loadUserData(user));
    dispatch(fetchOrgsSuccess([org]));
    dispatch(fetchOrgNodesSuccess(orgNodes || []));
  },
  onFailure: (errors, dispatch) => {
    // server is down
    if (
      errors?.[0]?.message ===
      ErrorResponseHandler.defaultCustomError()?.[0]?.message
    ) {
      dispatch(serverDown());
    }
  },
});

export const preLogOut = (explicitLogout = false) => ({
  type: AuthActionTypes.PRE_LOGOUT,
  explicitLogout,
});

// LOGOUT
export const logOut = () => async dispatch => {
  try {
    dispatch({
      type: AuthActionTypes.LOGOUT,
    });
    await AuthService.logOut();
  } catch (e) {
    console.error(e);
    // FIXME
    alert('Unable to logout. Please try again');
  }
};

// CHECK ACCOUNT
export const checkAccount = (email, authUrl = null) => ({
  actions: [checkAccountRequest, checkAccountSuccess, checkAccountFailure],
  callAPI: () => AuthService.checkAccount(email, authUrl),
  responseMapper: new ActionsResponseMapperSimple(),
});

// LOGIN
export const logIn = (email, password, authUrl = null) => ({
  actions: [logInRequest, logInSuccess, logInFailure],
  callAPI: () => AuthService.authenticate(email, password, authUrl),
  responseMapper: new ActionsResponseMapperSimple(),
  successPayload: null,
  beforeSuccess(response, dispatch) {
    // After successfull login, bootstrap the app.
    // Note that it's dispatched before logInSuccess because we don't want the main component to dispatch it again accidentally
    dispatch(bootstrap());
  },
});

export const mobileLogIn = (email, password, authUrl = null) => ({
  actions: [logInRequest, logInSuccess, logInFailure],
  callAPI: () => AuthService.authenticateMobile(email, password, authUrl),
  responseMapper: new ActionsResponseMapperSimple(),
  successPayload: null,
});

// SIGNUP
export const signUp = (data, authUrl = null) => ({
  actions: [signUpRequest, signUpSuccess, signUpFailure],
  callAPI: () => AuthService.signUp(data, authUrl),
  responseMapper: new ActionsResponseMapperEmpty(),
});

// FETCH AUTH DETAILS
export const fetchAuthDetails = authUrl => ({
  actions: [
    fetchAuthDetailsRequest,
    fetchAuthDetailsSuccess,
    fetchAuthDetailsFailure,
  ],
  callAPI: () => AuthService.getAuthDetails(authUrl),
  responseMapper: new ActionsResponseMapperSimple(),
});

// NEW USER FINALIZE
export const newUserFinalize = (code, email, newPassword, authUrl = null) => ({
  actions: [
    newUserFinalizeRequest,
    newUserFinalizeSuccess,
    newUserFinalizeFailure,
  ],
  callAPI: () => AuthService.newUserFinal(code, email, newPassword, authUrl),
  responseMapper: new ActionsResponseMapperEmpty(),
});
