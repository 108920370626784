import loadDateLib from './load-date-lib';

/**
 * NOTE this file used to be implemented with MomemtJS library. But it's a heavy and deprecated library.
 * So we decided to use dayjs, a light-weight alternative with the same interface.
 * But it's extremely difficult to make all its features work on ie11.
 * So we decided to dynamically load dayjs for modern browsers and moment for legacy browsers
 */

/**
 * I introduced these mutable imports due to the dynamic nature of the library.
 * Otherwise, these will need to be functions and I will need to adjust the code base.
 * No issues were found with the current approach.
 * Note that ideally, we would export a promise and each file exporting it would wait until for it.
 */
// eslint-disable-next-line import/no-mutable-exports
let dayjs = null;
// eslint-disable-next-line import/no-mutable-exports
export let today = null;
// eslint-disable-next-line import/no-mutable-exports
export let todayISO = null;
// eslint-disable-next-line import/no-mutable-exports
export let timezone = null;

// You can use this promise if you need to immediately use the library.
// Components take sufficiently long to load and mount.
export const readyPromise = loadDateLib();
readyPromise.then(lib => {
  dayjs = lib;
  today = lib();
  todayISO = today.toISOString();
  timezone = lib.tz.guess();
});

export const POSTGRES_DATE_FORMAT = 'YYYY-MM-DD';

export const createExpirationDate = (month, year) => {
  const strMonth = String(month).padStart(2, '0');
  const strYear = String(year);
  return dayjs(`${strMonth}${strYear}`, 'MMYYYY').endOf('month');
};

export const isAfter = left => right => {
  return right.isAfter(left);
};

export const isAfterToday = isAfter(today);

export const formatDate = (v, tz = timezone) =>
  dayjs(v)
    .tz(tz)
    .format(`L`);

export const formatTime = (v, tz = timezone) =>
  dayjs(v)
    .tz(tz)
    .format(`LT z`);

export const formatTimeNoTimezone = (v, tz = timezone) =>
  dayjs(v)
    .tz(tz)
    .format(`LT`);

export const formatDateTime = (v = today, tz = timezone) =>
  dayjs(v)
    .tz(tz)
    .format(`L | LT z`);

export const formatDateTimeSpaced = (v = today, tz = timezone) =>
  dayjs(v)
    .tz(tz)
    .format(`L LT z`);

export const formatExpDate = (v, tz = timezone) =>
  dayjs(v)
    .tz(tz)
    .format(`MM/YY`);

export const formatToday = (v = today, tz = timezone) =>
  dayjs(v)
    .tz(tz)
    .format(`YYYY-MM-DD`);

export const formatTomorrow = (v = today, tz = timezone) =>
  dayjs(v)
    .tz(tz)
    .add(1, 'days')
    .format(`YYYY-MM-DD`);

/**
 * ISO time returning the next whole hour
 * e.g. "2022-07-19T23:14:32.159Z" (timezone UTC -4) ===> "8:00 PM"
 */
export const formatTimeNextHour = (v, tz = timezone) =>
  dayjs(v)
    .tz(tz)
    .add(1, 'hours')
    .format('h:00 A');

export const formatFunctions = {
  formatDate,
  formatTime,
  formatDateTime,
  formatExpDate,
  formatTimeNoTimezone,
};

/**
 * Compares dates of today and the provided timestamp, returning true if the timestamp
 * date has passed. Will return false if timestamp contains today's date.
 *
 * @param {String} deadline - ISO-8601 standard timestamp
 * @returns boolean
 */
export const dateHasPassed = (deadline, date = today) =>
  dayjs(date).isAfter(deadline, 'date');

/**
 *
 * Returns the date x numbers of days ago.
 *
 * @param {Number} number - Number of days.
 * @returns {String} ISO-8601 timestamp exactly X days ago.
 */
export const daysAgo = (number, from = today) =>
  dayjs(from)
    .subtract(number, 'days')
    .toISOString();

/**
 *
 * @param {String} left
 * @param {String} right
 * @returns boolean
 */
export const isSameDay = (left, right) => dayjs(left).isSame(right, 'date');

/**
 *
 * @param {String=} timestamp
 * @returns {String} formatted date string
 */
export const toPostgresDate = timestamp =>
  dayjs(timestamp)
    .format(POSTGRES_DATE_FORMAT)
    .toString();

export const toShortExpDate = (MM, YYYY) =>
  dayjs(`${MM}/${YYYY}`, 'MM/YYYY').format('MM/YY');

/**
 * Convert invoicePeriod: "202403" to a date format: "03/01/2024"
 * Use day "01" if no day is supplied. Day supplied should be 2 number format
 * @param invoicePeriod 202403
 * @param day 05
 * @returns {*} 03/05/2024
 */
export const convertInvoicePeriodToDate = (invoicePeriod, day) => {
  const YYYY = invoicePeriod.substring(0, 4);
  const MM = invoicePeriod.substring(4, 6);
  const DD = day || '01';
  return dayjs(`${MM}/${DD}/${YYYY}`).format('MM/DD/YYYY');
};

/**
 *
 * Sorts an array by date using a provided object key.
 *
 * @param {Array} arr - Array of objects.
 * @returns {Array} Sorted array by date.
 */
export const sortArrayByDate = (arr, key) =>
  arr.sort((a, b) => dayjs(a[key]) - dayjs(b[key]));

/**
 * Checks for 00:00:00 meaning no time recorded
 * if no time recorded do not convert timezone
 *
 * @param {String} ISO date
 * @returns {String} MM/DD/YYYY
 */
export const formatDateCheckTimestamp = date => {
  const dateStr = dayjs(date);
  const timeComponent = dateStr.utc().format('HH:mm:ss');
  if (timeComponent === '00:00:00') {
    return dateStr.utc().format(`MM/DD/YYYY`);
  }
  return formatDate(date);
};

export const format24hrTime = date => {
  return dayjs(date).format('HHmm');
};

/**
 * take a 24 hour string and convert it to ISO string
 * with hours/minutes taken from 24 hour string
 *
 * @param {String} time "1200"
 * @returns {Object} ISO
 */
export const convert24HrTimeToISO = time => {
  const hours = time.substring(0, 2);
  const minutes = time.substring(2, 4);
  return dayjs(today)
    .set('hour', hours)
    .set('minute', minutes)
    .set('second', 0);
};

/**
 * Converts an ISO-8601 date, without timezone, to a JavaScript Date object with the users
 * assumed timezone.
 */
export const toDate = v => dayjs(v).toDate();

export { dayjs as default };
