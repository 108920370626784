import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FilteringProvider, operators, useFiltering } from '../use-filtering';
import { PagingProvider, usePaging } from '../use-paging';
import { SearchingProvider, useSearching } from '../use-searching';
import { SortingProvider, SORT_DIRECTIONS, useSorting } from '../use-sorting';
import { ViewProvider } from '../use-view';
import { ScrollProvider } from '../use-scroll';
import moment from '../util/moment';

const SEP = '~';

/**
 * @param {string} id - The field ID.
 * @param {string} - The operation used for range comparison. (gt, gte, lt, lte)
 */
export const toRangeQueryParam = (id, operator) => `${id}${SEP}${operator}`;

/**
 * @param {string} string The URL query parameter to be split.
 */
export const fromRangeQueryParam = string => string.split(SEP);

/**
 * Since the API supplies a DATE and not a DATETIME we have to assume the offset is the user's
 * local timezone. Please reference this comment when the inevitable bug is opened.
 *
 * @param {string} date -A date string
 */
export function toDateFn(date) {
  return moment(date).toDate();
}

const StateChangeWatcher = ({ onStateChange }) => {
  const { filters, lastInteractedField } = useFiltering();
  const { searchTerm } = useSearching();
  const { field: sortField, direction: sortDirection } = useSorting();
  const { skip, take } = usePaging();

  useEffect(() => {
    onStateChange({
      take,
      skip,
      sortDirection,
      sortField,
      searchTerm,
      filters,
      lastInteractedField,
    });
  }, [
    onStateChange,
    take,
    skip,
    sortDirection,
    sortField,
    searchTerm,
    filters,
    lastInteractedField,
  ]);

  return null;
};

const ResetSkipOnFilterChange = () => {
  const { setState } = usePaging();
  const { filters } = useFiltering();
  const { searchTerm } = useSearching();
  useEffect(() => {
    return () => {
      setState(currState =>
        currState.skip === 0 ? currState : { ...currState, skip: 0 }
      );
    };
  }, [filters, searchTerm, setState]);

  return null;
};

export const CitrusFramework = props => {
  const {
    initialState,
    children,
    view,
    onStateChange,
    scrollName,
    selectedScrollItem,
    localScrollTime,
    setLocalStorage,
  } = props;

  const take = get(initialState, 'take', get(view, 'take', null));

  const skip = get(initialState, 'skip', 0);

  const sortDirection = get(
    initialState,
    'sortDirection',
    get(view, 'defaultSortDirection', SORT_DIRECTIONS.ASC)
  );

  const sortField = get(
    initialState,
    'sortField',
    get(view, 'defaultSortField')
  );

  const searchTerm = get(initialState, 'searchTerm', '');

  const filters = get(initialState, 'filters', []);
  const lastInteractedField = get(initialState, 'lastInteractedField', null);

  return (
    <FilteringProvider
      filters={filters}
      lastInteractedField={lastInteractedField}
    >
      <SearchingProvider term={searchTerm}>
        <SortingProvider field={sortField} direction={sortDirection}>
          <PagingProvider take={take} skip={skip}>
            <ViewProvider view={view}>
              <ScrollProvider
                selectedScrollItem={selectedScrollItem}
                scrollName={scrollName}
                localScrollTime={localScrollTime}
                setLocalStorage={setLocalStorage}
              >
                <StateChangeWatcher onStateChange={onStateChange} />
                <ResetSkipOnFilterChange />
                {children}
              </ScrollProvider>
            </ViewProvider>
          </PagingProvider>
        </SortingProvider>
      </SearchingProvider>
    </FilteringProvider>
  );
};

const FilterShape = {
  id: PropTypes.string.isRequired, // field.id
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      zip: PropTypes.string.isRequired,
      distance: PropTypes.number.isRequired,
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
    }),
  ]).isRequired,
  operator: PropTypes.oneOf(operators),
};

CitrusFramework.propTypes = {
  view: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  initialState: PropTypes.shape({
    skip: PropTypes.number,
    take: PropTypes.number,
    sortDirection: PropTypes.oneOf(['asc', 'desc']),
    sortField: PropTypes.string, // field.id
    searchTerm: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.shape(FilterShape)),
  }),
  children: PropTypes.node.isRequired,
  scrollName: PropTypes.string,
  selectedScrollItem: PropTypes.string,
  onStateChange: PropTypes.func,
  localScrollTime: PropTypes.string,
  setLocalStorage: PropTypes.func,
};

CitrusFramework.defaultProps = {
  initialState: {},
  onStateChange: () => {},
  localScrollTime: '',
  scrollName: undefined,
  setLocalStorage: undefined,
  selectedScrollItem: undefined,
};

export default CitrusFramework;
