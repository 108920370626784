import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '~/shared/components/makeStyles';
import Typography from '~/shared/components/atom/typography';

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0.5rem 0 1rem 0',
    border: `1px solid ${theme.palette.grey.greyLight}`,
  },
  cardContent: {
    textAlign: 'center',
    padding: theme.spacing(3, 2, 2),
  },
}));

export function ErrorMessage({ title, children }) {
  const classes = useStyles();
  return (
    <div className={classes.card} role="alert">
      <div className={classes.cardContent}>
        <Typography variant="h4">{title}</Typography>
        <div>{children}</div>
      </div>
    </div>
  );
}

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

ErrorMessage.defaultProps = {
  title: 'Something went wrong',
};

export default ErrorMessage;
