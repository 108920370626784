import axios from 'axios';
import api from '../../util/api';
import { appendQueryParams } from '../../hooks/assets/util';

export const listAssets = params => api.get('/asset/list', params);

export const createAsset = asset => api.post('/asset', asset);

export const updateAsset = asset => api.patch('/asset', asset);

export const getStatuses = (params = {}) =>
  api.get('/asset/statuses', { params });

export const getVehicleTypeList = (params = { all: false }) =>
  api.get(`/asset/vehicleTypes${params.all ? '?all=true' : ''}`);

export const getValidVin = (params = {}) =>
  api.get('/asset/assetnum-vin-validation', { params });

export const getSignedUrlUploadedAsset = (type, name) =>
  api.get(
    `/asset/images/upload?type=${type}&fileExt=${name.substring(
      name.lastIndexOf('.'),
      name.length
    )}`
  );

export const uploadAssetImg = (url, file, type) =>
  axios.put(url, file, {
    headers: {
      'Content-Type': type,
    },
  });

export const getServices = () => api.get('/service');

export const getAsset = asset => api.get(`/asset/${asset}`);

export const getAssetUpdateView = () => {
  return api.get(`/asset/view/asset-update`);
};

// list of assets: assetId, assetNo, year, make, model and  with user firstName and lastName if associated to a user
export const getAssetUsers = () => api.get(`/asset/asset-users`);

export const getAssetOdometer = assetId =>
  api.get(`/asset/${assetId}/odometer`);

export const getFleetAssetsUrl = (
  location = undefined,
  assetStatus = undefined,
  activeStatus = undefined,
  insurance = undefined,
  minYear = undefined,
  maxYear = undefined,
  make = undefined,
  model = undefined,
  odometer = undefined,
  licenseState = undefined,
  assetGroupName = undefined,
  orgName = undefined,
  format = '',
  isCTAIncluded = true,
  parentOrg = undefined
) => {
  const query = new URLSearchParams({ format, isCTAIncluded });
  if (location) {
    appendQueryParams(query, 'orgNodeLabel', location);
  }
  if (assetStatus) {
    appendQueryParams(query, 'active', assetStatus);
  }
  if (activeStatus) {
    appendQueryParams(query, 'customer_additional_status', activeStatus);
  }
  if (insurance) {
    appendQueryParams(query, 'insurance', insurance);
  }
  if (minYear) {
    appendQueryParams(query, 'year~gte', minYear);
  }
  if (maxYear) {
    appendQueryParams(query, 'year~lte', maxYear);
  }
  if (make) {
    appendQueryParams(query, 'make', make);
  }
  if (model) {
    appendQueryParams(query, 'model', model);
  }
  if (odometer) {
    appendQueryParams(query, 'odometerRange', odometer);
  }
  if (licenseState) {
    appendQueryParams(query, 'license_state', licenseState);
  }
  if (assetGroupName) {
    appendQueryParams(query, 'selectedUser.assetGroupName', assetGroupName);
  }

  if (parentOrg) {
    if (orgName) {
      appendQueryParams(query, 'org_name', orgName);
    }
    return `/parent-org/asset/report?${query}`;
  }

  return `/asset/report?${query}`;
};

export const getAssetFields = () => api.get('/asset/fields');

export const getAuxFields = () => api.get('/organizations/asset-aux-metadata');
