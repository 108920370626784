import common from './common.json';
import dashboard from './dashboard.json';
import main from './main.json';
import map from './map.json';
import marketplace from './marketplace.json';
import validator from './validator.json';
import vehicles from './vehicles.json';

export default {
  common,
  dashboard,
  main,
  map,
  marketplace,
  validator,
  vehicles,
};
