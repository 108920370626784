import { ServiceRequestActionTypes } from '../../actions/action-types';

const { 
  WATCHLIST_OPENED,
  WATCHLIST_CLOSED,
  WATCHLIST_SELECTED_ROW,
  WATCHLIST_REQUEST_MANAGEMENT_URL,
} = ServiceRequestActionTypes;

const initialState = {
  isWatchlistOpen: false,
  watchlistSelectedRow: null,
  watchlistRequestManagementUrl: null,
}

const serviceRequestsReducer = (state = initialState, action) => {
  switch(action.type) {
    case WATCHLIST_OPENED:
      return {
        ...state,
        isWatchlistOpen: true,
      };
    case WATCHLIST_CLOSED:
      return {
        ...state,
        isWatchlistOpen: false,
        watchlistSelectedRow: null,
      }
    case WATCHLIST_SELECTED_ROW:
      return {
        ...state,
        watchlistSelectedRow: action.requestId,
      }
    case WATCHLIST_REQUEST_MANAGEMENT_URL:
      return {
        ...state,
        watchlistRequestManagementUrl: action.url,
      }
    default:
      return state;
  }
};

export default serviceRequestsReducer;

export const getWatchlistStatus = state => state.isWatchlistOpen;
export const getWatchlistSelectedRow = state => state.watchlistSelectedRow;
export const getWatchlistRequestManagementUrl = state => state.watchlistRequestManagementUrl;
