import { useSelector } from 'react-redux';
import { selectors } from '../redux/reducers/index.reducer';

import {
  canAccessFeature,
  canAccessService,
  canAccessFullMenu,
  hasAssetBasedService,
} from '../permissions/permissions-hoc';

const usePermissions = () => {
  const features = useSelector(selectors.getFeatures);
  const org = useSelector(selectors.getOrg);
  const isOrgAdmin = useSelector(selectors.getIsOrgAdmin);

  return {
    canAccessFeature: canAccessFeature({ features }),
    canAccessService: canAccessService({ org }),
    canAccessFullMenu: canAccessFullMenu(org),
    hasAssetBasedService: hasAssetBasedService(org),
    generateProps: {
      features,
      isOrgAdmin,
      org,
      services: org?.services,
    },
  };
};

export default usePermissions;
