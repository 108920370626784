import pluralize from 'pluralize';
import { capitalizeFirstLetter } from './util';

export function createPagedReducer(entity) {
  const pluralEntity = pluralize(entity);
  const actionEntity = pluralEntity.toUpperCase();

  const initialState = {
    isFetching: false,
    [pluralEntity]: [],
    query: null,
    totalCount: 0,
    messages: [],
    failed: false,
    [`${pluralEntity}List`]: {
      hasMore: true,
      rows: [],
      count: 0,
    },
    [`${pluralEntity}Filters`]: {},
    listQuery: null,
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case `FETCH_PAGED_${actionEntity}_REQUEST`:
        return {
          ...state,
          isFetching: true,
          messages: [],
          failed: false,
        };

      case `FETCH_PAGED_${actionEntity}_SUCCESS`:
        return {
          ...state,
          isFetching: false,
          [pluralEntity]: action.payload.rows,
          query: action.payload.query,
          totalCount: action.payload.count,
          messages: action.messages || [],
        };

      case `FETCH_INITIAL_PAGED_${actionEntity}_LIST_SUCCESS`: {
        const mergedAssets = {};
        mergedAssets.hasMore = action.payload.hasMore;
        mergedAssets.rows = action.payload.rows;
        mergedAssets.count = action.payload.count;

        return {
          ...state,
          isFetching: false,
          [`${pluralEntity}List`]: mergedAssets,
          listQuery: action.payload.listQuery,
          query: action.payload.query,
          messages: action.messages || [],
        };
      }

      case `FETCH_PAGED_${actionEntity}_LIST_SUCCESS`: {
        const mergedAssets = { ...state[`${pluralEntity}List`] };
        mergedAssets.hasMore = action.payload.hasMore;
        mergedAssets.rows = [
          ...state[`${pluralEntity}List`].rows,
          ...action.payload.rows,
        ];
        mergedAssets.count = action.payload.count;

        return {
          ...state,
          isFetching: false,
          [`${pluralEntity}List`]: mergedAssets,
          listQuery: action.payload.listQuery,
          query: action.payload.query,
          messages: action.messages || [],
        };
      }

      case `FETCH_PAGED_${actionEntity}_FAILURE`:
        return {
          ...state,
          isFetching: false,
          failed: true,
          messages: action.errors,
        };

      case `FETCH_${actionEntity}_FILTERS_SUCCESS`:
        return {
          ...state,
          [`${pluralEntity}Filters`]: action.payload,
        };

      case `FETCH_${actionEntity}_FILTER_SUCCESS`: {
        if (!action.payload.rows) return { ...state };

        const { key } = action.payload;
        const prevFilters = { ...state[`${pluralEntity}Filters`] };
        prevFilters[key].hasMore = action.payload.hasMore;
        prevFilters[key].rows = [
          ...state[`${pluralEntity}Filters`][key].rows,
          ...action.payload.rows,
        ];

        return {
          ...state,
          [`${pluralEntity}Filters`]: {
            ...prevFilters,
          },
        };
      }

      case `FETCH_PAGED_${actionEntity}_TOGGLE_FILTER`: {
        const data = action.payload;
        const combinedFilters = { ...state[`${pluralEntity}Filters`] };

        if (data && data.length > 0) {
          // array is not empty and has filters
          data.forEach(({ key, val }) => {
            const currFiltersByKey = combinedFilters[key];

            const newArr = currFiltersByKey.rows;
            const idx = newArr.findIndex(x => {
              if (val === 'true' || val === 'false') {
                try {
                  return JSON.parse(x.val) === JSON.parse(val);
                } catch (err) {
                  console.error(err);
                  return x.val;
                }
              }
              if (typeof val === 'number') {
                return x.val === val;
              }
              if (/^[12][0-9]{3}$/g.test(val) && typeof val === 'string') {
                return x.val === Number(val);
              }
              return x.val === val;
            });

            if (val === 'true' || val === 'false') {
              try {
                val = JSON.parse(val);
              } catch (err) {
                console.error(err);
                return val;
              }
              newArr[idx] = { val, isSelected: !newArr[idx].isSelected };
            }
            if (typeof val === 'number') {
              newArr[idx] = {
                val: Number(val),
                isSelected: !newArr[idx].isSelected,
              };
            }
            if (typeof val === 'string') {
              const isYear = val.match(/\d{4}$/g);
              if (/^[12][0-9]{3}$/g.test(val) && isYear !== null) {
                val = Number(val);
              }
              newArr[idx] = { val, isSelected: !newArr[idx].isSelected };
            }

            combinedFilters[key].hasMore = currFiltersByKey.hasMore;
            combinedFilters[key].rows = [...newArr];
          });
        }

        return {
          ...state,
          [`${pluralEntity}Filters`]: {
            ...combinedFilters,
          },
        };
      }

      case `FETCH_PAGED_${actionEntity}_RESET_FILTERS`: {
        const combinedFilters = { ...state[`${pluralEntity}Filters`] };

        Object.keys(combinedFilters).forEach(val => {
          let alteredRows = [...combinedFilters[val].rows];
          alteredRows = alteredRows.map(r => {
            return { val: r.val, isSelected: false };
          });
          combinedFilters[val].rows = alteredRows;
        });
        return {
          ...state,
          [`${pluralEntity}Filters`]: {
            ...combinedFilters,
          },
        };
      }

      default:
        return state;
    }
  };
}

export function createPagedSelectors(entity) {
  const entityCap = capitalizeFirstLetter(entity);
  const pluralEntityCap = pluralize(entityCap);
  const pluralEntity = pluralize(entity);

  return {
    [`getPaged${pluralEntityCap}`]: state => state[pluralEntity],
    [`getPaged${pluralEntityCap}List`]: state => state[`${pluralEntity}List`],
    [`getPaged${pluralEntityCap}Filters`]: state =>
      state[`${pluralEntity}Filters`],
    [`get${pluralEntityCap}PageQuery`]: state => state.query,
    [`get${pluralEntityCap}ListPageQuery`]: state => state.listQuery,
    [`getPaged${pluralEntityCap}TotalCount`]: state => state.totalCount,
    [`getIsFetchingPaged${pluralEntityCap}`]: state => state.isFetching,
    [`getPaged${pluralEntityCap}Messages`]: state => ({
      failed: state.failed,
      messages: state.messages,
    }),
  };
}
