import produce from 'immer';
import {
  readFail,
  readFinish,
  readStart,
  updateFail,
  updateFinish,
  updateStart,
} from './rudd.actions';
import { statuses } from './rudd.constants';

function readReducer(action, draft) {
  if (readStart.match(action)) {
    draft.status =
      draft.status === statuses.fulfilled
        ? statuses.refreshing
        : statuses.fetching;
  }

  if (readFinish.match(action)) {
    draft.status = statuses.fulfilled;
    draft.data = action.payload;
  }

  if (readFail.match(action)) {
    draft.status =
      draft.status === statuses.refreshing
        ? statuses.errorRefreshing
        : statuses.errorFetching;

    draft.error = action.payload;
  }
}

function updateReducer(action, draft) {
  if (updateStart.match(action)) {
    draft.status = statuses.updating;
    draft.error = undefined;
  }

  if (updateFinish.match(action)) {
    draft.status = statuses.updated;
    draft.data = action.payload;
  }

  if (updateFail.match(action)) {
    draft.status = statuses.errorUpdating;
    draft.error = action.payload;
  }
}

const reducer = (draft, action) => {
  readReducer(action, draft);
  updateReducer(action, draft);
};

export default produce(reducer);
