import { useSelector } from '../../redux';
import { selectors } from '../../redux/reducers/index.reducer';

function useEnrolledInService(serviceName) {
  const features = useSelector(selectors.getFeatures) || [];

  return features.includes(serviceName);
}

export default useEnrolledInService;
