import { OrgActionTypes } from '../action-types';
import { makeActionCreator, ActionsResponseMapper } from '../util';
import { getOrganizationNodes } from '../../../services/organization';

// %%%%%%%%%%%%%%%%%%%%%%%%%% ACTION CREATORS %%%%%%%%%%%%%%%%%%%%%.

// FETCH.
export const fetchOrgNodesRequest = makeActionCreator(
  OrgActionTypes.FETCH_ORG_NODES_REQUEST
);
export const fetchOrgNodesSuccess = makeActionCreator(
  OrgActionTypes.FETCH_ORG_NODES_SUCCESS,
  'orgNodes',
  'messages'
);
export const fetchOrgNodesFailure = makeActionCreator(
  OrgActionTypes.FETCH_ORG_NODES_FAILURE,
  'errors'
);

export const fetchOrgNodes = () => ({
  actions: [fetchOrgNodesRequest, fetchOrgNodesSuccess, fetchOrgNodesFailure],
  callAPI: () => getOrganizationNodes(),
  responseMapper: new ActionsResponseMapper(),
});
