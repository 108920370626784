import { useEffect } from 'react';
import { useSelector } from '../../redux';
import { selectors } from '../../redux/reducers/index.reducer';
import { getAssetUpdateView } from '../../services/assets';
import useAsync from '../use-async';

function useAssetPmNotificationForm(
  initialValues,
  values,
  orgContact,
  setFieldValue,
  handleChange,
  setFieldTouched,
  tree
) {
  // selectedOrgNode is the selected locations level
  // it is used to get the associated email when radio option = hierarchyLocations
  const orgNodes = useSelector(selectors.getOrgNodes);
  const selectedOrgNode = orgNodes.find(orgNode => {
    return orgNode.id === values?.orgNodeId;
  });
  // disable the radio selection for hierarchyLocations when no email is available for selected location
  const isDisabled = !selectedOrgNode?.contact?.email;

  const hasLocations = Boolean(tree?.length);

  const locationLevelSelected = Boolean(
    values?.hierarchyLevel1?.length ||
      values?.hierarchyLevel2?.length ||
      values?.hierarchyLevel3?.length
  );

  const { data: pmNotificationView, run } = useAsync({ status: 'pending' });
  // get the PM Email Notifications form view
  useEffect(() => {
    run(
      getAssetUpdateView().then(res => {
        const options =
          res?.payload?.forms?.pmNotification?.fieldTypes?.main?.fields
            ?.pmEmailType?.options;
        // if no locations have been defined in the org, remove from the option from the list:
        if (Array.isArray(options) && orgNodes?.length === 0) {
          options.splice(-1);
        }
        return res.payload;
      })
    );
  }, [run, orgNodes]);
  const pmEmailOptions =
    pmNotificationView?.forms?.pmNotification?.fieldTypes?.main?.fields
      ?.pmEmailType?.options;

  const handleChangePmEmailRadio = e => {
    handleChange(e);
    setFieldValue('pmEmailType', e.target.value);
    // with every change of the radio selection, set pm-email as touched in order to trigger validation on textbox
    setTimeout(() => setFieldTouched('pmEmail', true));
    if (e.target.value === 'defaultOrgAddress') {
      setFieldValue('pmEmail', orgContact?.email);
    }
    if (e.target.value === 'manualEnter') {
      // if previously saved as manualEnter, use initialValues, otherwise value is overwritten if radio selection changes
      setFieldValue(
        'pmEmail',
        initialValues.pmEmailType === 'manualEnter' ? initialValues.pmEmail : ''
      );
    }
    if (e.target.value === 'hierarchyLocations') {
      setFieldValue(
        'pmEmail',
        selectedOrgNode ? selectedOrgNode?.contact?.email : ''
      );
    }
    if (e.target.value === 'none') {
      setFieldValue('pmEmail', '');
    }
  };

  // When locations is changed, update with the corresponding email address
  useEffect(() => {
    if (values.pmEmailType === 'hierarchyLocations') {
      setFieldValue(
        'pmEmail',
        selectedOrgNode ? selectedOrgNode?.contact?.email : ''
      );
    }
    if (isDisabled && values.pmEmailType === 'hierarchyLocations') {
      // if hierarchyLocations was selected, and then locations was updated to a level with no email, force radio selection back to defaultOrgAddress
      setFieldValue('pmEmailType', 'defaultOrgAddress');
      setFieldValue('pmEmail', orgContact?.email);
    }
  }, [selectedOrgNode]);

  return {
    pmEmailOptions,
    handleChangePmEmailRadio,
    isDisabled,
    hasLocations,
    locationLevelSelected,
  };
}

export default useAssetPmNotificationForm;
