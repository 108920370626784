import React, { useState, useContext, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import noop from '../fp/noop';
import { useNSTranslation } from '../lang';

const ScrollContext = createContext({
  selectedScrollItem: undefined,
  scrollName: undefined,
  setScrollItem: noop,
});

const updateJson = (jsonObject, scrollName, value) => {
  return JSON.stringify(
    Object.assign({}, jsonObject, {
      [scrollName]: value,
    })
  );
};

export const ScrollProvider = ({
  selectedScrollItem: propsSelectedScrollItem,
  scrollName: propsScrollName,
  localScrollTime: propsLocalScrollTime,
  setLocalStorage,
  ...props
}) => {
  const scrollTime = React.useRef(propsLocalScrollTime);
  const parsedScrollItem = propsSelectedScrollItem
    ? JSON.parse(propsSelectedScrollItem)
    : {};
  const [selectedScrollItem, setState] = useState(
    parsedScrollItem?.[propsScrollName]
  );
  React.useEffect(() => {
    // if there is no propsScrollName then don't do anything. Having no name means there is no reason to scroll to an element
    if (propsScrollName && propsScrollName.length > 0) {
      // if the scrollTime has never been set or saved to localStorage, it will be set and saved now. Also initializes the selectedItem variable (sets it to null with the props name => { 'request-management': null }).
      if (!scrollTime.current) {
        setLocalStorage(
          'selectedItem',
          updateJson(parsedScrollItem, propsScrollName, null)
        );
        scrollTime.current = new Date().getTime();
        setLocalStorage('scrollTime', scrollTime.current);
      } else {
        // If there is a scrollTime, that means we've arrived on a scroll page. So we are going to check how much time has passed.
        const d1 = scrollTime.current;
        const d2 = new Date().getTime();
        const resetTime = 5 * 60 * 1000;
        const timePassed = d2 - d1 > resetTime;
        // if more than 5 mins has passed and there is a record of a scrollItem, the localStorage will get cleared out and the time will be updated.
        if (
          timePassed &&
          parsedScrollItem[propsScrollName] === selectedScrollItem
        ) {
          setLocalStorage(
            'selectedItem',
            updateJson(parsedScrollItem, propsScrollName, null)
          );
          setLocalStorage('scrollTime', d2);
        } else {
          // if the time did not pass or there is not a record of scrollItem we just set the localStorage for 'selectedItem' and update the time.
          scrollTime.current = new Date().getTime();
          setLocalStorage('scrollTime', scrollTime.current);
          setLocalStorage(
            'selectedItem',
            updateJson(parsedScrollItem, propsScrollName, selectedScrollItem)
          );
        }
      }
    }
  }, [selectedScrollItem, propsScrollName, parsedScrollItem, setLocalStorage]);
  const ctx = useMemo(
    () => ({ selectedScrollItem, setState, propsScrollName }),
    [selectedScrollItem, propsScrollName]
  );

  return <ScrollContext.Provider value={ctx} {...props} />;
};

ScrollProvider.propTypes = {
  selectedScrollItem: PropTypes.string,
  scrollName: PropTypes.string,
  localScrollTime: PropTypes.string,
  setLocalStorage: PropTypes.func,
};

ScrollProvider.defaultProps = {
  selectedScrollItem: undefined,
  scrollName: undefined,
  setLocalStorage: undefined,
  localScrollTime: '',
};

export function useScroll() {
  const t = useNSTranslation('main', 'use-scroll');
  const ctx = useContext(ScrollContext);

  if (!ctx) {
    throw new Error(t('error'));
  }

  return ctx;
}
