import { useMemo } from 'react';
import { useNSTranslation } from '../lang';

function useValidationSchema(schema, conditions = {}, translationProps = []) {
  const { t } = useNSTranslation(...translationProps);
  const validationSchema = useMemo(() => schema(t, conditions), [
    conditions,
    schema,
    t,
  ]);
  return validationSchema;
}

export default useValidationSchema;
