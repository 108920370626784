import API from './api';

export const startAceSession = async (userId, orgId) =>
  API.post('/su/customer-service/ace', { orgId, userId });

export const stopAceSession = async () =>
  API.delete('/su/customer-service/ace');

export const getAceSessionStatus = async () =>
  API.get('/su/customer-service/ace');

export const getAceSelectListOptions = async () =>
  API.get('/su/customer-service/ace/user-org-listing');
