import BaseButton from '@material-ui/core/Button';
import { alpha } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '../../makeStyles';
import LoadingOverlay from '../../loading-overlay';

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const useStyles = makeStyles(theme => ({
  textError: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.error.main,
        theme.palette.action.hoverOpacity
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  textSuccess: {
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.success.main,
        theme.palette.action.hoverOpacity
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  containedError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  containedSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.success.main,
      },
    },
  },
  outlinedError: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: alpha(
        theme.palette.error.main,
        theme.palette.action.hoverOpacity
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  outlinedSuccess: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    '&:hover': {
      border: `1px solid ${theme.palette.success.main}`,
      backgroundColor: alpha(
        theme.palette.success.main,
        theme.palette.action.hoverOpacity
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const Button = props => {
  const classes = useStyles(props);
  const {
    className,
    children,
    disabled,
    variant,
    color,
    loading,
    ...rest
  } = props;

  const updateClassName = classnames(
    className,
    'browser-default',
    classes[`${variant}${capitalize(color)}`]
  );

  return (
    <BaseButton
      {...rest}
      color={color === 'error' || color === 'success' ? undefined : color}
      variant={variant}
      className={updateClassName}
      disabled={disabled || loading}
    >
      {children}
      {loading && <LoadingOverlay size={18} />}
    </BaseButton>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
    'error',
    'success',
  ]),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  className: undefined,
  color: 'default',
  variant: 'text',
  loading: false,
  disabled: false,
};

export default Button;
