import * as React from 'react';

const parseMessage = msg => {
  return typeof msg === 'object' ? JSON.stringify(msg) : msg;
};

const addMessage = (current, msg) => {
  return `${current}${current ? ', ' : ''}${parseMessage(msg)}`;
};

const messageType = {
  error: 'error',
  warning: 'warning',
};

const categorizeError = code => {
  if (code === 'unmapped-error' || code === 'untyped-error') {
    return 'critical';
  }
  return 'nonCritical';
};

function useServerMessages(messages) {
  const serverMessages = React.useMemo(() => {
    const initialValue = { errors: '', warnings: [] };
    if (!Array.isArray(messages)) {
      return initialValue;
    }

    return messages.reduce((acc, msg) => {
      if (msg.type === messageType.error) {
        acc.errors = addMessage(acc.errors, msg.message);
        acc.errorCategory = categorizeError(msg.code);
      }
      if (msg.type === messageType.warning) {
        acc.warnings.push(parseMessage(msg.message));
      }
      return acc;
    }, initialValue);
  }, [messages]);
  return serverMessages;
}

export default useServerMessages;
