import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import Typography from '~/shared/components/atom/typography/typography';
import RequestDetailRows from '~/areas/maintenance-repair-history/request-detail-rows';
import CircularProgress from '~/shared/components/circular-progress';
import GenericNoDisplay from '~/areas/individual-assets/generic-nothing-to-display';

const MaintenanceHistorySection = props => {
  const { data, parentOrg } = props;
  const { t } = useNSTranslation('individualAsset', 'maintenance');

  if (!data) {
    return <CircularProgress size={24} inlineStyle />;
  }

  return (
    <div>
      <Typography variant="h6" style={{ padding: '24px 0' }}>
        {t('maintenance-history')}
      </Typography>
      <div>
        {data?.length > 0 ? (
          <RequestDetailRows
            parentOrg={parentOrg}
            items={data}
            individualAsset
          />
        ) : (
          <GenericNoDisplay category={t('history')} />
        )}
      </div>
    </div>
  );
};

MaintenanceHistorySection.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  parentOrg: PropTypes.bool,
};

MaintenanceHistorySection.defaultProps = {
  parentOrg: undefined,
  data: null,
};

export default MaintenanceHistorySection;
