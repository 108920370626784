import { OrgActionTypes } from '../../actions/action-types';

export const initialState = {
  isFetching: false,
  failed: false,
  messages: [],
};

const orgNodesMetaReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrgActionTypes.FETCH_ORG_NODES_REQUEST:
      return {
        ...state,
        isFetching: true,
        messages: [],
        failed: false,
      };

    case OrgActionTypes.FETCH_ORG_NODES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        messages: action.messages || [],
        failed: false,
      };

    case OrgActionTypes.FETCH_ORG_NODES_FAILURE:
      return {
        ...state,
        isFetching: false,
        failed: true,
        messages: action.errors,
      };

    default:
      return state;
  }
};

export default orgNodesMetaReducer;

export const getIsFetchingOrgNodes = state => state.isFetching;
export const getOrgNodesMessages = state => ({
  failed: state.failed,
  messages: state.messages,
});
