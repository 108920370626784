import { compose, lifecycle } from 'recompose';

export default compose(
  lifecycle({
    componentDidUpdate(prevProps) {
      const { loggingOut, onLogout, redirectUrl, redirect } = this.props;
      if (!prevProps.loggingOut && loggingOut) {
        onLogout().then(() => redirect(redirectUrl));
      }
    },
  })
);
