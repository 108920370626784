import get from 'lodash/get';
import getFieldValues from '../util/get-field-values';
import dayjs from '../util/moment';

export const FILTER_TYPES = {
  ONE: 'ONE',
  MANY: 'MANY',
  RANGE: 'RANGE',
  SELECT_RANGE: 'SELECT_RANGE',
  DISTANCE_FROM_ZIP: 'DISTANCE_FROM_ZIP',
};

export const isFilterable = ({ filter }) => Boolean(filter);
const needsOptions = ({ filter, type }) =>
  filter.type !== FILTER_TYPES.RANGE &&
  filter.type !== FILTER_TYPES.DISTANCE_FROM_ZIP &&
  (filter?.type !== FILTER_TYPES.SELECT_RANGE || type !== 'date') &&
  !filter.fixedOptions;
export const isFilterableWithOptions = field =>
  isFilterable(field) && needsOptions(field);
export const isFilterableWithoutOptions = field =>
  isFilterable(field) && !needsOptions(field);

export function parseValue(value, type) {
  if (!value) {
    return value;
  }
  switch (type) {
    case 'number':
      return Number(value);

    case 'date':
      return value;

    case 'boolean':
      return value === 'true';

    case 'string':
    default:
      return value;
  }
}

/**
 * Format value based on "format" field in the view definition
 * @param {string} value data value
 * @param {string} format value format from view definition
 * @returns formatted data value
 */
export const formatValue = (value, format) => {
  if (!value || !format) {
    return value;
  }
  const [formatType, formatVal] = format?.split('|');
  switch (formatType) {
    case 'date':
      return dayjs(value).format(formatVal);
    case 'split':
      return value.split('|')[+formatVal];
    default:
      return value;
  }
};

const operatorMap = {
  contains: (a, b) => (a || '').indexOf(b) >= 0,
  doesnotcontain: (a, b) => (a || '').indexOf(b) === -1,
  doesnotendwith: (a, b) =>
    (a || '').indexOf(b, (a || '').length - (b || '').length) < 0,
  doesnotstartwith: (a, b) => (a || '').lastIndexOf(b, 0) === -1,
  endswith: (a, b) =>
    (a || '').indexOf(b, (a || '').length - (b || '').length) >= 0,
  eq: (a, b) => a === b,
  gt: (a, b) => a > b,
  gte: (a, b) => a >= b,
  isempty: a => a === '',
  isnotempty: a => a !== '',
  isnotnull: a => a !== null && a !== undefined,
  isnull: a => a === null || a === undefined,
  lt: (a, b) => a < b,
  lte: (a, b) => a <= b,
  neq: (a, b) => a !== b,
  startswith: (a, b) => (a || '').lastIndexOf(b, 0) === 0,
  closeTo: (a, location, distance) => {
    throw new Error(
      'closeTo operator is not supported by local juice yet. Args:',
      a,
      location,
      distance
    );
  },
};

export const operators = Object.keys(operatorMap);

const getOperator = key => operatorMap[key] || key;

function prepareValue(v, ignoreCase) {
  return typeof v === 'string' && ignoreCase ? v.toLowerCase() : v;
}

/**
 * Why is this so complicated?
 *
 * Because we nest values in arrays for example vendor capabilities is an array of strings. So the
 * FIELD function will extract those values, and those values are inevitably passed to the OPERATOR
 * function wherein the array is checked to see if any elements match the test VALUE supplied.
 */
export function toFacetFilter(path, value, op = 'eq', ignoreCase = true) {
  return {
    field: item => getFieldValues(item, path),
    value,
    operator: (itemValue, testValue) => {
      const operator = getOperator(op);
      itemValue = Array.isArray(itemValue) ? itemValue : Array.of(itemValue);
      return itemValue.some(v =>
        operator(prepareValue(v, ignoreCase), testValue)
      );
    },
    ignoreCase,
  };
}

/**
 * Converts our Filters to a filters usable by @progress/kendo-data-query process.
 *
 * @param {Filter[]} filters
 * @param {object[]} fields
 * @returns {(FilterDescriptor[]|CompositeFilterDescriptor[])}
 */
export function createFacetFilters(filters, fields) {
  return fields.flatMap(field => {
    const { id, filter } = field;

    if (!isFilterable(field)) {
      return [];
    }

    const fieldFilters = filters.filter(f => f.id === field.id);

    if (fieldFilters.length === 0) {
      return [];
    }

    const operator = get(filter, 'operator', 'eq');

    const ignoreCase = get(filter, 'ignoreCase', true);

    switch (field.filter.type) {
      case FILTER_TYPES.ONE:
        return fieldFilters
          .map(({ value }) => toFacetFilter(id, value, operator, ignoreCase))
          .pop();

      case FILTER_TYPES.MANY:
        return {
          logic: 'or',
          filters: fieldFilters.map(({ value }) =>
            toFacetFilter(id, value, operator, ignoreCase)
          ),
        };

      case FILTER_TYPES.RANGE:
      case FILTER_TYPES.SELECT_RANGE: {
        return {
          logic: 'and',
          filters: fieldFilters.map(({ value, operator: filterOperator }) =>
            toFacetFilter(id, value, filterOperator, ignoreCase)
          ),
        };
      }

      default:
        return [];
    }
  });
}
