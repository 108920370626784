import get from 'lodash/get';
import { createNoopTransition } from '../common/status-transition-helpers';
import { formatDate } from '../util/moment';

/**
 * Generates the initial form data for the request form
 * @param {*} data data attribute from the Rudd framework
 */
export const generateOverviewInitialValues = data => {
  const { statuses, request } = data;
  const {
    costLimit,
    requestedDate,
    dueAt,
    instructions,
    requestTypeName,
    status,
  } = request;

  const startedDate = statuses.find(
    ({ name, date }) => name === 'started' && date
  );

  const initialValues = {
    dueAt,
    instructions,
    transition: createNoopTransition(status),
    startedDate: startedDate && formatDate(startedDate.date),
    vendorId: get(data, 'requestVendor.id', ''),
    /**
     * The following are uneditable for the time being, but we're passing them through Formik for
     * display.
     */
    requestType: requestTypeName,
    maxPrice: costLimit || undefined,
    requestedDate: formatDate(requestedDate),
  };

  return initialValues;
};

export const generateDetailsInitialValues = data => {
  const { maintenance } = data;
  const { maintenanceRepairDetails } = maintenance;
  if (maintenanceRepairDetails) {
    return maintenanceRepairDetails.reduce((formikInitialValues, detail) => {
      formikInitialValues[detail.id] = detail.approved ?? true; // if null, default to true. Allows form submission without explicitly clicking approve.
      return formikInitialValues;
    }, {});
  }
  return {};
};
