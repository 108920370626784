const primarySelections = [
  'vin',
  'make',
  'model',
  'year',
  'trim',
  'series',
  'type',
  'engineCylinders',
  'fuel',
  'gvwr',
  'errorMessage',
];

const isUpperCase = aCharacter => {
  return aCharacter >= 'A' && aCharacter <= 'Z';
};

const returnReadableLabel = label => {
  let fullLabel = '';
  for (let i = 0; i < label.length; i += 1) {
    if (i === 0) {
      fullLabel += label.charAt(i)?.toUpperCase();
    } else if (isUpperCase(label.charAt(i))) {
      fullLabel += ` ${label.charAt(i)}`;
    } else {
      fullLabel += label.charAt(i);
    }
  }
  return fullLabel;
};

const returnFilteredAssetAttributes = vinData =>
  Object.keys(vinData)
    .filter(key => !primarySelections.includes(key))
    .reduce((obj, key) => {
      obj[key] = vinData[key];
      return obj;
    }, {});

const AssetHelpers = {
  returnFilteredAssetAttributes,
  returnReadableLabel,
};

export default AssetHelpers;
