/* eslint-disable no-mixed-operators */
import { createSelector } from 'reselect';
import { OrgActionTypes } from '../../actions/action-types';

const initialState = {
  isFetching: false,
  orgNode: null,
  messages: [],
  failed: false,
};

const orgNodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrgActionTypes.FETCH_ORG_NODE_REQUEST:
    case OrgActionTypes.UPDATE_ORG_NODE_REQUEST:
    case OrgActionTypes.ADD_ORG_NODE_REQUEST:
    case OrgActionTypes.REMOVE_ORG_NODE_REQUEST:
      return {
        ...state,
        isFetching: true,
        messages: [],
        failed: false,
      };

    case OrgActionTypes.FETCH_ORG_NODE_SUCCESS:
    case OrgActionTypes.UPDATE_ORG_NODE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        orgNode: {
          ...state.orgNode,
          ...action.orgNode,
          childIds:
            action.orgNode.childIds ||
            (state.orgNode && state.orgNode.childIds) ||
            [],
        },
        messages: action.messages || [],
      };

    case OrgActionTypes.ADD_ORG_NODE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        orgNode: { ...action.orgNode, childIds: [] },
        messages: action.messages || [],
      };

    case OrgActionTypes.FETCH_ORG_NODE_FAILURE:
    case OrgActionTypes.UPDATE_ORG_NODE_FAILURE:
    case OrgActionTypes.ADD_ORG_NODE_FAILURE:
    case OrgActionTypes.REMOVE_ORG_NODE_FAILURE:
      return {
        ...state,
        isFetching: false,
        failed: true,
        messages: action.errors,
      };

    case OrgActionTypes.ADD_ORG_NODE_CHILD:
      return {
        ...state,
        orgNode: {
          ...state.orgNode,
          childIds: [...state.orgNode.childIds, action.childId],
        },
      };

    default:
      return state;
  }
};

export default orgNodeReducer;

export const getOrgNode = state => state.orgNode;
export const getIsFetchingOrgNode = state => state.isFetching;
export const getOrgNodeMessages = createSelector(
  [state => state.failed, state => state.messages],
  (failed, messages) => ({ failed, messages })
);
