import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Analytics from '~/shared/analytics';
import { publicAppRoutes } from './areas/nav/public-routes';
import PublicAppLayout from './layout/public-app-layout';
import SEORoute from './shared/seo';
import useViewType from './hooks/useViewType';

export const PublicMain = () => {
  useEffect(() => Analytics.init(), []);
  // check for view query param
  useViewType()

  return (
    <PublicAppLayout>
      <Suspense fallback={null}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/marketplace/shop-stock-vehicles/landing" />
          </Route>
          {publicAppRoutes.map(route => (
            <SEORoute key={route.id} {...route} />
          ))}
        </Switch>
      </Suspense>
    </PublicAppLayout>
  );
};

PublicMain.propTypes = {};

PublicMain.defaultProps = {};

export default PublicMain;
