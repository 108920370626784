import has from './has';
import isArray from './isArray';
import isInteger from './isInteger';
import isNil from './isNil';
import set from './set';
import tail from './tail';

function defaultShape(path) {
  return isInteger(path[1]) ? [] : {};
}

export default function setPath(path, val, obj) {
  if (path.length === 0) {
    return val;
  }

  const idx = path[0];
  if (path.length > 1) {
    const nextObj =
      !isNil(obj) && has(idx, obj) ? obj[idx] : defaultShape(path);
    val = setPath(tail(path), val, nextObj);
  }

  if (isInteger(idx) && isArray(obj)) {
    const arr = [].concat(obj);
    arr[idx] = val;
    return arr;
  }
  return set(idx, val, obj);
}
