import React from 'react';
import PropTypes from 'prop-types';
import clx from 'classnames';
import { connect } from 'formik';
import { useNSTranslation } from '@holmanfm/lib/lang';
import useAssetPmNotificationForm from '@holmanfm/lib/hooks/assets/use-asset-pm-notification-form';
import TextField from '~/shared/components/forms/text-field';
import RadioGroup from '~/shared/components/radio-group';
import FormControlLabel from '~/shared/components/form-control-label';
import Radio from '~/shared/components/radio';
import Typography from '~/shared/components/atom/typography';
import makeStyles from '~/shared/components/makeStyles';
import FormHelperText from '~/shared/components/form-helper-text';

const useStyles = makeStyles(theme => ({
  radioGroup: {
    padding: theme.spacing(1, 0, 2),
  },
  emailInput: {
    maxWidth: '400px',
    marginTop: theme.spacing(1),
  },
  hierarchyLocationHelper: {
    paddingLeft: '27px',
    color: theme.palette.secondary.main,
    marginTop: '-5px',
    fontSize: '0.75rem',
  },
}));

const PmNotifications = props => {
  const {
    orgContact,
    className,
    tree,
    formik: {
      initialValues,
      values,
      handleChange,
      setFieldValue,
      setFieldTouched,
    },
  } = props;
  const { t } = useNSTranslation('assets', 'add-asset');
  const classes = useStyles();

  const {
    pmEmailOptions,
    handleChangePmEmailRadio,
    isDisabled,
    hasLocations,
    locationLevelSelected,
  } = useAssetPmNotificationForm(
    initialValues,
    values,
    orgContact,
    setFieldValue,
    handleChange,
    setFieldTouched,
    tree
  );

  return (
    <>
      <Typography variant="h6">{t('pm-title')}</Typography>
      <Typography variant="body2">{t('pm-subtitle')}</Typography>
      <RadioGroup
        aria-label="PM Notification"
        name="pmEmailType"
        value={values?.pmEmailType}
        onChange={handleChangePmEmailRadio}
        className={classes.radioGroup}
      >
        {pmEmailOptions?.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio size="small" id={option.value} />}
            label={option.label}
            disabled={
              option.value === 'hierarchyLocations' ? isDisabled : false
            }
          />
        ))}
        {hasLocations && locationLevelSelected && isDisabled && (
          <FormHelperText className={classes.hierarchyLocationHelper}>
            {t('no-location-email-provided')}
          </FormHelperText>
        )}
      </RadioGroup>
      <TextField
        value={values?.pmEmail}
        name="pmEmail"
        fullWidth
        label={t('pm-email')}
        variant="outlined"
        disabled={values?.pmEmailType !== 'manualEnter'}
        className={clx(classes.emailInput, className)}
      />
    </>
  );
};

PmNotifications.propTypes = {
  tree: PropTypes.oneOfType([PropTypes.array]).isRequired,
  orgContact: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    initialValues: PropTypes.shape({}).isRequired,
    values: PropTypes.shape({}).isRequired,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }).isRequired,
};

PmNotifications.defaultProps = {};

export default connect(PmNotifications);
