/* eslint-disable no-restricted-syntax,no-prototype-builtins */

export const isEmptyObject = object => {
  let key;
  for (key in object) {
    if (object.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

/**
 * Create an object.
 *
 * @sig String -> a -> { String: a }
 * @example
 *  obj('name')('rob') // => { name: 'rob }
 *  obj('age')(999) // => { age: 999 }
 */
export const obj = key => value => ({ [key]: value });

/**
 * Rename object keys based on a keyMap dict.
 *
 * @sig Object -> Object -> Object
 * @example
 *  const request = { role: 'abc-123-def-345', name: 'rob' }
 *  const renameRequesKeys = renameKeys({ role: 'role_id' });
 *
 * renameRequesKeys(request) // => { role_id: abc-123-def-345, name: 'rob }
 */
export const renameKeys = keyMap => object =>
  Object.keys(object).reduce(
    (result, key) => ({
      ...result,
      ...obj(keyMap[key] || key)(object[key]),
    }),
    {}
  );

/**
 * Safely access a property on an object that may not exist.
 *
 * @param {object} [data] - Possibly undefined object.
 * @param {string} key - The key to access.
 * @param {any} [defaultValue] - The value to return if key is not found.
 * @returns {(undefined | any)}
 * @example
 *  get({ name: 'bob' }, 'name') // => undefined
 *  get({ age: 22 }, 'name') // => undefined
 *  get(null, 'name') // => undefined
 *  get({ age: 22 }, 'name', 'mary') // => mary
 */
export const get = (data, key, defaultValue = undefined) =>
  (data && data[key]) || defaultValue;

export function isDefined(val) {
  return typeof val !== 'undefined' && val !== null;
}

export function isNumber(val) {
  return typeof val === 'number';
}

export function isString(val) {
  return typeof val === 'string';
}

/**
 * Safely access a property on object hierarchy that may not exist.
 * i.e. a.b.c.d is safeGet(a, ['b','c','d'])
 * @param {*} o the object
 * @param {*} p is an array of names of properties
 */
export const safeGet = (o, p) =>
  p.reduce((xs, x) => (xs && isDefined(xs[x]) ? xs[x] : null), o);

/**
 * set item's nested property, i.e.
 * ({}, ['a'], val1) => { a: val1 }
 * ({}, ['a','b'], val2) => { a: { b: val2 } }
 * ({a:{c:'d'}}, ['a','b'], val3) => { a: {c:'d', b:val3 } } }
 */
export const safeSet = (item, props, value) => {
  if (!item || typeof item !== 'object') {
    throw new Error('safeSet: object required');
  } else if (!Array.isArray(props) || props.length < 1) {
    throw new Error('safeSet: props array required');
  }
  let pItem = item;
  const last = props.length - 1;
  for (let i = 0; i < last; i += 1) {
    const prop = props[i];
    if (!pItem[prop]) {
      pItem[prop] = {};
    } else if (typeof pItem[prop] !== 'object') {
      throw new Error(`safeSet: prop is not an object: ${prop}`);
    }
    pItem = pItem[prop];
  }
  pItem[props[last]] = value;
};

/**
 * Create an array that has duplicate values removed
 * new Set() removes duplicate values
 * Array.from() converts the returned object into an array
 * example breakdown
 * new Set(['a','a','b','c','d','c']) => {'a','b','c','d'}
 * Array.from(new Set(['a','a','b','c','d','c'])) => ['a','b','c','d']
 * @param arr
 * @returns {any[]}
 */
export const uniqueArray = arr => Array.from(new Set(arr));

/**
 * I add value to an array. If the array is undefined, I create one and then push.
 *
 * @param {any} value The value to push
 * @param {any[]} [data] The array to push the value onto.
 */
export const append = (value, data = []) => {
  data.push(value);
  return data;
};

/**
 * I take a list of objects, and return an object grouping the list by the property
 * specified.
 *
 * Arguably this would be faster using a for loop, but that's not relevant.
 *
 * @param {string|number} prop The object property you want to group by.
 * @param {any[]} data The list of objects you want grouped.
 */
export const groupBy = (prop, data) =>
  data.reduce((result, current) => {
    const value = current[prop]; // -> The property we want to group by.
    result[value] = append(current, result[value]); // -> Add the current value to the groups list.
    return result;
  }, {});

/**
 * A convience method for checking if an object has a property.
 *
 * @param {string|number} prop The property to check for.
 * @param {object} obj The object to be checked.
 */
export const has = (prop, obj2) =>
  Object.prototype.hasOwnProperty.call(obj2, prop);

/**
 * I take an object and return an array containing tuples of the key and value pairs of the object.
 *
 * @param {object} obj The object you want to convert to pairs.
 */
export const toPairs = obj4 => {
  const pairs = [];
  for (const key in obj4) {
    if (has(key, obj4)) {
      pairs.push([key, obj4[key]]);
    }
  }
  return pairs;
};

/**
 * I turn tuple into an object.
 *
 * @param {[string|number, any]} tuple The tuple [key, value] pair to be turned into an object.
 */
export const toObject = tuple => ({ [tuple[0]]: tuple[1] });

/**
 * I turn a string into a number, if NaN then return 0.
 * @param val string or number
 * @returns {number|number} 0 if NaN
 */
export const toNumber = val => {
  const n = Number(val);
  return Number.isNaN(n) ? 0 : n;
};

export { default as debounced } from './debounced';

export function sortByKeyCompare(key, order, dir) {
  return (a, b) => {
    const k = key && key.split('.');
    const aVal = safeGet(a, k);
    const bVal = safeGet(b, k);
    let comp;

    if (aVal === null || aVal === undefined) {
      return 1;
    }

    if (bVal === null || bVal === undefined) {
      return -1;
    }

    if (
      order &&
      Array.isArray(order) &&
      (order.indexOf(aVal) >= 0 || order.indexOf(bVal) >= 0)
    ) {
      // custom order, array of strings
      const aOrder = order.indexOf(aVal);
      if (aOrder === -1) {
        return 1; // place not found at the end
      }
      const bOrder = order.indexOf(bVal);
      if (bOrder === -1) {
        return -1; // place not found at the end
      }
      comp = Math.sign(aOrder - bOrder);
    } else if (typeof aVal === 'number' && typeof bVal === 'number') {
      comp = aVal - bVal;
      /** JS Date objects. */
    } else if (aVal.getTime && bVal.getTime) {
      comp = aVal - bVal;
    } else {
      comp = aVal ? aVal.localeCompare(bVal) : 1;
    }
    return dir === 'ASC' ? comp : -comp;
  };
}

// sort array by key. Key may be nested, e.g. 'vehicle.make'.
export const sortByKey = (arr, key, dir = 'DESC', order) =>
  arr.slice().sort(sortByKeyCompare(key, order, dir));

// Block special characters in an input
export const removeInputPunctuation = value => {
  if (!value) {
    return '';
  }
  return value.replace(/[^\w\s]|_/g, '').replace(/\s+/g, ' ');
};

// // Stop spaces from being at the beginning of an input
// export const hasLeadingSpace = e => {
//   if (e.target.value.length === 1 && /^\s+/.test(e.target.value) === true) {
//     return true;
//   }
// };

// Stop spaces from being at the beginning of an input
export const hasLeadingSpace = value => {
  if (value.length === 1 && /^\s+/.test(value) === true) {
    return true;
  }
};

export const hasLeadingSpaceDirect = value => {
  if (value.length === 1 && /^\s+/.test(value) === true) {
    return true;
  }
};
export const hasLeadingSpacesNoCharsDirect = value => {
  if (
    value.length > 1 &&
    /\s+\W/.test(value) === true &&
    /[^\s]/.test(value) === false
  ) {
    return true;
  }
};
export const getPriceFromString = priceStr =>
  priceStr ? Number(priceStr.replace(/[^0-9.-]+/g, '')) : 0;
export const getPriceUnitFromString = priceStr =>
  priceStr ? priceStr.replace(/[0-9.-]+/g, '') : '$';

// Checks array of modules to see if they have both the same course/module id and dueDate if so returns true
export const handleDupeModules = (modules, moduleId) => {
  const found = modules.findIndex(
    ({ trainingModuleId }) => trainingModuleId === moduleId
  );
  return found > -1;
};

export const getFormattedMileage = (mileage, unknown, source = null) => {
  if (!mileage) {
    return unknown;
  }

  let res = Number(mileage).toLocaleString();
  if (source) {
    res += ` (${source})`;
  }
  return res;
};

export const strToBoolCheck = val => {
  if (val === 'true') {
    return true;
  }
  if (val === 'false') {
    return false;
  }
  return val;
};

// Used for radio buttons.
export const boolToStrCheck = val => {
  if (typeof val === 'boolean') {
    if (val) {
      return 'true';
    }
    return 'false';
  }
  return val;
};

export const mapWexPinStatusToUI = val => {
  const status = val?.toLowerCase();
  let pinStatus = '';
  if (status === 'active') {
    pinStatus = 'Active';
  }
  if (status === 'terminated') {
    pinStatus = 'Inactive';
  }
  if (status === 'purged') {
    pinStatus = 'Deleted';
  }
  return pinStatus;
};

/**
 * Copied from API
 * Map number to a range according to provided bins.
 * Example: numberToRange(55.5, [0, 50, 100], '$') => '$50 - $100'
 * @param val number to be converted to a bin
 * @param bins bins as an array of numbers
 */
export const numberToRange = (val, bins, prefix = '') => {
  if (!isDefined(val) || !bins || bins.length <= 0) {
    return null;
  }
  for (let i = bins.length - 1; i >= 0; i -= 1) {
    if (val >= bins[i]) {
      if (i === bins.length - 1) {
        return `over ${prefix}${bins[i]}`;
      }
      return `${prefix}${bins[i]} - ${prefix}${bins[i + 1]}`;
    }
  }
  return `less than ${prefix}${bins[0]}`;
};
