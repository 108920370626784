import { connect, getIn } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import FormControlLabel from '../form-control-label';
import SwitchComponent from '../switch';

const Switch = props => {
  const { formik, name, switchProps = {}, ...rest } = props;
  const { values, setFieldValue, setFieldTouched } = formik;

  const value = getIn(values, name);

  function onChange() {
    setFieldTouched(name);
    setFieldValue(name, !value);
  }

  return (
    <FormControlLabel
      control={
        <SwitchComponent {...switchProps} checked={value} onChange={onChange} />
      }
      {...rest}
    />
  );
};

Switch.propTypes = {
  formik: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  switchProps: PropTypes.shape(),
};

Switch.defaultProps = {
  switchProps: undefined,
};

export default connect(Switch);
