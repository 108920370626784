/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import clx from 'classnames';
import ReactPhoneInput from 'react-phone-input-2';
// Using the new library 'react-phone-input-2' - https://github.com/bl00mber/react-phone-input-2
// The issue, the old library wasn't letting us change the style to match the new 'contained' style of inputs. After much tinkering Nancy and I gave up trying to repurpose the 'react-phone-input' library to use MaterialUI inputs. Dragan found this library and with a little work it's pretty seamless.
// Value is not saved the save. In the component it is being used in you need to manually add the + to the start of the value. Ex. 18569489506 needs to be +18569489506.

import makeStyles from '~/shared/components/makeStyles';
import './phone-input-2-style.css';
// this css is copied and pasted from the library lib of css => import 'react-phone-input-2/lib/material.css'. There is an issue and webpack won't compile. This worked to out advantage because it needed some tweaks anyway. So, this imported css file is giving all the styles to the component.

const DEFAULT_COUNTRY = 'us';

const DEFAULT_COUNTRIES = ['us', 'ca'];

const useStyles = makeStyles(theme => ({
  disabledClass: {
    color: theme.palette.grey[400],
    '& .form-control:hover': {
      borderColor: '#CACACA !important',
    },
  },
  errorClass: {
    color: theme.palette.error.main,
  },
  defaultInputStyle: {
    fontSize: '0.875rem',
    fontFamily: ['Poppins'],
    '&.form-control': {
      padding: '14.65px 14px 8.65px 58px !important',
    },
  },
  labelStyle: {
    '& > .special-label': {
      transform: 'translate(-15px, -1px) scale(0.95)',
      fontWeight: 500,
    },
    '& > .flag-dropdown': {
      height: 50,
    },
  },
}));

const PhoneInput = props => {
  const {
    autoComplete,
    autoFocus,
    defaultValue,
    disabled,
    error,
    id,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required,
    value,
    className,
  } = props;

  const classes = useStyles();

  /**
   * Storing last selected country code to handle the case where "value" is an empty string.
   */
  const [dialCode, setDialCode] = React.useState('+1');

  const wrappedOnChange = (v = '', data) => {
    setDialCode(data.dialCode);
    onChange({
      target: {
        name: name || id,
        // If the value contains only country code, reset it to an empty string.
        // Otherwise, add "+" sign
        value: v === data.dialCode ? '' : `+${v}`,
      },
    });
  };

  const wrappedOnBlur = () => {
    onBlur({
      target: {
        name,
        id,
      },
    });
  };

  return (
    <ReactPhoneInput
      onlyCountries={DEFAULT_COUNTRIES}
      country={DEFAULT_COUNTRY}
      countryCodeEditable={false}
      inputClass={clx(classes.defaultInputStyle, {
        [classes.disabledClass]: disabled,
      })}
      containerClass={clx(
        `react-tel-input-2 ${className} ${classes.labelStyle}`,
        {
          [classes.disabledClass]: disabled,
        },
        {
          [classes.errorClass]: error,
        }
      )}
      // If the value is empty, default to country code. Otherwise, the library gets confused and does not allow the user to enter anything
      value={value === '' ? dialCode : value}
      name={name}
      id={id}
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      onBlur={wrappedOnBlur}
      placeholder={placeholder}
      onChange={wrappedOnChange}
      disabled={disabled}
      onFocus={onFocus}
      specialLabel={label}
      isValid={!error}
      inputProps={{
        name,
        required,
        autoFocus,
      }}
    />
  );
};

PhoneInput.propTypes = {
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
   */
  autoComplete: PropTypes.string,
  /**
   * If `true`, the `input` element will be focused during the first mount.
   */
  autoFocus: PropTypes.bool,
  /**
   * The default value of the `input` element.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * If `true`, the `input` element will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the label will be displayed in an error state.
   */
  error: PropTypes.bool,
  /**
   * The id of the `input` element.
   * Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id: PropTypes.string,
  /**
   * The label content.
   */
  label: PropTypes.node,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * @ignore
   */
  onBlur: PropTypes.func,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /**
   * @ignore
   */
  onFocus: PropTypes.func,
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder: PropTypes.string,
  /**
   * If `true`, the label is displayed as required and the `input` element` will be required.
   */
  required: PropTypes.bool,
  /**
   * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The passed down classes of the phone input in another component
   */
  className: PropTypes.string,
};

PhoneInput.defaultProps = {
  autoComplete: undefined,
  autoFocus: undefined,
  defaultValue: undefined,
  disabled: undefined,
  error: undefined,
  id: undefined,
  label: undefined,
  name: undefined,
  onBlur: v => v,
  onChange: v => v,
  onFocus: v => v,
  placeholder: undefined,
  required: undefined,
  value: undefined,
  className: undefined,
};

export default PhoneInput;
