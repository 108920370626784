import React from 'react';
import MuiTooltip from '~/shared/components/muiTooltip';
import withStyles from '~/shared/components/withStyles';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'white',
    color: theme.palette.secondary.main,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

const DarkTooltip = withStyles(theme => ({
  tooltip: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

const LFTooltip = ({ lightTooltip, title, children, placement = 'bottom' }) =>
  typeof title === 'undefined' ? (
    children
  ) : (
    <>
      {lightTooltip ? (
        <LightTooltip
          title={title}
          placement={placement}
          enterTouchDelay={0}
          leaveTouchDelay={5000}
        >
          {children}
        </LightTooltip>
      ) : (
        <DarkTooltip
          title={title}
          placement={placement}
          enterTouchDelay={0}
          leaveTouchDelay={5000}
        >
          {children}
        </DarkTooltip>
      )}
    </>
  );

LFTooltip.propTypes = MuiTooltip.propTypes;
export default LFTooltip;
