/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '@holmanfm/lib/common/marketplace-helpers';
import { useNSTranslation } from '@holmanfm/lib/lang';
import makeStyles from '~/shared/components/makeStyles';
import Typography from '~/shared/components/atom/typography/typography';
import typeStyles from '~/shared/components/styles/global-type-styles';
import CircularProgress from '~/shared/components/circular-progress';

const useStyles = makeStyles(theme => ({
  tabGroup: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.backgroundColors.tan}`,
    marginBottom: theme.spacing(3),
    display: 'flex',
  },
  kpiSection: {
    marginRight: theme.spacing(3),
  },
}));

const sumTotalCost = (acc, obj) => {
  return obj.totalCost
    ? parseFloat(obj.totalCost.replace('$', '').replace(',', '')) + acc
    : acc;
};

const MaintKPI = props => {
  const { data, asset } = props;
  const classes = useStyles();
  const typeClasses = typeStyles();
  const { t } = useNSTranslation('individualAsset', 'maintenance');
  const completedItems = data?.filter(x => x.requestStatus === 'Completed');
  const totalCostSum = completedItems?.reduce(sumTotalCost, 0);
  const costPerMile = asset?.latest_odometer
    ? totalCostSum / asset?.latest_odometer
    : 0;

  if (!data) {
    return <CircularProgress size={24} inlineStyle />;
  }

  return (
    <div className={classes.tabGroup}>
      <div className={classes.kpiSection}>
        <Typography
          variant="subtitle2"
          className={typeClasses.driveBlue}
          style={{ marginBottom: 4 }}
        >
          {t('maintenance-cost')}
        </Typography>{' '}
        <Typography
          variant="h3"
          className={typeClasses.freshBlue}
          style={{ fontWeight: 'normal' }}
        >
          {formatPrice(totalCostSum)}
        </Typography>
      </div>
      <div className={classes.kpiSection}>
        <Typography
          variant="subtitle2"
          className={typeClasses.driveBlue}
          style={{ marginBottom: 4 }}
        >
          {t('maintenance-per-mile')}
        </Typography>
        <Typography
          variant="h3"
          className={typeClasses.freshBlue}
          style={{ fontWeight: 'normal' }}
        >
          {`$${costPerMile.toFixed(3)}`}
        </Typography>
      </div>
    </div>
  );
};

MaintKPI.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  asset: PropTypes.shape({
    latest_odometer: PropTypes.number,
  }).isRequired,
};

MaintKPI.defaultProps = {
  data: null,
};

export default MaintKPI;
