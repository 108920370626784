import makeStyles from '~/shared/components/makeStyles';

const typeStyles = makeStyles(theme => ({
  freshBlueBold: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  driveBlueBold: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  fieldGreenBold: {
    color: theme.palette.tertiary.main,
    fontWeight: 'bold',
  },
  fieldGreen: {
    color: theme.palette.tertiary.main,
  },
  freshBlue: {
    color: theme.palette.secondary.main,
  },
  driveBlue: {
    color: theme.palette.primary.main,
  },
  linkSubtitle2: {
    '& > h6': {
      fontWeight: 'bold',
    },
  },
}));

export default typeStyles;
