import React from 'react';
import PropTypes from 'prop-types';
import {
  addFile,
  deleteFile,
  getFiles,
} from '@holmanfm/lib/services/file-manager';
import FileManager from './file-manager';
import { FileType } from './prop-types';

const ResourceFileManager = ({
  resource,
  resourceId,
  prefix,
  files,
  setFiles,
  ...managerProps
}) => {
  const fetchFiles = React.useCallback(() => {
    return getFiles(resource, resourceId, prefix);
  }, [resource, resourceId, prefix]);
  return (
    <>
      <FileManager
        files={files}
        setFiles={setFiles}
        onUploadFile={file => addFile(resource, resourceId, prefix, file)}
        onDeleteFile={name => deleteFile(resource, resourceId, prefix, name)}
        fetchFiles={fetchFiles}
        {...managerProps}
      />
    </>
  );
};

ResourceFileManager.propTypes = {
  resource: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(FileType).isRequired,
  setFiles: PropTypes.func.isRequired,
  accept: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
};

ResourceFileManager.defaultProps = {
  accept: undefined,
  multiple: false,
};

export default ResourceFileManager;
