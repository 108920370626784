import React from 'react';
import PropTypes from 'prop-types';
import {
  getPage,
  getPages,
  usePaging,
  goToPage,
} from '@holmanfm/lib/use-paging';
import { useJuice } from '@holmanfm/lib/citrus-framework/juice';
import Pagination from '~/shared/components/molecule/mui-pagination/pagination';
import LemonDateRange from '~/shared/components/lemon-framework/lemon-date-range';
import ExportExcel from '~/areas/fuel/transactions/export-to-excel';

const type = 'SELECT_RANGE';
const operator = ['gte', 'lte'];
const id = 'specificRange';
const display = 'Fuel Date Range';

const filterComponentProps = {
  display,
  id,
  operator,
  type,
  'data-testid': `browse-filter-${id}`,
  key: `browse-filter-${id}`,
};

const DateRangeContainer = () => {
  return (
    <div>
      <LemonDateRange {...filterComponentProps} horizontal />
    </div>
  );
};

const PaginationLayout = props => {
  const { children, assetId } = props;
  const { skip, take, setState: setPaginationState } = usePaging();
  const { totalCount: total } = useJuice();

  const page = getPage(skip, take);
  const pages = getPages(take, total);

  const handlePageChange = React.useCallback(
    nextPage => setPaginationState(goToPage(nextPage, total)),
    [setPaginationState, total]
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <DateRangeContainer />
        <ExportExcel assetId={assetId} />
      </div>
      {children}
      <Pagination
        totalPages={pages}
        page={page}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

PaginationLayout.propTypes = {
  children: PropTypes.node.isRequired,
  assetId: PropTypes.string.isRequired,
};

export default PaginationLayout;
