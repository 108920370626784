import axios from 'axios';
import api from '../../util/api';

const getSignedUrl = async (resource, resourceId, prefix, file) => {
  const name = encodeURIComponent(file.name);
  const { type } = file;
  const response = await api.get(
    `/documents/${resource}/${resourceId}/upload-url`,
    {
      params: {
        name,
        type,
        prefix,
      },
    }
  );
  return response?.payload;
};

const uploadFile = async (file, uploadUrl) => {
  const { type } = file; // mime type is okay as is, I think.
  await axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': type,
    },
  });
};

/**
 * Delete a file
 * @param {string} resource resource name, such as 'assets'.
 * @param {string} resourceId resource id, such as uuid
 * @param {string} prefix files prefix, such as 'BuyDirect/Images'
 * @param {string} name file name
 */
export const deleteFile = async (resource, resourceId, prefix, name) =>
  api.delete(`/documents/${resource}/${resourceId}`, {
    params: {
      prefix,
      name,
    },
  });

/**
 * Get all files for a resource
 * @param {string} resource resource name, such as 'assets'.
 * @param {string} resourceId resource id, such as uuid
 * @param {string} prefix files prefix, such as 'BuyDirect/Images'
 */
export const getFiles = async (resource, resourceId, prefix) => {
  const response = await api.get(`/documents/${resource}/${resourceId}`, {
    params: { prefix },
  });
  return response.payload;
};

/**
 * Upload a new file
 * @param {string} resource resource name, such as 'assets'.
 * @param {string} resourceId resource id, such as uuid
 * @param {string} prefix files prefix, such as 'BuyDirect/Images'
 * @param {*} file uploadable by axios file object. Must have name and type properties.
 */
export const addFile = async (resource, resourceId, prefix, file) => {
  const response = await getSignedUrl(resource, resourceId, prefix, file);
  const { uploadUrl, downloadUrl, type, name } = response;
  await uploadFile(file, uploadUrl);
  return {
    downloadUrl,
    type,
    name,
  };
};
