import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'formik';
import PropTypes from 'prop-types';

const SubmitButton = ({ formik, handleSubmit, ...props }) => {
  const { disabled, ...rest } = props;
  const { isValid, isSubmitting } = formik;

  return (
    <Button
      variant="contained"
      color="secondary"
      type="submit"
      onClick={handleSubmit || formik.handleSubmit}
      disabled={disabled || isValid === false || isSubmitting}
      {...rest}
    />
  );
};

SubmitButton.propTypes = {
  formik: PropTypes.shape({
    isValid: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

SubmitButton.defaultProps = {
  disabled: undefined,
  handleSubmit: undefined,
};

export default connect(SubmitButton);
