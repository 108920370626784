import { createSelector } from 'reselect';
import { capitalizeFirstLetter } from './util';

export function createRecordReducer(entity) {
  const initialState = {
    isFetching: false,
    [entity]: null,
    messages: [],
    failed: false,
  };

  const actionEntity = entity.toUpperCase();

  return (state = initialState, action) => {
    switch (action.type) {
      case `FETCH_${actionEntity}_REQUEST`:
      case `ADD_${actionEntity}_REQUEST`:
      case `REMOVE_${actionEntity}_REQUEST`:
      case `UPDATE_${actionEntity}_REQUEST`:
        return {
          ...state,
          isFetching: true,
          messages: [],
          failed: false,
        };

      case `FETCH_${actionEntity}_SUCCESS`:
        return {
          ...state,
          isFetching: false,
          [entity]: { ...state[entity], ...action[entity] },
          messages: action.messages || [],
        };

      case `ADD_${actionEntity}_SUCCESS`:
      case `UPDATE_${actionEntity}_SUCCESS`:
        return {
          ...state,
          isFetching: false,
          [entity]: action[entity],
          messages: action.messages || [],
        };

      case `FETCH_${actionEntity}_FAILURE`:
      case `ADD_${actionEntity}_FAILURE`:
      case `REMOVE_${actionEntity}_FAILURE`:
      case `UPDATE_${actionEntity}_FAILURE`:
        return {
          ...state,
          isFetching: false,
          failed: true,
          messages: action.errors,
        };

      default:
        return state;
    }
  };
}

export function createRecordSelectors(entity) {
  const entityCap = capitalizeFirstLetter(entity);

  return {
    [`get${entityCap}`]: state => state[entity],
    [`getIsFetching${entityCap}`]: state => state.isFetching,
    [`get${entityCap}Messages`]: createSelector(
      [state => state.failed, state => state.messages],
      (failed, messages) => ({ failed, messages })
    ),
  };
}
