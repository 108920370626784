import React, { useState, useContext, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SORT_DIRECTIONS } from './util';

const SortingContext = createContext({
  field: undefined,
  direction: undefined,
  setState: f => f(),
});

export const SortingProvider = ({
  field: propsField,
  direction: propsDirection,
  ...props
}) => {
  const [state, setState] = useState(() => ({
    field: propsField,
    direction: propsDirection,
  }));
  const { field, direction } = state;
  const ctx = useMemo(() => ({ field, direction, setState }), [
    field,
    direction,
  ]);

  return <SortingContext.Provider value={ctx} {...props} />;
};

SortingProvider.propTypes = {
  field: PropTypes.string,
  direction: PropTypes.oneOf([SORT_DIRECTIONS.ASC, SORT_DIRECTIONS.DESC]),
};

SortingProvider.defaultProps = {
  field: undefined,
  direction: SORT_DIRECTIONS.ASC,
};

/**
 * @typedef {Object} PagingState
 * @property {string} sortField
 * @property {('asc'|'desc')} sortDirection
 *
 * @typedef {Object} UseSorting
 * @property {(s: PagingState) => void} setState
 * @property {string} sortField
 * @property {('asc'|'desc')} sortDirection
 */
export function useSorting() {
  const ctx = useContext(SortingContext);

  if (!ctx) {
    throw new Error(``);
  }

  return ctx;
}
