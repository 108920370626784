/* eslint-disable camelcase */
import React from 'react';
import { decodeVinAsync } from '../../services/vin-decoder';
import { updateInputs, dupeCheck } from '../../common/add-asset-helpers';

const useVinDecoding = props => {
  const [isDecodingVin, setIsDecodingVin] = React.useState(false);
  const [vinError, setVinError] = React.useState(false);
  const [showVinDecodedMessage, setShowVinDecodedMessage] = React.useState(
    false
  );
  const [isVinDecoded, setIsVinDecoded] = React.useState(
    props?.vin_decoded || false
  );

  const fetchDecodeVin = async (newVin, setFieldValue) => {
    setIsDecodingVin(true);
    setVinError(false);
    try {
      const decodedData = await decodeVinAsync(newVin);
      const decodeError = decodedData?.error;
      if (decodeError) {
        if (decodeError === 'Invalid VIN') {
          setIsDecodingVin(false);
          setShowVinDecodedMessage(true);
          setIsVinDecoded(false);
          updateInputs.forEach(d => {
            if (d === 'vin_decoded_data') {
              return setFieldValue(
                'vinDecodedData',
                JSON.parse(decodedData?.[d])
              );
            }
            if (decodedData?.[d]) {
              try {
                setIsDecodingVin(false);
                setIsVinDecoded(true);
                return setFieldValue(d, JSON.parse(decodedData?.[d]));
              } catch (err) {
                setIsDecodingVin(false);
                return setFieldValue(d, decodedData?.[d]);
              }
            }
            return setFieldValue(d, '');
          });
          // yearInput focus
          // yearInput.current.focus();
          return;
        }
        setVinError(decodeError);
        // setVinErrorMessage();
        setIsDecodingVin(false);
      }
      setIsDecodingVin(false);
      setShowVinDecodedMessage(true);
      setIsVinDecoded(true);
      updateInputs.forEach(d => {
        if (d === 'vin_decoded_data') {
          return setFieldValue('vinDecodedData', JSON.parse(decodedData?.[d]));
        }
        if (decodedData?.[d]) {
          return setFieldValue(d, decodedData?.[d]);
        }
        return setFieldValue(d, '');
      });
    } catch (err) {
      setIsVinDecoded(true);
      setIsDecodingVin(false);
      setShowVinDecodedMessage(false);
    }
  };

  const vinOnChange = async (e, values, setFieldValue) => {
    const newVin = e.target.value;
    if (/[^\w\w]/g.test(newVin) === true) {
      return;
    }
    setFieldValue('vin', newVin, true);
    if (newVin === '' || newVin.length !== 17 || values.vin === newVin) {
      setIsVinDecoded(false);
      updateInputs.forEach(d => setFieldValue(d, ''));
      return;
    }

    // dupeCheck - Test if dupeCheck fails
    const dupeCheckResults = await dupeCheck(newVin, '');

    if (!dupeCheckResults?.payload?.valid) {
      setIsVinDecoded(true);
      setIsDecodingVin(false);
      setShowVinDecodedMessage(false);
      setVinError(dupeCheckResults?.payload?.errors?.vin);
    } else {
      await fetchDecodeVin(newVin, setFieldValue);
    }
  };

  const tagNumberCheck = (e, setFieldValue) => {
    if (/^\S*$/.test(e.target.value) === true) {
      setFieldValue('tagNumber', e.target.value);
    }
  };

  return {
    fetchDecodeVin,
    vinOnChange,
    tagNumberCheck,
    isDecodingVin,
    vinError,
    showVinDecodedMessage,
    isVinDecoded,
  };
};

export default useVinDecoding;
