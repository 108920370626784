import { STRIPE_KEY } from '@holmanfm/lib/common/environment';
import PropTypes from 'prop-types';
import React from 'react';
import useScript from 'react-script-hook';
import { StripeProvider } from 'react-stripe-elements';
import ServerDownMessage from './messages/error/server-down-message';

const ConfiguredStripeProvider = ({ children }) => {
  const [loading, error] = useScript({
    src: 'https://js.stripe.com/v3',
    checkForExisting: true,
  });

  if (error) {
    return <ServerDownMessage />;
  }

  if (loading === false) {
    return <StripeProvider apiKey={STRIPE_KEY}>{children}</StripeProvider>;
  }

  return null;
};

ConfiguredStripeProvider.propTypes = {
  children: PropTypes.node,
};

ConfiguredStripeProvider.defaultProps = {
  children: undefined,
};

export default ConfiguredStripeProvider;
