import React from 'react';
import useAsync from '@holmanfm/lib/hooks/use-async';
import { getFuelKpiData } from '@holmanfm/lib/services/fuel';

const useGetFuelKpis = () => {
  const { run, data } = useAsync();

  // date structure ==> 2023-03-01

  const currentDate30DaysAgo = Date.now() - 1000 * 60 * 60 * 24 * 30;
  const monthAgo = new Date(currentDate30DaysAgo).toISOString().split('T')[0];

  React.useEffect(() => {
    run(getFuelKpiData(monthAgo).then(res => res.payload.summary));
  }, [run, monthAgo]);

  const listOfFuelTypes = data?.filter(ft => ft.description !== 'Totals');

  const costByFuelType = listOfFuelTypes?.reduce((acc, ft) => {
    const cost = data.find(d => d.description === ft.description)?.totalCost;
    if (cost) {
      acc.push({ [ft.description]: cost });
    }
    return acc;
  }, []);

  const totalFuelCost = data?.find(d => d.description === 'Totals')?.totalCost;

  const avgQuantity = data?.find(d => d.description === 'Totals')?.avgQuantity;

  const avgUnitCost = data?.find(d => d.description === 'Totals')?.avgUnitCost;

  const avgCost = data?.find(d => d.description === 'Totals')?.avgCost;

  return {
    listOfFuelTypes,
    costByFuelType,
    totalFuelCost,
    avgCost,
    avgUnitCost,
    avgQuantity,
  };
};

export default useGetFuelKpis;
