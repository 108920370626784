import { createAction } from '@reduxjs/toolkit';

export const readStart = createAction('read-start');

export const readFinish = createAction('read-finish');

export const readFail = createAction('read-fail');

export const updateStart = createAction('update-start');

export const updateFinish = createAction('update-finish');

export const updateFail = createAction('update-fail');
