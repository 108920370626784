import { useCallback } from 'react';

import { withSnackbar, useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';

export function successToastFactory(enqueueSnackbar) {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'success', ...options });
}

export function infoToastFactory(enqueueSnackbar) {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'info', ...options });
}

export function warningToastFactory(enqueueSnackbar) {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'warning', ...options });
}

export function errorToastFactory(enqueueSnackbar) {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'error', ...options });
}

export default compose(
  withSnackbar,
  withHandlers({
    toastSuccess: ({ enqueueSnackbar }) => successToastFactory(enqueueSnackbar),
    toastError: ({ enqueueSnackbar }) => errorToastFactory(enqueueSnackbar),
    toastWarning: ({ enqueueSnackbar }) => warningToastFactory(enqueueSnackbar),
    toastInfo: ({ enqueueSnackbar }) => infoToastFactory(enqueueSnackbar),
  })
);

export const WithToastProps = {
  toastSuccess: PropTypes.func.isRequired,
  toastError: PropTypes.func.isRequired,
  toastWarning: PropTypes.func.isRequired,
  toastInfo: PropTypes.func.isRequired,
};

export const useToasts = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const success = useCallback(successToastFactory(enqueueSnackbar), [
    enqueueSnackbar,
  ]);

  const error = useCallback(errorToastFactory(enqueueSnackbar), [
    enqueueSnackbar,
  ]);

  const warning = useCallback(warningToastFactory(enqueueSnackbar), [
    enqueueSnackbar,
  ]);

  const info = useCallback(infoToastFactory(enqueueSnackbar), [
    enqueueSnackbar,
  ]);

  const close = useCallback(infoToastFactory(closeSnackbar), [closeSnackbar]);

  return {
    success,
    error,
    warning,
    info,
    close,
  };
};
