import DayJSUtils from '@date-io/dayjs';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { useFiltering } from '@holmanfm/lib/use-filtering';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React from 'react';
import FormLabel from '~/shared/components/form-label';
import InputAdornment from '~/shared/components/input-adornment';
import DateRangeIcon from '~/shared/icons/date-range';
import Typography from '~/shared/components/atom/typography';
import { RangeOperator } from './lemon.prop-types';
import typeStyles from '~/shared/components/styles/global-type-styles';

const LemonSelectRange = props => {
  const { display, id, operator, horizontal, ...rest } = props;
  const { t } = useNSTranslation('browse', ['filter-component', 'datepicker']);
  const typeClasses = typeStyles();

  const { filters, addFilter, removeByOperator } = useFiltering();

  const [minOperator, maxOperator] = operator;

  const foundMinFilter = filters.find(
    f => f.id === id && f.operator === minOperator
  );
  const minValue = foundMinFilter?.value || null;

  const foundMaxFilter = filters.find(
    f => f.id === id && f.operator === maxOperator
  );
  const maxValue = foundMaxFilter?.value || null;

  const handleMinChange = value => {
    if (!value) {
      return removeByOperator(id, minOperator);
    }
    const date = value.startOf('day').toDate();

    removeByOperator(id, minOperator);
    return addFilter(id, date, minOperator);
  };

  const handleMaxChange = value => {
    if (!value) {
      return removeByOperator(id, maxOperator);
    }
    const date = value.endOf('day').toDate();
    removeByOperator(id, maxOperator);
    return addFilter(id, date, maxOperator);
  };

  if (horizontal) {
    return (
      <div {...rest}>
        <FormLabel style={{ color: 'black', fontWeight: 700 }}>
          <Typography variant="subtitle2" className={typeClasses.driveBlue}>
            {display}
          </Typography>
        </FormLabel>
        <div
          style={{
            marginTop: 8,
            marginBottom: 8,
            display: 'flex',
            maxWidth: 420,
          }}
        >
          <MuiPickersUtilsProvider utils={DayJSUtils}>
            <DatePicker
              views={['year', 'month', 'date']}
              id={`${id}-date-range-begin`}
              name="begin-date"
              label={t('after-label')}
              format="dddd, MM/DD/YYYY"
              value={minValue}
              onChange={handleMinChange}
              fullWidth
              clearable
              inputVariant="outlined"
              DialogProps={{
                'data-testid': 'after-date-dialog',
              }}
              inputProps={{
                className: 'browser-default',
                style: { marginRight: 8 },
              }}
              style={{ marginRight: 16 }}
              // eslint-disable-next-line
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DateRangeIcon />
                  </InputAdornment>
                ),
              }}
            />
            <DatePicker
              views={['year', 'month', 'date']}
              id={`${id}-date-range-end`}
              name="end-date"
              label={t('before-label')}
              format="dddd, MM/DD/YYYY"
              value={maxValue}
              onChange={handleMaxChange}
              fullWidth
              clearable
              inputVariant="outlined"
              DialogProps={{
                'data-testid': 'before-date-dialog',
              }}
              inputProps={{
                className: 'browser-default',
              }}
              // eslint-disable-next-line
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DateRangeIcon />
                  </InputAdornment>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    );
  }

  return (
    <div {...rest}>
      <FormLabel style={{ color: 'black', fontWeight: 700 }}>
        <Typography variant="h6">{display}</Typography>
      </FormLabel>
      <div style={{ marginTop: 8, display: 'flex' }}>
        <MuiPickersUtilsProvider utils={DayJSUtils}>
          <div>
            <DatePicker
              views={['year', 'month', 'date']}
              id={`${id}-date-range-begin`}
              name="begin-date"
              label={t('after-label')}
              format="dddd, MM/DD/YYYY"
              value={minValue}
              onChange={handleMinChange}
              fullWidth
              DialogProps={{
                'data-testid': 'after-date-dialog',
              }}
              inputProps={{
                className: 'browser-default',
              }}
            />
            <DatePicker
              views={['year', 'month', 'date']}
              id={`${id}-date-range-end`}
              name="end-date"
              label={t('before-label')}
              format="dddd, MM/DD/YYYY"
              value={maxValue}
              onChange={handleMaxChange}
              fullWidth
              DialogProps={{
                'data-testid': 'before-date-dialog',
              }}
              inputProps={{
                className: 'browser-default',
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

LemonSelectRange.propTypes = {
  display: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  operator: RangeOperator.isRequired,
  horizontal: PropTypes.bool,
};

LemonSelectRange.defaultProps = {
  horizontal: false,
};

export default LemonSelectRange;
