/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import api from '../../util/api';
import { appendQueryParams } from '../../hooks/assets/util';

export const NoteShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
});

export const MaintenanceRepairDetailsShape = PropTypes.shape({
  id: PropTypes.string,
  ataCode: PropTypes.string,
  ataDesc: PropTypes.string,
  cause: PropTypes.string,
  complaint: PropTypes.string,
  correction: PropTypes.string,
  description: PropTypes.string,
  extendedCost: PropTypes.string,
  quantity: PropTypes.string,
  repairTypeCode: PropTypes.string,
  repairTypeDescription: PropTypes.string,
  unitCost: PropTypes.string,
  notes: PropTypes.arrayOf(NoteShape),
  note: PropTypes.string,
});

export const MaintenanceRepairRejectionShape = PropTypes.shape({
  ruleId: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
});

export const MaintenanceRepairAssetShape = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  assetNo: PropTypes.string.isRequired,
  bodyType: PropTypes.string,
  calculatedOdometer: PropTypes.string,
  customerAssetStatus: PropTypes.string,
  latestOdometer: PropTypes.number,
  odometerSource: PropTypes.string,
  id: PropTypes.string.isRequired,
  make: PropTypes.string,
  model: PropTypes.string,
  tagNo: PropTypes.string,
  vehicleType: PropTypes.string,
  vin: PropTypes.string.isRequired,
  year: PropTypes.number,
});

export const MaintenanceRepairItemsShape = PropTypes.shape({
  hasNotes: PropTypes.bool,
  id: PropTypes.string,
  odometer: PropTypes.number,
  odometerMiles: PropTypes.number,
  repairDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  requestId: PropTypes.string,
  requestStatus: PropTypes.string,
  requestedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  status: PropTypes.string,
  totalCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  headerCreatedAt: PropTypes.string,
  headerCreatedBy: PropTypes.string,
  headerUpdatedAt: PropTypes.string,
  headerUpdatedBy: PropTypes.string,
  supplierPoNumber: PropTypes.string,
  vendor: PropTypes.shape({}),
  maintenanceRepairDetails: PropTypes.arrayOf(MaintenanceRepairDetailsShape),
  notes: PropTypes.arrayOf(NoteShape),
});

export const getMaintenanceHistory = (assetNo, config) =>
  api.get(`/maint-repair/asset/${assetNo}`, config);

export const getAllMaintenanceHistory = () => api.get('/maint-repair/assets');

export const getMaintenanceHistoryExcelUrl = (
  requestedAtMin = undefined,
  requestedAtMax = undefined,
  totalCostRange = undefined,
  repairTypeDescription = undefined,
  vendorName = undefined,
  vendorType = undefined,
  requestStatus = undefined,
  sortDirection = undefined,
  sortField = undefined,
  format = 'xlsx'
) => {
  const query = new URLSearchParams({ format });
  if (requestedAtMin) {
    appendQueryParams(query, 'requestedAt~gte', requestedAtMin);
  }
  if (requestedAtMax) {
    appendQueryParams(query, 'requestedAt~lte', requestedAtMax);
  }
  if (totalCostRange) {
    appendQueryParams(query, 'totalCostRange', totalCostRange);
  }
  if (repairTypeDescription) {
    appendQueryParams(query, 'repairTypeDescription', repairTypeDescription);
  }
  if (vendorName) {
    appendQueryParams(query, 'vendorName', vendorName);
  }
  if (vendorType) {
    appendQueryParams(query, 'vendorType', vendorType);
  }
  if (requestStatus) {
    appendQueryParams(query, 'requestStatus', requestStatus);
  }

  if (sortDirection) {
    appendQueryParams(query, 'sortDirection', sortDirection);
  }

  if (sortField) {
    appendQueryParams(query, 'sortField', sortField);
  }

  return `/maint-repair/assets?${query}`;
};
