import React from 'react';

export default (initialState = false) => {
  const [open, setOpen] = React.useState(initialState);

  return {
    open,
    openDialog: () => setOpen(true),
    closeDialog: () => setOpen(false),
  };
};
