import { createSelector } from 'reselect';

import { PaymentCustomerActionTypes } from '../actions/action-types';

const {
  UPDATE_PAYMENT_CUSTOMER_FAILURE,
  UPDATE_PAYMENT_CUSTOMER_REQUEST,
  UPDATE_PAYMENT_CUSTOMER_SUCCESS,

  FETCH_PAYMENT_CUSTOMER_FAILURE,
  FETCH_PAYMENT_CUSTOMER_REQUEST,
  FETCH_PAYMENT_CUSTOMER_SUCCESS,

  UPDATE_DEFAULT_PAYMENT_SOURCE,
} = PaymentCustomerActionTypes;

export const initialState = {
  isFetching: false,
  messages: [],
  paymentCustomer: {},
  failed: false,
};

const paymentCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DEFAULT_PAYMENT_SOURCE:
      return {
        ...state,
        default_payment_source_id: action.payload,
      };

    case FETCH_PAYMENT_CUSTOMER_REQUEST:
    case UPDATE_PAYMENT_CUSTOMER_REQUEST:
      return {
        ...state,
        isFetching: true,
        messages: [],
        failed: false,
      };

    case FETCH_PAYMENT_CUSTOMER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        paymentCustomer: action.paymentCustomer,
        messages: action.messages || [],
      };

    case UPDATE_PAYMENT_CUSTOMER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        paymentCustomer: action.paymentCustomer,
        messages: action.messages || [],
      };

    case FETCH_PAYMENT_CUSTOMER_FAILURE:
    case UPDATE_PAYMENT_CUSTOMER_FAILURE:
      return {
        ...state,
        isFetching: false,
        failed: true,
        messages: action.errors,
      };

    default:
      return state;
  }
};

export default paymentCustomerReducer;

export const getPaymentCustomer = state => state.paymentCustomer;
export const getIsFetchingPaymentCustomer = state => state.isFetching;
export const getPaymentCustomerMessages = createSelector(
  [state => state.failed, state => state.messages],
  (failed, messages) => ({ failed, messages })
);
