/**
 * Trivial class that stores sentry object.
 * It is required because there are different sentry instances in web and in mobile
 */
export default class SentryProvider {
  static sentry = null;

  static setSentry(sentry) {
    this.sentry = sentry;
  }

  static getSentry() {
    return this.sentry;
  }
}
