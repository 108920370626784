/**
 * Just to keep this consistent everywhere I'm taking the material ui color scheme out of play
 * By referencing just hex we can use these values in the mobile app as well.
 * https://material-ui.com/customization/color/
 */

// New Colors
const freshBlue = '#0069A7';
const freshBlueLight = '#b3d2e5';
const freshBlueBg = '#F2F4F7';
const driveBlue = '#003057';
const driveBlueDark = '#002033';
const neutralBg = '#F9F4ED';
const fieldGreen = '#2C9942';
const yellow = '#F7B500';

// currently used by mobile - no longer used in web
// leaving for mobile to update to new colors - remove when updated
const blue = '#0d47a1';
const lightBlue = '#03a9f4';
//

// new chart colors
const tier1 = driveBlue;
const tier2 = freshBlue;
const tier3 = fieldGreen;
const tier4 = '#C2BAAE';
const tier5 = '#808080';
const tier6 = '#004A75';
//

const white = '#ffffff';
const red1 = '#B00020';

const sharedPalette = {
  primary: {
    main: driveBlue,
    dark: driveBlueDark,
  },
  secondary: {
    main: freshBlue,
    light: freshBlueLight,
  },
  tertiary: {
    main: fieldGreen,
  },
  grey: {
    greyLight: '#e6e6e6',
    greyMedium: '#bfbfbf',
    greyDark: '#191919',
  },
  backgroundColors: {
    tan: neutralBg,
    blue: freshBlueBg,
    body: '#ffffff',
  },
  error: {
    main: red1,
  },
  warning: {
    main: yellow,
  },
  success: {
    main: '#008000',
    light: '#69cc77',
    dark: '#006b1f',
  },
  body: 'rgba(0, 0, 0, 0.87)',
  chartColors: {
    tier1,
    tier2,
    tier3,
    tier4,
    tier5,
    tier6,
  },
  metricSummaryChartColors: [tier1, tier2, tier3, tier4, tier5, tier6],
  pastDue: {
    main: tier2,
    contrastText: white,
  },
  due30: {
    main: tier1,
    contrastText: white,
  },
  due60: {
    main: tier3,
    contrastText: white,
  },
  due90: {
    main: tier6,
    contrastText: white,
  },
  duePast90: {
    main: tier4,
    contrastText: white,
  },
  missingData: {
    main: tier5,
    contrastText: white,
  },
  /** The following colors are use by preventive maintenance. */
  requestUnassigned: {
    main: '#E02020',
  },
  requestOutToBid: {
    main: '#949494',
  },
  requestAssigned: {
    main: '#00B0FF',
  },
  requestStarted: {
    main: '#F7B500',
  },
  requestCompleted: {
    main: '#6DD400',
  },
  blueGrey: {
    base: '#607d8b',
  },
  // follows the FM-StyleGuide-v1.pdf Tints and Shades format
  neutral: {
    base: '#F3E8DA',
    light30: '#f7efe5',
    dark30: '#aaa299'
  },
  // Called commonColors instead of common to avoid clashing with MUI defaults
  commonColors: {
    black: '#000000',
    disabled: '#D8D8D8',
  },
};

const CommonColors = {
  blue,
  lightBlue,
  fieldGreen,
  sharedPalette,
};

export default CommonColors;
