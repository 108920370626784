import BusIcon from '../assets/icons/stock-landing/bus.svg';
import CarIcon from '../assets/icons/stock-landing/car.svg';
import GenericIcon from '../assets/icons/stock-landing/missing-image.svg';
import PickupIcon from '../assets/icons/stock-landing/pickup.svg';
import SuvIcon from '../assets/icons/stock-landing/suv.svg';
import TruckIcon from '../assets/icons/stock-landing/truck.svg';
import VanIcon from '../assets/icons/stock-landing/van.svg';

function getTruckIcon(assetVehicleBodyType = '') {
  const normalizedAssetVehicleBodyType = assetVehicleBodyType
    .toLowerCase()
    .trim();

  switch (normalizedAssetVehicleBodyType) {
    case 'pickup':
      return PickupIcon;

    default:
      return TruckIcon;
  }
}

function getVehicleAssetIcon(assetType = '', assetVehicleBodyType) {
  const normalizedAssetType = assetType.toLowerCase().trim();
  switch (normalizedAssetType) {
    case 'car':
    case 'passenger car':
      return CarIcon;
    case 'truck':
    case 'truck md':
    case 'truck ld':
    case 'truck hd':
      return getTruckIcon(assetVehicleBodyType);
    case 'multipurpose passenger vehicle (mpv)':
    case 'multipurpose passenger vehicle mpv':
    case 'suv':
      return SuvIcon;
    case 'van':
      return VanIcon;
    case 'bus':
      return BusIcon;
    default:
      return GenericIcon;
  }
}

const AssetIconUtils = {
  getTruckIcon,
  getVehicleAssetIcon,
};

export default AssetIconUtils;
