import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import Typography from '~/shared/components/atom/typography/typography';

const GenericNoDisplay = props => {
  const { category } = props;
  const { t } = useNSTranslation('individualAsset', 'generic');
  return (
    <div>
      <Typography variant="body2">
        {t('no')} {category} {t('to-display')}
      </Typography>
    </div>
  );
};

GenericNoDisplay.propTypes = {
  category: PropTypes.string.isRequired,
};

export default GenericNoDisplay;
