import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 'bold',
    fontSize: 'inherit',
    letterSpacing: 'inherit',
    textTransform: 'none',
    lineHeight: 'inherit',
    minWidth: 'unset',
    padding: 0,
    verticalAlign: 'inherit',
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    },
  },
  label: {
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'underline',
    },
  },
}));

/**
 * Use when you want a text "link" to navigate or interact with the DOM programatically
 * (without HREF).
 *
 * @example
 *   <TextLink onClick={navigateSomewhere}>Go</TextLink>
 *   <TextLink onClick={openModal}>Go</TextLink>
 */
const LinkText = props => {
  const classes = useStyles();

  return (
    <Button
      variant="text"
      disableRipple
      color="secondary"
      classes={classes}
      {...props}
    />
  );
};

export default LinkText;
