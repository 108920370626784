import React from 'react';
import useAsync from '../use-async';
import { ErrorResponseHandler } from '../../util/api';
import { useNSTranslation } from '../../lang';

const useFileManager = ({
  files,
  setFiles,
  fetchFiles,
  onUploadFile,
  onDeleteFile,
  maxFileSizeInBytes,
  accept,
  multiple,
  inputRef,
}) => {
  const { run, status, error } = useAsync();
  const { t } = useNSTranslation('common', 'file-manager.validation');

  React.useEffect(() => {
    run(fetchFiles().then(apiFiles => setFiles(apiFiles)));
    // I hate to ignore it, but when manager is used in forms driver, setFiles changes on every input change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFiles, run]);

  const validateFile = file => {
    if (file.size > maxFileSizeInBytes) {
      throw ErrorResponseHandler.createError(
        t('file-size', { size: maxFileSizeInBytes * 1e-6 })
      );
    }
    if (accept && !accept.includes(file.type)) {
      throw ErrorResponseHandler.createError(
        t('supported-file-types', { types: accept.join(', ') })
      );
    }
    return null;
  };

  const handleUpload = e =>
    run(
      (async () => {
        const { files: rawFiles } = e.target;
        if (rawFiles?.length > 0) {
          let newFiles = [...files];
          if (!multiple && files.length === 1) {
            await onDeleteFile(files[0].name);
            newFiles = [];
          }

          await Promise.all(
            Array.from(rawFiles).map(async rawFile => {
              validateFile(rawFile);
              const { downloadUrl, type, name } = await onUploadFile(rawFile);
              const index = newFiles.findIndex(f => f.name === rawFile.name);
              if (index >= 0) {
                newFiles[index] = { downloadUrl, type, name };
              } else {
                newFiles.push({ downloadUrl, type, name });
              }
            })
          );

          setFiles(newFiles);

          // Reset html value so that user can select the same file again
          inputRef.current.value = '';
        }
      })()
    );

  const handleRemoveFile = fileName =>
    run(
      (async () => {
        const updatedFiles = [...files];
        const index = updatedFiles.findIndex(f => f.name === fileName);
        if (index >= 0) {
          await onDeleteFile(fileName);
          updatedFiles.splice(index, 1);
          setFiles(updatedFiles);
        }
      })()
    );

  return {
    handleUpload,
    handleRemoveFile,
    status,
    error,
  };
};

export default useFileManager;
