import {
  findUnassignedTransition,
  getTransitionForUpdate,
  getVendorIdForUpdate,
} from '../../common/request-status-helpers';
import { toPostgresDate } from '../../util/moment';
import { handleSubmitting } from '../../util/form';

export const makeHandleOverviewSubmit = (updateHandler, data, flushCache) => {
  const { request, requestVendor, statuses } = data;
  return handleSubmitting(async (values, actions) => {
    const { dueAt, vendorId, instructions, transition, startedDate } = values;
    const { resetForm } = actions;

    const currStatus = statuses.find(({ name }) => name === request.status);

    if (!currStatus) {
      return;
    }

    const unassignTransition = currStatus.transitions.find(
      findUnassignedTransition
    );

    const params = {
      dueDate: dueAt,
      vendorId: getVendorIdForUpdate(
        transition,
        currStatus.transitions,
        vendorId
      ),
      instructions,
      transition: getTransitionForUpdate(
        requestVendor,
        vendorId,
        transition,
        unassignTransition
      ),
      startedDate: startedDate ? toPostgresDate(startedDate) : undefined,
    };
    await updateHandler(params);
    flushCache();
    resetForm();
  });
};

export const isDetailsFormEnabled = state =>
  state?.data?.request?.approvalStatus === 'pending-approval' &&
  state?.data?.request?.status === 'started';

export const isChangeDisabled = (detail, state) => {
  return !isDetailsFormEnabled(state) || detail.approved === true;
};

export const isEveryChangeDisabled = state =>
  state.data.maintenance.maintenanceRepairDetails.every(detail =>
    isChangeDisabled(detail, state)
  );

/**
 * Sample input data shape:
 {
    2bc3ae40-6575-45f3-9c4c-b33a642f808f: false
    5f790b80-f531-4475-aca0-ab71da5b0384: true
    973e02bf-4345-42b3-82cf-fccfab7b57ec: false
    79778e5e-2ffe-45a6-a71c-e07c37778e0f: true
    766789ee-278b-4996-917c-3dd739159915: false
    ed442b8a-1691-46d8-bfd0-a78a947b7d46: true
    notes: {
      2bc3ae40-6575-45f3-9c4c-b33a642f808f: "three"
      973e02bf-4345-42b3-82cf-fccfab7b57ec: "two"
    }
  }
  * Sample output data shape:
  updateDetails: {
    [
      {
        id: '2bc3ae40-6575-45f3-9c4c-b33a642f808f',
        approved: false,
        note: 'three',
      },
      {
        id: '5f790b80-f531-4475-aca0-ab71da5b0384',
        approved: true,
      },
      ... (for each id in the root object.)
    ]
  }
 */
const repackageFormikStateForApi = (formikState, state) => {
  const result = {
    updateDetails: [],
  };
  // filter "in" keys from formikState where the id matches dbItems that are null.
  //  That produces true PATCH, where only updated line items are sent to the API.
  Object.keys(formikState).forEach(key => {
    const dbItem = state.data.maintenance.maintenanceRepairDetails.find(
      mrdItem => mrdItem.id === key
    );
    if (key !== 'notes' && !isChangeDisabled(dbItem, state)) {
      // assume it is a uuid.
      result.updateDetails.push({
        id: key,
        approved: formikState[key],
        // 'note' could be undefined if there were no rejection notes added.
        // If 'note' is defined, but a uuid of the line item is not present,
        // 'note' will not be reprsented in outgoing json payload (this is desired).
        note: formikState.notes?.[key],
      });
    }
  });
  return result;
};

export const makeHandleDetailsSubmit = (updateHandler, state, flushCache) => {
  return handleSubmitting(async (values, actions) => {
    const { resetForm } = actions;
    await updateHandler(repackageFormikStateForApi(values, state));
    flushCache();
    resetForm();
  });
};
