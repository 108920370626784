/* eslint-disable import/prefer-default-export,react/forbid-foreign-prop-types */
import PropTypes from 'prop-types';
import { ServiceRequestShape } from '../services/service-request';

export const Rooftop = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  lat: PropTypes.number,
  long: PropTypes.number,
});

export const OrgData = PropTypes.shape({
  organizationName: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      phoneOffice: PropTypes.string.isRequired,
      phoneMobile: PropTypes.string.isRequired,
      // add more when needed
    })
  ).isRequired,
  address: PropTypes.arrayOf(
    PropTypes.shape({
      street1: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
      // add more when needed
    })
  ).isRequired,
});

export const Org = PropTypes.shape({
  id: PropTypes.string.isRequired,
  data: OrgData.isRequired,
  openRequestsCount: PropTypes.number,
});

export const Payments = PropTypes.shape({
  payments: PropTypes.object,
});

export const Stripe = PropTypes.shape({
  stripe: PropTypes.object,
});

export const User = PropTypes.shape({
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phone: PropTypes.string,
  // TODO: add more here as necessary. No need to add the token or cognito stuff yet.
});

export const MapProvider = PropTypes.shape({
  get: PropTypes.func,
  create: PropTypes.func,
});

export const MapMarkersMapper = PropTypes.shape({
  getTelemetry: PropTypes.func,
  getIcon: PropTypes.func,
  generateInfoContent: PropTypes.func,
});

export const Document = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  completed: PropTypes.bool,
});

export const ServerMessages = PropTypes.shape({
  failed: PropTypes.bool,
  messages: PropTypes.array,
});

export const MpData = PropTypes.shape({
  totalCount: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
});

export const viewPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const MpView = PropTypes.shape({
  name: PropTypes.string.isRequired,
  template: PropTypes.string,
  main: PropTypes.shape({
    propTypes: PropTypes.objectOf(viewPropType).isRequired,
    details: PropTypes.object,
    labels: PropTypes.object,
  }),
});

export const Route = PropTypes.shape({
  route: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.elementType,
  ]).isRequired,
  register: PropTypes.bool.isRequired,
  exact: PropTypes.bool.isRequired,
});

export const AccordionView = PropTypes.shape({
  accordionView: PropTypes.array,
  view: PropTypes.object,
  data: PropTypes.object,
  collapsible: PropTypes.object.isRequired,
});

export const AuthDetails = PropTypes.shape({
  logo: PropTypes.string,
});

/** ************* WIDGETS ************* */

export const DashboardWidget = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  template: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
});

export const MapFilterWidget = PropTypes.shape({
  name: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
});

// React-Native navigation prop type used throughout mobile app
export const Navigation = PropTypes.shape({
  navigate: PropTypes.func,
  getScreenProps: PropTypes.func,
  state: PropTypes.shape({
    routeName: PropTypes.string,
    key: PropTypes.string,
  }),
});

// React-Native Screen Prop
export const ScreenProps = PropTypes.shape({
  checkedJWT: PropTypes.bool.isRequired,
});

// React-Native mobile dimension App Prop Typeß
export const MobileDimensions = PropTypes.shape({
  window: PropTypes.shape({
    scale: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    fontScale: PropTypes.number.isRequired,
  }),
  screen: PropTypes.shape({
    scale: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    fontScale: PropTypes.number.isRequired,
  }),
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isPortrait: PropTypes.bool.isRequired,
});

export const RouterProps = {
  history: PropTypes.shape({
    /** There's more, I just didnt type them yet. */
    push: PropTypes.func.isRequired,
  }),

  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    state: PropTypes.shape({}),
    key: PropTypes.string,
  }),

  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.shape({}).isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

const errorPropTypesFromValues = values =>
  Object.keys(values).reduce((result, key) => {
    result[key] = PropTypes.string;
    return result;
  }, {});

const touchedPropTypesFromValues = values =>
  Object.keys(values).reduce((result, key) => {
    result[key] = PropTypes.bool;
    return result;
  }, {});

export const FormikShape = (values, status) => ({
  /** Formik State */
  /** @todo Programatically generate the following. */
  // initialValues: PropTypes.shape({}),
  // errors: PropTypes.shape({}),
  // touched: PropTypes.shape({}),
  values: PropTypes.shape(values),
  status: PropTypes.shape(status),
  errors: PropTypes.shape(errorPropTypesFromValues(values)),
  touched: PropTypes.shape(touchedPropTypesFromValues(values)),
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  isValidating: PropTypes.bool,
  submitCount: PropTypes.number,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,

  /** Formik Handlers */
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handlReset: PropTypes.func,
  registerField: PropTypes.func,
  resetForm: PropTypes.func,
  setError: PropTypes.func,
  setErrors: PropTypes.func,
  setFieldError: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFormikState: PropTypes.func,
  setStatus: PropTypes.func,
  setSubmitting: PropTypes.func,
  setTouched: PropTypes.func,
  setValues: PropTypes.func,
  submitForm: PropTypes.func,
  unregisterField: PropTypes.func,
  validateField: PropTypes.func,
  validateForm: PropTypes.func,
});

export const BillingInformationShape = PropTypes.shape({
  'cc-name': PropTypes.string,
  'address-line1': PropTypes.string,
  'address-line2': PropTypes.string,
  'address-level1': PropTypes.string,
  'address-level2': PropTypes.string,
  'postal-code': PropTypes.string,
});

/** @todo */
export const PaymentCustomerShape = PropTypes.shape({});

/** @todo */
export const PaymentAccountShape = PropTypes.shape({});

export const PaymentSourceShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  type: PropTypes.oneOf(['bank_account', 'card']),
  payment_token: PropTypes.string.isRequired,

  paymentAccount: PaymentAccountShape,
  payment_account_id: PropTypes.string,
  payment_customer_id: PropTypes.string.isRequired,
  paymentCustomer: PaymentCustomerShape.isRequired,

  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
});

export const TrainingModule = PropTypes.shape({
  id: PropTypes.string.isRequired,
  unitPrice: PropTypes.string,
  moduleName: PropTypes.string.isRequired,
  lmsModuleId: PropTypes.string,
  modules: PropTypes.arrayOf(PropTypes.shape()),
});

export const OrgTrainingModule = PropTypes.shape({
  trainingModuleId: PropTypes.string,
  bundle: PropTypes.string,
  moduleName: PropTypes.string,
  availableQuantity: PropTypes.number,
  purchasedQuantity: PropTypes.number,
  unitPrice: PropTypes.string,
});

export const OrgTrainingBundle = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isBundle: PropTypes.bool,
  moduleName: PropTypes.string.isRequired,
  modules: PropTypes.arrayOf(OrgTrainingModule),
  unitPrice: PropTypes.string.isRequired,
});

export const BundledOrgTrainingModule = PropTypes.oneOfType([
  OrgTrainingModule,
  OrgTrainingBundle,
]);

export const ThirdPartyResponse = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

export const PaymentCustomerType = PropTypes.shape({
  created_at: PropTypes.string,
  currency: PropTypes.string,
  default_payment_source_id: PropTypes.string,
  id: PropTypes.string,
  org_id: PropTypes.string,
  org_node_id: PropTypes.string,
  paymentSources: PropTypes.arrayOf(PropTypes.shape()),
  third_party_id: PropTypes.string,
  third_party_response: ThirdPartyResponse,
  updated_at: PropTypes.string,
});

export const vinDecodedData = PropTypes.shape({
  count: PropTypes.number,
  message: PropTypes.string,
  results: PropTypes.arrayOf({}),
  searchCriteria: PropTypes.string,
});

export const assetData = PropTypes.shape({
  active: PropTypes.bool,
  asset_no: PropTypes.string,
  asset_type: PropTypes.string,
  body_type: PropTypes.string,
  created_at: PropTypes.string,
  created_by: PropTypes.string,
  curb_weight_lb: PropTypes.number,
  customer_asset_status: PropTypes.string,
  device_id: PropTypes.string,
  door: PropTypes.number,
  driveline_type: PropTypes.string,
  engine_cylinder: PropTypes.string,
  engine_type: PropTypes.string,
  entry_date: PropTypes.string,
  exterior_color: PropTypes.string,
  fuel_type: PropTypes.string,
  full_hierarchy: PropTypes.string,
  id: PropTypes.string,
  in_service_date: PropTypes.string,
  interior_color: PropTypes.string,
  invoice_no: PropTypes.string,
  invoice_price: PropTypes.string,
  label1: PropTypes.string,
  label2: PropTypes.string,
  label3: PropTypes.string,
  last_active_date: PropTypes.string,
  license_plate: PropTypes.string,
  license_state: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  msrp: PropTypes.string,
  on_subscription: PropTypes.bool,
  openRequestsCount: PropTypes.number,
  org_id: PropTypes.string,
  org_node_id: PropTypes.string,
  photo_url: PropTypes.string,
  registration_renew_date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  rent_ready_date: PropTypes.string,
  seat: PropTypes.number,
  latest_odometer: PropTypes.number,
  series: PropTypes.string,
  sold_date: PropTypes.string,
  stock_no: PropTypes.string,
  stock_type_id: PropTypes.string,
  subscription_eligible: PropTypes.bool,
  third_party_asset_id: PropTypes.string,
  third_party_client_code: PropTypes.string,
  third_party_client_location: PropTypes.string,
  third_party_data_source: PropTypes.string,
  trim: PropTypes.string,
  updated_at: PropTypes.string,
  updated_by: PropTypes.string,
  use_vin_decoder: PropTypes.bool,
  vehicle_class: PropTypes.string,
  vehicle_type: PropTypes.string,
  vin: PropTypes.string,
  vin_decoded: PropTypes.bool,
  vin_decoded_data: PropTypes.oneOfType([PropTypes.string, vinDecodedData]),
  year: PropTypes.number,
});

const ModifiedServiceRequest = {
  ...ServiceRequestShape,
  dueAt: PropTypes.instanceOf(Date),
};

export const ServiceRequestRowProps = {
  ...ModifiedServiceRequest,
};

export const ServiceRequestRowMobileProps = {
  ...ModifiedServiceRequest,
};
