import { useEffect } from 'react';
import {
  getDealerMembers,
  getOrgInfo,
  getSavedEstimate,
} from '../../services/payment-estimator';
import useAsync from '../use-async';

function useDealerQuoteRequest(workflowId, isDealerGroup) {
  const {
    data: savedEstimate,
    run: runSavedEstimate,
    error: errorSavedEstimate,
    status: statusSavedEstimate,
  } = useAsync();
  useEffect(() => {
    runSavedEstimate(getSavedEstimate(workflowId).then(res => res.payload));
  }, [runSavedEstimate, workflowId]);
  const savedEstimateRequest = savedEstimate?.data?.request;

  const { data: orgData, run: runOrgData, error: errorOrgData } = useAsync();
  useEffect(() => {
    if (savedEstimateRequest?.org?.orgId) {
      runOrgData(
        getOrgInfo(savedEstimateRequest?.org?.orgId).then(res => res.payload)
      );
    } else if (savedEstimateRequest) {
      // to display new client data in the resumed-estimator page
      // need to match the values saved from NewClientDialog to existing client orgData fields
      runOrgData(
        Promise.resolve({
          data: {
            organizationName: savedEstimateRequest?.org?.organizationName,
            contacts: [
              {
                ...savedEstimateRequest?.org,
                email: savedEstimateRequest?.org?.emailAddress,
                phoneMobile: savedEstimateRequest?.org?.mobileNumber,
                phoneOffice: savedEstimateRequest?.org?.officeNumber,
                phoneFax: savedEstimateRequest?.org?.faxNumber,
              },
            ],
          },
        })
      );
    }
  }, [runOrgData, savedEstimateRequest]);

  const {
    data: dealerMembers,
    run: runDealerMembers,
    error: errorDealerMembers,
  } = useAsync();
  useEffect(() => {
    // isDealerGroup is passed in from PaymentEstimatorQuoteRequest, required to select a child dealer/dealer member
    if (isDealerGroup) {
      runDealerMembers(getDealerMembers().then(res => res.payload));
    }
  }, [runDealerMembers, isDealerGroup]);

  return {
    dealerMembers,
    savedEstimate,
    savedEstimateRequest,
    orgData,
    errorSavedEstimate,
    errorOrgData,
    errorDealerMembers,
    statusSavedEstimate,
  };
}

export default useDealerQuoteRequest;
