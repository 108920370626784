export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const isOrderable = ({ order }) => Boolean(order);

export const toggleSortDirection = () => state => {
  const { direction, ...rest } = state;

  return {
    ...rest,
    direction:
      direction === SORT_DIRECTIONS.ASC
        ? SORT_DIRECTIONS.DESC
        : SORT_DIRECTIONS.ASC,
  };
};

export const setSortField = nextField => state => {
  const { field, ...rest } = state;

  return field !== nextField ? { ...rest, field: nextField } : state;
};
