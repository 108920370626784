import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useAceControls } from '@holmanfm/lib/hooks/ace';
import { useNSTranslation } from '@holmanfm/lib/lang';

const ACEBanner = () => {
  const { t } = useNSTranslation('customerService', 'ace.component');
  const history = useHistory();
  const {
    isAceSessionActive,
    statusMessage,
    stopAceSession,
  } = useAceControls();
  if (!isAceSessionActive) {
    return null;
  }
  return (
    // TODO: looking for help/advice styling this component. It's ugly on purpose to catch attention.
    <div
      id="ace-banner"
      style={{ backgroundColor: '#FF0F00', textAlign: 'center' }}
    >
      <span style={{ color: '#FFF' }}>{statusMessage}</span>
      <br />
      <button
        type="button"
        onClick={async () => {
          await stopAceSession();
          history.push('/my-account');
        }}
      >
        {t('button-stop')}
      </button>
    </div>
  );
};

export default ACEBanner;
