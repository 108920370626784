import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { formatMoney } from '@holmanfm/lib/common/marketplace-helpers';
import { ANNUAL_MILEAGE_DISPLAY_VALUES } from '@holmanfm/lib/common/constants';
import LeasingOptionsChart from '~/areas/dealer-portal/payment-estimator/leasing-options-chart';
import Typography from '~/shared/components/atom/typography';
import makeStyles from '~/shared/components/makeStyles';
import typeStyles from '~/shared/components/styles/global-type-styles';

const useStyles = makeStyles(theme => ({
  optionsHeader: {
    background: theme.palette.backgroundColors.tan,
    padding: theme.spacing(3),
  },
  internalChartHolder: {
    border: `1px solid #F3E8DA`,
    padding: theme.spacing(4),
  },
}));

const LeasingChart = props => {
  const {
    leasingOptions,
    estimateLeasingOptions,
    assetInformation,
    updateSelection,
    chosenOptions,
    quoteSubmitted,
    chartTitle,
    selectedLease,
    setSelectedLease,
  } = props;
  const classes = useStyles();
  const typeClasses = typeStyles();
  const { t } = useNSTranslation('dealerPortal', 'payment-estimator');
  const { year, make, model, trim, vin } = assetInformation;
  const {
    annualMileage,
    financeAmount,
    downPayment,
    additionalFI,
    upfit,
    rateMarkup,
    taxRate,
    capitalizeTax,
    acqFee,
  } = estimateLeasingOptions;

  const optByTerm = leasingOptions.reduce((group, option) => {
    const { term } = option;
    group[term] = group[term] ?? [];
    group[term].push(option);
    return group;
  }, {});

  return (
    <div style={{ marginTop: 32 }}>
      {chartTitle && (
        <Typography className={typeClasses.driveBlueBold} variant="h6">
          {chartTitle}
        </Typography>
      )}
      <div className={classes.optionsHeader}>
        <div>
          <Typography variant="h5">
            {year} {make} {model} {trim}
          </Typography>
          <Typography variant="body2" style={{ marginBottom: 8 }}>
            {vin}
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography
              variant="subtitle1"
              style={{ marginBottom: 8 }}
              className={typeClasses.driveBlue}
            >
              {t('annual-mileage')}
            </Typography>
            <Typography variant="body2">
              {ANNUAL_MILEAGE_DISPLAY_VALUES[annualMileage]?.label}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ marginBottom: 8 }}
              className={typeClasses.driveBlue}
            >
              {t('financed-amount')}
            </Typography>
            <Typography variant="body2">
              {formatMoney(financeAmount)}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ marginBottom: 8 }}
              className={typeClasses.driveBlue}
            >
              {t('down-payment')}
            </Typography>
            <Typography variant="body2">
              {downPayment === 0 ? '$0' : formatMoney(downPayment)}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ marginBottom: 8 }}
              className={typeClasses.driveBlue}
            >
              {t('additional-fi')}
            </Typography>
            <Typography variant="body2">
              {additionalFI === 0 ? '$0' : formatMoney(additionalFI)}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ marginBottom: 8 }}
              className={typeClasses.driveBlue}
            >
              {t('acquisition-fee')}
            </Typography>
            <Typography variant="body2">{formatMoney(acqFee)}</Typography>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ marginBottom: 8 }}
              className={typeClasses.driveBlue}
            >
              {t('upfit-cost')}
            </Typography>
            <Typography variant="body2">
              {upfit === 0 ? '$0' : formatMoney(upfit)}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ marginBottom: 8 }}
              className={typeClasses.driveBlue}
            >
              {t('rate-markup')}
            </Typography>
            <Typography variant="body2">{rateMarkup}%</Typography>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ marginBottom: 8 }}
              className={typeClasses.driveBlue}
            >
              {t('tax-rate')}
            </Typography>
            <Typography variant="body2">{taxRate}%</Typography>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ marginBottom: 8 }}
              className={typeClasses.driveBlue}
            >
              {t('capitalize-tax')}
            </Typography>
            <Typography variant="body2">
              {capitalizeTax ? t('y') : t('n')}
            </Typography>
          </div>
        </div>
      </div>
      {!quoteSubmitted && (
        <>
          <div className={classes.internalChartHolder}>
            <LeasingOptionsChart
              leasingOptions={[optByTerm]}
              updateSelection={updateSelection}
              chosenOptions={chosenOptions}
              selectedLease={selectedLease}
              setSelectedLease={setSelectedLease}
            />
          </div>
          <Typography
            variant="body2"
            style={{ paddingTop: 16, fontStyle: 'italic' }}
          >
            {t('lease-chart-disclaimer')}
          </Typography>
        </>
      )}
    </div>
  );
};

LeasingChart.propTypes = {
  leasingOptions: PropTypes.arrayOf(PropTypes.shape({})),
  assetInformation: PropTypes.shape({
    make: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    model: PropTypes.string,
    trim: PropTypes.string,
    vin: PropTypes.string,
  }).isRequired,
  estimateLeasingOptions: PropTypes.shape({
    annualMileage: PropTypes.number,
    financeAmount: PropTypes.number,
    downPayment: PropTypes.number,
    additionalFI: PropTypes.number,
    upfit: PropTypes.number,
    rateMarkup: PropTypes.number,
    taxRate: PropTypes.number,
    capitalizeTax: PropTypes.bool,
    acqFee: PropTypes.number,
  }).isRequired,
  updateSelection: PropTypes.func.isRequired,
  chosenOptions: PropTypes.arrayOf(PropTypes.shape({})),
  quoteSubmitted: PropTypes.bool,
  chartTitle: PropTypes.string,
  selectedLease: PropTypes.string,
  setSelectedLease: PropTypes.func,
};

LeasingChart.defaultProps = {
  leasingOptions: undefined,
  chosenOptions: [],
  quoteSubmitted: undefined,
  chartTitle: undefined,
  selectedLease: '',
  setSelectedLease: () => {},
};

export default LeasingChart;
