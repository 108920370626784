import React from 'react';
import PropTypes from 'prop-types';
import NavBar from '~/areas/nav/nav';
import IdleTimerLogout from '~/idle-timer-logout';
import Box from '~/shared/components/box';
import ScrollToTop from '../scroll-to-top';
import Footer from './footer';
import DebugPanel from '~/areas/DebugPanel';

const AppLayout = ({ navigationRoutes, children }) => {
  return (
    <>
      <IdleTimerLogout />
      <ScrollToTop>
        <Box display="flex" flexDirection="column" height="100vh">
          <NavBar routeData={navigationRoutes} />
          <Box flexGrow={1} flexShrink={0} m={[2, 3, 4]}>
            {children}
          </Box>
          <Footer />
          <DebugPanel />
        </Box>
      </ScrollToTop>
    </>
  );
};

AppLayout.propTypes = {
  navigationRoutes: PropTypes.arrayOf(PropTypes.shape()),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

AppLayout.defaultProps = {
  navigationRoutes: [],
};

export default AppLayout;
