const vinHelperText = (
  error,
  errorMessage,
  isVinDecodedMessageShowing,
  emptyVin,
  t
) => {
  if (error) {
    return errorMessage;
  }
  if (!error && isVinDecodedMessageShowing && !emptyVin) {
    return t('assets:add-asset:vin-cannot-decode');
  }
};

export default vinHelperText;
