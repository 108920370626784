export const RolesActionTypes = {
  FETCH_ROLES_REQUEST: 'FETCH_ROLES_REQUEST',
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
  FETCH_ROLES_FAILURE: 'FETCH_ROLES_FAILURE',

  FETCH_ROLE_REQUEST: 'FETCH_ROLE_REQUEST',
  FETCH_ROLE_SUCCESS: 'FETCH_ROLE_SUCCESS',
  FETCH_ROLE_FAILURE: 'FETCH_ROLE_FAILURE',

  ADD_ROLE_REQUEST: 'ADD_ROLE_REQUEST',
  ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
  ADD_ROLE_FAILURE: 'ADD_ROLE_FAILURE',

  UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILURE: 'UPDATE_ROLE_FAILURE',

  REMOVE_ROLE_REQUEST: 'REMOVE_ROLE_REQUEST',
  REMOVE_ROLE_SUCCESS: 'REMOVE_ROLE_SUCCESS',
  REMOVE_ROLE_FAILURE: 'REMOVE_ROLE_FAILURE',
};

export const PermissionsActionTypes = {
  FETCH_PERMISSIONS_REQUEST: 'FETCH_PERMISSIONS_REQUEST',
  FETCH_PERMISSIONS_SUCCESS: 'FETCH_PERMISSIONS_SUCCESS',
  FETCH_PERMISSIONS_FAILURE: 'FETCH_PERMISSIONS_FAILURE',
};

export const AnalyticsActionTypes = {
  FETCH_WIDGET_REQUEST: 'FETCH_WIDGET_REQUEST',
  FETCH_WIDGET_SUCCESS: 'FETCH_WIDGET_SUCCESS',
  FETCH_WIDGET_FAILURE: 'FETCH_WIDGET_FAILURE',

  FETCH_WIDGETS_REQUEST: 'FETCH_WIDGETS_REQUEST',
  FETCH_WIDGETS_SUCCESS: 'FETCH_WIDGETS_SUCCESS',
  FETCH_WIDGETS_FAILURE: 'FETCH_WIDGETS_FAILURE',
};

export const AssetsActionTypes = {
  FETCH_ASSET_REQUEST: 'FETCH_ASSET_REQUEST',
  FETCH_ASSET_SUCCESS: 'FETCH_ASSET_SUCCESS',
  FETCH_ASSET_FAILURE: 'FETCH_ASSET_FAILURE',

  FETCH_ASSETS_REQUEST: 'FETCH_ASSETS_REQUEST',
  FETCH_ASSETS_SUCCESS: 'FETCH_ASSETS_SUCCESS',
  FETCH_ASSETS_FAILURE: 'FETCH_ASSETS_FAILURE',

  FETCH_PAGED_ASSETS_REQUEST: 'FETCH_PAGED_ASSETS_REQUEST',
  FETCH_PAGED_ASSETS_SUCCESS: 'FETCH_PAGED_ASSETS_SUCCESS',
  FETCH_INITIAL_PAGED_ASSETS_LIST_SUCCESS:
    'FETCH_INITIAL_PAGED_ASSETS_LIST_SUCCESS',
  FETCH_PAGED_ASSETS_LIST_SUCCESS: 'FETCH_PAGED_ASSETS_LIST_SUCCESS',
  FETCH_PAGED_ASSETS_FAILURE: 'FETCH_PAGED_ASSETS_FAILURE',

  FETCH_ASSETS_FILTERS_REQUEST: 'FETCH_ASSETS_FILTERS_REQUEST',
  FETCH_ASSETS_FILTERS_SUCCESS: 'FETCH_ASSETS_FILTERS_SUCCESS',
  FETCH_ASSETS_FILTERS_FAILURE: 'FETCH_ASSETS_FILTERS_FAILURE',

  FETCH_ASSETS_FILTER_REQUEST: 'FETCH_ASSETS_FILTER_REQUEST',
  FETCH_ASSETS_FILTER_SUCCESS: 'FETCH_ASSETS_FILTER_SUCCESS',
  FETCH_ASSETS_FILTER_FAILURE: 'FETCH_ASSETS_FILTER_FAILURE',

  FETCH_PAGED_ASSETS_TOGGLE_FILTER: 'FETCH_PAGED_ASSETS_TOGGLE_FILTER',
  FETCH_PAGED_ASSETS_RESET_FILTERS: 'FETCH_PAGED_ASSETS_RESET_FILTERS',
};

export const AuthActionTypes = {
  BOOTSTRAP_REQUEST: 'BOOTSTRAP_REQUEST',
  BOOTSTRAP_SUCCESS: 'BOOTSTRAP_SUCCESS',
  BOOTSTRAP_FAILURE: 'BOOTSTRAP_FAILURE',

  LOAD_USER_DATA: 'LOAD_USER_DATA',

  CHECK_ACCOUNT_REQUEST: 'CHECK_ACCOUNT_REQUEST',
  CHECK_ACCOUNT_SUCCESS: 'CHECK_ACCOUNT_SUCCESS',
  CHECK_ACCOUNT_FAILURE: 'CHECK_ACCOUNT_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  NEW_USER_FINALIZE_REQUEST: 'NEW_USER_FINALIZE_REQUEST',
  NEW_USER_FINALIZE_SUCCESS: 'NEW_USER_FINALIZE_SUCCESS',
  NEW_USER_FINALIZE_FAILURE: 'NEW_USER_FINALIZE_FAILURE',
  FETCH_AUTH_DETAILS_REQUEST: 'FETCH_AUTH_DETAILS_REQUEST',
  FETCH_AUTH_DETAILS_SUCCESS: 'FETCH_AUTH_DETAILS_SUCCESS',
  FETCH_AUTH_DETAILS_FAILURE: 'FETCH_AUTH_DETAILS_FAILURE',

  LOGOUT: 'LOGOUT',
  PRE_LOGOUT: 'PRE_LOGOUT',
  RESET_ACCOUNT_STATUS: 'RESET_ACCOUNT_STATUS',

  SERVER_DOWN: 'SERVER_DOWN',
};

export const GeofenceTypeActionTypes = {
  ADD_GEOFENCETYPE_REQUEST: 'ADD_GEOFENCETYPE_REQUEST',
  ADD_GEOFENCETYPE_SUCCESS: 'ADD_GEOFENCETYPE_SUCCESS',
  ADD_GEOFENCETYPE_FAILURE: 'ADD_GEOFENCETYPE_FAILURE',

  ADD_GEOFENCETYPES_REQUEST: 'ADD_GEOFENCETYPES_REQUEST',
  ADD_GEOFENCETYPES_SUCCESS: 'ADD_GEOFENCETYPES_SUCCESS',
  ADD_GEOFENCETYPES_FAILURE: 'ADD_GEOFENCETYPES_FAILURE',

  FETCH_GEOFENCETYPE_REQUEST: 'FETCH_GEOFENCETYPE_REQUEST',
  FETCH_GEOFENCETYPE_SUCCESS: 'FETCH_GEOFENCETYPE_SUCCESS',
  FETCH_GEOFENCETYPE_FAILURE: 'FETCH_GEOFENCETYPE_FAILURE',

  FETCH_GEOFENCETYPES_REQUEST: 'FETCH_GEOFENCETYPES_REQUEST',
  FETCH_GEOFENCETYPES_SUCCESS: 'FETCH_GEOFENCETYPES_SUCCESS',
  FETCH_GEOFENCETYPES_FAILURE: 'FETCH_GEOFENCETYPES_FAILURE',

  REMOVE_GEOFENCETYPE_REQUEST: 'REMOVE_GEOFENCETYPE_REQUEST',
  REMOVE_GEOFENCETYPE_SUCCESS: 'REMOVE_GEOFENCETYPE_SUCCESS',
  REMOVE_GEOFENCETYPE_FAILURE: 'REMOVE_GEOFENCETYPE_FAILURE',

  REMOVE_GEOFENCETYPES_REQUEST: 'REMOVE_GEOFENCETYPES_REQUEST',
  REMOVE_GEOFENCETYPES_SUCCESS: 'REMOVE_GEOFENCETYPES_SUCCESS',
  REMOVE_GEOFENCETYPES_FAILURE: 'REMOVE_GEOFENCETYPES_FAILURE',

  UPDATE_GEOFENCETYPE_REQUEST: 'UPDATE_GEOFENCETYPE_REQUEST',
  UPDATE_GEOFENCETYPE_SUCCESS: 'UPDATE_GEOFENCETYPE_SUCCESS',
  UPDATE_GEOFENCETYPE_FAILURE: 'UPDATE_GEOFENCETYPE_FAILURE',

  UPDATE_GEOFENCETYPES_REQUEST: 'UPDATE_GEOFENCETYPES_REQUEST',
  UPDATE_GEOFENCETYPES_SUCCESS: 'UPDATE_GEOFENCETYPES_SUCCESS',
  UPDATE_GEOFENCETYPES_FAILURE: 'UPDATE_GEOFENCETYPES_FAILURE',
};

export const OrgActionTypes = {
  UPDATE_ORG_REQUEST: 'UPDATE_ORG_REQUEST',
  UPDATE_ORG_SUCCESS: 'UPDATE_ORG_SUCCESS',
  UPDATE_ORG_FAILURE: 'UPDATE_ORG_FAILURE',

  FETCH_ORGS_REQUEST: 'FETCH_ORGS_REQUEST',
  FETCH_ORGS_SUCCESS: 'FETCH_ORGS_SUCCESS',
  FETCH_ORGS_FAILURE: 'FETCH_ORGS_FAILURE',

  FETCH_ORG_NODE_REQUEST: 'FETCH_ORG_NODE_REQUEST',
  FETCH_ORG_NODE_SUCCESS: 'FETCH_ORG_NODE_SUCCESS',
  FETCH_ORG_NODE_FAILURE: 'FETCH_ORG_NODE_FAILURE',

  ADD_ORG_NODE_REQUEST: 'ADD_ORG_NODE_REQUEST',
  ADD_ORG_NODE_SUCCESS: 'ADD_ORG_NODE_SUCCESS',
  ADD_ORG_NODE_FAILURE: 'ADD_ORG_NODE_FAILURE',
  ADD_ORG_NODE_CHILD: 'ADD_ORG_NODE_CHILD',

  UPDATE_ORG_NODE_REQUEST: 'UPDATE_ORG_NODE_REQUEST',
  UPDATE_ORG_NODE_SUCCESS: 'UPDATE_ORG_NODE_SUCCESS',
  UPDATE_ORG_NODE_FAILURE: 'UPDATE_ORG_NODE_FAILURE',

  REMOVE_ORG_NODE_REQUEST: 'REMOVE_ORG_NODE_REQUEST',
  REMOVE_ORG_NODE_SUCCESS: 'REMOVE_ORG_NODE_SUCCESS',
  REMOVE_ORG_NODE_FAILURE: 'REMOVE_ORG_NODE_FAILURE',

  FETCH_ORG_NODES_REQUEST: 'FETCH_ORG_NODES_REQUEST',
  FETCH_ORG_NODES_SUCCESS: 'FETCH_ORG_NODES_SUCCESS',
  FETCH_ORG_NODES_FAILURE: 'FETCH_ORG_NODES_FAILURE',

  SELECT_ORG_REQUEST: 'SELECT_ORG_REQUEST',
  SELECT_ORG_SUCCESS: 'SELECT_ORG_SUCCESS',
  SELECT_ORG_FAILURE: 'SELECT_ORG_FAILURE',

  INIT_ORG_DATA_LABELS: 'INIT_ORG_DATA_LABELS',
  ADD_EMPTY_ORG_DATA_LABEL: 'ADD_EMPTY_ORG_DATA_LABEL',
  REMOVE_EMPTY_ORG_DATA_LABEL: 'REMOVE_EMPTY_ORG_DATA_LABEL',
  UPDATE_USER_CONFIG_DISPLAY_FIELDS: 'UPDATE_USER_CONFIG_DISPLAY_FIELDS',
  UPDATE_ASSET_AUX_METADATA: 'UPDATE_ASSET_AUX_METADATA',
};

export const SettingsActionTypes = {
  // FETCH_METADATA_REQUEST: 'FETCH_METADATA_REQUEST',
  // FETCH_METADATA_SUCCESS: 'FETCH_METADATA_SUCCESS',
  // FETCH_METADATA_FAILURE: 'FETCH_METADATA_FAILURE',
  FETCH_SETTINGS_REQUEST: 'FETCH_SETTINGS_REQUEST',
  FETCH_SETTINGS_SUCCESS: 'FETCH_SETTINGS_SUCCESS',
  FETCH_SETTINGS_FAILURE: 'FETCH_SETTINGS_FAILURE',

  UPDATE_SETTINGS_REQUEST: 'UPDATE_SETTINGS_REQUEST',
  UPDATE_SETTINGS_SUCCESS: 'UPDATE_SETTINGS_SUCCESS',
  UPDATE_SETTINGS_FAILURE: 'UPDATE_SETTINGS_FAILURE',
};

export const UsersActionTypes = {
  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',

  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',

  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
};

export const StripeActionTypes = {
  FETCH_STRIPE_PAYMENT_REQUEST: 'FETCH_STRIPE_PAYMENT_REQUEST',
  FETCH_STRIPE_PAYMENT_SUCCESS: 'FETCH_STRIPE_PAYMENT_SUCCESS',
  FETCH_STRIPE_PAYMENT_FAILURE: 'FETCH_STRIPE_PAYMENT_FAILURE',

  ADD_STRIPE_PAYMENT_REQUEST: 'ADD_STRIPE_PAYMENT_REQUEST',
  ADD_STRIPE_PAYMENT_SUCCESS: 'ADD_STRIPE_PAYMENT_SUCCESS',
  ADD_STRIPE_PAYMENT_FAILURE: 'ADD_STRIPE_PAYMENT_FAILURE',

  DELETE_STRIPE_PAYMENT_REQUEST: 'DELETE_STRIPE_PAYMENT_REQUEST',
  DELETE_STRIPE_PAYMENT_SUCCESS: 'DELETE_STRIPE_PAYMENT_SUCCESS',
  DELETE_STRIPE_PAYMENT_FAILURE: 'DELETE_STRIPE_PAYMENT_FAILURE',

  UPDATE_STRIPE_PAYMENT_REQUEST: 'UPDATE_STRIPE_PAYMENT_REQUEST',
  UPDATE_STRIPE_PAYMENT_SUCCESS: 'UPDATE_STRIPE_PAYMENT_SUCCESS',
  UPDATE_STRIPE_PAYMENT_FAILURE: 'UPDATE_STRIPE_PAYMENT_FAILURE',
};

export const DocumentsActionTypes = {
  FETCH_DOCUMENT_REQUEST: 'FETCH_DOCUMENT_REQUEST',
  FETCH_DOCUMENT_SUCCESS: 'FETCH_DOCUMENT_SUCCESS',
  FETCH_DOCUMENT_FAILURE: 'FETCH_DOCUMENT_FAILURE',
};

export const PaymentHistoryActionTypes = {
  FETCH_PAGED_PAYMENTHISTORIES_REQUEST: 'FETCH_PAGED_PAYMENTHISTORIES_REQUEST',
  FETCH_PAGED_PAYMENTHISTORIES_SUCCESS: 'FETCH_PAGED_PAYMENTHISTORIES_SUCCESS',
  FETCH_PAGED_PAYMENTHISTORIES_FAILURE: 'FETCH_PAGED_PAYMENTHISTORIES_FAILURE',
};

// Marketplace
export const MPActionTypes = {
  // Shop Vehicles
  SV: {
    FETCH_MP_SV_VIEW_REQUEST: 'FETCH_MP_SV_VIEW_REQUEST',
    FETCH_MP_SV_VIEW_SUCCESS: 'FETCH_MP_SV_VIEW_SUCCESS',
    FETCH_MP_SV_VIEW_FAILURE: 'FETCH_MP_SV_VIEW_FAILURE',

    FETCH_MP_SV_DATA_REQUEST: 'FETCH_MP_SV_DATA_REQUEST',
    FETCH_MP_SV_DATA_SUCCESS: 'FETCH_MP_SV_DATA_SUCCESS',
    FETCH_MP_SV_DATA_FAILURE: 'FETCH_MP_SV_DATA_FAILURE',

    UPDATE_DELIVERY_STATE: 'UPDATE_DELIVERY_STATE',
    UPDATE_DELIVERY_LOCATION: 'UPDATE_DELIVERY_LOCATION',

    UPDATE_DELIVERY_PREFERENCE: 'UPDATE_DELIVERY_PREFERENCE',

    UPDATE_VEHICLE_TITLE: 'UPDATE_VEHICLE_TITLE',

    SET_PURCHASE_CHECKOUT_OPEN: 'SET_PURCHASE_CHECKOUT_OPEN',
    SET_VEHICLE_PURCHASE_AGREEMENT_AGREED:
      'SET_VEHICLE_PURCHASE_AGREEMENT_AGREED',
    SET_DELIVERY_AUTHORIZATION_RELEASE_AGREED:
      'SET_DELIVERY_AUTHORIZATION_RELEASE_AGREED',
  },
};

export const PaymentCardActionTypes = {
  FETCH_PAYMENT_CARDS_REQUEST: 'FETCH_PAYMENTCARDS_REQUEST',
  FETCH_PAYMENT_CARDS_SUCCESS: 'FETCH_PAYMENTCARDS_SUCCESS',
  FETCH_PAYMENT_CARDS_FAILURE: 'FETCH_PAYMENTCARDS_FAILURE',

  ADD_PAYMENT_CARD_REQUEST: 'ADD_PAYMENTCARD_REQUEST',
  ADD_PAYMENT_CARD_SUCCESS: 'ADD_PAYMENTCARD_SUCCESS',
  ADD_PAYMENT_CARD_FAILURE: 'ADD_PAYMENTCARD_FAILURE',

  REMOVE_PAYMENT_CARD_REQUEST: 'REMOVE_PAYMENTCARD_REQUEST',
  REMOVE_PAYMENT_CARD_SUCCESS: 'REMOVE_PAYMENTCARD_SUCCESS',
  REMOVE_PAYMENT_CARD_FAILURE: 'REMOVE_PAYMENTCARD_FAILURE',

  UPDATE_PAYMENT_CARD_REQUEST: 'UPDATE_PAYMENTCARD_REQUEST',
  UPDATE_PAYMENT_CARD_SUCCESS: 'UPDATE_PAYMENTCARD_SUCCESS',
  UPDATE_PAYMENT_CARD_FAILURE: 'UPDATE_PAYMENTCARD_FAILURE',
};

export const PaymentAccountActionTypes = {
  FETCH_PAYMENT_ACCOUNTS_REQUEST: 'FETCH_PAYMENTACCOUNTS_REQUEST',
  FETCH_PAYMENT_ACCOUNTS_SUCCESS: 'FETCH_PAYMENTACCOUNTS_SUCCESS',
  FETCH_PAYMENT_ACCOUNTS_FAILURE: 'FETCH_PAYMENTACCOUNTS_FAILURE',

  ADD_PAYMENT_ACCOUNT_REQUEST: 'ADD_PAYMENTACCOUNT_REQUEST',
  ADD_PAYMENT_ACCOUNT_SUCCESS: 'ADD_PAYMENTACCOUNT_SUCCESS',
  ADD_PAYMENT_ACCOUNT_FAILURE: 'ADD_PAYMENTACCOUNT_FAILURE',

  REMOVE_PAYMENT_ACCOUNT_REQUEST: 'REMOVE_PAYMENTACCOUNT_REQUEST',
  REMOVE_PAYMENT_ACCOUNT_SUCCESS: 'REMOVE_PAYMENTACCOUNT_SUCCESS',
  REMOVE_PAYMENT_ACCOUNT_FAILURE: 'REMOVE_PAYMENTACCOUNT_FAILURE',

  UPDATE_PAYMENT_ACCOUNT_REQUEST: 'UPDATE_PAYMENTACCOUNT_REQUEST',
  UPDATE_PAYMENT_ACCOUNT_SUCCESS: 'UPDATE_PAYMENTACCOUNT_SUCCESS',
  UPDATE_PAYMENT_ACCOUNT_FAILURE: 'UPDATE_PAYMENTACCOUNT_FAILURE',
};

export const PaymentCustomerActionTypes = {
  FETCH_PAYMENT_CUSTOMER_REQUEST: 'FETCH_PAYMENTCUSTOMER_REQUEST',
  FETCH_PAYMENT_CUSTOMER_SUCCESS: 'FETCH_PAYMENTCUSTOMER_SUCCESS',
  FETCH_PAYMENT_CUSTOMER_FAILURE: 'FETCH_PAYMENTCUSTOMER_FAILURE',

  UPDATE_PAYMENT_CUSTOMER_REQUEST: 'UPDATE_PAYMENTCUSTOMER_REQUEST',
  UPDATE_PAYMENT_CUSTOMER_SUCCESS: 'UPDATE_PAYMENTCUSTOMER_SUCCESS',
  UPDATE_PAYMENT_CUSTOMER_FAILURE: 'UPDATE_PAYMENTCUSTOMER_FAILURE',

  UPDATE_DEFAULT_PAYMENT_SOURCE: 'UPDATE_DEFAULT_PAYMENT_SOURCE',
};
export const PaymentMethodsActionTypes = {
  FETCH_PAYMENT_METHODS_REQUEST: 'FETCH_PAYMENT_METHODS_REQUEST',
  FETCH_PAYMENT_METHODS_SUCCESS: 'FETCH_PAYMENT_METHODS_SUCCESS',
  FETCH_PAYMENT_METHODS_FAILURE: 'FETCH_PAYMENT_METHODS_FAILURE',

  UPDATE_PAYMENT_CUSTOMER_REQUEST: 'UPDATE_PAYMENTCUSTOMER_REQUEST',
  UPDATE_PAYMENT_CUSTOMER_SUCCESS: 'UPDATE_PAYMENTCUSTOMER_SUCCESS',
  UPDATE_PAYMENT_CUSTOMER_FAILURE: 'UPDATE_PAYMENTCUSTOMER_FAILURE',

  UPDATE_DEFAULT_PAYMENT_SOURCE: 'UPDATE_DEFAULT_PAYMENT_SOURCE',
};

export const DriverTrainingActionTypes = {
  FETCH_DRIVER_TRAINING_REQUEST: 'FETCH_DRIVERTRAININGS_REQUEST',
  FETCH_DRIVER_TRAINING_SUCCESS: 'FETCH_DRIVERTRAININGS_SUCCESS',
  FETCH_DRIVER_TRAINING_FAILURE: 'FETCH_DRIVERTRAININGS_FAILURE',
};

export const CartActionTypes = {
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  CLEAR_CART: 'CLEAR_CART',
  SET_CART_CHECKOUT_OPEN: 'SET_CART_CHECKOUT_OPEN',
};

export const ServicesActionTypes = {
  FETCH_SERVICES_REQUEST: 'FETCH_SERVICES_REQUEST',
  FETCH_SERVICES_SUCCESS: 'FETCH_SERVICES_SUCCESS',
  FETCH_SERVICES_FAILURE: 'FETCH_SERVICES_FAILURE',

  ENROLL_SERVICE_REQUEST: 'UPDATE_SERVICE_REQUEST',
  ENROLL_SERVICE_SUCCESS: 'UPDATE_SERVICE_SUCCESS',
  ENROLL_SERVICE_FAILURE: 'UPDATE_SERVICE_FAILURE',
};

export const UICacheActionTypes = {
  SET_BROWSE_CONFIG: 'SET_BROWSE_CONFIG',
};

export const ServiceRequestActionTypes = {
  WATCHLIST_OPENED: 'WATCHLIST_OPENED',
  WATCHLIST_CLOSED: 'WATCHLIST_CLOSED',
  WATCHLIST_SELECTED_ROW: 'WATCHLIST_SELECTED_ROW',
  WATCHLIST_REQUEST_MANAGEMENT_URL: 'WATCHLIST_REQUEST_MANAGEMENT_URL',
};
