import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';
import { useNSTranslation } from '../lang';

const ViewContext = createContext(undefined);

export const ViewProvider = ({ view, ...props }) => {
  const { t } = useNSTranslation('browse', view.type);

  const translatedView = useMemo(
    () => ({
      ...view,
      fields: view.fields.map(field => ({
        ...field,
        display: t(`fields.${field.id}`),
      })),
    }),
    [view]
  );

  return <ViewContext.Provider value={translatedView} {...props} />;
};

ViewProvider.propTypes = {
  view: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

ViewProvider.displayName = 'ViewProvider';

export function useView() {
  const state = useContext(ViewContext);

  if (!state) {
    throw new Error(`useView was called outside of the ViewProvider.`);
  }

  return state;
}
