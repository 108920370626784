const maxDate = () => {
  const d = new Date();
  const year = new Date().getFullYear();
  return d.setFullYear(year + 5, 12, 32);
};

const minDate = () => {
  const d = new Date();
  const year = new Date().getFullYear();
  return d.setFullYear(year - 5, 12, 1);
};

const DateHelpers = {
  maxDate,
  minDate,
};

export default DateHelpers;
