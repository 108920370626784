import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '~/shared/components/table-row';

const HSBSTableRow = ({ children }) => {
  return (
    <>
      <TableRow>{children}</TableRow>
    </>
  );
};

HSBSTableRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
};

export default HSBSTableRow;
