import { useEffect } from 'react';

export const VIEW_STORAGE_KEY = 'hfm_view';

export const URL_PARAM = 'view';

export const VIEW_STORAGE_TYPES = {
  STOCKTEAM: 'stockteam',
  DEFAULT: 'default'
}

/**
 * Retrieves the value of the VIEW_STORAGE_KEY from local storage and checks if it equals VIEW_STORAGE_TYPES.STOCKTEAM.
 *
 * @returns {boolean} Returns true if the value of VIEW_STORAGE_KEY in local storage is equal to VIEW_STORAGE_TYPES.STOCKTEAM, otherwise returns false.
 */
export const getViewStorageKey = () => localStorage.getItem(VIEW_STORAGE_KEY) === VIEW_STORAGE_TYPES.STOCKTEAM;

/**
 * Adds a key value to localstorage if query param is provided
 * We want F&M's Stock ordering team to be able to navigate 
 * Shop Stock Vehicles to help locate a vehicle for our customers 
 * without accidentally triggering the order process through 
 * HSBS/Dynamics so that we don't create an ordering conflict/issue.
 *
 * @returns {string|null} The 'viewType' parameter from the URL, or null if it doesn't exist.
 *
 * @example
 * import useViewType from './useViewType';
 *
 * const MyComponent = () => {
 *   useViewType();
 *   // rest of your component logic
 * };
 */ 
const useViewType = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const viewType = urlParams.get(URL_PARAM);

  useEffect(() => {
    if (viewType === VIEW_STORAGE_TYPES.STOCKTEAM) {
      localStorage.setItem(VIEW_STORAGE_KEY, viewType);
    } else if (viewType === VIEW_STORAGE_TYPES.DEFAULT) {
      localStorage.removeItem(VIEW_STORAGE_KEY);
    }
  }, [viewType]);

  return viewType;
};

export default useViewType;
