/**
 * Whether the browser url corresponds to public app.
 */
export const isPublicApp = window.location?.host?.startsWith('public');

/**
 * If isPublicApp is true, we need to know the private app url for links.
 */
export const privateAppBase = window.location.origin.replace(
  process.env.HOST_ENV === 'local' ? 'public.' : 'public-',
  ''
);
