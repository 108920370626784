import { useCallback, useEffect } from 'react';
import { debounced } from '../util/helpers';

/**
 * This hook acts like a normal useEffect but with "debounced" calls of the effect
 * @param effect
 * @param deps
 * @param delay
 */
const useDebouncedEffect = (effect, deps, delay) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(debounced(effect, delay), deps);
  useEffect(() => {
    callback();
    return () => {
      callback.cancel();
    };
  }, [callback]);
};

export default useDebouncedEffect;
