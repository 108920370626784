import CircularProgressMUI from '@material-ui/core/CircularProgress';
import commonColors from '@holmanfm/lib/common/colors';
import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '~/shared/components/makeStyles';

const useStyles = makeStyles(() => ({
  centerProcessing: {
    position: 'absolute',
    zIndex: 999,
    top: '50%',
    left: '50%',
    marginTop: '-40px',
    marginLeft: '-40px',
    color: commonColors.fieldGreen,
  },
  inlineProcessing: {
    color: commonColors.fieldGreen,
  },
}));

const CircularProgress = props => {
  const { inlineStyle, size, thickness, style, ...rest } = props;
  const classes = useStyles();

  return (
    <CircularProgressMUI
      size={size}
      thickness={thickness}
      className={
        inlineStyle ? classes.inlineProcessing : classes.centerProcessing
      }
      style={style}
      {...rest}
    />
  );
};

CircularProgress.propTypes = {
  inlineStyle: PropTypes.bool,
  size: PropTypes.number,
  thickness: PropTypes.number,
  style: PropTypes.shape({}),
};

CircularProgress.defaultProps = {
  inlineStyle: false,
  size: 60,
  thickness: 2,
  style: null,
};

export default CircularProgress;
