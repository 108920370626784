/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import AssetIconUtils from '@holmanfm/lib/util/asset-icons';
import AddAPhotoOutlinedIcon from '~/shared/icons/add-photo-outlined';
import Typography from '~/shared/components/atom/typography/typography';
import makeStyles from '~/shared/components/makeStyles';

const generatePreviewImg = (file, callback) => {
  const reader = new FileReader();
  // url is being used somehow, if I remove it the image data isn't generated
  // eslint-disable-next-line no-unused-vars
  const url = reader.readAsDataURL(file);
  reader.onloadend = () => callback(reader.result);
};

const useStyles = makeStyles(theme => ({
  vehicleBox: {
    padding: '0 0 24px 0',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      display: 'table',
      '& $uploadButton': {
        justifyContent: 'center',
      },
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      paddingRight: theme.spacing(2),
    },
  },
  vehicleIconBox: {
    width: 115,
    height: 85,
    border: '1px solid #efefef',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      backgroundColor: '#fff',
      padding: theme.spacing(2),
      width: '85%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
  },
  uploadedImage: {
    width: 115,
    height: 'auto',
    border: '1px solid #efefef',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 176,
      height: 162,
    },
  },
  uploadButton: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    display: 'flex',
    paddingTop: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const AssetImgUpload = ({ data, assetImg, setAssetImg }) => {
  const classes = useStyles();
  const { t } = useNSTranslation('assets', 'add-asset');
  const [previewImg, setPreviewImg] = useState();
  const [vehicleType, setVehicleType] = useState();

  React.useEffect(() => {
    if (data.vehicleType || data.vehicle_type) {
      setVehicleType(data.vehicleType || data.vehicle_type);
    }
    if (!assetImg) {
      setPreviewImg(null);
    }
  }, [data, assetImg, setPreviewImg]);

  const uploadChange = async e => {
    const file = e.target.files[0];

    if (!file) {
      setPreviewImg();
      setAssetImg();
      return;
    }

    generatePreviewImg(file, previewImgUrl => {
      setPreviewImg({ previewImgUrl });
      setAssetImg(file);
    });
  };

  const currentImg =
    previewImg?.previewImgUrl || (Array.isArray(assetImg) && assetImg[0]);

  return (
    <div className={classes.vehicleBox}>
      {currentImg && (
        <div
          className={
            currentImg ? classes.uploadedImage : classes.vehicleIconBox
          }
        >
          <img
            alt={vehicleType}
            src={
              currentImg ||
              AssetIconUtils.getVehicleAssetIcon(
                vehicleType || '',
                data.body_type || ''
              )
            }
          />
        </div>
      )}
      <label htmlFor="myuniqueid">
        <Typography variant="body1" className={classes.uploadButton}>
          <AddAPhotoOutlinedIcon fontSize="small" style={{ marginRight: 8 }} />{' '}
          {t('upload-photo')}
        </Typography>
      </label>
      <input
        style={{ display: 'none' }}
        type="file"
        id="myuniqueid"
        accept="image/*"
        onChange={uploadChange}
      />
    </div>
  );
};

AssetImgUpload.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    vehicleType: PropTypes.string,
    vehicle_type: PropTypes.string,
    body_type: PropTypes.string,
  }).isRequired,
  assetImg: PropTypes.oneOfType([
    PropTypes.shape({
      previewImgUrl: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  setAssetImg: PropTypes.func.isRequired,
};

AssetImgUpload.defaultProps = {
  assetImg: null,
};

export default AssetImgUpload;
