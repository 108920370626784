import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { MaintenanceRepairDetailsShape } from '@holmanfm/lib/services/maintenance-repair-history';
import { useNSTranslation } from '@holmanfm/lib/lang';
import Dialog from '~/shared/components/dialog';
import DialogActions from '~/shared/components/dialog-actions';
import DialogContent from '~/shared/components/dialog-content';
import DialogTitle from '~/shared/components/dialog-title';
import TextField from '~/shared/components/forms/text-field';
import IconButton from '~/shared/components/atom/icon-button';
import Close from '~/shared/icons/close';
import makeStyles from '~/shared/components/makeStyles';
import SubmitButton from '~/shared/components/forms/submit-button';
import Table from '~/shared/components/table';
import TableHead from '~/shared/components/table-head';
import TableRow from '~/shared/components/table-row';
import TableCell from '~/shared/components/table-cell';
import TableBody from '~/shared/components/table-body';

const useStyles = makeStyles(theme => ({
  modalClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 999,
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: 0,
    },
    '& svg': {
      fontSize: '30px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
  },
}));

const RejectionNotesEntryModal = ({
  open,
  rejectedDetails,
  onClose,
  onSubmit,
  title,
}) => {
  const { t } = useNSTranslation('maintenanceRepair', 'history');
  const classes = useStyles();
  const handleSubmit = values => {
    onSubmit(values);
    onClose();
  };

  const initialValues = rejectedDetails.reduce((acc, detail) => {
    acc[detail.id] = '';
    return acc;
  }, {});

  const validate = values => {
    return Object.entries(values).reduce((acc, [key, value]) => {
      if (!value) {
        acc[key] = 'Required';
      }
      return acc;
    }, {});
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <IconButton onClick={onClose} className={classes.modalClose}>
        <Close />
      </IconButton>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {() => {
          return (
            <>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('description')}</TableCell>
                      <TableCell>{t('repair-type')}</TableCell>
                      <TableCell>{t('extended-cost')}</TableCell>
                      <TableCell>{t('reason')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rejectedDetails.map(detail => (
                      <TableRow key={detail.id}>
                        <TableCell>{detail.description}</TableCell>
                        <TableCell>{detail.repairType}</TableCell>
                        <TableCell>{detail.extendedCost}</TableCell>
                        <TableCell>
                          <TextField
                            id={detail.id}
                            name={detail.id}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                              style: { color: '#000', fontWeight: 'bold' },
                            }}
                            multiline
                            rows={2}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </DialogContent>

              <DialogActions>
                <SubmitButton color="secondary" variant="contained">
                  {t('submit-rejections')}
                </SubmitButton>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

RejectionNotesEntryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  rejectedDetails: PropTypes.arrayOf(MaintenanceRepairDetailsShape).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

RejectionNotesEntryModal.defaultProps = {};

export default RejectionNotesEntryModal;
