import { useNSTranslation } from '@holmanfm/lib/lang';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import { useSelector } from '@holmanfm/lib/redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { LogoWhite } from '../../../../lib/common/constants';

import makeStyles from '~/shared/components/makeStyles';

/** Configured Logo */
const useLogoStyles = makeStyles(() => ({
  logo: { height: 45 },
}));

const Logo = () => {
  const classes = useLogoStyles();
  const { t } = useNSTranslation();
  const org = useSelector(selectors.getOrg);
  // eslint-disable-next-line camelcase
  const logo = org?.auth_details?.logo ?? org?.parent?.auth_details?.logo;
  return (
    <img
      className={classes.logo}
      src={logo || LogoWhite}
      alt={t('main:brand-name')}
    />
  );
};

/** Configred Flex Container */
const useStyles = makeStyles(theme => ({
  logoBox: {
    transition: '300ms ease-in-out',
    '&:hover': {
      opacity: '0.3',
    },
    margin: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
}));

const LogoBox = props => {
  const classes = useStyles();

  return <div className={classes.logoBox} {...props} />;
};

const HomeButton = props => {
  const { enabled, link, onClick } = props;
  const cssClasses = classnames({ disabled: !enabled });

  if (link) {
    return (
      <LogoBox>
        <Link to="/" className={cssClasses}>
          <Logo />
        </Link>
      </LogoBox>
    );
  }

  return (
    <LogoBox>
      <div role="button" onClick={onClick}>
        <Logo />
      </div>
    </LogoBox>
  );
};

HomeButton.propTypes = {
  enabled: PropTypes.bool,
  link: PropTypes.bool,
  onClick: PropTypes.func,
};

HomeButton.defaultProps = {
  enabled: true,
  link: true,
  onClick: null,
};

export default HomeButton;
