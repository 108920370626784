/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import AssetDetailsEditForm from '~/areas/individual-assets/edit-forms/asset-details';
import RegistrationInformationEditForm from '~/areas/individual-assets/edit-forms/registration-information';
import DriverInformationEditForm from '~/areas/individual-assets/edit-forms/driver-information';
import CustomAuxFieldsEditForm from '~/areas/individual-assets/edit-forms/custom-aux-fields';

const ChooseLayout = ({
  layout,
  data,
  closeEditing,
  setHasDataUpdated,
  hasDataUpdated,
  auxData,
}) => {
  if (layout === 'asset-details') {
    return (
      <AssetDetailsEditForm
        data={data}
        closeEditing={closeEditing}
        setHasDataUpdated={setHasDataUpdated}
        hasDataUpdated={hasDataUpdated}
      />
    );
  }
  if (layout === 'registration-info') {
    return (
      <RegistrationInformationEditForm
        data={data}
        closeEditing={closeEditing}
        setHasDataUpdated={setHasDataUpdated}
        hasDataUpdated={hasDataUpdated}
      />
    );
  }
  if (layout === 'driver-info') {
    return (
      <DriverInformationEditForm
        data={data}
        closeEditing={closeEditing}
        setHasDataUpdated={setHasDataUpdated}
        hasDataUpdated={hasDataUpdated}
      />
    );
  }
  if (layout === 'aux') {
    return (
      <CustomAuxFieldsEditForm
        data={data}
        closeEditing={closeEditing}
        setHasDataUpdated={setHasDataUpdated}
        hasDataUpdated={hasDataUpdated}
        auxData={auxData}
      />
    );
  }
  return (
    <div>
      <div>Editing stuff</div>
    </div>
  );
};

ChooseLayout.propTypes = {
  layout: PropTypes.string,
  data: PropTypes.shape({}).isRequired,
  closeEditing: PropTypes.func.isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  hasDataUpdated: PropTypes.bool,
  auxData: PropTypes.shape({}),
};

ChooseLayout.defaultProps = {
  layout: undefined,
  hasDataUpdated: undefined,
  auxData: undefined,
};

export default ChooseLayout;
