import React from 'react';
import PropTypes from 'prop-types';

import PublicNavBar from '~/areas/nav/public-nav';
import Box from '~/shared/components/box';
import ScrollToTop from '../scroll-to-top';
import Footer from './footer';
import DebugPanel from '~/areas/DebugPanel';

const PublicAppLayout = ({ children }) => {
  return (
    <>
      <ScrollToTop>
        <Box display="flex" flexDirection="column" height="100vh">
          <PublicNavBar />
          <Box flexGrow={1} flexShrink={0} m={[0, 2, 4]}>
            {children}
          </Box>
          <Footer />
          <DebugPanel />
        </Box>
      </ScrollToTop>
    </>
  );
};

PublicAppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default PublicAppLayout;
