import sleep from '@holmanfm/lib/util/sleep';
import React from 'react';

const Placeholder = () => <div>Loading...</div>;

/**
 * Wrapper around dynamic imports used for lazy loading of react components.
 * It handles the case when the app is re-deployed and the user did not refresh the page.
 * In this case, the user can see a white screen after navigating because the code tries to load chunks that no longer exist.
 * This code attempts to fetch the chunks several times and then just refreshes the page.
 * @param {Function} dynamicImportFun - function that returns an import call, e.g., () => import('./my-component')
 * @param {Number} attemptsLeft - number of attempts to make before refreshing the page
 * @returns {Component} - result of calling dynamicImportFun.
 */
export async function safeLoadModule(dynamicImportFun, attemptsLeft = 3) {
  try {
    return await dynamicImportFun();
  } catch (e) {
    console.log('failed to load component, retrying...');
    if (attemptsLeft === 1) {
      alert('A new version of the app was released. Refreshing the page...');
      window.location.reload();
      return {
        // return a placeholder component to avoid an error while the page is being reloaded
        default: Placeholder,
      };
    }
    // sleep and retry
    await sleep(null, 300);
    return safeLoadModule(dynamicImportFun, attemptsLeft - 1);
  }
}

export default function lazyLoadComponent(dynamicImportFun) {
  return React.lazy(() => safeLoadModule(dynamicImportFun));
}
