// @TODO - Get API call
import api from '../../util/api';
// export const getAllDocuments = () => {};

// eslint-disable-next-line import/prefer-default-export
export const getAllDocuments = id =>
  api.get(`/asset/documents/lease?assetId=${id}`);

export const getAssetDocuments = id =>
  api.get(`/asset/documents/all?assetId=${id}`);
