/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import Table from '~/shared/components/table';
import TableBody from '~/shared/components/table-body';
import TableCell from '~/shared/components/table-cell';
import TableRow from '~/shared/components/table-row';
import makeStyles from '~/shared/components/makeStyles';
import typeStyles from '~/shared/components/styles/global-type-styles';
import Typography from '~/shared/components/atom/typography/typography';
import useGetFuelKpis from '~/areas/individual-assets/fuel-management-components/use-get-fuel-kpis';

const useStyles = makeStyles(theme => ({
  kpiHolder: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.backgroundColors.tan}`,
    marginBottom: theme.spacing(3),
    display: 'flex',
  },
  kpiSection: {
    marginRight: theme.spacing(3),
  },
  kpiSectionTitle: {
    marginBottom: 8,
    textAlign: 'center',
  },
}));

const FuelKpi = () => {
  const classes = useStyles();
  const typeClasses = typeStyles();
  const { t } = useNSTranslation('individualAsset', 'fuelManagement');

  const {
    avgQuantity,
    avgCost,
    avgUnitCost,
    listOfFuelTypes,
    costByFuelType,
    totalFuelCost,
  } = useGetFuelKpis();

  return (
    <div className={classes.kpiHolder}>
      {/* <div className={classes.kpiSection}>
        <Typography variant="subtitle2" className={classes.kpiSectionTitle}>
          {t('miles-per-gallon')}
        </Typography>
        <Typography
          variant="h3"
          className={typeClasses.freshBlue}
          style={{ fontWeight: 'normal', textAlign: 'center' }}
        >
          {gallonSummary || asset?.latest_odometer
            ? (gallonSummary / asset?.latest_odometer).toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })
            : '—'}
        </Typography>
      </div> */}
      <div className={classes.kpiSection}>
        <Typography variant="subtitle2" className={classes.kpiSectionTitle}>
          {t('type-of-fuel')}
        </Typography>
        {listOfFuelTypes?.length > 0 ? (
          <Table>
            <TableBody>
              {listOfFuelTypes?.map(tf => (
                <TableRow key={`type-${tf.description}`}>
                  <TableCell
                    style={{ borderBottom: 'none', padding: '5.5px 8px' }}
                  >
                    <Typography variant="body2">{tf.description}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          '—'
        )}
      </div>
      <div className={classes.kpiSection}>
        <Typography variant="subtitle2" className={classes.kpiSectionTitle}>
          {t('total-fuel-cost-by-type')}
        </Typography>
        {costByFuelType?.length > 0 ? (
          <Table style={{ display: 'flex', alignItems: 'center' }}>
            <TableBody>
              {costByFuelType?.map(c => (
                // It keeps pushing it to 18 spaces insted of 16 I cannot control this error
                // eslint-disable-next-line react/jsx-indent
                <TableRow key={`fuelType-${Object.keys(c)[0]}`}>
                  <TableCell
                    align="right"
                    style={{ borderBottom: 'none', padding: '4px 8px 4px 0' }}
                  >
                    <Typography
                      variant="h3"
                      className={typeClasses.freshBlue}
                      style={{ fontWeight: 'normal' }}
                    >
                      {Object.values(c)[0]}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ borderBottom: 'none', padding: '4px 0 4px 8px' }}
                  >
                    <Typography variant="body2">{Object.keys(c)[0]}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          '—'
        )}
      </div>
      <div className={classes.kpiSection}>
        <Typography variant="subtitle2" className={classes.kpiSectionTitle}>
          {t('total-fuel-cost')}
        </Typography>
        <Typography
          variant="h3"
          className={typeClasses.freshBlue}
          style={{ fontWeight: 'normal', textAlign: 'center' }}
        >
          {totalFuelCost?.length > 0 ? totalFuelCost : '—'}
        </Typography>
      </div>
      <div className={classes.kpiSection}>
        <Typography variant="subtitle2" className={classes.kpiSectionTitle}>
          {t('avg-quantity')}
        </Typography>
        <Typography
          variant="h3"
          className={typeClasses.freshBlue}
          style={{ fontWeight: 'normal', textAlign: 'center' }}
        >
          {avgQuantity?.length > 0 ? avgQuantity : '—'}
        </Typography>
      </div>
      <div className={classes.kpiSection}>
        <Typography variant="subtitle2" className={classes.kpiSectionTitle}>
          {t('avg-unit-cost')}
        </Typography>
        <Typography
          variant="h3"
          className={typeClasses.freshBlue}
          style={{ fontWeight: 'normal', textAlign: 'center' }}
        >
          {avgUnitCost || '—'}
        </Typography>
      </div>
      <div className={classes.kpiSection}>
        <Typography variant="subtitle2" className={classes.kpiSectionTitle}>
          {t('avg-cost')}
        </Typography>
        <Typography
          variant="h3"
          className={typeClasses.freshBlue}
          style={{ fontWeight: 'normal', textAlign: 'center' }}
        >
          {avgCost || '—'}
        </Typography>
      </div>
    </div>
  );
};

FuelKpi.propTypes = {
  asset: PropTypes.shape({
    latest_odometer: PropTypes.number,
  }).isRequired,
};

export default FuelKpi;
