/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from '@holmanfm/lib/redux';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import { updateAsset } from '@holmanfm/lib/services/assets';
import usePermissions from '@holmanfm/lib/hooks/use-permissions';
import { Formik, Form } from 'formik';
import useValidationSchema from '@holmanfm/lib/hooks/useValidationSchema';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { emailRegex } from '@holmanfm/lib/lib-global/regex';
import * as Yup from 'yup';
import AddAssetHelpers from '@holmanfm/lib/common/add-asset-helpers';
import { isEmptyObject } from '@holmanfm/lib/util/helpers';
import Features from '@holmanfm/lib/lib-global';
import PmNotifications from '~/areas/assets/update-page/components/pm-notifications';
import ProgressButton from '~/shared/components/progress-btn';
import ServerMessages from '~/shared/components/messages/server-messages';
import PmNotificationsNoUpdate from '~/areas/assets/update-page/components/pm-notifications-no-update';

const PmNotificationSchema = t =>
  Yup.object().shape({
    pmEmailType: Yup.string(),
    pmEmail: Yup.string().when('pmEmailType', {
      is: 'manualEnter',
      then: schema =>
        schema
          .email(t('validator:email-content'))
          .matches(emailRegex, { message: t('validator:email-content') })
          .required(t('validator:required-generic')),
    }),
  });

const PmNotificationSection = props => {
  const { asset, setHasDataUpdated, hasDataUpdated, parentOrg } = props;
  const { t } = useNSTranslation('individualAsset', 'maintenance');
  const { canAccessFeature } = usePermissions();

  const { defaultEmpty } = AddAssetHelpers;
  const orgContact = useSelector(selectors.getOrgDataContact);
  const validationSchema = useValidationSchema(PmNotificationSchema);
  const tree = useSelector(state => selectors.getOrgNodesTree(state));
  return (
    <div style={{ margin: '24px 0 0' }}>
      <Formik
        initialValues={{
          thirdPartyLeaseType: defaultEmpty(asset?.third_party_lease_type),
          pmEmail:
            asset?.pmEmailType === 'defaultOrgAddress' || !asset?.pmEmailType
              ? orgContact?.email
              : asset?.pmEmail,
          pmEmailType: asset?.pmEmailType || 'defaultOrgAddress',
          orgNodeId: asset?.org_node_id,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setSubmitting(true);
          const updatedData = {
            id: asset?.id,
            assetNo: asset?.assetNo,
            ...values,
          };
          updateAsset(updatedData)
            .then(async () => {
              setSubmitting(false);
              setTimeout(() => {
                setHasDataUpdated(!hasDataUpdated);
              }, 3000);
            })
            .catch(err => {
              setSubmitting(false);
              setFieldError('api', err);
            });
        }}
      >
        {FormikBag => {
          const {
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            errors,
          } = FormikBag;

          return (
            <Form onSubmit={handleSubmit}>
              {canAccessFeature(Features.ASSETS.UPDATE) ? (
                <>
                  <PmNotifications
                    tree={tree}
                    orgContact={orgContact}
                    className=""
                  />
                  {!isEmptyObject(errors) && errors.api && (
                    <div style={{ maxWidth: 750, marginTop: 16 }}>
                      <ServerMessages showErrors messages={errors.api} />
                    </div>
                  )}
                  <div style={{ marginTop: 16 }}>
                    <ProgressButton
                      btnTitle={t('pm-notif-btn')}
                      submitFunc={handleSubmit}
                      processing={isSubmitting}
                      disabled={!dirty || !isValid || isSubmitting || parentOrg}
                    />
                  </div>
                </>
              ) : (
                <PmNotificationsNoUpdate />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

PmNotificationSection.propTypes = {
  asset: PropTypes.shape({}).isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  hasDataUpdated: PropTypes.bool.isRequired,
  parentOrg: PropTypes.bool.isRequired,
};

export default PmNotificationSection;
