import PropTypes from 'prop-types';
import React from 'react';
import clx from 'classnames';
import { Formik } from 'formik';
import { useNSTranslation } from '@holmanfm/lib/lang';
import useDialog from '@holmanfm/lib/hooks/use-dialog';
import useDialogItem from '@holmanfm/lib/hooks/use-dialog-item';
import { generateDetailsInitialValues } from '@holmanfm/lib/initial-form-values/update-request-form-values';
import {
  isDetailsFormEnabled,
  isChangeDisabled,
  makeHandleDetailsSubmit,
  isEveryChangeDisabled,
} from '@holmanfm/lib/hooks/service-requests/maint-repair-submit-handlers';
import { useRudd } from '@holmanfm/lib/rudd-framework';
import { getSortedItems } from '@holmanfm/lib/common/service-request-helpers';
import Table from '~/shared/components/table';
import TableHead from '~/shared/components/table-head';
import TableRow from '~/shared/components/table-row';
import TableCell from '~/shared/components/table-cell';
import TableBody from '~/shared/components/table-body';
import makeStyles from '~/shared/components/makeStyles';
import RadioGroup from '~/shared/components/radio-group';
import Radio from '~/shared/components/radio';
import FormControlLabel from '~/shared/components/form-control-label';
import InsertDriveFile from '~/shared/icons/insert-drive-file';
import IconButton from '~/shared/components/atom/icon-button';
import RejectionNotesEntryModal from './rejection-notes-entry-modal';
import SaveButtons from '../save-buttons';
import ViewNotesModal from './view-notes-modal';
import { flushCache } from '../../browse-service-requests/service-request-cache';
import ErrorOutline from '~/shared/icons/error-outline';
import RejectionReasonsModal from './rejection-reasons-modal';
import Typography from '~/shared/components/atom/typography';

const useStyles = makeStyles(theme => ({
  hideBelowDesktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  radioGroup: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },
  radioCol: {
    width: '50px',
    margin: 'auto',
    display: 'inline-block',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '35px',
      fontSize: '.7rem',
    },
  },
  radioHeader: {
    '& span': {
      padding: 0,
    },
  },
  radioHeaderCell: {
    paddingBottom: '2px',
  },
  disabled: {
    color: theme.palette.grey[500],
  },
  requestTableHead: {
    paddingTop: '10px',
  },
  iconsCell: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

const MaintenanceRepairDetailsRequest = props => {
  const { t } = useNSTranslation('maintenanceRepair', 'history');
  const { t: tReq } = useNSTranslation('serviceRequests', 'details');
  const classes = useStyles();
  const { className, size } = props;

  const {
    state,
    handlers: { updateHandler },
  } = useRudd();
  const enableApprovals = isDetailsFormEnabled(state);
  const dbItems = state?.data?.maintenance?.maintenanceRepairDetails;

  const initialValues = generateDetailsInitialValues(state.data);

  const notesDialog = useDialogItem();
  const rejectionsDialog = useDialogItem();
  const notesEntryDialog = useDialog();

  const handleChange = (event, formikSetFieldValue) => {
    const approved = event.target.value === 'true';
    formikSetFieldValue(event.target.name, approved);
  };

  const addRejectionNotes = (notes, formikHandleSubmit, setFieldValue) => {
    setFieldValue('notes', notes);
    formikHandleSubmit();
  };

  const submitFormikOrShowDialog = (
    rejectedItems,
    formikHandleSubmit,
    formikValues
  ) => {
    if (rejectedItems?.length > 0) {
      notesEntryDialog.openDialog();
    } else {
      formikHandleSubmit(formikValues);
    }
  };

  const getAllApprovedRejectedValue = formikValues => {
    const enabledItems = dbItems.filter(
      dbItem => !isChangeDisabled(dbItem, state)
    );
    const allApproved = enabledItems.every(dbItem => formikValues[dbItem.id]);
    if (allApproved) {
      return true;
    }
    const allRejected = enabledItems.every(dbItem => !formikValues[dbItem.id]);
    if (allRejected) {
      return false;
    }
    return null;
  };

  const handleAllApprovedRejected = (event, formikValues, formikSetValues) => {
    const newValues = dbItems.reduce((acc, dbItem) => {
      const formikValue = formikValues[dbItem.id];
      if (!isChangeDisabled(dbItem, state)) {
        acc[dbItem.id] = event.target.value === 'true';
      } else {
        acc[dbItem.id] = formikValue;
      }
      return acc;
    }, {});
    formikSetValues(newValues);
  };

  const hasApcRejections = detail =>
    state.data.maintenance.repairRejectionReasons?.lineItemReasons[
      detail.sourceItemId
    ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={makeHandleDetailsSubmit(updateHandler, state, flushCache)}
      enableReinitialize
      validateOnMount
    >
      {formikProps => {
        const rejectedItems = dbItems.filter(
          dbItem =>
            !isChangeDisabled(dbItem, state) &&
            formikProps.values[dbItem.id] === false
        );

        return (
          <>
            <Table
              size={size}
              aria-label={t('maint-repair-details')}
              className={className}
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.radioHeaderCell}>
                    <RadioGroup
                      name="all"
                      value={getAllApprovedRejectedValue(formikProps.values)}
                      onChange={event =>
                        handleAllApprovedRejected(
                          event,
                          formikProps.values,
                          formikProps.setValues
                        )
                      }
                      className={classes.radioGroup}
                    >
                      <div className={classes.radioCol}>
                        <div
                          className={
                            !enableApprovals ? classes.disabled : undefined
                          }
                        >
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 'bold' }}
                          >
                            {t('approve')}
                          </Typography>
                        </div>
                        <FormControlLabel
                          disabled={!enableApprovals}
                          value
                          control={<Radio size="small" />}
                          className={clx(classes.radioCol, classes.radioHeader)}
                          label={null}
                        />
                      </div>
                      <div className={classes.radioCol}>
                        <div
                          className={
                            !enableApprovals ? classes.disabled : undefined
                          }
                        >
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 'bold' }}
                          >
                            {t('reject')}
                          </Typography>
                        </div>
                        <FormControlLabel
                          disabled={!enableApprovals}
                          value={false}
                          control={<Radio size="small" />}
                          className={clx(classes.radioCol, classes.radioHeader)}
                          label={null}
                        />
                      </div>
                    </RadioGroup>
                  </TableCell>
                  {/* Empty table cell is for the icons */}
                  <TableCell />
                  <TableCell className={classes.requestTableHead}>
                    <Typography
                      variant="caption"
                      style={{ fontWeight: 'bold' }}
                    >
                      {t('description')}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.requestTableHead}>
                    <Typography
                      variant="caption"
                      style={{ fontWeight: 'bold' }}
                    >
                      {t('ata-code')} / {t('ata-code-category')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={clx(
                      classes.hideBelowDesktop,
                      classes.requestTableHead
                    )}
                  >
                    <Typography
                      variant="caption"
                      style={{ fontWeight: 'bold' }}
                    >
                      {t('complaint')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={clx(
                      classes.hideBelowDesktop,
                      classes.requestTableHead
                    )}
                  >
                    <Typography
                      variant="caption"
                      style={{ fontWeight: 'bold' }}
                    >
                      {t('correction')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={clx(
                      classes.hideBelowDesktop,
                      classes.requestTableHead
                    )}
                  >
                    <Typography
                      variant="caption"
                      style={{ fontWeight: 'bold' }}
                    >
                      {t('repair-type')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={clx(
                      classes.hideBelowDesktop,
                      classes.requestTableHead
                    )}
                    align="right"
                  >
                    <Typography
                      variant="caption"
                      style={{ fontWeight: 'bold' }}
                    >
                      {t('quantity')}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.requestTableHead} align="right">
                    <Typography
                      variant="caption"
                      style={{ fontWeight: 'bold' }}
                    >
                      {t('extended-cost')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dbItems &&
                  getSortedItems(dbItems, 'ataCode').map(detail => (
                    <TableRow key={detail.id}>
                      <TableCell>
                        <RadioGroup
                          name={detail.id}
                          value={formikProps.values[detail.id]}
                          onChange={event =>
                            handleChange(event, formikProps.setFieldValue)
                          }
                          className={classes.radioGroup}
                        >
                          <FormControlLabel
                            value
                            disabled={isChangeDisabled(detail, state)}
                            control={<Radio size="small" />}
                            className={classes.radioCol}
                            label={null}
                          />
                          <FormControlLabel
                            disabled={isChangeDisabled(detail, state)}
                            value={false}
                            control={<Radio size="small" />}
                            className={classes.radioCol}
                            label={null}
                          />
                        </RadioGroup>
                      </TableCell>
                      <TableCell>
                        <div className={classes.iconsCell}>
                          {hasApcRejections(detail) && (
                            <IconButton
                              type="button"
                              aria-label="rejection reasons"
                              onClick={() =>
                                rejectionsDialog.openDialog(detail)
                              }
                            >
                              <ErrorOutline color="error" />
                            </IconButton>
                          )}
                          <IconButton
                            aria-label="note"
                            className={classes.iconButton}
                            onClick={() => notesDialog.openDialog(detail)}
                            disabled={detail?.notes?.length === 0}
                          >
                            <InsertDriveFile />
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell className="xlCell">
                        <Typography variant="body2">
                          {detail.description}
                        </Typography>
                      </TableCell>
                      <TableCell className="lgCell">
                        <Typography variant="body2">
                          {detail.ataCode} {detail.repairTypeDescription && '/'}{' '}
                          {detail.repairTypeDescription}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clx(classes.hideBelowDesktop, 'lgCell')}
                      >
                        <Typography variant="body2">
                          {detail.complaintDescription}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clx(classes.hideBelowDesktop, 'mdCell')}
                      >
                        <Typography variant="body2">
                          {detail.correction}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clx(classes.hideBelowDesktop, 'mdCell')}
                      >
                        <Typography variant="body2">
                          {detail.repairType}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={clx(classes.hideBelowDesktop, 'smCell')}
                      >
                        <Typography variant="body2">
                          {detail.quantity}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" className="smCell">
                        <Typography variant="body2">
                          {detail.extendedCost}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

            <SaveButtons
              disableSubmit={isEveryChangeDisabled(state)}
              handleSubmit={() =>
                submitFormikOrShowDialog(
                  rejectedItems,
                  formikProps.handleSubmit,
                  formikProps.values
                )
              }
            />

            <ViewNotesModal
              open={notesDialog.open}
              notes={notesDialog.item?.notes}
              onClose={notesDialog.closeDialog}
              title={t('view-notes-title', {
                description: notesDialog.item?.description || '',
              })}
            />

            <RejectionNotesEntryModal
              open={notesEntryDialog.open}
              rejectedDetails={rejectedItems}
              onClose={notesEntryDialog.closeDialog}
              onSubmit={notes =>
                addRejectionNotes(
                  notes,
                  formikProps.handleSubmit,
                  formikProps.setFieldValue
                )
              }
              title={t('rejection-notes-entry-title')}
            />

            <RejectionReasonsModal
              open={rejectionsDialog.open}
              title={tReq('apc-line-item-rejections-title')}
              items={rejectionsDialog.open ? [rejectionsDialog.item] : []}
              rejectionReasons={
                state.data.maintenance.repairRejectionReasons?.lineItemReasons
              }
              onClose={rejectionsDialog.closeDialog}
            />
          </>
        );
      }}
    </Formik>
  );
};

MaintenanceRepairDetailsRequest.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

MaintenanceRepairDetailsRequest.defaultProps = {
  className: undefined,
  size: undefined,
};

export default MaintenanceRepairDetailsRequest;
