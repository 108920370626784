import api from '../../util/api';

export const PM_ASSET_CATEGORIES = [
  'car',
  'suv',
  'mpv',
  'misc',
  'truck',
  'van',
];

export const PM_AUTO_GENERATE_OPTIONS = [0, 7, 14, 21, 30];

export const getPMNotifications = config =>
  api.get(`/preventive-maintenance`, config);

export const getPMNotification = assetId =>
  api.get(`/preventive-maintenance/asset/${assetId}`);

export const getPreferredVendor = () =>
  api.get('/maint-repair/preferred-vendors');

export const updatePreferredVendor = vendorId =>
  api.post(`/maint-repair/preferred-vendor`, { vendorId });

export const deletePreferredVendor = () =>
  api.delete(`/maint-repair/preferred-vendor`);

export const createPMSchedule = (intervalMonth, intervalOdometer) =>
  api.post(`/preventive-maintenance/service-schedule`, {
    intervalMonth,
    intervalOdometer,
  });

export const getPMSchedules = () =>
  api.get('/preventive-maintenance/service-schedules');

export const updatePMSchedule = (id, update) =>
  api.patch(`/preventive-maintenance/service-schedule/${id}`, update);

export const getPMSchedule = id =>
  api.get(`/preventive-maintenance/service-schedule/${id}`);
