import API from '../../../util/api';
import { OrgActionTypes } from '../action-types';
import { makeActionCreator, ActionsResponseMapper } from '../util';

// %%%%%%%%%%%%%%%%%%%%%%%%%% ACTION CREATORS %%%%%%%%%%%%%%%%%%%%%.

// FETCH.
export const fetchOrgNodeRequest = makeActionCreator(
  OrgActionTypes.FETCH_ORG_NODE_REQUEST,
  'id'
);
export const fetchOrgNodeSuccess = makeActionCreator(
  OrgActionTypes.FETCH_ORG_NODE_SUCCESS,
  'orgNode',
  'messages'
);
export const fetchOrgNodeFailure = makeActionCreator(
  OrgActionTypes.FETCH_ORG_NODE_FAILURE,
  'id',
  'errors'
);

// ADD.
export const addOrgNodeRequest = makeActionCreator(
  OrgActionTypes.ADD_ORG_NODE_REQUEST,
  'id'
);
export const addOrgNodeSuccess = makeActionCreator(
  OrgActionTypes.ADD_ORG_NODE_SUCCESS,
  'orgNode',
  'messages'
);
export const addOrgNodeFailure = makeActionCreator(
  OrgActionTypes.ADD_ORG_NODE_FAILURE,
  'id',
  'errors'
);
export const addOrgNodeChild = makeActionCreator(
  OrgActionTypes.ADD_ORG_NODE_CHILD,
  'parentId',
  'childId'
);

export const updateOrgNodeRequest = makeActionCreator(
  OrgActionTypes.UPDATE_ORG_NODE_REQUEST,
  'id'
);
export const updateOrgNodeSuccess = makeActionCreator(
  OrgActionTypes.UPDATE_ORG_NODE_SUCCESS,
  'orgNode',
  'messages'
);
export const updateOrgNodeFailure = makeActionCreator(
  OrgActionTypes.UPDATE_ORG_NODE_FAILURE,
  'id',
  'errors'
);

// REMOVE
export const removeOrgNodeRequest = makeActionCreator(
  OrgActionTypes.REMOVE_ORG_NODE_REQUEST,
  'id'
);
export const removeOrgNodeSuccess = makeActionCreator(
  OrgActionTypes.REMOVE_ORG_NODE_SUCCESS,
  'id',
  'messages'
);
export const removeOrgNodeFailure = makeActionCreator(
  OrgActionTypes.REMOVE_ORG_NODE_FAILURE,
  'id',
  'errors'
);

export const fetchOrgNode = id => ({
  actions: [fetchOrgNodeRequest, fetchOrgNodeSuccess, fetchOrgNodeFailure],
  addPayload: id,
  callAPI: () => API.get(`/organization/node/${id}`),
  responseMapper: new ActionsResponseMapper(),
});

export const addOrgNode = (orgNode, parentId) => {
  const id = 'toBeAdded';
  orgNode.id = id;

  return {
    actions: [addOrgNodeRequest, addOrgNodeSuccess, addOrgNodeFailure],
    callAPI: () => API.post('/organization/node', orgNode),
    addPayload: id,
    responseMapper: new ActionsResponseMapper(),
    onSuccess: (response, dispatch) => {
      dispatch(
        addOrgNodeSuccess(
          {
            ...response.payload,
            id,
            apiId: response.payload.id,
          },
          response.messages
        )
      );

      dispatch(addOrgNodeChild(parentId, response.payload.id));
    },
  };
};

export const updateOrgNode = (id, updates) => ({
  actions: [updateOrgNodeRequest, updateOrgNodeSuccess, updateOrgNodeFailure],
  callAPI: () => API.patch(`/organization/node/${id}`, updates),
  addPayload: id,
  responseMapper: new ActionsResponseMapper(),
});

export const removeOrgNode = id => ({
  actions: [removeOrgNodeRequest, removeOrgNodeSuccess, removeOrgNodeFailure],
  callAPI: () => API.delete(`/organization/node/${id}`),
  addPayload: id,
  successPayload: id,
  responseMapper: new ActionsResponseMapper(),
});
