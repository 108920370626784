import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';

import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import TextLink from './text-link';

const AdapterLink = React.forwardRef((props, ref) => (
  <RouterLink ref={ref} {...props} style={{ fontWeight: 'bold' }} />
));

// get style from rest and add new style with bold and then put through to component
const LinkComponent = pure(({ to, ...rest }) =>
  to ? (
    <Link to={to} component={AdapterLink} color="secondary" {...rest} />
  ) : (
    <TextLink {...rest} />
  )
);

LinkComponent.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

LinkComponent.defaultProps = {
  to: undefined,
};

export default LinkComponent;
