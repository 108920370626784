/* eslint-disable camelcase */
// We can't change the label of node_label. It will break a lot of things...
import { useSelector } from 'react-redux';
import { selectors } from '../../redux/reducers/index.reducer';

const useGetLocationsFromId = props => {
  const orgNodesFlattened = useSelector(selectors.getOrgNodes);

  if (!props) {
    return null;
  }

  const selectedNode = orgNodesFlattened.find(orgNode => orgNode.id === props);

  const findParent = nodeId =>
    orgNodesFlattened.find(x => x.childIds.includes(nodeId));

  if (selectedNode) {
    const splits = selectedNode?.completeLabel.split('\n');
    const selectedNodeId = selectedNode?.id;
    let level1Node = { name: '', id: '' };
    let level2Node = { name: '', id: '' };
    let level3Node = { name: '', id: '' };

    if (splits?.length === 1) {
      level1Node = selectedNode;
    }

    if (splits?.length === 2) {
      const level1 = findParent(selectedNodeId);
      level1Node = level1;
      level2Node = selectedNode;
    }

    if (splits?.length === 3) {
      const level2 = findParent(selectedNodeId);
      level2Node = level2;
      const level1 = findParent(level2?.id);
      level1Node = level1;
      level3Node = selectedNode;
    }

    return {
      level1: { name: level1Node?.node_label, id: level1Node?.id },
      level2: { name: level2Node?.node_label, id: level2Node?.id },
      level3: { name: level3Node?.node_label, id: level3Node?.id },
    };
  }
};

export default useGetLocationsFromId;
