import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import isBase64 from 'validator/lib/isBase64';
import queryString from 'query-string';
import xss from 'xss';
import * as Sentry from '@sentry/react';
import { useNSTranslation } from '../../../../lib/lang';

/**
 * Variables and config for xss attack validation
 */
const xssOptions = {
  whiteList: [],
  stripIgnoreTag: true,
  stripIgnoreTagBody: true,
};
const dangerList = ['javascript', 'vbscript', 'livescript'];

/**
 * Process single query item. Assign it to null if it has suspicious content inside.
 * Otherwise, return it as-is
 * @param {string} queryItem
 * @param {function} t
 */
const decodeQueryItem = queryItem => {
  try {
    if (queryItem === null || queryItem === undefined) {
      return null;
    }
    if (typeof queryItem === 'string' && isBase64(queryItem)) {
      const decoded = window.atob(queryItem);
      if (dangerList.some(string => decoded.includes(string))) {
        return null;
      }
    }
    return xss(queryItem, xssOptions);
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

/**
 * Iterate URL params to check for base64 encoded javascript
 */
function decodeParams(params) {
  return Object.keys(params).reduce((attrs, key) => {
    if (Array.isArray(params[key])) {
      attrs[key] = params[key].map(
        queryItem => queryItem !== null && decodeQueryItem(queryItem)
      );
    } else {
      attrs[key] = params[key] !== null && decodeQueryItem(params[key]);
    }
    return attrs;
  }, {});
}

/**
 * Checks whether the result of decodeParams has errors
 * @param {*} params
 */
const hasErrors = params => {
  return Object.values(params).some(value => {
    if (Array.isArray(value)) {
      return value.some(v => v === null);
    }
    return value === null;
  });
};

/**
 * This hook is a small wrapper on top of some basic browser items.
 * I mainly created it to avoid the need to import useLocation & queryString
 * everywhere that we need access to the query parameters.
 * It also will take care of parsing the query params with queryString as well.
 * As of now it creates all the necessary variables to re-structure the link
 * to the current page.
 */
function useBrowserInfo() {
  const { t } = useNSTranslation('main', 'use-browser-info');
  const location = useLocation();
  const { origin } = window.location;

  // Safely extract query parameters
  const params = useMemo(
    () => decodeParams(queryString.parse(location.search)),
    [location.search]
  );

  const errors = hasErrors(params)
    ? [
        {
          type: 'error',
          code: 'unmapped-error',
          message: t('invalid-parameters'),
        },
      ]
    : null;

  return { params, origin, location, errors };
}

export default useBrowserInfo;
