import { AuthActionTypes } from '../actions/action-types';

export const initialState = {
  bootstrapStatus: 'idle',
  loggingIn: false,
  loggedIn: false,
  loggingOut: false,
  explicitLogout: false,
  loadingAccount: false,
  user: null,
  errors: [],
  accountStatus: null,
  authDetails: null,
  isFetchingAuthDetails: false,
  authDetailsErrors: [],
  serverDown: false,
  hasLoggedOut: false,
};

const authReducer = (state = initialState, action) => {
  const { hasLoggedOut } = state;

  switch (action.type) {
    /**
     * The hasLoggedOut boolean value is used to prevent the bootstrapping request from
     * firing off immediately after logout (main.jsx). Although the rootReducer (index.reducer.js)
     * resets the store by returning the undefined state, this action is received after that reset
     * and this would be the first action the "reset" state see.s
     */
    case AuthActionTypes.LOGOUT: {
      return {
        ...state,
        hasLoggedOut: true,
      };
    }

    case AuthActionTypes.PRE_LOGOUT: {
      // ignore if user is not loggedIn. It causes redundant logout which leads to incorrect redirect.
      if (hasLoggedOut) {
        return state;
      }
      return {
        ...state,
        loggingOut: true,
        explicitLogout: action.explicitLogout,
      };
    }

    case AuthActionTypes.BOOTSTRAP_REQUEST: {
      return {
        ...state,
        bootstrapStatus: 'pending',
      };
    }

    case AuthActionTypes.BOOTSTRAP_FAILURE: {
      return {
        ...state,
        bootstrapStatus: 'rejected',
      };
    }

    case AuthActionTypes.BOOTSTRAP_SUCCESS: {
      return {
        ...state,
        bootstrapStatus: 'resolved',
      };
    }

    case AuthActionTypes.LOAD_USER_DATA: {
      return {
        ...state,
        user: action.user,
        loadingAccount: false,
      };
    }

    case AuthActionTypes.CHECK_ACCOUNT_REQUEST:
    case AuthActionTypes.SIGNUP_REQUEST:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        errors: [],
        accountStatus: null,
      };

    case AuthActionTypes.FETCH_AUTH_DETAILS_REQUEST:
      return {
        ...state,
        authDetailsErrors: [],
        authDetails: null,
        isFetchingAuthDetails: true,
      };

    case AuthActionTypes.CHECK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        accountStatus: action.accountStatus,
        errors: action.messages || [],
      };

    case AuthActionTypes.FETCH_AUTH_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingAuthDetails: false,
        authDetails: action.authDetails,
        authDetailsErrors: action.messages || [],
      };

    case AuthActionTypes.SIGNUP_SUCCESS:
    case AuthActionTypes.NEW_USER_FINALIZE_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        errors: action.messages || [],
      };

    case AuthActionTypes.LOGIN_REQUEST:
    case AuthActionTypes.CHANGE_PASSWORD_REQUEST:
    case AuthActionTypes.NEW_USER_FINALIZE_REQUEST:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        errors: [],
        user: null,
      };

    case AuthActionTypes.LOGIN_SUCCESS:
    case AuthActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        hasLoggedOut: false,
        errors: action.messages || [],
      };

    case AuthActionTypes.LOGIN_FAILURE:
    case AuthActionTypes.CHANGE_PASSWORD_FAILURE:
    case AuthActionTypes.CHECK_ACCOUNT_FAILURE:
    case AuthActionTypes.NEW_USER_FINALIZE_FAILURE:
    case AuthActionTypes.SIGNUP_FAILURE:
      return {
        ...state,
        errors: action.errors,
        loggingIn: false,
      };

    case AuthActionTypes.FETCH_AUTH_DETAILS_FAILURE:
      return {
        ...state,
        authDetailsErrors: action.errors,
        isFetchingAuthDetails: false,
      };

    case AuthActionTypes.RESET_ACCOUNT_STATUS:
      return {
        ...state,
        accountStatus: null,
      };

    case AuthActionTypes.SERVER_DOWN:
      return {
        ...state,
        serverDown: true,
      };

    // NOTE LOGOUT is handled in root reducer (to invalidate the whole store).

    default:
      return state;
  }
};

export default authReducer;

//-----------------------------------
// Selectors

export const getLoggingIn = state => state.loggingIn;

export const getLoadingAccount = state => state.loadingAccount;
export const getBootstrapStatus = state => state.bootstrapStatus;

export const getLoggedIn = state => state.loggedIn;

export const getHasLoggedOut = state => state.hasLoggedOut;

export const getLoggingOut = state => ({
  loggingOut: state.loggingOut,
  explicitLogout: state.explicitLogout,
});

export const getCurrentUser = state => state.user;

export const getAuthErrors = state => state.errors;

export const getAccountStatus = state => state.accountStatus;

export const getAuthDetails = state => state.authDetails;

export const getIsFetchingAuthDetails = state => state.isFetchingAuthDetails;
export const getAuthDetailsErrors = state => state.authDetailsErrors;
export const getServerDown = state => state.serverDown;
export const getLastUpdated = state => state.lastUpdated;
