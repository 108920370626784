import { process as kendoProcess } from '@progress/kendo-data-query';
import { createFacetFilters } from '../use-filtering';
import { createSearchFilters } from '../use-searching';

export function createProcessConfig(filters, sort, group, skip, take) {
  return { filter: { logic: 'and', filters }, sort, group, skip, take };
}

export { process as kendoProcess } from '@progress/kendo-data-query';

export default function process({
  fields,
  data,
  filters,
  searchTerm,
  sortField = undefined,
  sortDirection = undefined,
  skip,
  take,
}) {
  const facetFilters = createFacetFilters(filters, fields);

  const searchFilters =
    searchTerm !== '' ? createSearchFilters(searchTerm, fields) : [];

  const sorting =
    sortField || sortDirection
      ? [{ field: sortField, dir: sortDirection }]
      : [];

  const filterDescriptors = facetFilters.concat(searchFilters);

  const config = createProcessConfig(
    filterDescriptors,
    sorting,
    undefined,
    skip,
    take
  );

  return kendoProcess(data, config);
}
