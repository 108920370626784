/* eslint-disable prefer-promise-reject-errors */

/**
 *
 * @param {any} result - The value to return on success. If supplied an Error, the Promise will reject.
 * @param {number} [ms=250] - Number of milliseconds to delay before resolving or rejecting.
 */
export default function sleep(result, ms = 200) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return result instanceof Error
        ? reject([{ type: 'error', message: result.message }])
        : resolve(result);
    }, ms);
  });
}
