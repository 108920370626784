import axios from 'axios';
import { safeGet } from '@holmanfm/lib/util/helpers';

export default function redirectInterceptor() {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (!error.response) return Promise.reject(error);
      const { status } = error.response;
      const location = safeGet(error, ['response', 'data', 'location']);

      if (Math.floor(status / 100) === 3 && location) {
        console.log('INTERCEPTED API REDIRECT ', error.response.data);
        window.location = location;
        return Promise.resolve(error.response);
      }

      return Promise.reject(error);
    }
  );
}
