import api from '../../util/api';

export const getUsersRequest = () => api.get('/users');

export const updateUserRequest = (id, params) =>
  api.patch(`/users/${id}`, params);

export const createUserRequest = params => api.post(`/users`, params);

export const resendUserLogin = params => api.post(`/users/resendLogin`, params);

export const getResourceDisplayFields = resource =>
  api.get(`/users/account/config/${resource}/all-display-fields`);
export const saveUserResourceDisplayFields = (resource, displayFields) =>
  api.patch(`/users/account/config/${resource}/display-fields`, displayFields);
export const addPatchUserOrgNode = (userId, data) =>
  api.patch(`/users/${userId}`, data);
