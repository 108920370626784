import PropTypes from 'prop-types';
import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { MaintenanceRepairItemsShape } from '@holmanfm/lib/services/maintenance-repair-history';
import { formatDateTime, formatDate } from '@holmanfm/lib/util/moment';
import { floatToMoney } from '@holmanfm/lib/common/marketplace/money';
import { formatBySystemPrefix } from '@holmanfm/lib/common/service-request-helpers';
import { odometerMiles } from '@holmanfm/lib/common/odometer-conversion';
import { requestStatusEmptyDate } from '@holmanfm/lib/common/request-status-helpers';
import StatusText from '~/areas/service-requests/browse-service-requests/status-text';
import Link from '~/shared/components/link';
import makeStyles from '~/shared/components/makeStyles';
import Typography from '~/shared/components/atom/typography';
import InsertDriveFile from '~/shared/icons/insert-drive-file';
import AddCircleOutline from '~/shared/icons/add-circle-outline';
import RemoveCircleOutline from '~/shared/icons/remove-circle-outline';
import Tooltip from '~/shared/components/molecule/tooltip';
import Table from '~/shared/components/table';
import TableHead from '~/shared/components/table-head';
import TableRow from '~/shared/components/table-row';
import TableCell from '~/shared/components/table-cell';
import TableContainer from '~/shared/components/table-container';
import TableBody from '~/shared/components/table-body';
import { individualAssetView } from '~/shared/components/routes/link-to-path';
import IconButton from '~/shared/components/atom/icon-button';
import Collapse from '~/shared/components/transitions/collapse';
import MaintenanceRepairDetails from './maintenance-repair-details';

const useStyles = makeStyles(theme => ({
  table: {
    border: 'none',
  },
  collapsibleBorder: {
    border: '1px solid #e6e6e6',
    padding: 0,
  },
  headerRow: {
    '& th': {
      padding: theme.spacing(1),
      color: theme.palette.primary.main,
      border: 'none',
    },
  },
  listRow: {
    backgroundColor: theme.palette.backgroundColors.blue,
    '& td': {
      fontSize: '0.75rem',
      borderColor: theme.palette.backgroundColors.blue,
      padding: theme.spacing(1),
    },
  },
  notesIcon: {
    position: 'relative',
    marginLeft: theme.spacing(0.5),
    fontSize: '1.15rem',
    top: '3px',
  },
  additionalInfo: {
    margin: theme.spacing(2, 1, 1),
    color: theme.palette.grey[600],
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  additionalInfoItem: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  hideBelow: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  hideAbove: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const RequestDetailRow = ({ item, parentOrg, individualAsset }) => {
  const classes = useStyles();
  const { t } = useNSTranslation('maintenanceRepair', 'history');
  const { t: tHistory } = useNSTranslation('common', 'entry-metadata');
  const noOdometer = t('no-record');
  const noUserFound = t('no-user-found');
  const isOdometer = item.odometerMiles || item.odometer;
  const [open, setOpen] = useState(false);

  const asset = item?.asset || '';
  const vendor = item?.vendor || '';

  // TODO was used for scrolling to last item clicked - needs an update with new values
  // const { state } = useLocation();
  // useEffect(() => {
  //   if (state) {
  //     const assetId = state.id;
  //     const element = document.getElementById(assetId);
  //     if (element) {
  //       element.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center',
  //         inline: 'nearest',
  //       });
  //     }
  //   }
  // }, [state]);

  return (
    <TableBody>
      <TableRow className={classes.listRow}>
        <TableCell>
          {!individualAsset && (
            <>
              <Link
                color="secondary"
                to={individualAssetView(
                  `${asset?.assetNo}#maintenance`,
                  parentOrg
                )}
              >
                {asset?.assetNo}
              </Link>
              <br />
              <span>
                {asset?.year} {asset?.make} {asset?.model}
              </span>
            </>
          )}
        </TableCell>
        <TableCell style={{ whiteSpace: 'nowrap' }}>
          {!parentOrg
            ? item.requestId && (
              <Link
                to={{
                  pathname: `/customer/request-management/${item.requestId}`,
                  state: { type: 'mr', assetNo: item.requestId },
                }}
              >
                {item.requestId}
              </Link>
              )
            : item.requestId}
          {!parentOrg && (
            <Link
              to={{
                pathname: `/customer/request-management/${item.requestId}`,
                state: { type: 'mr', assetNo: item.requestId, tabId: 1 },
              }}
              className={classes.hideBelow}
            >
              <Tooltip lightTooltip title="Click to view notes" placement="top">
                <InsertDriveFile
                  className={classes.notesIcon}
                  style={
                    item.hasNotes ? { color: '#000' } : { color: '#D8D8D8' }
                  }
                />
              </Tooltip>
            </Link>
          )}
        </TableCell>
        <TableCell>{vendor?.VENDOR_NAME}</TableCell>
        <TableCell align="right">
          {isOdometer
            ? item?.odometerMiles || odometerMiles(item?.odometer)
            : noOdometer}
        </TableCell>
        <TableCell align="right">
          {item?.requestedAt
            ? formatDate(item.requestedAt)
            : requestStatusEmptyDate}
        </TableCell>
        <TableCell align="right">
          {item?.repairDate
            ? formatDate(item.repairDate)
            : requestStatusEmptyDate}
        </TableCell>
        <TableCell align="right">
          {item?.totalCost ? floatToMoney(item?.totalCost) : ''}
        </TableCell>
        <TableCell>
          {item?.requestStatus && (
            <>
              <StatusText
                status={item.requestStatus.toLowerCase()}
                className={classes.hideBelow}
              />
              <span className={classes.hideAbove}>{item.requestStatus}</span>
            </>
          )}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <RemoveCircleOutline /> : <AddCircleOutline />}
          </IconButton>
        </TableCell>
      </TableRow>
      {/* collapsed row */}
      <TableRow>
        <TableCell
          className={classes.collapsibleBorder}
          colSpan={9}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <MaintenanceRepairDetails
              item={item?.maintenanceRepairDetails}
              className={classes.table}
              size="small"
            />
            <div className={classes.additionalInfo}>
              <div className={classes.additionalInfoItem}>
                {item?.headerCreatedAt && (
                  <Typography
                    variant="caption"
                    style={{ paddingRight: '16px' }}
                  >
                    {t('created-by')}:
                    {formatBySystemPrefix(
                      item.headerCreatedBy,
                      tHistory('system'),
                      noUserFound
                    )}{' '}
                    {formatDateTime(item.headerCreatedAt)}
                  </Typography>
                )}
                {item?.headerUpdatedAt && (
                  <Typography variant="caption">
                    {t('last-updated-by')}:
                    {formatBySystemPrefix(
                      item.headerUpdatedBy,
                      tHistory('system'),
                      noUserFound
                    )}{' '}
                    {formatDateTime(item.headerUpdatedAt)}
                  </Typography>
                )}
              </div>
              {item?.supplierPoNumber && (
                <div className={classes.additionalInfoItem}>
                  <Typography variant="caption">
                    {t('ref-id')}: {item.supplierPoNumber}
                  </Typography>
                </div>
              )}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      {/* spacer row */}
      <TableRow>
        <TableCell colSpan={9} style={{ border: 'none', padding: 8 }} />
      </TableRow>
    </TableBody>
  );
};

RequestDetailRow.propTypes = {
  item: MaintenanceRepairItemsShape.isRequired,
  parentOrg: PropTypes.bool.isRequired,
  individualAsset: PropTypes.bool,
};
RequestDetailRow.defaultProps = {
  individualAsset: false,
};

const RequestDetailRows = props => {
  const { t } = useNSTranslation('maintenanceRepair', 'history');
  const classes = useStyles();
  const { items, parentOrg, individualAsset } = props;
  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell>
              {!individualAsset && (
                <Typography variant="subtitle1">{t('asset')}</Typography>
              )}
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">{t('request-id')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <span className={classes.hideBelow}>
                  {t('supplier-vendor')}
                </span>
                <span className={classes.hideAbove}>{t('vendor')}</span>
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle1">
                {t('repair-odometer')}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle1">{t('request-date')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle1">{t('repair-date')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle1">{t('total-cost')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">{t('status')}</Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {items?.map(item => (
          <RequestDetailRow
            parentOrg={parentOrg}
            item={item}
            individualAsset={individualAsset}
            key={item.id}
          />
        ))}
      </Table>
    </TableContainer>
  );
};

export default RequestDetailRows;

RequestDetailRows.propTypes = {
  items: PropTypes.arrayOf(MaintenanceRepairItemsShape).isRequired,
  parentOrg: PropTypes.bool,
  individualAsset: PropTypes.bool,
};

RequestDetailRows.defaultProps = {
  parentOrg: false,
  individualAsset: false,
};
