import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import { useSelector } from '@holmanfm/lib/redux';
import CreateMuiTheme from '~/shared/components/create-theme';
import CreateThemeV5 from '~/shared/components/create-theme-v5';
import responsiveFontSizes from '~/shared/components/responsive-font-sizes';
import ThemeProvider from '~/shared/components/theme-provider';
import ThemeProviderV5 from '~/shared/components/theme-provider-v5';
import { defaultThemeObject } from '~/theme';

/**
 * Provider for global app theme.
 * Uses default app theme by default.
 * If exists, overrides default theme properties with `organization.auth_details.theme` db object.
 * @param {*} children
 * @returns react element
 */
const CustomThemeProvider = ({ children }) => {
  // when logged in.
  // eslint-disable-next-line camelcase
  const themeAPIPrivate = useSelector(selectors.getOrg)?.auth_details?.theme;
  // eslint-disable-next-line camelcase
  const themeAPIParent = useSelector(selectors.getOrg)?.parent?.auth_details
    ?.theme;
  // when logged out.
  const themeAPIPublic = useSelector(selectors.getAuthDetails)?.theme;
  const themeAPI = themeAPIPrivate || themeAPIParent || themeAPIPublic;
  const themeV4 = useMemo(() => {
    return responsiveFontSizes(
      // Merge 2 objects recursively
      CreateMuiTheme(merge(cloneDeep(defaultThemeObject), themeAPI || {}))
    );
  }, [themeAPI]);

  // used for MUI X components and MUIv5 in the future
  const themeV5 = useMemo(() => {
    return responsiveFontSizes(
      CreateThemeV5(merge(cloneDeep(defaultThemeObject), themeAPI || {}))
    );
  }, [themeAPI]);

  return (
    <ThemeProvider theme={themeV4}>
      <ThemeProviderV5 theme={themeV5}>{children}</ThemeProviderV5>
    </ThemeProvider>
  );
};

CustomThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CustomThemeProvider;
