// Shape of an item, as returned by the ACE API:
// {
//   userId,
//   userFirstName,
//   userLastName,
//   userEmail,
//   userRoleInOrg,
//   orgName,
//   orgId,
// };

const formatUserLabel = o =>
  `${o.userLastName}, ${o.userFirstName} (${o.userEmail}) [${o.userRoleInOrg}]`;

const convertPayloadArrayItemToOrg = o => ({
  orgValue: o.orgId,
  orgLabel: o.orgName,
});

const convertPayloadArrayItemToUser = o => ({
  userValue: o.userId,
  userLabel: formatUserLabel(o),
});

const nestUserUnderOrg = (user, org) => {
  if (!org.orgUsers) {
    org.orgUsers = [user];
    return;
  }
  const existingUser = org.orgUsers.find(
    item => item.userValue === user.userId
  );
  if (!existingUser) {
    org.orgUsers.push(user);
  }
};

const convertPayloadToOrgUserList = payload => {
  const orgs = [];
  if (!Array.isArray(payload)) {
    return orgs;
  }
  payload.forEach(element => {
    const org = convertPayloadArrayItemToOrg(element);
    const user = convertPayloadArrayItemToUser(element);
    let existingOrg = orgs.find(o => o.orgValue === org.orgValue);
    if (!existingOrg) {
      orgs.push(org);
      existingOrg = org;
    }
    nestUserUnderOrg(user, existingOrg);
  });
  // dress up for presentation
  orgs.sort((a, b) => {
    if (a.orgLabel < b.orgLabel) {
      return -1;
    }
    if (a.orgLabel > b.orgLabel) {
      return 1;
    }
    return 0;
  });
  orgs.forEach(org =>
    org.orgUsers.sort((a, b) => {
      const left = a.userLabel.toLowerCase();
      const right = b.userLabel.toLowerCase();
      if (left < right) {
        return -1;
      }
      if (left > right) {
        return 1;
      }
      return 0;
    })
  );
  return orgs;
};

export default convertPayloadToOrgUserList;
