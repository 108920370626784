import React from 'react';
import PropTypes from 'prop-types';
import clx from 'classnames';

import { useNSTranslation } from '@holmanfm/lib/lang';
import makeStyles from '~/shared/components/makeStyles';
import Upload from '~/shared/icons/upload';
import Typography from '~/shared/components/atom/typography';
import useDrag from './use-drag';

const useStyles = makeStyles(theme => ({
  dragDropContainer: {
    position: 'relative',
    margin: theme.spacing(0, 0, 2),
    border: '2px dotted lightgray',
    padding: theme.spacing(2, 2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    minWidth: 300,
  },
  dragging: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  topDragDropText: {
    marginTop: 0,
    marginBottom: '0.5em',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  bottomDragDropText: {
    marginTop: '0.5em',
    marginBottom: '0em',
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
  fileInput: {
    fontSize: theme.spacing(2),
    display: 'block',
    width: '100%',
    border: 'none',
    textTransform: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    cursor: 'pointer',
  },
}));

const FileUpload = React.forwardRef(
  ({ onUpload, multiple, accept, ...otherProps }, ref) => {
    const { t } = useNSTranslation('common', 'file-manager');
    const classes = useStyles();
    const {
      dragging,
      handleDragLeave,
      handleDragEnter,
      handleDrop,
    } = useDrag();

    return (
      <div
        className={clx(classes.dragDropContainer, {
          [classes.dragging]: dragging,
        })}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <Typography variant="h6" className={classes.topDragDropText}>
          {t('upload-button-1')}
        </Typography>
        <Upload />
        <Typography variant="h6" className={classes.bottomDragDropText}>
          {t('upload-button-2')}
        </Typography>
        <input
          id="file-upload"
          className={classes.fileInput}
          type="file"
          onChange={onUpload}
          multiple={multiple}
          accept={accept?.join(', ')}
          {...otherProps}
          ref={ref}
        />
      </div>
    );
  }
);

FileUpload.propTypes = {
  onUpload: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.arrayOf(PropTypes.string),
};

FileUpload.defaultProps = {
  multiple: false,
  accept: undefined,
};

export default FileUpload;
