import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import makeStyles from '~/shared/components/makeStyles';
import Link from '~/shared/components/link';
import Typography from '../../atom/typography';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(6, 2),
    border: `1px solid ${theme.palette.grey.greyLight}`,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '80%',
    height: 'auto',
  },
  desktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  main: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  errorMessage: {
    color: '#808080',
    marginTop: theme.spacing(2),
  },
}));

const ServerDownMessage = ({ message, dataTestId }) => {
  const classes = useStyles();
  const { t } = useNSTranslation('common', ['errors', 'server-down']);
  return (
    <div className={classes.wrapper} data-testid={dataTestId} role="alert">
      <div className={classes.container}>
        <div>
          <img
            className={classes.image}
            width="170"
            alt="spinning wheels"
            src="https://assets.holmanfm.com/wheel-gif.gif"
          />
        </div>
        <div>
          <Typography variant="h3" style={{ marginBottom: '16px' }}>
            {t('title')}
          </Typography>

          <Typography variant="body1" className={classes.main} component="div">
            <div className={classes.desktop} style={{ marginBottom: '8px' }}>
              {t('copy1')}
            </div>
            <div>
              {t('copy2')}
              <Link href="mailto:support@holmanfm.com" target="_blank">
                support@holmanfm.com
              </Link>
              .
            </div>
          </Typography>

          {message && (
            <Typography variant="body2" className={classes.errorMessage}>
              {typeof message === 'string' ? message : JSON.stringify(message)}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

ServerDownMessage.propTypes = {
  // eslint-disable-next-line
  message: PropTypes.any,
  dataTestId: PropTypes.string,
};

ServerDownMessage.defaultProps = {
  message: null,
  dataTestId: 'error-message',
};

export default ServerDownMessage;
