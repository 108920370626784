import { createSelector } from 'reselect';
import { CartActionTypes } from '../../actions/action-types';
import { safeGet } from '../../../util/helpers';

const {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  SET_CART_CHECKOUT_OPEN,
} = CartActionTypes;

export const initialState = {
  items: [],
  cartCheckoutOpen: false,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const { payload } = action;

      const items = [...state.items];
      const idx = items.findIndex(x => x.id === payload.id);
      const itemsToReplace = items.filter(x => x.id !== payload.id);

      if (idx !== -1) {
        return {
          ...state,
          items: [...itemsToReplace, payload],
        };
      }
      return {
        ...state,
        items: [...state.items, payload],
      };
    }
    case REMOVE_FROM_CART: {
      const { id } = action.payload;
      const items = [...state.items];
      const newItems = items.filter(i => i.id !== id);
      return {
        ...state,
        items: newItems,
      };
    }

    case CLEAR_CART: {
      return { items: [] };
    }
    case SET_CART_CHECKOUT_OPEN: {
      return {
        ...state,
        cartCheckoutOpen: action.payload.cartCheckoutOpen,
      };
    }
    default:
      return state;
  }
};

export default cartReducer;

export const getCartItems = state => state.items;
export const getCartCheckoutOpen = state =>
  safeGet(state, ['cartCheckoutOpen']);

export const getCartItemsDict = createSelector([getCartItems], items =>
  items.reduce((dict, item) => {
    dict[item.id] = item;
    return dict;
  }, {})
);

export const getSortedCartItems = createSelector([getCartItems], items => {
  if (items && items.length > 0) {
    return items.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
  return [];
});

export const getCartTotal = createSelector([getCartItems], items => {
  if (!items || items.length === 0) {
    return 0;
  }
  return items.reduce((acc, { price, quantity }) => acc + price * quantity, 0);
});

export const getCartTotalUnit = createSelector([getCartItems], items => {
  if (!items || items.length === 0) {
    return '';
  }
  return items[0].unit;
});
