import React from 'react';

const useDrag = () => {
  const [dragging, setDragging] = React.useState(false);
  // Handle child events. Dragging is truly over when this count is 0
  const dragCounter = React.useRef(0);

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current += 1;
    setDragging(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current -= 1;
    if (dragCounter.current > 0) return;
    setDragging(false);
  };

  const handleDrop = () => {
    setDragging(false);
    dragCounter.current = 0;
  };

  return {
    dragging,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
  };
};

export default useDrag;
