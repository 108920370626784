import api from '../../util/api';

export const decodeVin = vin =>
  api.get('/asset/decode-vin', { params: { vin } });

export const getOrgInfo = orgId => api.get(`/dealer/customer/${orgId}`);

export const getSavedEstimate = workflowId =>
  api.get(`/dealer/estimate/${workflowId}`);

export const getChildOrgs = () => api.get('/parent-org');

export const sendForEstimate = data => api.post('/dealer/estimate', data);

export const getLeasingOptions = data =>
  api.post('/dealer/quote/estimate', data);

export const sendForQuote = (data, estimateId) =>
  api.post(`/dealer/estimate/${estimateId}/quote`, data);

export const updateEstimate = (data, workflowId) =>
  api.patch(`/dealer/estimate/${workflowId}`, data);

export const cancelEstimate = workflowId =>
  api.post(`/dealer/estimate/${workflowId}/action`, {
    action: 'CancelEstimate',
  });

export const emailDuplication = email =>
  api.get(`/dealer/email-availability/${email}`);

export const vinDuplication = vin => api.get(`/dealer/vin-availability/${vin}`);

export const getDealerMembers = () => api.get('/dealer/member-dealers');
