import * as React from 'react';
import CommonColors from './colors';
import {
  getServiceRequestDetails,
  updateServiceRequest,
} from '../services/service-request';
import fieldSorter from '../util/fieldSorter';

const UNASSIGNED = 'unassigned';
const ASSIGNED = 'assigned';
const STARTED = 'started';

/**
 * Hey! If you've come here to change which request statuses are grouped for each slice of the
 * pie chart, look no further! Just add or remove statuses in these two arrays and BOOM done-zo.
 * Dont add the same status twice. Im not going to check, because you're an adult and you were
 * warned. 💚
 */
const UNASSIGNED_SLICE_STATUSES = [UNASSIGNED];

const ASSIGNED_SLICE_STATUSES = [ASSIGNED, STARTED];

export const DISPLAYED_STATUSES = UNASSIGNED_SLICE_STATUSES.concat(
  ASSIGNED_SLICE_STATUSES
);

export function groupByStatus(acc, item) {
  if (ASSIGNED_SLICE_STATUSES.includes(item.status)) {
    acc.assigned += 1;
  }

  if (UNASSIGNED_SLICE_STATUSES.includes(item.status)) {
    acc.unassigned += 1;
  }

  return acc;
}

export function getPercent(num, total) {
  return Math.floor((num * 100) / total);
}

export function getServiceCounts(availableServices, allFilteredItems) {
  const countMap = availableServices.reduce((acc, value) => {
    acc[value] = 0;
    return acc;
  }, {});

  return allFilteredItems.reduce((acc, value) => {
    acc[value.service] += 1;
    return acc;
  }, countMap);
}

export const formatBySystemPrefix = (
  createdByUpdatedBy,
  prefix,
  nullCase = 'N/A'
) => {
  if (createdByUpdatedBy) {
    if (!createdByUpdatedBy.includes('@')) {
      return `${prefix}-${createdByUpdatedBy}`;
    }
    return createdByUpdatedBy;
  }

  return nullCase;
};

export function getStatusColorFromPalette(status) {
  switch (status) {
    case 'assigned':
      return CommonColors.sharedPalette.requestAssigned.main;
    case 'unassigned':
      return CommonColors.sharedPalette.requestUnassigned.main;
    case 'out-to-bid':
      return CommonColors.sharedPalette.requestOutToBid.main;
    case 'started':
      return CommonColors.sharedPalette.requestStarted.main;
    case 'completed':
      return CommonColors.sharedPalette.requestCompleted.main;
    case 'cancelled':
      return CommonColors.sharedPalette.commonColors.black;
    // fuel card statuses
    case 'canceled': // misspelled data in fuel cards
      return CommonColors.sharedPalette.commonColors.black;
    case 'active':
      return CommonColors.fieldGreen;
    case 'lost':
      return CommonColors.sharedPalette.requestStarted.main;
    case 'replacement':
      return CommonColors.sharedPalette.neutral.dark30;
    case 'stolen':
      return CommonColors.sharedPalette.commonColors.black;
    case 'suspended':
    case 'terminated':
      return CommonColors.sharedPalette.error.main;
    default:
      return CommonColors.sharedPalette.primary.main;
  }
}

export const onRead = async id => {
  const { payload } = await getServiceRequestDetails(id);
  return payload;
};

export const onUpdate = async (id, update, existing) => {
  /** Somewhat annoyingly the GET uses a "pretty ID" but the PATCH requires the UUID. */
  const { payload } = await updateServiceRequest(existing.request.id, update);
  return payload;
};

const descendingCreatedAt = (a, b) =>
  new Date(b.createdAt) - new Date(a.createdAt);

export const mapNotes = (requestNotes, NoteItem) =>
  requestNotes
    .slice() // If you try to mutate requestNotes, the application will explode.
    .sort(descendingCreatedAt)
    .map(requestedNote => (
      <NoteItem key={requestedNote.id} noteItem={requestedNote} />
    ));

export const getOdometerMiles = maintenance =>
  maintenance.odometerMiles ||
  Math.floor(maintenance.odometer * 0.000621371).toLocaleString();

export const getSortedItems = (item, on) =>
  item ? [...item].sort(fieldSorter(on)) : [];
