export default class ResponseMapper {
  static success(responseData) {
    return {
      payload: responseData.payload,
      messages: responseData.messages,
    };
  }

  static failure(responseData) {
    return responseData.messages;
  }

  static unversionedWarn() {
    console.warn('UNKNOWN SERVER VERSION');
  }
}
