import invariant from 'invariant';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import {
  readFail,
  readFinish,
  readStart,
  updateFail,
  updateFinish,
  updateStart,
} from './rudd.actions';
import { initialState, statuses } from './rudd.constants';
import RuddContext from './rudd.context';
import reducer from './rudd.reducer';

const RuddComponent = props => {
  const {
    id,
    onRead,
    onUpdate,
    LoadingComponent,
    ErrorComponent,
    ViewComponent,
    onReadSuccess,
    onReadFailure,
    onUpdateSuccess,
    onUpdateFailure,
    extraProps,
  } = props;

  invariant(
    typeof id === 'string',
    `RuddComponent requires an 'id' string or number property.`
  );

  invariant(
    typeof onRead === 'function',
    `RuddComponent requires an 'onRead' function property.`
  );

  invariant(
    typeof LoadingComponent !== 'undefined',
    `RuddComponent requires a 'LoadingComponent' React component property`
  );

  invariant(
    typeof ErrorComponent !== 'undefined',
    `RuddComponent requires a 'ErrorComponent' React component property`
  );

  invariant(
    typeof ViewComponent !== 'undefined',
    `RuddComponent requires a 'ViewComponent' React component property`
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  const { data, status } = state;

  const readHandler = useCallback(async () => {
    dispatch(readStart());
    try {
      const response = await onRead(id);
      onReadSuccess(response);
      dispatch(readFinish(response));
    } catch (error) {
      onReadFailure(error);
      dispatch(readFail(error));
    }
  }, [onRead, id, dispatch, onReadSuccess, onReadFailure]);

  const updateHandler = useMemo(() => {
    if (typeof onUpdate === 'function') {
      return async update => {
        dispatch(updateStart(id, update));
        try {
          const response = await onUpdate(id, update, data);
          onUpdateSuccess(response);
          dispatch(updateFinish(response));
        } catch (error) {
          onUpdateFailure(error);
          dispatch(updateFail(error));
        }
      };
    }
  }, [onUpdate, id, dispatch, onUpdateSuccess, onUpdateFailure, data]);

  const handlers = useMemo(() => ({ readHandler, updateHandler }), [
    readHandler,
    updateHandler,
  ]);

  const value = useMemo(() => ({ state, handlers, extraProps }), [
    state,
    handlers,
    extraProps,
  ]);

  const RenderedComponent = useMemo(() => {
    switch (status) {
      case statuses.fetching:
      case statuses.idle:
        return LoadingComponent;

      case statuses.errorFetching:
        return ErrorComponent;

      case statuses.errorRefreshing:
      case statuses.errorUpdating:
      case statuses.fulfilled:
      case statuses.refreshing:
      case statuses.updated:
      case statuses.updating:
        return ViewComponent;

      default:
        return ErrorComponent;
    }
  }, [status, LoadingComponent, ErrorComponent, ViewComponent]);

  useEffect(() => {
    readHandler();
  }, [readHandler]);

  return (
    <RuddContext.Provider value={value}>
      <RenderedComponent />
    </RuddContext.Provider>
  );
};

RuddComponent.propTypes = {
  /**
   * @param {string} id - The identifier used when requesting from the API. This is passed to the
   * onRead and onUpdate functions.
   */
  id: PropTypes.string.isRequired,

  /**
   * @param {(id: string) => Promise<any>} onRead - A function which takes an id and returns a
   * promise. The resolved value will become 'data' on the state object.
   */
  onRead: PropTypes.func.isRequired,

  /**
   *  @param {(id: string, update: T) => Promise<T>} [onUpdate] - A function which takes an id and data
   * object and returns a promise. The resolved value will become 'data' on the state object.
   */
  onUpdate: PropTypes.func,

  /**
   * @param {React.ComponentType} LoadingComponent - A React component displayed when the onRead request
   * is inflight.
   */
  LoadingComponent: PropTypes.elementType.isRequired,

  /**
   * @param {React.ComponentType} ErrorComponent - A React component displayed when the onRead request
   * has failed.
   */
  ErrorComponent: PropTypes.elementType.isRequired,

  /**
   * @param {React.ComponentType} ViewComponent - A React component displayed when the onRead has succeeded,
   * and during any status of the update process.
   */
  ViewComponent: PropTypes.elementType.isRequired,

  /**
   * @param {(response: any) => void} [onReadSuccess] - A side effect function called when the read
   * request succeeds.
   */
  onReadSuccess: PropTypes.func,

  /**
   * @param {(error: Error) => void} [onReadFailure] - A side effect function called when the read
   * request fails.
   */
  onReadFailure: PropTypes.func,

  /**
   * @param {(response: any) => void} [onUpdateSuccess] - A side effect function called when the
   * update request succeeds.
   */
  onUpdateSuccess: PropTypes.func,

  /**
   * @param {(error: Error) => void} [onUpdateFailure] - A side effect function called when the
   * update request fails.
   */
  onUpdateFailure: PropTypes.func,

  /**
   * @param {object} extraProps - An object of props to be based through the RuddComponent and used.
   */
  extraProps: PropTypes.shape({}),
};

RuddComponent.defaultProps = {
  onUpdate: undefined,
  onReadSuccess: noop,
  onReadFailure: noop,
  onUpdateSuccess: noop,
  onUpdateFailure: noop,
  extraProps: undefined,
};

export default RuddComponent;
