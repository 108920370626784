import {
  getValidVin,
  updateAsset,
  getSignedUrlUploadedAsset,
  uploadAssetImg,
} from '../services/assets';

export const updateInputs = [
  'year',
  'make',
  'model',
  'trim',
  'series',
  'vehicleType',
  'vin_decoded_data',
  'curbWeightLb',
  'engineCylinder',
  'fuelType',
  'bodyType',
  'door',
  'seat',
  'drivelineType',
  'engineType',
  'vehicleClass',
  'assetCategory',
];

const createUpdateValueFn = (setFieldTouched, setFieldValue) => (
  name,
  value,
  isTouched
) => {
  if (!isTouched) {
    setFieldTouched(name);
  }
  setFieldValue(name, value);
};

const isEmptyInput = string => {
  return string === '';
};

const updateValues = (data, updateValue) => {
  updateInputs.forEach(d => {
    if (data[d]) {
      if (d === 'vin_decoded_data') {
        try {
          return updateValue(d, JSON.parse(data[d]));
        } catch (err) {
          return updateValue(d, data[d]);
        }
      }
      return updateValue(d, data[d]);
    }
    return updateValue(d, '');
  });
};

const createAssetNo = setFieldValue => (vin, asset) => {
  if (asset.length > 0) {
    return;
  }
  // const assetNo = vin.slice(-8);
  const assetNo = ''; // Delegate to API
  setFieldValue('assetNo', assetNo);
};

export const dupeCheck = (vinN, assetN) => {
  try {
    return getValidVin({
      vin: vinN.toUpperCase() || '',
      assetNo: assetN.toUpperCase() || '',
    });
  } catch (e) {
    throw e;
  }
};

const handleSubmitErrors = (errors, setFieldError) => {
  const newPairs = Object.keys(errors).map(key => {
    return [key, errors[key].toString()];
  });
  newPairs.map(error => {
    return setFieldError(error[0], error[1]);
  });
};

const handleNumbers = content => {
  if (content) {
    return Number(content);
  }
  return content;
};

const createAssetData = (values, tree, isVinDecoded, extraData = {}) => {
  let level = null;
  let level1;
  let level2;
  let level3;
  if (values.hierarchyLevel1) {
    level1 = tree.filter(node => node.node_label === values.hierarchyLevel1);
    level = level1[0].id;
  }
  if (values.hierarchyLevel2 && level1) {
    level2 = level1[0].children.filter(
      child => child.node_label === values.hierarchyLevel2
    );
    level = level2[0].id;
  }
  if (values.hierarchyLevel3 && level2) {
    level3 = level2[0].children.filter(
      child => child.node_label === values.hierarchyLevel3
    );
    level = level3[0].id;
  }

  let aStatus;
  if (values.assetStatusInput) {
    aStatus = values.assetStatusInput;
  } else if (values.assetStatusSelect) {
    aStatus = values.assetStatusSelect;
  }

  let savedOdometer;
  if (!extraData.hasTelemetry) {
    savedOdometer = Number(values.odometer) || null;
  }

  return {
    ...extraData,
    ...updateInputs.reduce((acc, key) => {
      acc[key] = values[key] || null;
      return acc;
    }, {}),
    assetNo: values.assetNo,
    vin: values.vinNo || null,
    licensePlate: values.tagNumber.toUpperCase() || null,
    licenseState: values.tagState || null,
    registrationRenewDate: values.renewalDate || null,
    active: true,
    customerAssetStatus: aStatus || null,
    customerAdditionalStatus: values.customerAdditionalStatus,
    exteriorColor: values.colorExt || null,
    interiorColor: values.colorInt || null,
    orgNodeId: level,
    vinDecoded: isVinDecoded,
    thirdPartyAssetId: values.assetNo,
    latestOdometer: savedOdometer,
    vinDecodedData: !values.vin_decoded_data ? null : values.vin_decoded_data,
    vin_decoded_data: undefined,
    thirdPartyClientCode: 'SZA1',
    thirdPartyDataSource: 'Ari',
    stockNo: values.vinNo && values.vinNo.substr(values.vinNo.length - 8),
    thirdPartyLeaseType: '4',
    thirdPartyLeaseTypeDesc: 'Non Ari',
    excludeServices: values.excludeServices,
  };
};

const fetchDecodeVin = async (
  updateValue,
  vin,
  assetNo,
  generateAssetNo,
  dispatch,
  decodeVin,
  yearInput
) => {
  dispatch({ type: 'DECODING_VIN' });
  const decodedData = await decodeVin(vin);
  if (
    decodedData.payload.error &&
    decodedData.payload.error === 'Invalid VIN'
  ) {
    dispatch({ type: 'INVALID_VIN' });
    generateAssetNo(vin, assetNo);
    yearInput.current.focus();
    return;
  }
  if (decodedData.payload.error) {
    dispatch({
      type: 'VIN_DECODE_ERROR',
      message: decodedData.payload.error,
    });
  }
  updateValues(decodedData.payload, updateValue);
  generateAssetNo(vin, assetNo);
  dispatch({ type: 'VIN_DECODE_SUCCESS' });
};

const getSignedUrl = async file => {
  const { type, name } = file;
  const response = await getSignedUrlUploadedAsset(type, name);
  return response?.payload;
};

const uploadFile = async (file, uploadUrl) => {
  const { type } = file; // mime type is okay as is, I think.
  await uploadAssetImg(uploadUrl, file, type);
};

const imageUploadAndSave = async (data, image, errorToast) => {
  const { id, assetNo, thirdPartyAssetId } = data;
  let results;
  try {
    results = await getSignedUrl(image);
  } catch (err) {
    errorToast(err[0]?.message);
    return true;
  }
  const { uploadUrl, downloadUrl } = results;
  try {
    await uploadFile(image, uploadUrl);
  } catch (err) {
    errorToast(err[0]?.message);
    return true;
  }

  // taking name from just uploaded image and sending it to db
  const imageData = {
    id,
    assetNo: assetNo.toUpperCase(),
    thirdPartyAssetId,
    photoUrls: [downloadUrl],
  };
  await updateAsset(imageData).catch(err => errorToast(err[0]?.message));
};

// For Update Page
const updatePageUpdateValues = (data, updateValue) => {
  updateInputs.forEach(d => {
    if (data[d]) {
      return updateValue(d, data[d]);
    }
    return updateValue(d, '');
  });
};

const defaultEmpty = val => {
  if (val) {
    return val;
  }
  return '';
};

const checkForTelemetry = services => {
  return services.some(s => s.name === 'telematics');
};

const AddAssetHelpers = {
  createUpdateValueFn,
  isEmptyInput,
  updateValues,
  createAssetNo,
  dupeCheck,
  handleSubmitErrors,
  handleNumbers,
  createAssetData,
  updateInputs,
  fetchDecodeVin,
  imageUploadAndSave,
  updatePageUpdateValues,
  defaultEmpty,
  checkForTelemetry,
};

export default AddAssetHelpers;
