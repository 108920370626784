import React from 'react';
import clx from 'classnames';
import { useSelector } from '@holmanfm/lib/redux';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import { useNSTranslation } from '@holmanfm/lib/lang';
import makeStyles from '~/shared/components/makeStyles';
import Link from '~/shared/components/link';

const useStyles = makeStyles(theme => ({
  iosFooter: {
    paddingBottom: theme.spacing(3),
  },
  pageFooter: {
    color: '#fff',
    backgroundColor: '#9E9E9E',
    display: 'flex',
    padding: theme.spacing(1, 2),
    justifyContent: 'space-between',
  },
  legalContainer: {
    flex: 1,
    textAlign: 'right',
  },
  legal: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1.5),
  },
}));

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const year = new Date().getFullYear();

const Footer = () => {
  const classes = useStyles();
  const org = useSelector(selectors.getOrg);
  const showPoweredByFooter = org?.data?.organizationType === 'Dealer';
  const { t } = useNSTranslation('main', 'login');
  const { t: tLegal } = useNSTranslation('main', 'legal');
  return (
    <footer className={clx(classes.pageFooter, { [classes.iosFooter]: isIOS })}>
      <div style={{ fontSize: '12.6875px', flex: 1 }}>
        &copy; {year}{' '}
        {showPoweredByFooter
          ? `${t('powered-by')} ${t('brand')}`
          : t('brand-global')}{' '}
      </div>
      <div className={classes.legalContainer}>
        <Link
          data-qa-id="terms-of-service-link"
          href="/terms-of-use"
          target="_blank"
          className={classes.legal}
        >
          {tLegal('terms-of-use')}
        </Link>
        <Link
          data-qa-id="privacy-policy-link"
          href="/privacy-policy"
          target="_blank"
          className={classes.legal}
        >
          {tLegal('privacy-policy')}
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
