import React from 'react';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import clx from 'classnames';
import Box from '~/shared/components/box';
import makeStyles from '~/shared/components/makeStyles';
import SubmitButton from '~/shared/components/forms/submit-button';
import ResetButton from '~/shared/components/forms/reset-button';

const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(8),
    '& button': {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: '45%',
        margin: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      justifyContent: 'space-between',
    },
  },
}));

const SaveButtons = props => {
  const classes = useStyles();
  const { disableSubmit, handleSubmit, saveClass } = props;
  const { t } = useNSTranslation('serviceRequests', 'view-update');

  return (
    <Box className={clx(classes.buttons, saveClass)}>
      <SubmitButton
        color="secondary"
        variant="contained"
        disabled={disableSubmit}
        handleSubmit={handleSubmit}
      >
        {t('button-update')}
      </SubmitButton>
      <ResetButton color="secondary" variant="outlined">
        {t('button-cancel')}
      </ResetButton>
    </Box>
  );
};

SaveButtons.propTypes = {
  disableSubmit: PropTypes.bool,
  handleSubmit: PropTypes.func,
  saveClass: PropTypes.string,
};

SaveButtons.defaultProps = {
  disableSubmit: false,
  handleSubmit: undefined,
  saveClass: '',
};

export default connect(SaveButtons);
