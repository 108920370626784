/**
 * React router Route combined with SEO (Search Engine Optimization).
 * This component should be used instead of plain Route.
 * To override some SEO tags, just render Helmet in any of the components, like so:
 * <Helmet><title>Cosmic Page</title></Helmet>
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import { useTranslation } from '@holmanfm/lib/lang';

/**
 * Determine translation key based on route id.
 * faq, news, and uploads have special handling.
 * For instance, "vehicle-acquisition-faq" => "faq"
 */
export const getTitleKeyByRouteId = id => {
  id = ['faq', 'news', 'uploads'].reduce((key, str) => {
    if (id?.endsWith(str)) {
      return str;
    }
    return key;
  }, id);

  return `nav.primary.${id}`;
};

const SEORoute = ({ component: Component, render, ...routeProps }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const title = t(getTitleKeyByRouteId(routeProps.id));
  const description = 'Holman Fleet Marketplace'; // TODO
  const defaultTitle = 'Holman Fleet Marketplace';

  return (
    <Route
      {...routeProps}
      render={props => (
        <>
          <Helmet titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}>
            <html lang={language} />
            <title>{title}</title>
            <meta name="description" content={description} />
          </Helmet>

          {render ? render() : Component && <Component {...props} />}
        </>
      )}
    />
  );
};

SEORoute.propTypes = {
  component: PropTypes.elementType,
  render: PropTypes.func,
};
SEORoute.defaultProps = {
  component: undefined,
  render: undefined,
};

export default SEORoute;
