import { getOrganizations } from '../../../services/organization';
import AuthService from '../../../util/auth.service';
import { AuthActionTypes, OrgActionTypes } from '../action-types';
import { makeActionCreator, ActionsResponseMapper } from '../util';

// %%%%%%%%%%%%%%%%%%%%%%%%%% ACTION CREATORS %%%%%%%%%%%%%%%%%%%%%.

// FETCH ORGS.
export const fetchOrgsRequest = makeActionCreator(
  OrgActionTypes.FETCH_ORGS_REQUEST
);
export const fetchOrgsSuccess = makeActionCreator(
  OrgActionTypes.FETCH_ORGS_SUCCESS,
  'orgs',
  'messages'
);
export const fetchOrgsFailure = makeActionCreator(
  OrgActionTypes.FETCH_ORGS_FAILURE,
  'errors'
);

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%% ASYNC ACTIONS %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// FETCH ORGS
export const fetchOrgs = () => ({
  actions: [fetchOrgsRequest, fetchOrgsSuccess, fetchOrgsFailure],
  callAPI: () => getOrganizations(),
  responseMapper: new ActionsResponseMapper(),
  onSuccess(response, dispatch, _, state) {
    // Covers the following edge-case:
    // Super admin uses 2 browsers simultaneously.
    // User uses ICE to switch to a different org in one browser, and then using the other browser to continue.
    // The cookie is no longer good, so we can only log the user out.
    if (!response.payload?.some(org => org.id === state.orgs?.currentOrgId)) {
      dispatch({
        type: AuthActionTypes.LOGOUT,
      });
      AuthService.logOut();
    }
  },
});
