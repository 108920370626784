const clamp = (min, max, v) => {
  if (v < min) {
    return min;
  }
  if (v > max) {
    return max;
  }

  return v;
};

export const goToPage = (page, total) => state => {
  const { skip, take } = state;
  const nextSkip = clamp(0, Math.floor(total / take) * take, (page - 1) * take);

  return skip === nextSkip ? state : { ...state, skip: nextSkip };
};

export function getPages(take, total) {
  return Math.ceil(total / take);
}

export const getPage = (skip, take) => {
  return skip === 0 ? 1 : Math.ceil(skip / take + 1);
};
