/* eslint-disable no-use-before-define */
/**
  Creates normalized state for an entity.

  Responds to the following action types (substitute ENTITY with your concrete entity):

  FETCH_ENTITY_REQUEST, FETCH_ENTITY_SUCCESS, FETCH_ENTITY_FAILURE,
  FETCH_ENTITIES_REQUEST, FETCH_ENTITIES_SUCCESS, FETCH_ENTITIES_FAILURE,
  ADD_ENTITY_REQUEST, ADD_ENTITY_SUCCESS, ADD_ENTITY_FAILURE,
  UPDATE_ENTITY_REQUEST, UPDATE_ENTITY_SUCCESS, UPDATE_ENTITY_FAILURE,
  REMOVE_ENTITY_REQUEST, REMOVE_ENTITY_SUCCESS, REMOVE_ENTITY_FAILURE,
  FETCH_PAGED_ENTITIES_REQUEST, FETCH_PAGED_ENTITIES_SUCCESS, FETCH_PAGED_ENTITIES_FAILURE,

  Enables the following selectors (substitute Entity with your concrete entity):

  getEntity, getIsFetchingEntity, getEntityMessages,
  getEntityIds, getEntities, getEntitesDict getIsFetchingEntities,
  getEntitiesMessages,

  NOTE s:
    1) when you add FETCH_ENTITIES_*, you will also have to add FETCH_ENTITY_SUCCESS -
      they are related.
*/

import pluralize from 'pluralize';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { ReduxConstants } from '../../../common/constants';

import { createByIdReducer, createByIdSelectors } from './create-by-id.reducer';
import { createAllReducer, createAllSelectors } from './create-all.reducer';
import {
  createPagedReducer,
  createPagedSelectors,
} from './create-paged.reducer';
import { mapSelectors, capitalizeFirstLetter } from './util';

export function createEntityReducer(entity, options = {}) {
  return combineReducers({
    byId: createByIdReducer(entity, options),
    all: createAllReducer(entity, options),
    paged: createPagedReducer(entity, options),
  });
}

export function createEntitySelectors(entity) {
  const byIdSelectors = createByIdSelectors(entity);
  const allSelectors = createAllSelectors(entity);
  const pagedSelectors = createPagedSelectors(entity);

  const mappedSelectors = {
    ...mapSelectors(byIdSelectors, 'byId'),
    ...mapSelectors(allSelectors, 'all'),
    ...mapSelectors(pagedSelectors, 'paged'),
  };

  const newSelectors = addSelectors(
    {
      ...mappedSelectors,
      nextLevel: { ...byIdSelectors, ...allSelectors, ...pagedSelectors },
    },
    entity
  );

  return {
    ...mappedSelectors,
    ...newSelectors,
  };
}

function addSelectors(selectors, entity) {
  const entityCap = capitalizeFirstLetter(entity);
  const pluralEntityCap = pluralize(entityCap);

  return {
    ...selectors,
    // getEntities
    [`get${pluralEntityCap}`]: createSelector(
      [selectors[`get${entityCap}Ids`], state => state.byId],
      (ids, byId) =>
        ids
          .filter(useId)
          .map(id => selectors.nextLevel[`get${entityCap}`](byId, id))
    ),

    // getEntitiesDict
    [`get${pluralEntityCap}Dict`]: state => {
      const ids = selectors[`get${entityCap}Ids`](state);
      if (ids.length <= 0) return null;

      return ids.filter(useId).reduce((dict, id) => {
        dict[id] = selectors[`get${entityCap}`](state, id);
        return dict;
      }, {});
    },
  };
}

/**
  checks if id is valid and should be returned to clients.
  @return boolean
*/
function useId(id) {
  return !id.startsWith(ReduxConstants.NEW_ID_PREFIX);
}
